// component default
import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import InfoIcon from '../../images/Info.svg'

function InformationHover({ text }) {
  return (
    <span className="information-hover-container">
      <div>
        <InfoIcon />
      </div>
      <div className="information-text">{text}</div>
    </span>
  )
}

export default InformationHover

InformationHover.propTypes = {
  text: PropTypes.string,
}
