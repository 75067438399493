import {
  Suspense,
  ComponentType,
  memo,
  LazyExoticComponent,
  ComponentProps,
} from 'react'
import CenteredSpinner from './CenteredSpinner'

export type LazyComponentProps<
  T extends LazyExoticComponent<ComponentType<any>>,
> = {
  Component: T
  componentProps?: ComponentProps<T>
}

export const LazyComponent = memo(function LazyComponent<
  T extends LazyExoticComponent<ComponentType<any>>,
>({ Component, componentProps }: LazyComponentProps<T>) {
  return (
    <Suspense
      fallback={<CenteredSpinner circularProgressProps={{ size: 50 }} />}
    >
      {/** @ts-ignore*/}
      <Component {...componentProps} />
    </Suspense>
  )
})
