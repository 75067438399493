export const clearStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export function getRoleId() {
  return localStorage.getItem('roleId')
}

export function setRoleId(roleId) {
  localStorage.setItem('roleId', roleId)
}

export function getUserId() {
  return localStorage.getItem('userId')
}

export function setUserId(userId) {
  localStorage.setItem('userId', userId)
}

export function getProvider() {
  return JSON.parse(localStorage.getItem('user'))
}

export function setProvider(user) {
  localStorage.setItem('user', JSON.stringify(user))
}

export function getEntityId() {
  return localStorage.getItem('ssoId')
}

export function setEntityId(ssoId) {
  localStorage.setItem('ssoId', ssoId)
}

export function removeCallDetails() {
  localStorage.removeItem('callDetails')
}

export function setCaseloadMask(mask) {
  localStorage.setItem('caseloadMasks', JSON.stringify(mask))
}

export function getCaseloadMask() {
  const value = localStorage.getItem('caseloadMasks')
  if (value) return JSON.parse(value)
  return null
}
