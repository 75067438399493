import { ServiceCategoryCode } from '@valerahealth/rtk-query'
import {
  Divider,
  MenuItem,
  SingleSelect,
  Stack,
} from '@valerahealth/ui-components'
import LABELS from '@valerahealth/ui-translation/locales/en'
import {
  AddAppointmentView,
  EditAppointmentView,
  useReduxDispatch,
  actions,
} from '../reducer'
import {
  getApptViewProviderId,
  getApptViewServiceCategory,
  isAdminAppointmentFormView,
  isPatientAppointmentFormView,
  isPatientApptServiceCategory,
} from '../utilities'
import { ADMINISTRATIVE_APPOINTMENTS_SET } from '../utilities/constants'
import AddEditAdminAppt from './AddEditAdminAppt'
import { AddEditPatientAppointment } from './AddEditPatientAppointment'

const options = [
  ServiceCategoryCode.Patient,
  ...ADMINISTRATIVE_APPOINTMENTS_SET.values(),
]

export type AddEditAppointmentProps = {
  view: AddAppointmentView | EditAppointmentView
}
export function AddEditAppointment({ view }: AddEditAppointmentProps) {
  const dispatch = useReduxDispatch()
  const serviceCategoryCode = getApptViewServiceCategory(view)

  const { type, mode } = view

  const providerId = getApptViewProviderId(view)

  // Can only switch to admin appointments if you have a provider id
  return (
    <Stack flexGrow="1">
      {mode === 'add' && providerId && (
        <>
          <SingleSelect
            sx={{ m: 2 }}
            fullWidth
            value={serviceCategoryCode}
            onChange={(code) => {
              if (isPatientApptServiceCategory(code)) {
                dispatch(
                  actions.openView({
                    type,
                    mode,
                    code,
                    initialState: {
                      providerId,
                    },
                  }),
                )
              } else {
                dispatch(
                  actions.openView({
                    type,
                    mode,
                    code,
                    providerId,
                  }),
                )
              }
            }}
            clearable={false}
          >
            {options.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {LABELS.ServiceCategoryCodeAppointment[option]}
                </MenuItem>
              )
            })}
          </SingleSelect>
          <Divider />
        </>
      )}
      <Stack flexGrow="1">
        {isPatientAppointmentFormView(view) && (
          <AddEditPatientAppointment view={view} />
        )}

        {isAdminAppointmentFormView(view) && <AddEditAdminAppt view={view} />}
      </Stack>
    </Stack>
  )
}
