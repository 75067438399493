import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import WarningIcon from '../../images/demographics.svg'

function WarningInfo({ message, red }) {
  return (
    <div className={`warning-info ${red ? 'red-info' : ''}`}>
      <span className="icon">
        <WarningIcon />
      </span>
      <span className="text">{message}</span>
    </div>
  )
}

WarningInfo.propTypes = {
  message: PropTypes.string,
  red: PropTypes.bool,
}

export default WarningInfo
