/* eslint no-unused-vars: "off" */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { format } from '@valerahealth/ui-components/utils/date'
import EyeIcon from '../../../../../../images/eye.svg'
import './style.css'
import {
  getPlans,
  getPlanTemplateIcon,
} from '../../../../../utilities/planTemplate'
import { editItem } from '../../../../../../redux/actions/globalActions'
import PlanTimelineItemDetails from '../../../../../common/Timeline/PlanTimelineItemDetails'
import TreatmentTimelineItemDetails from '../../../../../common/Timeline/TreatmentTimelineItemDetails'
import { dateFormatEnum } from '../../../../../utilities/enums'

function TimelineItem({
  state,
  timelineElement,
  toolsTemplates,
  plans,
  editItem,
  planTemplates,
}) {
  let type
  let ToolIcon
  let toolTemplate
  let showEye
  let contextType
  let contextName

  if (timelineElement.item && timelineElement.item.context) {
    const { context } = timelineElement.item

    if (context.type !== 'CareManager') {
      if (plans && plans[context.id]) {
        contextType = plans[context.id].type
        contextName = plans[context.id].name
      } else if (planTemplates && planTemplates[context.id]) {
        contextType = planTemplates[context.id].type
        contextName = planTemplates[context.id].name
      }
    } else {
      contextType = context.type
      contextName = context.name
    }
  }

  if (state !== 'treatment') {
    type = timelineElement.item.type
    ToolIcon = toolsTemplates[type] && toolsTemplates[type].icon
    toolTemplate = toolsTemplates[type]
    showEye = toolTemplate.canBeView
  }

  return (
    <div className="timeline-item-element">
      <div className="timeline-item">
        <div className="timeline-item-left">
          <div className="timeline-item-time">
            {format(new Date(timelineElement.ts), dateFormatEnum.FULL_TIME)}
          </div>
          {timelineElement?.item?.context && contextName && (
            <div className="context-container xsmall-font">
              {contextType && contextType !== 'CareManager' && (
                <div className="context-icon">
                  {getPlanTemplateIcon(contextType)}
                </div>
              )}
              <div title={contextName} className="context-name">
                {contextName}
              </div>
            </div>
          )}
        </div>
        {state !== 'treatment' ? (
          <PlanTimelineItemDetails
            ToolIcon={ToolIcon}
            toolsTemplates={toolsTemplates}
            timelineElement={timelineElement}
            plans={plans}
          />
        ) : (
          <TreatmentTimelineItemDetails
            timelineElement={timelineElement}
            toolsTemplates={toolsTemplates}
          />
        )}
        {showEye && (
          <div className="timeline-item-view">
            <EyeIcon
              onClick={() => {
                editItem(timelineElement.item)
              }}
            />
          </div>
        )}
      </div>
      <div className="cursor-ball" />
    </div>
  )
}

function mapStateToProps(state, ownProp) {
  const plans = getPlans(state)

  return {
    ...ownProp,
    toolsTemplates: state.toolsTemplates,
    planTemplates: state.planTemplates && state.planTemplates.byId,
    plans: plans && plans.byId,
  }
}

const mapDispatchToProps = {
  editItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineItem)
