import { Popover } from '@valerahealth/ui-components'
import { useState } from 'react'
import { AppointmentDetails } from './AppointmentDetails'
import { AppointmentEventType } from './Calendar.type'

export function useAppointmentDetailsPopup(event: AppointmentEventType) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  return {
    anchorEl,
    setAnchorEl,
    AppointmentDetailsPopup: (
      <Popover
        onClose={() => {
          setAnchorEl(null)
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: 'none',
              backgroundColor: 'transparent',
              overflow: 'visible',
            },
          },
        }}
      >
        <AppointmentDetails event={event} onClose={() => setAnchorEl(null)} />
      </Popover>
    ),
  }
}
