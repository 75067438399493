import { useTranslation as baseUseTranslation } from '@valerahealth/ui-translation'
import _en from './en'

export const NOTES_NAMESPACE = 'notes'

export const en = { [NOTES_NAMESPACE]: _en }

/** DONT EXPORT IN PACKAGE. THIS IS INTERNAL ONLY */
export const useTranslation = () => {
  // forced to use its own namespace
  return baseUseTranslation(NOTES_NAMESPACE)
}
