import { useCallback, useMemo } from 'react'
import {
  ClinicalAges,
  CMPractitionerAggregates,
  CommunityIdentity,
  DayOfWeek,
  Ethnicity,
  GenderIdentity,
  InsurancePlanFull,
  LegalSex,
  LocationFragment,
  PatientPreferencesField as Pref,
  PatientPreferenceValue,
  ProviderServiceType,
  Race,
  DayPart,
  TaskServiceType,
} from '@valerahealth/rtk-query'
import { ValuesType } from 'utility-types'
import { ButtonLink } from '../base'
import {
  getClinicalAgesOptions,
  getCommunityIdentityOptions,
  getDayOfWeekOptions,
  getDayPartOptions,
  getEthnicityOptions,
  getGenderIdentityOptions,
  getLegalSexOptions,
  getPatientPreferenceDurationOptions,
  getPatientPreferenceTimeOptions,
  getProviderServiceTypeOptions,
  getRaceOptions,
  IValueOption,
} from '../grid/utils/valueOptions'
import {
  GridFilterItem,
  GridInitialState,
  GridLogicOperator,
  GridViewURLParamsKey,
  OperatorValueNumeric,
  OperatorValueSingleSelect,
} from '../grid'

export const AvailabilityColumns = {
  dayOfWeek: 'dayOfWeek',
  time: 'time', // virtual field
  duration: 'durationMinutes',
  dayPart: 'daypart', // virtual field
  serviceType: 'provider.serviceType',
  locations: 'locations',
  insurancePlan: 'provider.insurancePlans',
  legalSex: 'provider.legalSex',
  genderIdentity: 'provider.genderIdentity',
  race: 'provider.race',
  ethnicity: 'provider.ethnicity',
  languages: 'provider.languages',
  spirituality: 'provider.spirituality',
  clinicalAges: 'provider.clinicalAges',
  clinicalSpecialties: 'provider.clinicalSpecialties',
  clinicalModalities: 'provider.clinicalModalities',
  communityIdentity: 'provider.communityIdentity',
} as const

export type ColumnFilterModelMap = {
  [AvailabilityColumns.serviceType]: ProviderServiceType[]
  [AvailabilityColumns.insurancePlan]: string[]
  [AvailabilityColumns.locations]: string[]
  [AvailabilityColumns.legalSex]: LegalSex[]
  [AvailabilityColumns.genderIdentity]: GenderIdentity[]
  [AvailabilityColumns.race]: Race[]
  [AvailabilityColumns.ethnicity]: Ethnicity[]
  [AvailabilityColumns.languages]: string[]
  [AvailabilityColumns.spirituality]: string[]
  [AvailabilityColumns.clinicalAges]: ClinicalAges
  [AvailabilityColumns.clinicalSpecialties]: string[]
  [AvailabilityColumns.clinicalModalities]: string[]
  [AvailabilityColumns.communityIdentity]: CommunityIdentity[]
  [AvailabilityColumns.dayOfWeek]: DayOfWeek[]
  [AvailabilityColumns.time]: string
  [AvailabilityColumns.duration]: number
  [AvailabilityColumns.dayPart]: DayPart[]
}

export const PATIENT_PREFERENCE_TO_AVAILABILITY = {
  [Pref.serviceType]: AvailabilityColumns.serviceType,
  [Pref.insurancePlan]: AvailabilityColumns.insurancePlan,
  [Pref.locations]: AvailabilityColumns.locations,
  [Pref.legalSex]: AvailabilityColumns.legalSex,
  [Pref.genderIdentity]: AvailabilityColumns.genderIdentity,
  [Pref.race]: AvailabilityColumns.race,
  [Pref.ethnicity]: AvailabilityColumns.ethnicity,
  [Pref.languages]: AvailabilityColumns.languages,
  [Pref.spirituality]: AvailabilityColumns.spirituality,
  [Pref.clinicalAges]: AvailabilityColumns.clinicalAges,
  [Pref.clinicalSpecialties]: AvailabilityColumns.clinicalSpecialties,
  [Pref.clinicalModalities]: AvailabilityColumns.clinicalModalities,
  [Pref.communityIdentity]: AvailabilityColumns.communityIdentity,
  [Pref.dayOfWeek]: AvailabilityColumns.dayOfWeek,
  [Pref.time]: AvailabilityColumns.time,
  [Pref.duration]: AvailabilityColumns.duration,
  [Pref.dayPart]: AvailabilityColumns.dayPart,
}

export const COLUMN_TO_OPERATOR = {
  [AvailabilityColumns.serviceType]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.insurancePlan]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.locations]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.legalSex]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.genderIdentity]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.race]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.ethnicity]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.languages]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.spirituality]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.clinicalAges]: OperatorValueSingleSelect.is,
  [AvailabilityColumns.clinicalSpecialties]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.clinicalModalities]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.communityIdentity]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.dayOfWeek]: OperatorValueSingleSelect.isAnyOf,
  [AvailabilityColumns.time]: '', // Custom Implementation in grid
  [AvailabilityColumns.duration]: OperatorValueNumeric.gte,
  [AvailabilityColumns.dayPart]: '', // Custom Implementation in grid
}

type InsurancePlanPiece = Pick<InsurancePlanFull, 'stateCode' | 'name'>
export const getInsuranceCellString = (insurancePlan: InsurancePlanPiece) =>
  `${insurancePlan.stateCode} - ${insurancePlan.name}`

export enum GridViewNames {
  PRIORITY_PATIENT_MATCHING = 'Priority Patient Matching',
}

export const usePatientPreferenceValueOptions = (
  aggregates: CMPractitionerAggregates | undefined | null,
  locations: Pick<LocationFragment, 'id' | 'name'>[] | undefined | null,
  insurancePlans:
    | Pick<InsurancePlanFull, '_id' | 'stateCode' | 'name'>[]
    | undefined
    | null,
) => {
  const map = useMemo(() => {
    return {
      [Pref.clinicalAges]: getClinicalAgesOptions(),
      [Pref.languages]:
        aggregates?.languages.map(
          (value): IValueOption => ({ label: value, value }),
        ) || [],
      [Pref.clinicalSpecialties]:
        aggregates?.specialities.map(
          (value): IValueOption => ({ label: value, value }),
        ) || [],
      [Pref.clinicalModalities]:
        aggregates?.modalities.map(
          (value): IValueOption => ({ label: value, value }),
        ) || [],
      [Pref.spirituality]:
        aggregates?.spirituality.map(
          (value): IValueOption => ({ label: value, value }),
        ) || [],
      [Pref.insurancePlan]:
        insurancePlans?.map(({ _id, ...rest }) => ({
          value: _id,
          label: getInsuranceCellString(rest),
        })) || [],
      [Pref.locations]:
        locations?.map((v) => ({
          value: v.id,
          label: v.name,
        })) || [],
      [Pref.genderIdentity]: getGenderIdentityOptions(),
      [Pref.race]: getRaceOptions(),
      [Pref.ethnicity]: getEthnicityOptions(),
      [Pref.serviceType]: getProviderServiceTypeOptions(),
      [Pref.legalSex]: getLegalSexOptions(),
      [Pref.communityIdentity]: getCommunityIdentityOptions(),
      [Pref.dayOfWeek]: getDayOfWeekOptions(),
      [Pref.time]: getPatientPreferenceTimeOptions(),
      [Pref.dayPart]: getDayPartOptions(),
      [Pref.duration]: getPatientPreferenceDurationOptions(),
    }
  }, [aggregates, locations, insurancePlans])

  const labels = useMemo(() => {
    return Object.fromEntries(
      Object.entries(map).map(([key, values]) => {
        return [
          key,
          Object.fromEntries(values.map(({ value, label }) => [value, label])),
        ] as [keyof typeof map, Record<string, string | number>]
      }),
    )
  }, [map]) as Record<keyof typeof map, Record<string, string>>

  const getLabel = useCallback(
    (key: (typeof Pref)[keyof typeof Pref], value: string | number) => {
      return labels[key]?.[value] || ''
    },
    [labels],
  )

  return {
    map,
    labels,
    getLabel,
  }
}

const patientPreferenceToAvailabilityColumn = (
  patientPreferences: PatientPreferenceValue,
): Partial<ColumnFilterModelMap> => ({
  [AvailabilityColumns.serviceType]: patientPreferences[Pref.serviceType],
  [AvailabilityColumns.insurancePlan]: patientPreferences[Pref.insurancePlan],
  [AvailabilityColumns.locations]: patientPreferences[Pref.locations],
  [AvailabilityColumns.legalSex]: patientPreferences[Pref.legalSex],
  [AvailabilityColumns.genderIdentity]: patientPreferences[Pref.genderIdentity],
  [AvailabilityColumns.race]: patientPreferences[Pref.race],
  [AvailabilityColumns.ethnicity]: patientPreferences[Pref.ethnicity],
  [AvailabilityColumns.languages]: patientPreferences[Pref.languages],
  [AvailabilityColumns.spirituality]: patientPreferences[Pref.spirituality],
  [AvailabilityColumns.clinicalAges]:
    patientPreferences[Pref.clinicalAges]?.[0],
  [AvailabilityColumns.clinicalSpecialties]:
    patientPreferences[Pref.clinicalSpecialties],
  [AvailabilityColumns.clinicalModalities]:
    patientPreferences[Pref.clinicalModalities],
  [AvailabilityColumns.communityIdentity]:
    patientPreferences[Pref.communityIdentity],
  [AvailabilityColumns.dayOfWeek]: patientPreferences[Pref.dayOfWeek],
  [AvailabilityColumns.time]: patientPreferences[Pref.time]?.[0],
  [AvailabilityColumns.duration]: patientPreferences[Pref.duration]?.[0],
  [AvailabilityColumns.dayPart]: patientPreferences[Pref.dayPart],
})

const getSearchUrl = (filterItems?: GridFilterItem[]) => {
  const params = new URLSearchParams()
  if (filterItems?.length) {
    const initialState: GridInitialState = {
      filter: {
        filterModel: {
          logicOperator: GridLogicOperator.And,
          items: filterItems,
        },
      },
    }
    params.append(
      GridViewURLParamsKey.InitialState,
      JSON.stringify(initialState),
    )
  }

  return `https://${
    process.env.PRACTICE_MANAGER_UI_DOMAIN
  }/scheduling?${params.toString()}`
}

export const SearchAvailability = ({
  patientPreferences,
  serviceType,
}: {
  patientPreferences: PatientPreferenceValue
  serviceType?: TaskServiceType | null
}) => {
  const link = useMemo(() => {
    const filterItems = Object.entries(
      patientPreferenceToAvailabilityColumn(patientPreferences),
    )
      .filter((v) => !!v[1])
      .map(
        ([key, value]): GridFilterItem => ({
          field: key,
          operator:
            COLUMN_TO_OPERATOR[key as ValuesType<typeof AvailabilityColumns>],
          value,
        }),
      )
    if (serviceType) {
      filterItems.push({
        field: AvailabilityColumns.serviceType,
        operator: COLUMN_TO_OPERATOR[AvailabilityColumns.serviceType],
        value: [serviceType],
      })
    }
    return getSearchUrl(filterItems)
  }, [patientPreferences, serviceType])

  return (
    <ButtonLink target="_blank" to={link} size="small" variant="text">
      Search Providers
    </ButtonLink>
  )
}
