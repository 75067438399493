import CONFIG, { getHeader, handleResponse, handleError } from './apiUtils'

const { signal } = CONFIG

const baseUrl = `https://${process.env.CARE_MANAGER_API_DOMAIN}/api`

export const getPlan = (id) => {
  return fetch(`${baseUrl}/plan/${id}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getPlans = () => {
  return fetch(`${baseUrl}/plan`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const createPlan = (plan) => {
  return fetch(`${baseUrl}/plan`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(plan),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const updatePlan = (plan) => {
  return fetch(`${baseUrl}/plan/${plan.id}`, {
    method: 'PUT',
    signal,
    headers: getHeader(),
    body: JSON.stringify({
      name: plan.name,
      description: plan.description,
    }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const deleteTracks = (id) => {
  return fetch(`${baseUrl}/plan/${id}?force=true`, {
    method: 'DELETE',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const createTrack = (planId, tracks) => {
  return fetch(`${baseUrl}/plan/${planId}/track`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(tracks),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const updateTracks = (planId, tracks) => {
  return fetch(`${baseUrl}/plan/${planId}/track`, {
    method: 'PUT',
    signal,
    headers: getHeader(),
    body: JSON.stringify(tracks),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const deleteTrack = (planId, trackId) => {
  return fetch(`${baseUrl}/plan/${planId}/track/${trackId}`, {
    method: 'DELETE',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getPlanTimeline = (id) => {
  return fetch(`${baseUrl}/plan/${id}/timeline`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const stopPlan = (id) => {
  return fetch(`${baseUrl}/plan/${id}/stop`, {
    method: 'POST',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const startPlan = (id) => {
  return fetch(`${baseUrl}/plan/${id}/start`, {
    method: 'POST',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getAssignmentCount = (id) => {
  return fetch(`${baseUrl}/plan/${id}/assignment/count`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getAssignmentPatients = (id) => {
  return fetch(`${baseUrl}/plan/${id}/treatments`, {
    method: 'GET',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}
