import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

function OptionsMenuList({ items, openOn, handleClose }) {
  return (
    <div className={`options-menu ${openOn}-direction`}>
      {items.map((item, i) => {
        return (
          <div
            className={`option-item ${!item.class ? 'small-font' : item.class}${
              !item.border || i === items.length - 1 // no border for last item
                ? ''
                : ' option-item-border'
            }`}
            style={{
              color: !item.isRed ? '' : '#D24667',
            }}
            key={i}
            onClick={(e) => {
              item.onclick(e)
              handleClose()
            }}
          >
            {item.icon && <span className="option-item-icon">{item.icon}</span>}
            {item.title}
            {item.endIcon && (
              <span className="option-item-end-icon">{item.endIcon}</span>
            )}
          </div>
        )
      })}
    </div>
  )
}

OptionsMenuList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      isRed: PropTypes.bool,
      icon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.string,
      ]),
      endIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      class: PropTypes.string,
    }),
  ),
  openOn: PropTypes.string,
  handleClose: PropTypes.func,
}

export default OptionsMenuList
