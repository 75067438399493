// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-input-field-wrap{
  height: 36px;
  border: 1px dashed #D4D4D4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
  cursor: pointer;

}
.add-input-field-wrap.disable-add{
  cursor: not-allowed;
}
.add-input-field-wrap div{
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/AddInputField/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,eAAe;;AAEjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".add-input-field-wrap{\n  height: 36px;\n  border: 1px dashed #D4D4D4;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 0 10px;\n  background-color: #ffffff;\n  cursor: pointer;\n\n}\n.add-input-field-wrap.disable-add{\n  cursor: not-allowed;\n}\n.add-input-field-wrap div{\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
