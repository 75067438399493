import { useTranslation } from '@valerahealth/ui-translation'
import { careManagerApi } from '@valerahealth/rtk-query'
import {
  programIdSelector,
  useReduxSelectorWithAuthState,
} from '@valerahealth/redux-auth'
import { Button, CircularProgress, Modal, Stack } from '../../base'
import { useNotify } from '../Notifications'
import { FormProvider, TextField, useForm } from '../../form'

const { useAddProgramTagMutation } = careManagerApi

export default function CreateProgramTagDialog({
  value,
  onValueAdded,
  onCancel,
}: {
  value: string
  onValueAdded: (value: string) => void
  onCancel: () => void
}) {
  const programId = useReduxSelectorWithAuthState(programIdSelector)
  const methods = useForm<{ tag: string }>({
    values: { tag: value },
  })

  const [t] = useTranslation()
  const notify = useNotify()

  const [createTag, createTagStatus] = useAddProgramTagMutation()

  const onFormSubmit = async ({ tag }: { tag: string }) => {
    const res = await createTag({
      programId,
      body: { tag },
    })

    if ('error' in res) {
      const message = 'data' in res.error && res.error.data?.message

      notify({
        message:
          message === 'tag_already_exist'
            ? `A tag for "${tag}" already exists.`
            : message || 'There was an error creating a Tag.',
        severity: 'error',
      })
      return false
    }

    notify({
      message: 'Tag successfully added.',
      severity: 'success',
    })

    onValueAdded(tag)
  }

  const { isLoading } = createTagStatus

  return (
    <Modal
      open
      onClose={() => {
        if (!isLoading) onCancel()
      }}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      title="Add Tag"
    >
      <FormProvider {...methods}>
        <Stack
          component="form"
          sx={{ pt: 1, gap: 2 }}
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            methods.handleSubmit(onFormSubmit)(e)
          }}
        >
          <TextField name="tag" label="Tag Name" fullWidth required />
          <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
            <Button
              variant="text"
              size="large"
              color="inherit"
              disabled={isLoading}
              onClick={() => onCancel()}
            >
              {t('cancel')}
            </Button>
            <Button
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : undefined
              }
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              {t('save')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </Modal>
  )
}
