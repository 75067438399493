import { Stack } from '@valerahealth/ui-components/base'
import {
  ControlledTextField as TextField,
  Combobox,
} from '@valerahealth/ui-components/form'
import { TemplateNote } from '@valerahealth/rtk-query'
import { useTranslation } from '../locales'

export default function NoteDetails({
  templates = [],
  isLoading,
}: {
  templates?: TemplateNote[]
  isLoading: boolean
}) {
  const { t } = useTranslation()
  return (
    <Stack gap={2} alignContent="stretch">
      <TextField
        label={t('title')}
        placeholder={t('title')}
        required
        fullWidth
        variant="standard"
        name="title"
      />
      <Combobox
        label={t('templates')}
        name="templates"
        multiple
        fullWidth
        options={templates}
        getOptionLabel={(t) => {
          return t.name
        }}
        loading={isLoading}
        getOptionKey={(t) => t.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Stack>
  )
}
