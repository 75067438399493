import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import {
  Paper,
  debounce,
  useNotify,
  Typography,
  Stack,
} from '@valerahealth/ui-components'
import { FormProvider, useForm } from '@valerahealth/ui-components/form'
import {
  type AnswerOnFieldData,
  type ProgressNote,
  type TemplateNote as TemplateNoteType,
} from '@valerahealth/rtk-query'
import { noteApi } from '@valerahealth/rtk-query'
import NoteSection from './NoteSection'
import { type TemplateFormType, templateToForm } from './utilities'
import { useTranslation } from '../locales'

interface NoteTemplateProps {
  template: TemplateNoteType
  note: Pick<ProgressNote, 'saveSessionId' | 'id' | 'sections'>
  treatmentId: string
  disabled?: boolean
}

type FieldChange = AnswerOnFieldData & {
  treatmentId: string
  noteId: string
}

// handles processing updates sequentially to avoid backend race conditions.
let queue: Promise<void> = Promise.resolve()

/** we want to debounce each individual field, and save once */
const debounceFields: Record<
  string,
  (value: FieldChange) => Promise<void> | undefined
> = {}

export default function NoteTemplate({
  template,
  note,
  treatmentId,
  disabled,
}: NoteTemplateProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch() as ThunkDispatch<any, undefined, AnyAction>
  const defaultValues = useMemo(() => {
    const noteSection = note.sections.filter(
      (v) => v.templateId === template.id,
    )
    return templateToForm(noteSection.length ? noteSection : template.sections)
  }, [note, template])

  const methods = useForm<TemplateFormType>({ defaultValues })
  const { watch } = methods
  const notify = useNotify()

  useEffect(() => {
    const subscription = watch((form, { name }) => {
      if (name) {
        if (!debounceFields[name]) {
          debounceFields[name] = debounce(async (change: FieldChange) => {
            const { noteId, treatmentId } = change
            queue = queue.then(() =>
              (async () => {
                try {
                  if (change.fieldId.startsWith('section_')) {
                    const {
                      templateId,
                      value,
                      isDraft,
                      saveSessionId,
                      fieldId,
                    } = change

                    const sectionId = fieldId.split('_')[1]!

                    const data = {
                      sections: [{ sectionId, include: !!value, templateId }],
                      isDraft,
                      saveSessionId,
                    }

                    const action = noteApi.endpoints.includeSection.initiate({
                      treatmentId,
                      noteId,
                      data,
                    })

                    const res = await dispatch<ReturnType<typeof action>>(
                      action,
                    )

                    if ('error' in res) {
                      notify({
                        severity: 'error',
                        message: 'There was an issue saving your progress',
                      })
                    }
                  } else {
                    const action = noteApi.endpoints.answerOnField.initiate({
                      treatmentId,
                      noteId,
                      data: {
                        fieldId: change.fieldId,
                        templateId: change.templateId,
                        value: change.value,
                        isDraft: change.isDraft,
                        saveSessionId: change.saveSessionId,
                      },
                    })
                    const res = await dispatch<ReturnType<typeof action>>(
                      action,
                    )

                    if ('error' in res) {
                      notify({
                        severity: 'error',
                        message: 'There was an issue saving your progress',
                      })
                    }
                  }
                } catch (error) {
                  console.error(error)
                }
              })(),
            )
          }, 500)
        }
        const value = form[name]
        debounceFields[name]!({
          fieldId: name,
          // @ts-ignore - Type narrowing chokes on unions
          value:
            value === '' || value === undefined
              ? null
              : value instanceof Date
              ? value.toISOString()
              : Array.isArray(value)
              ? // @ts-ignore - Type narrowing chokes on unions
                value.filter((v) => !!v)
              : value,
          templateId: template.id,
          isDraft: false,
          saveSessionId: note?.saveSessionId,
          noteId: note.id,
          treatmentId,
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, note, dispatch, notify, template.id, treatmentId])

  return (
    <Paper
      key={template.id}
      sx={{
        marginBottom: 2,
        padding: 2,
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" component="h2">
          {template.name}
        </Typography>
        {disabled && (
          <Typography color="text.secondary" fontStyle="italic">
            {t('pendingTemplateMessage')}
          </Typography>
        )}
      </Stack>
      {!disabled && (
        <FormProvider {...methods}>
          {template.sections?.map((section) => {
            return <NoteSection key={section.id} section={section} />
          })}
        </FormProvider>
      )}
    </Paper>
  )
}
