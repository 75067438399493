import { Controller } from 'react-hook-form'
import {
  Stack,
  Fab,
  SxProps,
  Box,
  FormLabel,
  FormHelperText,
} from '@mui/material'
import { DAY_OF_WEEK_ARRAY } from '@valerahealth/rtk-query'

import { type BaseFormControlProps } from './index.types'
import { getErrorMessage, splitProps, useReadOnlyForm } from '../utils'
import { useTranslation } from '../../utils/hooks'
import { Theme } from '../../theme'

export type DayOfWeekFabsProps = {
  sx?: SxProps<Theme>
} & BaseFormControlProps

export function DayOfWeekFabs({
  name,
  required,
  helperText,
  label,
  hideLabel = false,
  setValueAs = (v) => v,
  readOnly,
  errorFieldName,
  sx,
  ...rest
}: DayOfWeekFabsProps) {
  const {
    registerProps,
    fieldProps: { disabled },
  } = splitProps(rest)
  const { t } = useTranslation()

  const isReadOnlyForm = useReadOnlyForm().readOnly
  const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

  return (
    <Controller
      name={name}
      rules={{
        ...registerProps,
        required,
      }}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => {
        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText

        const daysOfWeekSet = new Set(value)

        return (
          <Box sx={sx}>
            {!hideLabel && label && <FormLabel>{label}</FormLabel>}
            <Stack direction="row" justifyContent="center" gap={2}>
              {DAY_OF_WEEK_ARRAY.map((day) => {
                const selected = daysOfWeekSet.has(day)
                return (
                  <Fab
                    key={day}
                    disabled={disabled}
                    color="secondary"
                    size="small"
                    sx={{
                      transition: 'opacity 0.2s',
                      opacity: (theme) =>
                        selected ? 1 : theme.palette.action.disabledOpacity,
                      cursor: isReadOnly || disabled ? 'auto' : undefined,
                    }}
                    disableRipple={isReadOnly || disabled}
                    disableFocusRipple={isReadOnly || disabled}
                    disableTouchRipple={isReadOnly || disabled}
                    onClick={
                      isReadOnly
                        ? undefined
                        : () => {
                            if (selected) {
                              daysOfWeekSet.delete(day)
                            } else {
                              daysOfWeekSet.add(day)
                            }
                            onChange(setValueAs([...daysOfWeekSet]))
                          }
                    }
                  >
                    {day.at(0)}
                  </Fab>
                )
              })}
            </Stack>
            <FormHelperText error={!!error}>{errorOrHelper}</FormHelperText>
          </Box>
        )
      }}
    />
  )
}
