import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import {
  Box,
  Typography,
  MenuItem,
  TextField,
  SingleSelect,
  FormControl,
  LinearProgress,
} from '@valerahealth/ui-components'
import {
  useNotify,
  ConfirmationDialog as UIConfirmationDialog,
} from '@valerahealth/ui-components/features'
import {
  AppointmentStatus,
  StandardListItemFrag,
  configApi,
} from '@valerahealth/rtk-query'
import { useTranslation } from '../../locales'

export type CancelationReasonType = {
  reason: string
  detail: string
  open: boolean
  status: AppointmentStatus
  error: boolean
}

export const defaultCancelationReason: CancelationReasonType = {
  reason: '',
  detail: '',
  open: false,
  status: AppointmentStatus.Canceled,
  error: false,
}
const { useGetListQuery } = configApi
const CancelledApptForm = ({
  cancelationReasonState,
  setCancelationReason,
  apptCancelationReasons,
}: {
  cancelationReasonState: CancelationReasonType
  setCancelationReason: Dispatch<SetStateAction<CancelationReasonType>>
  apptCancelationReasons: StandardListItemFrag[]
}) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" flexDirection="column">
      <Typography sx={{ mb: '10px' }}>
        {t('cancelConfirmationDetail')}
      </Typography>
      <FormControl>
        <SingleSelect
          label={t('reason')}
          sx={{ mb: '8px' }}
          error={cancelationReasonState.error}
          value={cancelationReasonState.reason}
          onChange={(v) => {
            setCancelationReason((r) => ({
              ...r,
              reason: v,
            }))
          }}
        >
          {apptCancelationReasons!.map((r) => (
            <MenuItem key={r.id} value={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SingleSelect>
      </FormControl>
      <TextField
        sx={{ mt: '2px' }}
        label={t('additionalDetails')}
        multiline
        value={cancelationReasonState.detail}
        onChange={(e) => {
          setCancelationReason((r) => ({
            ...r,
            detail: e.target.value,
          }))
        }}
      />
    </Box>
  )
}

export const useCancelledStatusReasonPopup = ({
  onConfirm = () => {},
  onCancel = () => {},
}: {
  onConfirm?: () => void
  onCancel?: () => void
}) => {
  const { t } = useTranslation()
  const notify = useNotify()
  const { data, isLoading: isLoadingConfig } = useGetListQuery({
    keys: ['apptCancelationReasons'],
  })
  const apptCancelationReasons = data?.getList[0]?.list
  const [cancelationReasonState, setCancelationReason] =
    useState<CancelationReasonType>(defaultCancelationReason)
  const popup = useMemo(
    () => (
      <UIConfirmationDialog
        open={cancelationReasonState.open}
        header={t('cancelConfirmation')}
        cancelLabel={t('no')}
        confirmLabel={t('yes')}
        confirmButtonColor="primary"
        body={
          isLoadingConfig ? (
            <LinearProgress />
          ) : (
            <CancelledApptForm
              cancelationReasonState={cancelationReasonState}
              setCancelationReason={setCancelationReason}
              apptCancelationReasons={apptCancelationReasons!}
            />
          )
        }
        onConfirm={() => {
          if (!cancelationReasonState.reason) {
            notify({
              message: 'Reason is a required field.',
              severity: 'error',
            })
            setCancelationReason((c) => ({ ...c, error: true }))
            return
          }
          onConfirm()
          setCancelationReason(defaultCancelationReason)
        }}
        onCancel={() => {
          onCancel()
          setCancelationReason(defaultCancelationReason)
        }}
      />
    ),
    [
      apptCancelationReasons,
      cancelationReasonState,
      isLoadingConfig,
      notify,
      onCancel,
      onConfirm,
      t,
    ],
  )
  return {
    apptCancelationReasons,
    isLoadingConfig,
    cancelationReasonState,
    setCancelationReason,
    cancelationConfirmationDialog: popup,
  }
}
