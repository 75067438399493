import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import CheckList from '../CheckList'
import dStrings from '../../strings.json'

// originalList: [{key: "key1", title: "title1"}, {key: "key2", title: "title2"}]

function ElementsFilter({
  originalList,
  handleChange,
  headerClass,
  textForAllSelected = dStrings.all,
  title = dStrings.showing,
  hideSelected = false,
  singleSelection = false,
}) {
  const ref = useRef(null)
  const [displayFilter, setDisplayFilter] = useState(false)
  const [list, setList] = useState([])
  const [filteredElements, setFilteredElements] = useState([])

  useEffect(() => {
    const _list = Array.isArray(originalList)
      ? originalList
      : JSON.parse(originalList)
    const newList = _list.map((p) => {
      return { ...p, checked: p.checked || false }
    })

    setList(newList)
    afterUpdateList(newList, false)
  }, [originalList])

  const afterUpdateList = (_newList, returnList) => {
    const displayArr = []
    const returnedArray = []
    for (let i = 0; i < _newList.length; i++) {
      const element = _newList[i]
      if (element.checked) {
        displayArr.push(element.title || element.key)
        returnedArray.push(element.key)
      }
    }

    setFilteredElements(displayArr)
    if (returnList) handleChange(returnedArray)
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayFilter(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div
      className={`path-filter-container ${!displayFilter ? '' : 'opened'} ${
        headerClass || ''
      }`}
      ref={ref}
    >
      <div
        className="path-filter"
        onClick={() => {
          setDisplayFilter(!displayFilter)
        }}
      >
        {title}{' '}
        {!hideSelected &&
          (!filteredElements.length ? (
            textForAllSelected
          ) : (
            <span className="path-filter-selected">
              {filteredElements.join(', ')}
            </span>
          ))}
        <span className="arrow-down" />
      </div>
      {displayFilter && (
        <div className="path-filter-check-list-container">
          <CheckList
            singleSelection={singleSelection}
            selectListStyle
            list={list}
            handleChange={(l) => {
              setList(l)
              afterUpdateList(l, true)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ElementsFilter

ElementsFilter.propTypes = {
  originalList: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
  textForAllSelected: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}
