import React from 'react'
import { IconButton } from '@valerahealth/ui-core'
import PropTypes from 'prop-types'
import SuccessIcon from '../../../images/icon_success.svg'
import WarningIcon from '../../../images/icon_warning.svg'
import ErrorIcon from '../../../images/icon_error.svg'
import { messageType } from '../../utilities/enums'
import dStrings from '../../../strings.json'
/**
 * Every item has {type: "{success/warning/error}", text: "..."}
 * */
const messageItem = ({ type, text, id, deleteItem }) => {
  function IconMessage() {
    if (type === messageType.SUCCESS) {
      return <SuccessIcon />
    }
    if (type === messageType.WARNING) {
      return <WarningIcon />
    }
    return <ErrorIcon />
  }

  const textShowArray = text instanceof Array ? text : [text]
  return (
    <div className={`user-message-item ${type}-message`} key={id}>
      <div className="message-icon">
        <IconMessage />
      </div>
      <div className="message-body">
        <div className="message-title">{dStrings[type]}</div>
        {textShowArray.map((textItem, index) => {
          return (
            <div key={`user-message-key-index-${index}`}>
              {textItem}
              <br />
            </div>
          )
        })}
      </div>
      <div className="close-icon">
        <IconButton onClick={deleteItem} type="close" />
      </div>
    </div>
  )
}

messageItem.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
  deleteItem: PropTypes.func,
}

export default messageItem
