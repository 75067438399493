import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import OptionIcon from '../../images/options.svg'
import SearchInput from '../SearchInput'
import dStrings from '../../strings.json'

/**
 * This component recieve two parameters: list and handleChange
 * list - list of objects. Each object is like {key: "key1", title: "title 1", checked: false} (see proptypes).
 * Returned list is same as original, updated.
 */

function CheckList({
  list,
  selectListStyle,
  search,
  handleChange,
  singleSelection,
}) {
  const [searchText, setSearchText] = useState('')
  const [displayClearAll, setDisplayClearAll] = useState(false)
  const [displayedElements, setDisplayedElement] = useState({})

  useEffect(() => {
    const obj = {}
    for (let index = 0; index < list.length; index++) {
      const element = list[index]
      obj[element.key] =
        !searchText.length ||
        ~element.title.toLowerCase().indexOf(searchText.toLowerCase())
    }

    setDisplayedElement(obj)
  }, [searchText])

  useEffect(() => {
    for (let index = 0; index < list.length; index++) {
      if (list[index].checked) {
        return setDisplayClearAll(true)
      }
    }

    setDisplayClearAll(false)
  }, [])

  const clearAll = () => {
    const tempValues = list.map((val) => {
      return { ...val, checked: false }
    })

    handleChange(tempValues)
  }

  const getList = (elem, index) => {
    if (!singleSelection) {
      return (
        <div
          className={`check-list-element ${
            !search || displayedElements[elem.key] ? '' : 'hide'
          }`}
          key={index}
        >
          <input
            type="checkbox"
            checked={elem.checked || false}
            id={`check-elem-${elem.key}-${index}`}
            onChange={(event) => {
              handleChange(
                list.map((e, i) => {
                  if (i !== index) return e
                  return { ...e, checked: event.target.checked }
                }),
              )
            }}
          />
          <label
            title={elem.tooltip || elem.title || elem.key}
            htmlFor={`check-elem-${elem.key}-${index}`}
            dangerouslySetInnerHTML={{ __html: elem.title || elem.key }}
          />
          {elem.displayOption && (
            <div className="check-list-option">
              <OptionIcon />
            </div>
          )}
        </div>
      )
    }

    return (
      <div
        className={`check-list-element ${
          !search || displayedElements[elem.key] ? '' : 'hide'
        }`}
        key={index}
        dangerouslySetInnerHTML={{ __html: elem.title || elem.key }}
        onClick={(event) => {
          handleChange(
            list.map((e, i) => {
              if (i !== index) return { ...e, checked: false }
              return { ...e, checked: true }
            }),
          )
        }}
      >
        {elem.displayOption && (
          <div className="check-list-option">
            <OptionIcon />
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {search && (
        <SearchInput
          value={searchText}
          handleChange={(newVal) => {
            setSearchText(newVal)
          }}
        />
      )}
      <div
        className={`check-list-container ${
          selectListStyle ? 'select-list' : ''
        }`}
      >
        {displayClearAll && (
          <div
            className="check-list-element clear-all"
            onClick={() => clearAll()}
          >
            {dStrings.clearSelectedItems}
          </div>
        )}
        {list.map((elem, index) => {
          return getList(elem, index)
        })}
      </div>
    </>
  )
}

CheckList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      checked: PropTypes.bool,
      title: PropTypes.string,
    }),
  ),
  selectListStyle: PropTypes.bool,
  search: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
}

export default CheckList
