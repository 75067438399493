import { userAdminApi } from '@valerahealth/rtk-query'
import { type AuthUser } from '@valerahealth/redux-auth'
import { actions, SessionEvent } from '@valerahealth/redux-auth'
import {
  CognitoIdentityProviderClient,
  ChangePasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider'
import { Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNotify } from '../Notifications'
import { useNotifyAuthUI } from '../AuthMonitor'
import FormProvider from '../../form/FormProvider'
import { validate, onlyDirtyFields } from '../../form/utils'
import {
  SaveButton,
  TextField,
  MaskedPhoneInput,
  PasswordTextField,
} from '../../form/controls'

import { useTranslation } from '../../utils/hooks'

export type UserAccountFormProps = {
  user: AuthUser
  accessToken: string
  canEditLegalName?: boolean
}

type FormUser = {
  firstName: string
  lastName: string
  preferredName: string
  email: string
  pronouns: string
  phoneNumber: string
  passwordChange?: {
    current: string
    proposed: string
    match: string
  }
}

const cognito = new CognitoIdentityProviderClient({
  region: 'us-east-1',
})

/** if they fill in any single field all values are required */
const validatePasswordAllOrNothing = (
  value: string,
  passwordChange: FormUser['passwordChange'],
) => !(!value && Object.values(passwordChange || {}).some((v) => v))

export default function UserAccountForm({
  user,
  accessToken,
  canEditLegalName,
}: UserAccountFormProps) {
  const { t } = useTranslation()
  const notify = useNotify()
  const dispatch = useDispatch()

  const [updateUser, updateUserRes] = userAdminApi.useUpdateUserMutation()

  const methods = useForm<FormUser>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      preferredName: user.preferredName || '',
      email: user.email,
      pronouns: user.pronouns || '',
      phoneNumber: user.phoneNumber || '',
    },
  })

  const notifyAuthUI = useNotifyAuthUI()

  const onSubmit = async (form: FormUser) => {
    const { passwordChange, ...updates } = onlyDirtyFields(
      methods.formState.dirtyFields,
      form,
    ) as Partial<FormUser>

    try {
      // only reset password if every passwordChange value is filled out
      if (passwordChange && Object.values(passwordChange).every((v) => v)) {
        try {
          await cognito.send(
            new ChangePasswordCommand({
              AccessToken: accessToken,
              PreviousPassword: passwordChange.current,
              ProposedPassword: passwordChange.proposed,
            }),
          )
          notify({
            message: t('UserAccountForm.updatePasswordSuccess'),
            severity: 'success',
          })
        } catch (e: any) {
          if ((e as { $fault: string; message: string }).$fault === 'client') {
            methods.setError(
              'passwordChange.current',
              { message: e.message },
              {
                shouldFocus: true,
              },
            )
          } else {
            notify({
              message: `${t('serverError.500')} ${t(
                'UserAccountForm.updatePasswordFailure',
              )}`,
              severity: 'error',
            })
          }
          throw new Error()
        }
      }

      // only update user if they have changes one or more account fields (they may have only updated their password)
      if (Object.values(updates).some((v) => v)) {
        const { phoneNumber, ...rest } = updates

        const res = await updateUser({
          cognitoUserId: user.sub,
          content: {
            ...rest,
            phone: phoneNumber,
          },
        })

        if ('data' in res) {
          notify({
            message: t('UserAccountForm.updateSuccess'),
            severity: 'success',
          })
          dispatch(actions.updateUser(updates))
        } else {
          notify({
            message: res.error.message || t('networkError.500'),
            severity: 'error',
          })
          throw new Error()
        }
      }
    } catch {
      // each api is handling their specific errors but so this try catch is here just to stop function execution if either of them throw an error
      return
    }

    notifyAuthUI(SessionEvent.UserUpdate)

    methods.reset(
      {
        ...updates,
        passwordChange: {
          current: '',
          match: '',
          proposed: '',
        },
      },
      {
        keepErrors: true,
      },
    )
  }

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        gap={2}
        paddingTop={1}
      >
        {canEditLegalName && (
          <>
            <TextField
              fullWidth
              label={t('firstName')}
              name="firstName"
              required
            />
            <TextField
              fullWidth
              label={t('lastName')}
              name="lastName"
              required
            />
          </>
        )}
        <TextField fullWidth label={t('preferredName')} name="preferredName" />
        <TextField fullWidth label={t('pronouns')} name="pronouns" />
        <TextField
          fullWidth
          label={t('email')}
          name="email"
          required
          pattern={{
            value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/,
            message: t('form_invalid_field', { field: t('email') }),
          }}
        />
        <MaskedPhoneInput label={t('phone')} fullWidth name="phoneNumber" />
        <Typography variant="h6">{t('Update Password')}</Typography>
        <PasswordTextField
          fullWidth
          label={t('UserAccountForm.currentPassword')}
          name="passwordChange.current"
          validate={{
            required: (value, values) => {
              return validatePasswordAllOrNothing(value, values.passwordChange)
            },
          }}
          deps={['passwordChange.match', 'passwordChange.proposed']}
        />
        <PasswordTextField
          fullWidth
          label={t('UserAccountForm.proposedPassword')}
          name="passwordChange.proposed"
          validate={{
            required: (value, values) => {
              return validatePasswordAllOrNothing(value, values.passwordChange)
            },
            hasOneNumber: (value) =>
              validate.hasOneNumber(
                value,
                t('UserAccountForm.mustContainNumber', {
                  field: t('password'),
                }),
              ),
            hasOneSpecialCharacter: (value) =>
              validate.hasOneSpecialCharacter(
                value,
                t('UserAccountForm.mustContainSpecialCharacter', {
                  field: t('password'),
                }),
              ),
            hasOneUppercase: (value) =>
              validate.hasOneUppercase(
                value,
                t('UserAccountForm.mustContainUppercase', {
                  field: t('password'),
                }),
              ),
            hasOneLowercase: (value) =>
              validate.hasOneLowercase(
                value,
                t('UserAccountForm.mustContainLowercase', {
                  field: t('password'),
                }),
              ),
          }}
          deps={['passwordChange.match', 'passwordChange.current']}
        />
        <PasswordTextField
          fullWidth
          label={t('UserAccountForm.repeatProposedPassword')}
          name="passwordChange.match"
          minLength={8}
          errorFieldName={t('password')}
          validate={{
            required: (value, values) => {
              return validatePasswordAllOrNothing(value, values.passwordChange)
            },
            proposedMatch: (value, values) => {
              return (
                value === values?.passwordChange?.proposed ||
                t('UserAccountForm.passwordsDoNotMatch')
              )
            },
          }}
          deps={['passwordChange.proposed', 'passwordChange.current']}
        />
        <SaveButton
          sx={{ ml: 'auto' }}
          isError={updateUserRes.isError}
          isSuccess={updateUserRes.isSuccess}
        />
      </Stack>
    </FormProvider>
  )
}
