import { Grid, MenuItem } from '@valerahealth/ui-components'
import { Typography } from '@valerahealth/ui-components/base'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  PatientGenderIdentity,
  PatientLegalSex,
  PatientPronounsEnum,
  STATES,
} from '@valerahealth/rtk-query'
import {
  DatePicker,
  Select,
  ControlledTextField,
  USStateCombobox,
  validate,
  IntlPhoneInput,
} from '@valerahealth/ui-components/form'
import { getTimezoneValues } from '@valerahealth/ui-components/utils/date'
import { genderEnum2 } from '../utilities/enums'
import { formControlProps } from './utilities'

const PatientDetails = () => {
  const { t } = useTranslation()
  const timezones = getTimezoneValues()
  return (
    <Grid container flexDirection="row" spacing={3}>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={4}>
          <ControlledTextField
            required
            fullWidth
            label={t('firstName')}
            name="firstName"
            validate={{
              valid: (v) =>
                validate.withoutSpecialCharacter(
                  v,
                  t('form_invalid_field_characters', { field: t('firstName') }),
                ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTextField
            fullWidth
            label={t('middleName')}
            name="middleName"
            validate={{
              valid: (v) =>
                validate.withoutSpecialCharacter(
                  v,
                  t('form_invalid_field_characters', {
                    field: t('middleName'),
                  }),
                ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTextField
            required
            fullWidth
            label={t('lastName')}
            name="lastName"
            validate={{
              valid: (v) =>
                validate.withoutSpecialCharacter(
                  v,
                  t('form_invalid_field_characters', {
                    field: t('lastName'),
                  }),
                ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={4}>
          <ControlledTextField fullWidth label={t('MRN')} name="mrn" />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            required
            fullWidth
            label={t('birthday')}
            maxDate={new Date()}
            name="dateOfBirth"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            required
            formControlProps={formControlProps}
            label={t('sOnFileWithInsurance')}
            name="gender"
          >
            {Object.keys(genderEnum2).map((type) => (
              <MenuItem key={type} value={type}>
                {t(genderEnum2[type as PatientLegalSex])}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={4}>
          <ControlledTextField
            fullWidth
            label={t('preferredName')}
            name="preferredName"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            formControlProps={formControlProps}
            fullWidth
            label={t('pronouns')}
            name="pronouns"
          >
            {Object.keys(PatientPronounsEnum).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            formControlProps={formControlProps}
            fullWidth
            label={t('genderIdentity')}
            name="genderIdentity"
          >
            {Object.values(PatientGenderIdentity).map((type) => (
              <MenuItem key={type} value={type}>
                {t(`PatientGenderIdentity.${type}`)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid sx={{ pt: 3, pl: 3 }} container xs={12}>
        <ControlledTextField
          fullWidth
          label={t('genderDetails')}
          name="genderDetails"
        />
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={6}>
          <IntlPhoneInput
            required
            fullWidth
            label={t('primaryPhone')}
            name="primaryPhone"
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField
            required
            fullWidth
            label={t('primaryEmail')}
            name="primaryEmail"
          />
        </Grid>
      </Grid>
      <Grid sx={{ pt: 2 }}>
        <Typography variant="body2">{t('address')}</Typography>
      </Grid>
      <Grid container item xs={12} flexDirection="row" spacing={3}>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <ControlledTextField
              fullWidth
              label={t('street')}
              name="addressComponents.street"
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              fullWidth
              label={t('city')}
              name="addressComponents.city"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={6}>
            <USStateCombobox
              fullWidth
              label={t('state')}
              name="addressComponents.state"
              parseValue={(_v) => {
                const value = _v || ''
                return (
                  STATES.find(
                    // we allowed freetext at the beginning and so all different types of values got into the DB. over time this component self corrects to standardize on stateCode
                    (s) =>
                      s.code.toLowerCase() === value.toLowerCase() ||
                      s.name.toLowerCase() === value.toLowerCase(),
                  ) || null
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              fullWidth
              label={t('zipcode')}
              name="addressComponents.zipcode"
              validate={{
                valid: (v) =>
                  validate.validPostalCode(
                    v,
                    t('form_invalid_field', { field: t('zipcode') }),
                  ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={6}>
            <Select
              required
              formControlProps={formControlProps}
              label={t('timezone')}
              name="timezone"
            >
              {timezones.map((timezone) => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientDetails
