import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import { connect } from 'react-redux'
import { Popup, CallLoader, SearchInput } from '@valerahealth/ui-core'
import dStrings from '../../../strings.json'
import { loadPlanTemplates } from '../../../redux/actions/planTemplateActions'
import { assignPlans } from '../../../redux/actions/caseloadActions'
import { plansDetails } from '../../utilities/planTemplate'
import { planType } from '../../utilities/enums'
import { getSelectedCount } from '../../utilities/treatments'
import { getErrorMessage } from '../../../redux/actions/utilities'
import { addUserMessage } from '../../../redux/actions/globalActions'

function AssignCarePlanPopup({
  careplans,
  careplansToRemove,
  treatmentIds,
  bulkAction,
  handleClose,
  loadPlanTemplates,
  assignPlans,
  showIndividualTrack,
  selectedCount,
  addUserMessage,
}) {
  const [careplanList, setCareplanList] = useState()
  const [searchText, setSearchText] = useState('')
  const [planState, setPlanState] = useState('population') // "population" or "individual_public"
  const [selectedIds, setSelectedIds] = useState({})

  useEffect(() => {
    if (!careplans || !careplans.allIds) loadPlanTemplates()
    else {
      const list = getCarePlanList()
      setCareplanList(list)
    }
  }, [careplans])

  useEffect(() => {
    setCareplanList(getCarePlanList())
  }, [planState, searchText])

  const getCarePlanList = () => {
    if (!careplans || !careplans.allIds) return null

    const list = careplansToRemove
      ? careplans.allIds.filter((c) => !~careplansToRemove.indexOf(c))
      : careplans.allIds

    const arr = []
    for (let i = 0; i < list.length; i++) {
      const careplan = careplans.byId[list[i]]
      if (
        careplan.type === planState &&
        (!searchText.length ||
          ~careplan.name.toLowerCase().indexOf(searchText.toLowerCase()))
      ) {
        arr.push({
          key: careplan.id,
          title: careplan.name,
        })
      }
    }

    return arr
  }

  return (
    <Popup
      title={dStrings.assignTracks}
      onClosed={handleClose}
      buttons={[
        {
          text: dStrings.cancel,
          type: 'link',
          onClick: () => {
            handleClose()
          },
        },
        {
          text: dStrings.assign,
          type: 'primary',
          disable: !Object.keys(selectedIds).find((key) => selectedIds[key]),
          onClick: () => {
            assignPlans(
              treatmentIds,
              Object.keys(selectedIds).filter((key) => selectedIds[key]),
              (err) => {
                const obj = getErrorMessage(err)
                if (obj && obj.message) {
                  addUserMessage('error', obj.message || dStrings.errorOccurred)
                }
              },
            )
            handleClose()
          },
        },
      ]}
    >
      <div
        className={`assign-careplan-popup ${
          bulkAction && selectedCount ? 'bulk-state' : ''
        }`}
      >
        {bulkAction && selectedCount && (
          <div className="bulk-message">
            {dStrings.assignTracksToPatients.replace(
              '${selectedCount}',
              selectedCount,
            )}
          </div>
        )}
        <SearchInput
          value={searchText}
          handleChange={(newVal) => {
            setSearchText(newVal)
          }}
        />
        <div className="plan-categorie">
          <div
            className={`plan-categorie-element ${
              planState === 'population' ? 'selected' : ''
            }`}
            onClick={() => {
              setPlanState('population')
            }}
          >
            <span className="icon">{plansDetails.population.icon}</span>{' '}
            {plansDetails.population.title}
          </div>
          {showIndividualTrack && (
            <div
              className={`plan-categorie-element ${
                planState === planType.INDIVIDUAL_PUBLIC ? 'selected' : ''
              }`}
              onClick={() => {
                setPlanState(planType.INDIVIDUAL_PUBLIC)
              }}
            >
              <span className="icon">
                {plansDetails[planType.INDIVIDUAL_PUBLIC].icon}
              </span>{' '}
              {plansDetails[planType.INDIVIDUAL_PUBLIC].title}
            </div>
          )}
        </div>
        <div className="check-list-container">
          {careplanList ? (
            careplanList.map((elem, index) => {
              return (
                <div className="check-list-element " key={index}>
                  <input
                    type="checkbox"
                    checked={selectedIds[elem.key] || false}
                    id={`check-elem-${elem.key}-${index}`}
                    onChange={(event) => {
                      setSelectedIds({
                        ...selectedIds,
                        [elem.key]: event.target.checked,
                      })
                    }}
                  />
                  <label
                    title={elem.title || elem.key}
                    htmlFor={`check-elem-${elem.key}-${index}`}
                    dangerouslySetInnerHTML={{ __html: elem.title || elem.key }}
                  />
                </div>
              )
            })
          ) : (
            <CallLoader />
          )}
        </div>
      </div>
    </Popup>
  )
}

AssignCarePlanPopup.propTypes = {
  careplans: PropTypes.object,
  careplansToRemove: PropTypes.array,
  treatmentIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleClose: PropTypes.func,
  loadPlanTemplates: PropTypes.func,
  assignPlans: PropTypes.func,
}

function mapStateToProps(state, ownProps) {
  const careplansToRemove = []
  if (!ownProps.treatmentIds && state.treatmentRoom.selectedId) {
    const ids =
      state.treatmentRoom.byId[state.treatmentRoom.selectedId].plansDetails.byId
    for (const key in ids) {
      careplansToRemove.push(ids[key].assignment.templateId)
    }
  }

  return {
    careplans: state.planTemplates,
    handleClose: ownProps.handleClose,
    bulkAction: ownProps.bulkAction,
    treatmentIds: ownProps.treatmentIds || state.treatmentRoom.selectedId,
    careplansToRemove: careplansToRemove.length ? careplansToRemove : undefined,
    showIndividualTrack: !ownProps.treatmentIds,
    selectedCount: getSelectedCount(state),
  }
}

const mapDispatchToProps = {
  loadPlanTemplates,
  assignPlans,
  addUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignCarePlanPopup)
