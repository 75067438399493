import { getProfileName } from 'components/utilities/treatments'
import { CareTeamMember, CareTeamMemberLegacy } from '@valerahealth/rtk-query'

const navHeaderHeight: string = '62px'
const t_r_header_height: string = '90px'
const sub_menu_navigation: string = '60px'

export const searchHeight: string = '54px'
export const sideHeaderHeight: string = '48px'
export const ownCareMemberHeight: string = '36px'

export const pageHeight: string = `calc(100vh - ${navHeaderHeight} - ${t_r_header_height} - ${sub_menu_navigation})`

export const sideBarHeight: string = `calc(100vh - ${navHeaderHeight} - ${t_r_header_height} - ${sub_menu_navigation} - 32px)`

export function sortCareTeamByRole(list: CareTeamMemberLegacy[]) {
  if (!list) return []

  const roleOrder: Record<string, number> = {
    therapist: 1,
    prescriber: 2,
    'Case Management': 3,
  }

  list.sort((a: CareTeamMemberLegacy, b: CareTeamMemberLegacy) => {
    const roleA = a.providerRole
    const roleB = b.providerRole

    return roleOrder[roleA]! - roleOrder[roleB]!
  })

  return list
}

type CoveringProviderType = {
  [key: string]: {
    startDate: string
    endDate: string
    covered: string
    coveredName: string
  }
}

export const getCareTeamList = (careTeamList?: CareTeamMemberLegacy[]) => {
  try {
    if (!careTeamList) return []

    const coveringProvider: CoveringProviderType = {}

    const ctList = careTeamList.map((element: CareTeamMemberLegacy) => {
      if (element.outOfOffice?.coveringProviderId) {
        const { startDate, endDate } = element.outOfOffice

        coveringProvider[element.outOfOffice?.coveringProviderId] = {
          startDate,
          endDate,
          covered: element.id,
          coveredName: getProfileName(element),
        }

        const covering = careTeamList.find(
          (p) => p.id === element.outOfOffice?.coveringProviderId,
        )

        const coveringName = getProfileName(covering)

        return {
          ...element,
          outOfOffice: {
            ...element.outOfOffice,
            coveringName,
          },
        }
      }

      return element
    })

    // return room.careTeamProfiles

    // TODO: this code is temporary!!! Should be removed when backend will work
    const list2 = ctList.map((a: any, i: number) => ({
      ...a,
      email: i % 2 ? 'firemenBens@valerahealth.com' : undefined,
      covering: coveringProvider[a.id],
    }))

    return [
      ...list2,
      ...list2.map((a: any) => ({ ...a, id: `${a.id}a`, isActive: true })),
    ]
  } catch (error) {
    return []
  }
}

export enum careTeamActionsEnum {
  MOVE_TO_PAST = 'moveToPast',
  RESTORE = 'restore',
  DELETE = 'delete',
  CHANGE_SERVICE_TYPE = 'changeServiceType',
  ADD_CT_MEMBER = 'addCTMember',
}

export type CareTeamActionsEnumType =
  (typeof careTeamActionsEnum)[keyof typeof careTeamActionsEnum]
