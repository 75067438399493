import { ReactNode } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import {
  format,
  differenceInMinutes,
  isSameDay,
} from '@valerahealth/ui-components/utils/date'
import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  PopupMenu,
  useAuthMonitorLogout,
} from '@valerahealth/ui-components'
import { MoreHoriz } from '@valerahealth/ui-components/icons'
import { RootState } from 'redux/reducers'
import { getDrChronoAppointmentURL } from '@valerahealth/scheduling'
import { TreatmentRoomChannelMessage } from 'redux/reducers/legacyRootState'
import {
  getPlanTemplateDetails,
  getToolTemplateIcon,
} from '../utilities/planTemplate'
import BadgeIcon from '../../images/icn_badge.svg'
import AppointmentIcon from '../../images/icn_appointment.svg'
import EyeIcon from '../../images/eye.svg'
import SentIcon from '../../images/icn_sent.svg'
import ConfirmedIcon from '../../images/Check.svg'
import DeclinedIcon from '../../images/Decline.svg'
import OutgoingCallIcon from '../../images/icn_outgoing.svg'
import IngoingCallIcon from '../../images/icn_incoming.svg'
import MissedCallIcon from '../../images/icn_missed.svg'
import ClockIcon from '../../images/clock.svg'
import VideoIcon from '../../images/icn_call-1.svg'
import RescheduledAppointmentIcon from '../../images/reschedule_appointment.svg'
import dStrings from '../../strings.json'
import { addUserMessage, editItem } from '../../redux/actions/globalActions'
import {
  highlightWords,
  getAlertIcon,
  openContent,
  getFormattedItemTs,
  getIsCTMMessage,
  getIsChatMessage,
} from '../utilities'
import { getMediaItem } from '../../api/toolsTemplatesAPI'
import { dateFormatEnum, domainEventEnum, errorsEnum } from '../utilities/enums'
import { getProfileName, getTreatment } from '../utilities/treatments'
import RefillRequestIcon from '../../images/refill_request_icon_main.svg'
import { setChannelUnseen } from '../../api/treatmentAPI'
import {
  loadNotifications,
  setMessageAsUnreaded,
} from '../../redux/actions/caseloadActions'
import { useNavigate } from 'react-router'

function mapStateToProps(
  state: RootState,
  ownProps: {
    message: TreatmentRoomChannelMessage
    ownMessage: boolean
    patientMessage: boolean
    index: number
    messagesArr: (TreatmentRoomChannelMessage | undefined)[]
    handleMarkAsUnread: () => void
  },
) {
  const treatment = getTreatment(state)
  return {
    message: ownProps.message,
    index: ownProps.index,
    messagesArr: ownProps.messagesArr,
    ownMessage: ownProps.ownMessage,
    patientMessage: ownProps.patientMessage,
    handleMarkAsUnread: ownProps.handleMarkAsUnread,
    toolsTemplates: state.toolsTemplates,
    specialWords: state.program.configurations?.chatHighlightedWords || [],
    treatment,
    displayMarkAsUnreadOptions:
      state.program.configurations.unreadChat?.active && treatment?.isCTM,
    events: state.program.configurations.unreadChat?.events,
  }
}

const mapDispatchToProps = {
  editItem,
  addUserMessage,
  loadNotifications,
  setMessageAsUnreaded,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type Props = ConnectedProps<typeof connector>

function ChatDate({ date }: { date: Date }) {
  return (
    <div className="text-between-lines chat-date xsmall-font">
      <span>{format(date, 'EEEE, dd MMM yyyy').toUpperCase()}</span>
    </div>
  )
}
export function AlertMessage({
  children,
  type,
}: {
  type: any
  children: ReactNode
}) {
  return (
    <div className="system-message-element message-content">
      <div className="message-type-icon">{getAlertIcon(type)}</div>
      {children}
    </div>
  )
}

function ChatMessage({
  message,
  index,
  messagesArr,
  ownMessage,
  patientMessage,
  handleMarkAsUnread,
  treatment,
  displayMarkAsUnreadOptions,
  events,
  editItem,
  specialWords,
  addUserMessage,
  loadNotifications,
  setMessageAsUnreaded,
}: Props) {
  const _logout = useAuthMonitorLogout()
  const navigate = useNavigate()
  const previousMessage: TreatmentRoomChannelMessage | undefined =
    messagesArr[index - 1]
  const isAlertMessage =
    message.attachment && message.attachment.type === 'info'
  const isCTMMessage = getIsCTMMessage(message.domainEvent)
  const isChatMessage = getIsChatMessage(message.domainEvent)
  const messageDate = new Date(message.ts)
  const previousMessageDate = previousMessage && new Date(previousMessage.ts)
  const displayIconAndDate =
    !index ||
    message.sender?.id !== previousMessage?.sender?.id ||
    (previousMessageDate &&
      differenceInMinutes(messageDate, previousMessageDate) > 4)

  let messageClass = 'message-container'
  if (!displayIconAndDate) {
    messageClass += ' no-date-message'
  }

  if (!isCTMMessage && !isChatMessage) {
    if (ownMessage) {
      messageClass += ' own-message'
    } else if (patientMessage) {
      messageClass += ' patient-message'
    }
  }

  const getMessageSenderName = () => {
    const { sender } = message
    const { type, firstName, lastName, displayName } = sender || {}

    if (type === 'System') return 'System'
    if (displayName) return displayName
    return firstName && lastName ? `${firstName} ${lastName}` : ''
  }

  const getVideoTypeIcon = () => {
    if (message.attachment?.item?.type === 'video_session_terminated') {
      if (ownMessage) {
        return <OutgoingCallIcon />
      }
      return <IngoingCallIcon />
    }
    return <MissedCallIcon />
  }

  const displayNote = () => {
    return (
      message.domainEvent === 'appointment_reschedule_requested' &&
      message.attachment?.item?.custom?.notes
    )
  }

  const getTypeIcon = () => {
    switch (message.attachment?.item?.type) {
      case 'sent':
        return <SentIcon />
      case 'approved':
        return <ConfirmedIcon />
      case 'assessment_answer':
      case 'assessment_score':
        return <DeclinedIcon />
      case 'declined':
        if (message.domainEvent === domainEventEnum.APPOINTMENT_RESCHEDULED)
          return <RescheduledAppointmentIcon />
        return <DeclinedIcon />
      default:
        break
    }
  }

  const getMessage = () => {
    if (message.domainEvent === 'chat_message_sent') {
      return (
        <>
          <div
            className={`message-content-container ${
              message.tempMessage ? 'temp-message' : ''
            }`}
          >
            <div className="message-content">
              {highlightWords(message.message, 'highlight-word', specialWords)}
            </div>
          </div>
          {message.tempMessage && (
            <div className="sending-message">
              <ClockIcon /> {dStrings.sendingMessage}
            </div>
          )}
        </>
      )
    }

    if (message.domainEvent === 'medication_refill_created') {
      return (
        <div className="message-content">
          <div className="item-message">
            <div className="item-message-title">
              <span className="item-message-title-icon">
                <RefillRequestIcon />
              </span>
              <span className="item-message-title-text">
                {dStrings.refillRequestForm}
              </span>
              <span className="item-message-eye-icon">
                <EyeIcon
                  onClick={() => {
                    if (!message.attachment?.item?.id) return
                    editItem({
                      assigned: true,
                      type: message.attachment.category,
                      info: {
                        id: message.attachment.item.id,
                      },
                    })
                  }}
                />
              </span>
            </div>
            <div className="item-message-description">{dStrings.submitted}</div>
          </div>
        </div>
      )
    }

    if (!message.attachment) {
      return <div>{dStrings.errorOccurred}</div>
    }

    switch (message.attachment.category) {
      case 'appointment':
      case 'consent_form':
      case 'questionnaire':
      case 'sentiment':
      case 'reminder':
        return (
          <div className="message-content">
            <div className="item-message">
              <div className="item-message-title">
                <span className="item-message-title-icon">
                  {getToolTemplateIcon(message.attachment.category)}
                </span>
                <span className="item-message-title-text">
                  {message.attachment.category !== 'appointment' ||
                  message.domainEvent === 'patient_request_sent'
                    ? message.attachment.title
                    : getFormattedItemTs(message, 'chatMessage')}
                </span>
                {message.attachment.item &&
                  message.attachment.category !== 'reminder' && (
                    <span className="item-message-eye-icon">
                      <EyeIcon
                        onClick={() => {
                          if (message.attachment?.item?.emrId) {
                            window.open(
                              getDrChronoAppointmentURL({
                                appointmentEmrId: message.attachment.item.emrId,
                              }),
                            )
                            return
                          }
                          /*
                          if (message.attachment?.category === 'appointment'){
                            navigate(
                              `/caseload/treatment/${
                                treatment?.id
                              }/appointments?${
                                message.attachment?.item?.ts
                                  ? `scrollTo=${format(
                                      new Date(message.attachment.item.ts),
                                      'yyyy-MM-dd',
                                    )}`
                                  : ''
                              }`,
                            )
                            return
                          }
                            */
                          if (message.attachment?.category === 'appointment') {
                            const appointmentId = message.attachment?.item?.id
                            navigate(
                              `/caseload/treatment/${
                                treatment?.id
                              }/appointments?${
                                appointmentId ? `scrollTo=${appointmentId}` : ''
                              }`,
                            )
                            if (appointmentId)
                              document
                                .getElementById(appointmentId)
                                ?.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'start',
                                })
                            return
                          }
                          editItem({
                            assigned: true,
                            type: message.attachment?.category,
                            info: {
                              id: message.attachment?.item?.id,
                            },
                          })
                        }}
                      />
                    </span>
                  )}
              </div>
              {message.attachment.item && (
                <>
                  {message.attachment.item.description && (
                    <div className="item-message-description">
                      {message.attachment?.item?.description}
                    </div>
                  )}
                  {message.attachment.item.eventDetails && (
                    <>
                      <div className="item-message-event-details">
                        {getTypeIcon()} {message.attachment.item.eventDetails}
                        {message.domainEvent ===
                          domainEventEnum.APPOINTMENT_RESCHEDULED &&
                          message.attachment.item.custom?.providerName &&
                          ` {${message.attachment.item.custom?.providerName}}`}
                      </div>
                      {displayNote() && (
                        <div className="message-note">
                          {dStrings.notes}:{' '}
                          {message.attachment.item.custom?.notes}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )

      case 'media':
        return (
          <Box
            className="media-message"
            onClick={() => {
              if (message.attachment?.item?.url) {
                openContent(message.attachment?.item?.url)
              } else {
                getMediaItem(message.attachment?.item?.id)
                  .then((res) => {
                    if (
                      res &&
                      res.internalContent &&
                      res.internalContent.mediaUrl
                    ) {
                      openContent(res.internalContent.mediaUrl)
                    } else {
                      addUserMessage('error', dStrings.errorOccurred)
                    }
                  })
                  .catch((res) => {
                    if (res && res.message === errorsEnum.AUTHENTICATION) {
                      _logout()
                      return
                    }

                    addUserMessage('error', dStrings.errorOccurred)
                  })
              }
            }}
          >
            {message.attachment?.item?.picture && (
              <img
                src={message.attachment?.item?.picture}
                alt={message.attachment?.category || ''}
                height="110px"
              />
            )}

            <div className="media-message-footer">
              <div className="media-title">{message.attachment.title}</div>
              {message.attachment?.item?.url && (
                <div className="media-url xsmall-font">
                  {message.attachment?.item?.url}
                </div>
              )}
            </div>
          </Box>
        )
      case 'telehealth':
        return (
          <div className="message-content">
            <div className="item-message">
              <div className="item-message-title">
                <span className="item-message-title-icon">
                  <VideoIcon />
                </span>
                <span className="item-message-title-text">
                  {message.attachment.title}
                </span>
              </div>
              {message.attachment?.item?.description && (
                <div className="item-message-description">
                  {message.attachment.item.description}
                </div>
              )}
              {message.attachment?.item?.eventDetails && (
                <div className="item-message-event-details">
                  {getVideoTypeIcon()}{' '}
                  <span className="content">
                    {message.attachment?.item?.eventDetails}
                  </span>
                </div>
              )}
            </div>
          </div>
        )
      default:
        // debugger;
        break
    }
  }

  const getSystemMessage = () => {
    let systemMessage: ReactNode
    const { details } = message.sender || {}
    if (!details) return <div>Error occured. Please contact support...</div>

    const planTypeDetails = getPlanTemplateDetails(details.type)

    switch (details.module) {
      case 'CarePlan':
        systemMessage = (
          <div className="system-message-details">
            <span className="message-type-icon">{planTypeDetails.icon}</span>{' '}
            {details.name}{' '}
          </div>
        )
        break
      case 'Appointment':
        systemMessage = (
          <div className="system-message-details">
            <span>
              <AppointmentIcon />
            </span>{' '}
            {details.name}{' '}
          </div>
        )
        break

      default:
        systemMessage = null
    }

    return (
      <div className="system-message">
        <div
          className="message-content system-message-element"
          style={{
            borderColor: planTypeDetails ? planTypeDetails.color : '#9068BE',
          }}
        >
          {systemMessage}
        </div>
        {getMessage()}
      </div>
    )
  }

  const getMessageContainer = () => {
    if (!isAlertMessage && !isCTMMessage && !isChatMessage) {
      return message.sender?.type !== 'System'
        ? getMessage()
        : getSystemMessage()
    }

    if (isAlertMessage) {
      return (
        <AlertMessage type={message.attachment?.item?.type}>
          {message.attachment?.title}
        </AlertMessage>
      )
    }

    if (isCTMMessage)
      return (
        <div className="ctm-alert message-content">
          <span className="icon">
            <BadgeIcon />
          </span>
          <b>{message.attachment?.title}&nbsp;</b>
          <span>{dStrings[message.domainEvent]}</span>
        </div>
      )

    // isChatMessage
    try {
      return (
        <div className="ctm-alert message-content">
          <span>
            {dStrings[message.domainEvent]}{' '}
            <b>{!!treatment && getProfileName(treatment.profile)}</b>
          </span>
        </div>
      )
    } catch (error) {
      console.error('invalid chat message', JSON.stringify(message))
      return (
        <div className="ctm-alert message-content">
          <span>{dStrings.errorOccurred}</span>
        </div>
      )
    }
  }

  const hasDayChanged =
    !previousMessageDate || !isSameDay(messageDate, previousMessageDate)

  return (
    <div className={messageClass}>
      {hasDayChanged && <ChatDate date={messageDate} />}
      <Box
        className="message"
        id={`message_id_${message.id}`}
        sx={{
          '&:hover .message-menu': {
            visibility: 'visible',
          },
        }}
      >
        <div
          className={`message-date-and-content ${
            !isAlertMessage && !isCTMMessage && !isChatMessage
              ? ''
              : 'alert-message'
          } patient-message-content`}
        >
          <div className="message-date xsmall-font">
            {displayIconAndDate &&
              (!isAlertMessage && !isCTMMessage && !isChatMessage && !ownMessage
                ? `${getMessageSenderName()}, `
                : '') + format(messageDate, dateFormatEnum.FULL_TIME)}
          </div>
          {getMessageContainer()}
          {displayMarkAsUnreadOptions &&
            !ownMessage &&
            events?.includes(message.domainEvent) && (
              <Box
                sx={{
                  position: 'absolute !important',
                  right: '0',
                  top: '-10px',
                }}
              >
                <PopupMenu
                  button={
                    <IconButton
                      className="message-menu"
                      size="small"
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <MoreHoriz />
                    </IconButton>
                  }
                >
                  <MenuItem
                    onClick={() => {
                      setChannelUnseen(treatment?.id, [message.id])
                        .then(() => {
                          loadNotifications({
                            hideLoader: true,
                            nextPage: undefined,
                            cb: undefined,
                          })
                          setMessageAsUnreaded(message.id)
                          handleMarkAsUnread()
                        })
                        .catch(() => {})
                    }}
                    key={dStrings.markAsUnread}
                  >
                    <ListItemText>{dStrings.markAsUnread}</ListItemText>
                  </MenuItem>
                </PopupMenu>
              </Box>
            )}
        </div>
      </Box>
    </div>
  )
}

export default connector(ChatMessage)
