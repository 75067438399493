import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './style.less'
import { connect } from 'react-redux'
import { Button } from '@valerahealth/ui-core'
import {
  Grid,
  Typography,
  useConfirmationDialog,
} from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import EditItemPopup from '../../PlanTemplate/EditItemPopup'
import {
  selectAllTreatments,
  unselectAllSelectedTreatments,
  assignItems,
} from '../../../redux/actions/caseloadActions'
import dStrings from '../../../strings.json'
import AssignCarePlanPopup from '../../common/AssignCarePlanPopup'
import {
  addUserMessage,
  displayConfirmation,
} from '../../../redux/actions/globalActions'
import { restorePatient } from '../../../redux/actions/treatmentRoom'
import { caseloadStateEnum } from '../../utilities/enums'
import RemoveIcon from '../../../images/remove.svg'
import { getSelectedCount } from '../../utilities/treatments'
import { getErrorMessage } from '../../../redux/actions/utilities'
import { getTemplateTitle } from '../../utilities/planTemplate'

function PatientsSelectedFooter({
  selectedCount,
  treatmentCount,
  filtered = false,
  caseloadState,
  toolsTemplates,
  selectedTreatments,
  selectedDischargedTreatments,
  unselectAllSelectedTreatments,
  assignItems,
  displayConfirmation,
  restorePatient,
  selectAllTreatments,
  addUserMessage,
}) {
  const { ConfirmationDialog, confirm } = useConfirmationDialog()
  const [displayAssignCPPopup, setDisplayAssignCPPopup] = useState(false)
  const [displaySendItemPopup, setDisplaySendItemPopup] = useState(false)
  const { t } = useTranslation()

  const filterText = () => {
    let text

    if (selectedCount !== treatmentCount) {
      if (filtered) {
        text = dStrings.selectedPatientsFiltered
      } else {
        text = dStrings.selectedPatients
      }

      text = text.replace('${selectedCount}', selectedCount)
    } else if (filtered) {
      text = dStrings.allFilteredPatientsSelected
    } else {
      text = dStrings.allPatientsSelected
    }

    text = text.replace('${treatmentCount}', treatmentCount)

    return text
  }

  if (
    caseloadState === caseloadStateEnum.DISCHARGE &&
    !selectedDischargedTreatments
  )
    return <span />

  return (
    <>
      {displaySendItemPopup && (
        <EditItemPopup
          bulkAction
          title={dStrings.SendItem}
          saveText={dStrings.send}
          handleClose={() => {
            setDisplaySendItemPopup(false)
          }}
          handleSave={async (item) => {
            if (selectedCount > 1) {
              const confirmed = await confirm({
                header: t('confirmSend'),
                body: (
                  <Grid>
                    {t('sendToAmountPatient')
                      ?.replace('${amount}', selectedCount)
                      .replace(
                        '${itemName}',
                        getTemplateTitle(toolsTemplates, item?.actionItem),
                      )}
                    {selectedCount === treatmentCount && (
                      <Typography color="error" variant="body2">
                        <Grid mt={1}>{t('allPatientWarning')}</Grid>
                      </Typography>
                    )}
                  </Grid>
                ),
                confirmLabel: t('yes'),
                cancelLabel: t('no'),
                confirmButtonColor: 'primary',
                cancelButtonColor: 'error',
              })

              if (!confirmed) return
            }

            assignItems(item.actionItem, (err) => {
              const obj = getErrorMessage(err)
              if (obj && obj.message) {
                addUserMessage('error', obj.message || dStrings.errorOccurred)
              }
            })
            setDisplaySendItemPopup(false)
          }}
        />
      )}
      <div className="list-footer">
        <div className="list-footer-num" style={{ display: 'flex' }}>
          <div className="selected-count">{filterText()}</div>
          {selectedCount !== treatmentCount && (
            <div
              className="select-all link-click"
              style={{ marginLeft: '28px' }}
              onClick={() => {
                selectAllTreatments()
              }}
            >
              {dStrings.selectAllPatients.replace(
                '${treatmentCount}',
                treatmentCount,
              )}
            </div>
          )}
        </div>
        <div className="footer-buttons">
          {caseloadState === caseloadStateEnum.ACTIVE ? (
            <>
              <Button
                type="secondary"
                text={dStrings.assignTracks}
                onClick={() => setDisplayAssignCPPopup(true)}
              />
              <Button
                type="secondary"
                text={dStrings.SendItem}
                onClick={() => setDisplaySendItemPopup(true)}
              />
            </>
          ) : (
            <Button
              type="primary"
              text={`${
                selectedDischargedTreatments.length > 1
                  ? dStrings.restorePatients
                  : dStrings.restorePatient
              }...`}
              onClick={() => {
                displayConfirmation({
                  details: {
                    title:
                      selectedDischargedTreatments.length > 1
                        ? dStrings.restorePatients
                        : dStrings.restorePatient,
                    content:
                      selectedDischargedTreatments.length > 1
                        ? dStrings.restorePatientsConfirmation
                        : dStrings.restorePatientConfirmation,
                  },
                  callback: () => {
                    restorePatient()
                  },
                })
              }}
            />
          )}
          <RemoveIcon
            className="remove-icon"
            onClick={unselectAllSelectedTreatments}
          />
        </div>
      </div>
      {displayAssignCPPopup && (
        <AssignCarePlanPopup
          bulkAction
          treatmentIds={selectedTreatments}
          handleClose={() => {
            setDisplayAssignCPPopup(false)
          }}
        />
      )}
      <ConfirmationDialog />
    </>
  )
}

PatientsSelectedFooter.propTypes = {
  selectedTreatments: PropTypes.array,
  selectAllTreatments: PropTypes.func,
  assignItems: PropTypes.func,
  unselectAllSelectedTreatments: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    filtered:
      state.caseload.filters.server &&
      Object.keys(state.caseload.filters.server).length > 0,
    caseloadState: state.caseload.caseloadState,
    selectedTreatments: state.caseload.selectedTreatments,
    selectedDischargedTreatments: state.caseload.selectedDischargedTreatments,
    treatmentCount: state.caseload && state.caseload.treatmentCount,
    selectedCount: getSelectedCount(state),
    toolsTemplates: state.toolsTemplates,
  }
}

const mapDispatchToProps = {
  selectAllTreatments,
  unselectAllSelectedTreatments,
  assignItems,
  displayConfirmation,
  restorePatient,
  addUserMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientsSelectedFooter)
