import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form as FormComponent } from '@valerahealth/ui-core'
import { setIntlPhoneNumber } from '@valerahealth/ui-components'
import dStrings from '../../../strings.json'
import { checkEmail } from '../../utilities'
import { additionalContactRelationEnum } from '../../utilities/enums'
import WarningIcon from '../../../images/warning-icon.svg'

function AdditionalContactFields({
  editedContact,
  handleChangeEditedContact,
  displayInvalid,
  relationsList,
}) {
  const [contact, setContact] = useState(editedContact)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [invalidMail, setInvalidMail] = useState(false)
  const [displayWarning, setDisplayWarning] = useState(false)

  useEffect(() => {
    setContact(editedContact)
  }, [])

  useEffect(() => {
    handleChangeEditedContact(contact)
  }, [contact])

  useEffect(() => {
    if (!displayInvalid) {
      if (invalidPhone) {
        setInvalidPhone(false)
      }

      if (invalidMail) {
        setInvalidMail(false)
      }

      return
    }

    if (
      (!contact.phone || !contact.phone.length) &&
      (!contact.email || !contact.email.length)
    ) {
      setDisplayWarning(true)
      return
    }

    if (contact.phone && !setIntlPhoneNumber(contact.phone)) {
      setInvalidPhone(true)
    } else {
      setInvalidPhone(false)
    }

    if (contact.email && !checkEmail(contact.email)) {
      setInvalidMail(true)
    } else {
      setInvalidMail(false)
    }
  }, [displayInvalid, contact])

  const fields = [
    {
      key: 'firstName',
      text: dStrings.firstName,
      type: 'text',
      required: true,
    },
    {
      key: 'lastName',
      text: dStrings.lastName,
      type: 'text',
    },
    {
      key: 'relation',
      text: dStrings.relation,
      type: 'select',
      select: relationsList,
      required: true,
    },
    {
      type: 'title',
      lineClass: 'contact-details-title',
      text: (
        <b style={{ color: 'inherit' }}>
          {dStrings.contactDetails} <span style={{ color: '#D24667' }}>*</span>
        </b>
      ),
    },
    {
      key: 'phone',
      text: dStrings.phone,
      type: 'text',
      invalid: invalidPhone,
      invalidMessage: invalidPhone && dStrings.invalidPhone,
    },
    {
      key: 'email',
      text: dStrings.email,
      placeholder: 'example@valerahealth.com',
      type: 'text',
      invalid: invalidMail,
      invalidMessage: invalidMail && dStrings.invalidMail,
    },
  ]

  return (
    <>
      <FormComponent
        fields={fields}
        data={contact}
        handleDataChange={setContact}
        displayInvalid={displayInvalid}
      />
      {displayWarning && (
        <div className="contact-warning">
          <WarningIcon />
          {dStrings.addPhoneOrMail}
        </div>
      )}
    </>
  )
}

AdditionalContactFields.propTypes = {
  editedContact: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
  let relationsList = {}

  try {
    state.program.configurations.contacts.relations.forEach((element) => {
      if (element !== 'Self') relationsList[element] = element
    })
  } catch (error) {
    relationsList = additionalContactRelationEnum
  }

  return {
    editedContact: ownProps.editedContact,
    handleChangeEditedContact: ownProps.handleChangeEditedContact,
    displayInvalid: ownProps.displayInvalid,
    relationsList,
  }
}

export default connect(mapStateToProps)(AdditionalContactFields)
