import { callApi } from '../../utilities'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import dStrings from '../../../../strings.json'

export const getAppointments = (from, to) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'getAppointments',
      dispatch,
      call: () => {
        return treatmentAPI.getAppointments(
          getState().treatmentRoom.selectedId,
          from && from.toISOString(),
          to && to.toISOString(),
        )
      },
      success: (appointments) => {
        const app = appointments.sort((a, b) => {
          return new Date(a.date).getTime() < new Date(b.date).getTime()
            ? -1
            : 1
        })

        dispatch({
          type: types.LOAD_PROFILE_APPOINTMENT,
          appointments: app,
          date: app && app[0] && app[0].date,
        })
      },
    })
  }
}

export const getAppointment = (appointmentId) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'getAppointment',
      dispatch,
      call: () => {
        return treatmentAPI.getAppointment(
          getState().treatmentRoom.selectedId,
          appointmentId,
        )
      },
      success: (appointment) => {
        dispatch({
          type: types.GET_APPOINTMENT_DETAILS,
          appointment,
        })
      },
    })
  }
}

export const editAppointment = (data, appointmentId) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'editAppointment',
      dispatch,
      successMessage: dStrings.appointSaved,
      call: () => {
        return treatmentAPI.editAppointment(
          getState().treatmentRoom.selectedId,
          data,
          appointmentId,
        )
      },
      success: (appointment) => {
        dispatch({
          type: types.EDIT_APPOINTMENT_SUCCESS,
          appointment,
        })
      },
    })
  }
}

export const deleteAppointment = (id) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'deleteAppointment',
      dispatch,
      successMessage: dStrings.appointDeleted,
      call: () => {
        return treatmentAPI.deleteAppointment(
          getState().treatmentRoom.selectedId,
          id,
        )
      },
      success: () => {
        dispatch({
          type: types.DELETE_APPOINTMENT_SUCCESS,
          id,
        })
      },
    })
  }
}

export const addAppointment = (data, cb) => {
  return (dispatch, getState) => {
    const state = getState()
    const { selectedId } = state.treatmentRoom
    return callApi({
      name: 'addAppointment',
      dispatch,
      successMessage: dStrings.appointAdded,
      call: () => {
        return treatmentAPI.addAppointment(
          selectedId,
          state.treatmentRoom.byId[selectedId].patientId,
          data,
        )
      },
      success: (data) => {
        dispatch({
          type: types.ADD_APPOINTMENT_SUCCESS,
          data,
        })

        cb?.()
      },
    })
  }
}
