import {
  ComponentType,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CircularProgress } from '@mui/material'
import { Box, Stack, type SxProps } from '@mui/system'
import { throttle, useTranslation } from '../../utils'

export type InfiniteScrollGetData<DataType, PagingToken> = (
  pagingToken?: PagingToken,
) => Promise<{
  data: DataType[]
  pagingToken?: PagingToken
}>

export type InfiniteScrollType<DataType, PagingToken> = {
  /** styles applied to the container component */
  sx?: SxProps
  endMessage?: ReactNode
  /** callback (MAKE SURE IT DOESN'T CHANGE BETWEEN RENDERS) should accept whatever it neads to page, and in the promise response return what is needed to initiate the next page. if paging token is undefined it means we've retrieved all the results. make sure to handle errors internally in the callback */
  getData: InfiniteScrollGetData<DataType, PagingToken>
  /** a component which accepts DataType as its props */
  ListItem: ComponentType<DataType>
  /** used to key the children */
  getItemKey: (data: DataType) => string
}

const calcHasReachedBottom = ({ current }: RefObject<HTMLDivElement>) => {
  return (
    !!current &&
    current.scrollHeight - current.offsetHeight - current.scrollTop < 1
  )
}

export function InfiniteScroll<DataType, PagingToken>({
  sx,
  getData,
  endMessage,
  ListItem,
  getItemKey,
}: InfiniteScrollType<DataType, PagingToken>) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [records, setRecords] = useState<DataType[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [pagingToken, setPagingToken] = useState<PagingToken>()
  const [hasReachedBottom, setHasReachedBottom] = useState(false)
  const { t } = useTranslation()

  const fetch = useCallback(() => {
    setIsLoading(true)
    return getData(pagingToken).then((res) => {
      setRecords((records) => (records || []).concat(res.data))
      setPagingToken(res.pagingToken)
      setIsLoading(false)
    })
  }, [getData, pagingToken])

  // useCallback has a issue with having a generic fn as the argument
  const onScroll = useMemo(
    () =>
      throttle(() => {
        setHasReachedBottom(calcHasReachedBottom(containerRef))
      }, 100),
    [],
  )

  /** want to check height after list changes (in case we have not gotten tall enough to even have a scrollbar), in addition to the scroll event */
  useEffect(() => {
    if (records?.length) {
      onScroll()
    }
  }, [records?.length, onScroll])

  const hasRecords = !!records
  /** kick off first API call when component mounts (only time records is undefined) */
  useEffect(() => {
    if (!hasRecords) {
      fetch()
    }
  }, [fetch, hasRecords])

  /** reset if our fn changes changes */
  useEffect(() => {
    setRecords(undefined)
    setIsLoading(false)
    setPagingToken(undefined)
    setHasReachedBottom(false)
  }, [getData])

  /** MAIN EFFECT - if we have reached the bottom and have finished loading the last request and there is still a paging token then trigger the next call */
  useEffect(() => {
    if (!isLoading && hasReachedBottom && pagingToken) {
      fetch()
    }
  }, [hasReachedBottom, pagingToken, isLoading, fetch])

  return (
    <Stack
      sx={{
        ...sx,
        overflowY: 'auto',
      }}
      ref={containerRef}
      onScroll={onScroll}
    >
      {records?.map((r) => (
        <ListItem {...r} key={getItemKey(r)} />
      ))}
      {isLoading ? (
        <Box sx={{ p: 2, alignSelf: 'center' }}>
          <CircularProgress />
        </Box>
      ) : records && !pagingToken ? (
        <Box sx={{ alignSelf: 'center' }}>
          {endMessage || t('InfiniteScroll.allLoaded')}
        </Box>
      ) : null}
    </Stack>
  )
}
