import { ReactNode } from 'react'
import { Stack, SxProps } from '@mui/material'
import { Theme } from '../../../theme'

export type WorkflowSidebarActionsProps = {
  sx?: SxProps<Theme>
  children: ReactNode | ReactNode[]
}
export function WorkflowSidebarActions({
  sx,
  children,
}: WorkflowSidebarActionsProps) {
  return (
    <Stack
      sx={sx}
      direction="row"
      justifyContent="flex-end"
      p={2}
      gap={2}
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
    >
      {children}
    </Stack>
  )
}
