import { useMemo } from 'react'
import { useReduxSelector } from 'redux/store'
import { careManagerEnhancedApi } from 'api/careManagerAPIEnhanced'

export function useGetNotificationsForSignedInUser(
  pageNumber: number = 1,
  pageSize: number = 100,
) {
  const careManagerId = useReduxSelector(
    (state) => state.auth.session?.user.careManagerId,
  )

  const { treatmentNotifications: _treatmentNotifications, isLoading } =
    careManagerEnhancedApi.useGetNotificationsQuery(
      {
        careManagerId: careManagerId!,
        pageSize,
        pageNumber,
      },
      {
        skip: !careManagerId,
        selectFromResult: ({ data, isLoading }) => ({
          treatmentNotifications: data,
          isLoading,
        }),
      },
    )
    
  const treatmentNotifications = useMemo(() => {
    const temp = (_treatmentNotifications || []).map((n) => ({
      ...n,
      unread: n.unread?.filter((m) => m.domainEvent === 'chat_message_sent'),
    }))
    return temp?.filter((n) => n.unread && !!n.unread.length) || []
  }, [_treatmentNotifications])

  const idsWithTreatmentNotificationMap = useMemo(
    () =>
      new Map(
        treatmentNotifications?.map((n) => {
          const msgs = n.unread!
          return [n.id, msgs]
        }),
      ),
    [treatmentNotifications],
  )

  const idsWithTreatmentNotificationArray = useMemo(
    () => treatmentNotifications?.map((n) => n.id) || [],
    [treatmentNotifications],
  )

  return {
    // _meta: { treatmentNotifications: _treatmentNotifications }, // unfiltered notifications
    idsWithTreatmentNotificationArray,
    isLoading,
    idsWithTreatmentNotificationMap,
    treatmentNotifications,
  }
}
