// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.day-range {
  width: 190px;
  height: 32px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  background-color: #8a608b;
  padding: 8px 10px;
  border-radius: 16px;
  color: #ffffff !important;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 95px;
}
.day-range.past-event {
  background-color: #e2e2e2;
}
.items-num {
  width: 15px;
  height: 15px;
  background: white;
  text-align: center;
  font-size: 10px;
  border-radius: 15px;
}

.day-num {
  color: #ffffff;
}

.items-num.filtered {
  color: #e30930;
}
`, "",{"version":3,"sources":["webpack://./src/components/PlanTemplate/Content/Views/Timeline/DayRange/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,8BAA8B;EAC9B,wBAAwB;EACxB,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".day-range {\n  width: 190px;\n  height: 32px;\n  margin-right: 20px;\n  display: flex;\n  align-items: center;\n  background-color: #8a608b;\n  padding: 8px 10px;\n  border-radius: 16px;\n  color: #ffffff !important;\n  justify-content: space-between;\n  position: -webkit-sticky;\n  position: sticky;\n  top: 95px;\n}\n.day-range.past-event {\n  background-color: #e2e2e2;\n}\n.items-num {\n  width: 15px;\n  height: 15px;\n  background: white;\n  text-align: center;\n  font-size: 10px;\n  border-radius: 15px;\n}\n\n.day-num {\n  color: #ffffff;\n}\n\n.items-num.filtered {\n  color: #e30930;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
