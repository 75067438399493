import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { type GraphQLResponse } from 'graphql-request/src/types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import {
  AUTH_REDUCER_KEY,
  Permission,
  RootStateWithAuth,
} from '@valerahealth/redux-auth'
import {
  createService,
  initApplyPermissionheaders,
  type EndpointPermissions,
} from '../../utils'
import { api as endpoints } from './generated'
import {
  AppointmentStatus,
  ResourceType,
  ServiceTypeCode,
} from '../../shared/generated.types'

export type CustomErrorResponse<D = any> = {
  message: string
  stack: string
  name: string
  response: GraphQLResponse<D>
}

// this comes from backend monorepo, applications\scheduling-api\src\services\AppointmentService.ts
export type AppointmentConflict = {
  _id: string
  startDate: string
  duration?: number
  endDate: string
  status: AppointmentStatus
  serviceType?: ServiceTypeCode
}
export type AppointmentConflictParticipantRecord = Record<
  string, // in format "[ID]-{ResourceType}"
  AppointmentConflict[]
>

const endpointPermissions: EndpointPermissions = {
  getSchedule: Permission.Schedule_Read,
  searchSchedules: Permission.Schedule_Read,
  getSchedulesByProviderId: Permission.Schedule_Read,
  createSchedule: Permission.Schedule_Create,
  updateSchedule: Permission.Schedule_Update,
  deleteSchedule: Permission.Schedule_Delete,
  getAppointment: Permission.Appointment_Read,
  searchAppointments: Permission.Appointment_Read,
  createAppointment: Permission.Appointment_Create,
  createAppointmentV2: Permission.Appointment_Create,
  updateAppointment: Permission.Appointment_Update,
  deleteAppointment: Permission.Appointment_Delete,
  updateReoccuranceTemplate: Permission.Appointment_Update,
  getConflictApptSchedules: [
    Permission.Schedule_Read,
    Permission.Appointment_Read,
  ],
}

export type ConflictErrorResponse = ErrorResponse & {
  patientConflictAppt: AppointmentConflict[]
  providerConflictAppt: AppointmentConflict[]
  isConflict: boolean
}

const transferApptConflictErrorRes = (
  error: CustomErrorResponse,
): CustomErrorResponse | ConflictErrorResponse => {
  if (error.message && error.message.includes('appointment_overlap')) {
    try {
      const errorMsg = error.response.errors?.find((e) =>
        e.message.includes('appointment_overlap'),
      )?.message!
      const [, json] = errorMsg.split(' ')
      const obj = JSON.parse(
        json as string,
      ) as AppointmentConflictParticipantRecord
      const objArray = Object.entries(obj)
      const patientConflictAppt = objArray?.find((o) =>
        o[0].includes(ResourceType.Patient),
      )
      const providerConflictAppt = objArray?.find((o) =>
        o[0].includes(ResourceType.Practitioner),
      )
      const conflictError = {
        ...error,
        isConflict: true,
        patientConflictAppt:
          (patientConflictAppt && patientConflictAppt[1]) || [],
        providerConflictAppt:
          (providerConflictAppt && providerConflictAppt[1]) || [],
      }
      return conflictError
    } catch (e) {
      console.error('Failed to parse error response for appointment_overlap', e)
    }
  }
  return error
}

const schedulingApiService = createService(({ origin, getAccessToken }) => {
  const applyPermissionHeaders = initApplyPermissionheaders(endpointPermissions)

  const api = createApi({
    reducerPath: 'schedulingApi',
    tagTypes: ['schedule', 'appointment', 'reoccuranceTemplate'],
    endpoints,
    keepUnusedDataFor: 120,
    baseQuery: graphqlRequestBaseQuery<CustomErrorResponse>({
      url: `${origin}/graphql`,
      prepareHeaders: (headers, api) => {
        const state = api.getState() as RootStateWithAuth
        const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]

        if (isAuthenticated) {
          const accessToken = getAccessToken(state)
          headers.set('Authorization', `Bearer ${accessToken}`)
          applyPermissionHeaders(headers, session!, api.endpoint)
        }

        return headers
      },
      customErrors: ({ message, stack, name, response }) => ({
        message,
        stack: stack || '',
        name,
        response,
      }),
    }),
  })

  api.enhanceEndpoints({
    endpoints: {
      getSchedule: {
        keepUnusedDataFor: 300, // schedules dont update very frequently, safe to cache for longer duration
        providesTags: (res) => {
          const id = res?.getSchedule?._id
          return id ? [{ type: 'schedule', id }] : []
        },
      },
      searchSchedules: {
        keepUnusedDataFor: 300, // schedules dont update very frequently, safe to cache for longer duration
        providesTags: (res) => {
          const schedules = res?.searchSchedules?.results || []
          const tags = schedules.length
            ? schedules.map(
                ({ _id }) =>
                  ({
                    type: 'schedule',
                    id: _id,
                  } as const),
              )
            : (['schedule'] as const)
          return tags
        },
      },
      getSchedulesByProviderId: {
        keepUnusedDataFor: 300, // schedules dont update very frequently, safe to cache for longer duration
        providesTags: (res) => {
          const schedules = res?.searchSchedules?.results || []
          const tags = schedules.length
            ? schedules.map(
                ({ _id }) =>
                  ({
                    type: 'schedule',
                    id: _id,
                  } as const),
              )
            : (['schedule'] as const)
          return tags
        },
      },
      createSchedule: {
        invalidatesTags: ['schedule'],
      },
      updateSchedule: {
        invalidatesTags: (res) => {
          const id = res?.updateSchedule?._id
          return id ? [{ id, type: 'schedule' }] : []
        },
      },
      deleteSchedule: {
        invalidatesTags: (res) => {
          const id = res?.deleteSchedule?._id
          return id ? [{ id, type: 'schedule' }] : []
        },
      },
      getAppointment: {
        providesTags: (res) => {
          const { _id, reoccuranceTemplate } = res?.getAppointment || {}
          const templateId = reoccuranceTemplate?.id
          if (!_id) return []
          return [
            { type: 'appointment' as const, id: _id },
            ...(templateId
              ? [
                  {
                    type: 'reoccuranceTemplate' as const,
                    id: templateId,
                  },
                ]
              : []),
          ]
        },
      },
      searchAppointments: {
        providesTags: (res) => {
          const appointments = res?.searchAppointments?.results || []
          const apptTags = appointments.length
            ? appointments.map(
                ({ _id }) =>
                  ({
                    type: 'appointment',
                    id: _id,
                  } as const),
              )
            : ['appointment' as const]
          const templateTags = appointments
            .map((v) => v.reoccuranceTemplate?.id)
            .filter((v: string | undefined): v is string => !!v)
            .map((id) => ({
              type: 'reoccuranceTemplate' as const,
              id,
            }))

          return [...apptTags, ...templateTags]
        },
      },
      getAppointmentsByProviderId: {
        providesTags: (res) => {
          const appointments = res?.searchAppointments?.results || []
          const apptTags = appointments.length
            ? appointments.map(
                ({ _id }) =>
                  ({
                    type: 'appointment',
                    id: _id,
                  } as const),
              )
            : ['appointment' as const]
          const templateTags = appointments
            .map((v) => v.reoccuranceTemplate?.id)
            .filter((v: string | undefined): v is string => !!v)
            .map((id) => ({
              type: 'reoccuranceTemplate' as const,
              id,
            }))
          return [...apptTags, ...templateTags]
        },
      },
      getPatientAppointmentsByDateRange: {
        providesTags: (res) => {
          const appointments = res?.searchAppointments?.results || []
          const apptTags = appointments.length
            ? appointments.map(
                ({ _id }) =>
                  ({
                    type: 'appointment',
                    id: _id,
                  } as const),
              )
            : ['appointment' as const]

          const templateTags = appointments
            .map((v) => v.reoccuranceTemplate?.id)
            .filter((v: string | undefined): v is string => !!v)
            .map((id) => ({
              type: 'reoccuranceTemplate' as const,
              id,
            }))

          return [...apptTags, ...templateTags]
        },
      },
      getNextLastApptByTreatmentId: {
        providesTags: (res) => {
          const appointments = [
            ...(res?.getLastApptByPatientId?.results || []),
            ...(res?.getNextApptByPatientId?.results || []),
          ]
          return appointments.length
            ? appointments.map(
                ({ _id }) =>
                  ({
                    type: 'appointment',
                    id: _id,
                  } as const),
              )
            : ['appointment' as const]
        },
      },
      createAppointment: {
        invalidatesTags: ['appointment'],
        transformErrorResponse: transferApptConflictErrorRes,
      },
      updateAppointment: {
        invalidatesTags: (res, err, req) => [
          { type: 'appointment' as const, id: req.id },
        ],
        transformErrorResponse: transferApptConflictErrorRes,
      },
      deleteAppointment: {
        invalidatesTags: (res, err, req) => [
          { type: 'appointment' as const, id: req.id },
        ],
      },
      createAppointmentV2: {
        invalidatesTags: ['appointment'],
        transformErrorResponse: transferApptConflictErrorRes,
      },
      updateReoccuranceTemplate: {
        invalidatesTags: (res, err, req) => [
          { type: 'reoccuranceTemplate', id: req.id },
        ],
      },
    },
  })
  return api
})

export const schedulingApi = schedulingApiService({
  origin: `https://${process.env.SCHEDULING_API_DOMAIN}`,
})
export type SchedulingApiType = typeof schedulingApi
