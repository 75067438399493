import type { IconButtonProps } from '@mui/material'
import IconButton from '../IconButton'
import Link, { type LinkProps } from '../Link'

export type IconButtonLinkProps = Omit<
  IconButtonProps<'a', LinkProps>,
  'component'
>

export default function IconButtonLink(props: IconButtonLinkProps) {
  return (
    // @ts-expect-error not sure why it complains about this
    <IconButton component={Link} {...props} />
  )
}
