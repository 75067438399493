// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-button-component {
  color: #4285f4;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  cursor: pointer;
}

.icon-button-component.not-btn {
  cursor: inherit;
}
.icon-button-component.disable-btn {
  cursor: default;
  
}
.icon-button-component.disable-btn svg path {
  fill: #b9c2cb;
}
.icon-button-component.disable-btn svg polygon {
  fill: red;
}

.icon-button-component span {
  color: #4285f4;
  margin: 0 0 0 10px;
}
`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/IconButton/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;;AAEjB;AACA;EACE,aAAa;AACf;AACA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".icon-button-component {\n  color: #4285f4;\n  font-size: 13px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 18px;\n  cursor: pointer;\n}\n\n.icon-button-component.not-btn {\n  cursor: inherit;\n}\n.icon-button-component.disable-btn {\n  cursor: default;\n  \n}\n.icon-button-component.disable-btn svg path {\n  fill: #b9c2cb;\n}\n.icon-button-component.disable-btn svg polygon {\n  fill: red;\n}\n\n.icon-button-component span {\n  color: #4285f4;\n  margin: 0 0 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
