//redux component
import './style.css'
import { getMessageDateTime } from '../../utilities/treatments'
import { domainEventEnum } from '../../utilities/enums'
import dStrings from '../../../strings.json'
import { useGetNotificationsForSignedInUser } from "../../PatientSearch/useGetNotifications"
import { CMChannelItemOverview, TreatmentNotification } from '@valerahealth/rtk-query'
// @ts-ignore
import IconCount from '@valerahealth/ui-core/src/components/IconCount'

export const getMessageContent = (message: CMChannelItemOverview) => {
  if (message.domainEvent !== domainEventEnum.APPOINTMENT_RESCHEDULED) {
    return (
      <>
        <span className="message-name">{message.name}: </span> {message.text}
      </>
    )
  }

  return (
    <>
      <span className="message-name">
        {dStrings.rescheduleRequest} - {message.custom?.providerName}
      </span>
      {message.custom?.notes && (
        <div>
          {dStrings.patientNote}: "{message.custom?.notes}"
        </div>
      )}
    </>
  )
}

const UnreadChannelItem = ({ treatmentId }: { treatmentId: string }) => {
  const { treatmentNotifications } = useGetNotificationsForSignedInUser()
  const messages = treatmentNotifications.find(
    (n) => n.id === treatmentId,
  )?.unread
  const message = messages && messages[0]


  return message ? (
    <div className="event-channel-messages">
      <div className="message-line">
        <div className="message-text">{getMessageContent(message)}</div>
        <div className="message-time xsmall-font">
          {getMessageDateTime(message.ts)}
        </div>
      </div>
      {messages.length && (
        <IconCount count={messages.length} elementClass="xxxxsmall-font" />
      )}
    </div>
  ) : (
    <span className="no-chats xsmall-font">{dStrings.noNewNotification}</span>
  )
}



export default UnreadChannelItem
