import { callApi } from '../../utilities'
import { addUserMessage } from '../../globalActions'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import dStrings from '../../../../strings.json'

export const getAlerts = () => {
  return (dispatch, getState) => {
    return callApi({
      name: 'getAlerts',
      dispatch,
      call: () => {
        return treatmentAPI.getAlerts(getState().treatmentRoom.selectedId)
      },
      success: (alerts) => {
        dispatch({
          type: types.LOAD_PROFILE_ALERTS_SUCCESS,
          alerts: alerts.sort((a, b) => {
            return a.ts < b.ts ? 1 : -1
          }),
        })
      },
    })
  }
}

export const resolveAlert = (data, cb) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'resolveAlert',
      dispatch,
      call: () => {
        return treatmentAPI.resolveAlerts(
          getState().treatmentRoom.selectedId,
          data,
        )
      },
      success: (alerts) => {
        // replace with receive alerts
        dispatch({
          type: types.RESOLVE_ALERTS_SUCCESS,
          alerts,
          ids: data.alertIds,
          treatmentId: getState().treatmentRoom.selectedId,
        })

        cb?.(alerts)

        dispatch(
          addUserMessage(
            'success',
            data.alertIds.length > 1
              ? dStrings.alertsSucessfullyResolved
              : dStrings.alertSucessfullyResolved,
          ),
        )
      },
    })
  }
}
