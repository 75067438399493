// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.day-cursor {
  border: 1px solid #317b8c;
  position: relative;
  margin-left: -14px;
}
.day-cursor .cursor-ball {
  background-color: #317b8c;
  top: -7.5px;
  left: -6.5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/DayCursor/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd","sourcesContent":[".day-cursor {\n  border: 1px solid #317b8c;\n  position: relative;\n  margin-left: -14px;\n}\n.day-cursor .cursor-ball {\n  background-color: #317b8c;\n  top: -7.5px;\n  left: -6.5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
