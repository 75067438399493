import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CaseloadHeader from './Header'
import CaseloadFilters from './Filters'
import TreatmentsList from './TreatmentsList'
import PatientsSelectedFooter from './PatientsSelectedFooter'
import './style.less'
import {
  loadTreatments,
  loadPendingTreatments,
  resetGoToCaseload,
  loadCaseloadFilterNumbers,
} from '../../redux/actions/caseloadActions'
import { caseloadStateEnum } from '../utilities/enums'

function ProviderCaseload({
  user,
  displayFilterMenu,
  goToCaseload,
  clientFilters,
  selectedTreatments,
  selectedDischargedTreatments,
  caseloadState,
  loadTreatments,
  resetGoToCaseload,
  loadPendingTreatments,
  loadCaseloadFilterNumbers,
}) {
  const [withFooter, setWithFooter] = useState(false)
  const [resetScroll, setResetScroll] = useState(false)

  useEffect(() => {
    if (!resetScroll) return

    setTimeout(() => {
      setResetScroll(false)
    }, 500)
  }, [resetScroll])

  const getClass = () => {
    switch (caseloadState) {
      case caseloadStateEnum.ACTIVE:
        return 'caseload-page'
      case caseloadStateEnum.DISCHARGE:
        return 'discharge-caseload-page'
      default:
        return 'pending-page'
    }
  }

  useEffect(() => {
    if (user && user.id && !clientFilters) loadCaseloadFilterNumbers()
  }, [user, clientFilters])

  useEffect(() => {
    if (!user.id) return
    if (
      [caseloadStateEnum.DISCHARGE, caseloadStateEnum.ACTIVE].indexOf(
        caseloadState,
      ) !== -1
    ) {
      loadTreatments({})
    } else {
      loadPendingTreatments()
    }
  }, [caseloadState, user?.id])

  useEffect(() => {
    if (goToCaseload) resetGoToCaseload()
  }, [goToCaseload])

  useEffect(() => {
    if (caseloadState === caseloadStateEnum.ACTIVE) {
      setWithFooter(selectedTreatments?.length > 0)
      return
    }

    return setWithFooter(selectedDischargedTreatments?.length > 0)
  }, [caseloadState, selectedTreatments, selectedDischargedTreatments])

  return (
    <div className={getClass()}>
      <CaseloadHeader />
      <div
        className={`caseload-page-content ${withFooter ? 'with-footer' : ''}`}
      >
        {displayFilterMenu && (
          <CaseloadFilters
            handleLoadTreatment={() => {
              setResetScroll(true)
            }}
          />
        )}
        <TreatmentsList resetScroll={resetScroll} />
      </div>
      {withFooter ? <PatientsSelectedFooter /> : ''}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: state.global.user || {},
    caseloadState: state.caseload.caseloadState,
    treatments: state.caseload.treatments,
    discharged: state.caseload.discharged,
    pending: state.caseload.pending,
    displayFilterMenu: state.caseload.displayFilterMenu,
    selectedTreatments: state.caseload.selectedTreatments,
    selectedDischargedTreatments: state.caseload.selectedDischargedTreatments,
    goToCaseload: state.caseload.goToCaseload,
    clientFilters: state.caseload.filters.client,
  }
}

const mapDispatchToProps = {
  loadTreatments,
  loadPendingTreatments,
  resetGoToCaseload,
  loadCaseloadFilterNumbers,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderCaseload)
