import { Box, Typography } from '@valerahealth/ui-components'
import { format } from '@valerahealth/ui-components/utils/date'
import {
  AppointmentStatus,
  ServiceCategoryCode,
  ServiceTypeCode,
} from '@valerahealth/rtk-query'
import { EventBusy } from '@mui/icons-material'
import { AppointmentEventType, CalendarEventType } from './Calendar.type'
import { useAppointmentDetailsPopup } from './useAppointmentDetailsPopup'

interface Props {
  event: CalendarEventType
}

const isOOO = (event: CalendarEventType) =>
  event.resource.type === 'appointment' &&
  event.resource.serviceCategory === ServiceCategoryCode.OutOfOffice

const getTitleText = (event: AppointmentEventType) =>
  `${event.start ? format(event.start, 'p') : ''} ${
    event.resource.patient?.display || ''
  } ${event.resource.serviceType || ServiceTypeCode.Other}`

const getOOOTitle = (event: AppointmentEventType) =>
  `OOO - From ${event.start ? format(event.start, 'Pp') : ''} to ${
    event.end ? format(event.end, 'Pp') : ''
  }`

export function EventMonth({ event: _event }: Props) {
  const { setAnchorEl, AppointmentDetailsPopup } = useAppointmentDetailsPopup(
    _event as AppointmentEventType,
  )
  const event = _event as AppointmentEventType

  const { topColor } = event.resource.palette

  return isOOO(event) ? (
    <>
      <Box
        title={getOOOTitle(event)}
        display="flex"
        flexDirection="row"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          ml: '4px',
          mr: '4px',
          width: '99%',
          height: '1.3rem',
          backgroundColor: (theme) => theme.palette.action.disabled,
          borderRadius: '4px',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <EventBusy
          sx={{
            ml: '2px',
            color: (theme) => theme.palette.action.active,
            fontSize: '1.2rem',
          }}
        />
        <Typography
          sx={{
            ml: '6px',
            color: (theme) => theme.palette.action.active,
            fontSize: '0.8rem',
          }}
        >
          {getOOOTitle(event)}
        </Typography>
      </Box>
      {AppointmentDetailsPopup}
    </>
  ) : (
    <>
      <Box
        onClick={(e) => setAnchorEl(e.currentTarget)}
        title={getTitleText(event)}
        sx={{
          color: (theme) => theme.palette.primary.dark,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
      >
        <Box
          title={getTitleText(event)}
          sx={{
            height: '1rem',
            width: '1rem',
            ml: '4px',
            background: (theme) =>
              event.resource.status === AppointmentStatus.Proposed
                ? `repeating-linear-gradient(45deg, ${topColor}, ${topColor} 4px, ${theme.palette.withAlpha(
                    topColor!,
                    0.6,
                  )} 4px, ${theme.palette.withAlpha(topColor!, 0.6)} 8px)`
                : topColor,
            display: 'inline-block',
          }}
        />
        <Typography
          title={getTitleText(event)}
          sx={{
            mx: 0.5,
            fontSize: '0.8rem',
            height: '1rem',
            display: 'inline',
            position: 'relative',
            bottom: '4px',
            color: (theme) => theme.palette.primary.dark,
            textDecoration: event?.resource.isCanceled
              ? 'line-through'
              : undefined,
          }}
        >
          {event.resource.status === AppointmentStatus.Fulfilled ? (
            <b>{getTitleText(event)}</b>
          ) : (
            getTitleText(event)
          )}
        </Typography>
      </Box>
      {AppointmentDetailsPopup}
    </>
  )
}
