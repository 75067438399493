import React, { useEffect, useState } from 'react'
import { Popup } from '@valerahealth/ui-core'
import dStrings from '../../strings.json'
import PrescriptionIcon from '../../images/prescription_history.svg'
import './style.less'
import { getTreatmentMedication } from '../../api/treatmentAPI'
import { openContent } from '../utilities'

function MedicationPopup({ itemId, handleClose }) {
  const [data, setData] = useState()

  useEffect(() => {
    if (itemId) {
      getTreatmentMedication(itemId)
        .then((d) => {
          setData(d)
        })
        .catch((e) => {
          console.log('Cannot load medication')
        })
    }
  }, [itemId])

  const getButtons = () => {
    const withLink = data && data.drChronoLink

    const btns = [
      {
        text: dStrings.close,
        type: withLink ? 'link' : 'primary',
        onClick: () => {
          handleClose()
        },
      },
    ]

    if (withLink) {
      btns.push({
        text: (
          <span style={{ color: 'inherit' }}>
            {dStrings.openPrescriptionHistory} <PrescriptionIcon />
          </span>
        ),
        type: 'primary',
        onClick: () => {
          openContent(data.drChronoLink)
        },
      })
    }

    return btns
  }

  if (!data) return <span />

  return (
    <Popup
      title={dStrings.refillRequest}
      onClosed={() => {
        handleClose()
      }}
      buttons={getButtons()}
    >
      <div className="medication-popup">
        <div className="medication-info bottom-border">
          <span className="small-font title">
            <b>{dStrings.medicationName}</b>
          </span>
          <span className="content">{data.medication}</span>
        </div>
        {data.pharmacy && (
          <>
            <div className="medication-info">
              <span className="small-font title">
                <b>{dStrings.pharmacyName}</b>
              </span>
              <span className="content">
                {data.pharmacy.name || dStrings.defaultPharmacy}
              </span>
            </div>
            <div className="medication-info bottom-border">
              <span className="small-font title">
                <b>{dStrings.pharmacyAddress}</b>
              </span>
              <span className="content">{data.pharmacy.address || '-'}</span>
            </div>
          </>
        )}
        <div className="medication-info">
          <span className="small-font title">
            <b>{dStrings.patientNote}</b>
          </span>
          <span className="content patient-note ">{data.notes}</span>
        </div>
      </div>
    </Popup>
  )
}
export default MedicationPopup
