import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import CloseIcon from '../../images/icon_close.svg'

function TimePicker({ value, onChange, separator = ':', className, disabled }) {
  const [minutes, setMinutes] = useState('00')
  const [hours, setHours] = useState('00')
  const [time, setTime] = useState('null')
  const hourRef = useRef(null)
  const minutesRef = useRef(null)
  const selectAllHours = () => {
    hourRef.current.select()
  }

  const selectAllMinutes = () => {
    const h = hours
    if (h) minutesRef.current.select()
  }

  const reset = () => {
    setMinutes('00')
    setHours('00')
    setTime('null')
  }

  const initValue = () => {
    if (!value) return
    const a = value.split(separator)
    if (a[0] && a[0].length) {
      const b = parseInt(a[0])
      if (isNaN(b) || b > 23) return
      if (b < 10) {
        setTime('am')

        if (b === 0) setHours('12')
        else setHours(`0${b}`)
      } else if (b >= 12) {
        setTime('pm')

        if (b === 12) setHours('12')
        else setHours(((b % 13) + 1).toString())
      } else {
        setHours(b.toString())
        setTime('am')
      }
    }

    if (a[1] && a[1].length) {
      const c = parseInt(a[1])
      if (isNaN(c) || c > 59) return
      if (c > 9) setMinutes(c)
      else {
        setMinutes(`0${c}`)
      }
    }
  }

  useEffect(() => {
    initValue()
  }, [value])

  useEffect(() => {
    if (!time || time === 'null') return
    if (time === 'am') {
      onChange(`${parseInt(hours) % 12}:${minutes}`)
    } else {
      const h = parseInt(hours)
      if (h !== 12) onChange(`${h + 12}:${minutes}`)
      else {
        onChange(`${12}:${minutes}`)
      }
    }
  }, [minutes, hours, time])

  return (
    <div
      className={`timepicker ${className || ''}${disabled ? 'disabled' : ''}`}
    >
      <input
        ref={hourRef}
        className="timepicker-input timepicker-hours"
        onFocus={selectAllHours}
        value={hours}
        type="number"
        placeholder="--"
        min={1}
        max={12}
        onChange={(e) => {
          let v = 0
          if (e.target.value === '00') v = 12
          else if (e.target.value && e.target.value.length)
            v = parseInt(e.target.value)
          else {
            v = e.target.value
          }

          if (v > 12) v = (v % 13) + 1

          if (v > 1) minutesRef.current.focus()

          if (v > 9) setHours(v.toString())
          else {
            setHours(`0${v}`)
          }
        }}
      />
      {separator}
      <input
        ref={minutesRef}
        className="timepicker-input timepicker-minutes"
        onFocus={selectAllMinutes}
        value={minutes}
        type="number"
        placeholder="--"
        min={0}
        max={59}
        onChange={(e) => {
          let v = e.target.value
          if (v === '0') {
            setMinutes('00')
            return
          }
          if (v && v.length) v = parseInt(v)

          if (v > 59) v = 59

          if (v > 9) setMinutes(v.toString())
          else {
            setMinutes(`0${v}`)
          }
        }}
      />
      <select
        value={time}
        onChange={(e) => {
          setTime(e.target.value)
        }}
      >
        <option value="null">--</option>
        <option value="am">AM</option>
        <option value="pm">PM</option>
      </select>
      <span className="close-btn">
        <CloseIcon onClick={reset} />
      </span>
    </div>
  )
}

TimePicker.propTypes = {
  value: PropTypes.string,
  separator: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

export default TimePicker
