import { useMemo, useCallback } from 'react'
import {
  Stack,
  Typography,
  Button,
  Box,
  useConfirmationDialog,
  useNotify,
  InfiniteScroll,
  InfiniteScrollGetData,
  WorkflowSidebarContent,
  WorkflowSidebarActions,
  ConfirmTrigger,
} from '@valerahealth/ui-components'
import { startOfDay } from '@valerahealth/ui-components/utils/date'
import {
  type Appointment,
  type ScheduleFragment,
  AppointmentStatus,
  ServiceCategoryCode,
  schedulingApi,
  AppointmentFragment,
  SearchFilterEnum,
  Operator,
  AppointmentParticipantTypeCode,
  SortOrder,
  ServiceTypeCode,
} from '@valerahealth/rtk-query'
import ScheduleSummary from './ScheduleSummary'
import { useTranslation } from '../locales'
import { useGetMutationPermissionsForPMCalendar } from '../hooks/useGetMutationPermissions'
import { useReduxDispatch, actions, ApptListView } from '../reducer'

export type OnEditFn = (arg: ScheduleFragment | Appointment) => void

/** trying to keep any dependancy on react-router out of components */
export type AppointmentListProps = {
  serviceCategoryCode: ServiceCategoryCode
}

const { useDeleteAppointmentMutation } = schedulingApi

const getApptListItem =
  (confirm: ConfirmTrigger, mode: ApptListView['mode'], providerId: string) =>
  (appointment: AppointmentFragment) => {
    const { startDate, endDate, status, _id: id } = appointment
    const [deleteAppointment] = useDeleteAppointmentMutation()
    const { canUpdateAppointment, canDeleteAppointment } =
      useGetMutationPermissionsForPMCalendar(providerId)
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const notify = useNotify()

    const handleDelete = async (id: string) => {
      const confirmed = await confirm({
        header: t('deleteConfirmation'),
        body: t('SchedulingSidebar.confirmDeleteApptBody'),
        cancelLabel: t('no'),
        confirmLabel: t('yes'),
        confirmButtonColor: 'primary',
      })

      if (confirmed) {
        const res = await deleteAppointment({ id })
        if ('error' in res) {
          notify({
            message: t('api.appointment.deleteFailure'),
            severity: 'error',
          })
        } else {
          notify({
            message: t('api.appointment.deleteSuccess'),
            severity: 'success',
          })
        }
      }
    }

    return (
      <ScheduleSummary
        startDate={startDate!}
        endDate={endDate}
        dateFormat="dateTime"
        isPending={status === AppointmentStatus.Proposed}
        onEdit={
          canUpdateAppointment
            ? () =>
                dispatch(
                  actions.openView({
                    type: 'appointmentForm',
                    mode: 'edit',
                    appointment,
                  }),
                )
            : null
        }
        onDelete={
          canDeleteAppointment && mode === 'future'
            ? () => handleDelete(id)
            : null
        }
      />
    )
  }

function Appts({ code, mode, providerId }: Omit<ApptListView, 'type'>) {
  const { t } = useTranslation()
  const dispatch = useReduxDispatch()
  const notify = useNotify()
  const { ConfirmationDialog, confirm } = useConfirmationDialog()

  const fetch = useCallback<InfiniteScrollGetData<AppointmentFragment, string>>(
    async (searchAfter) => {
      const res = await dispatch(
        schedulingApi.endpoints.searchAppointments.initiate({
          pageSize: 8,
          content: [
            {
              filter: SearchFilterEnum.ProviderId,
              value: providerId,
            },
            {
              filter: SearchFilterEnum.ParticipantTypeCode,
              value: AppointmentParticipantTypeCode.PrimaryPerformer,
            },
            {
              filter: SearchFilterEnum.CategoryCode,
              value: code,
            },
            {
              filter: SearchFilterEnum.EndDate,
              value: startOfDay(new Date()).toISOString(),
              operator: mode === 'future' ? Operator.Gte : Operator.Lte,
            },
          ],
          sort: [
            {
              field: SearchFilterEnum.EndDate,
              order: mode === 'future' ? SortOrder.Asc : SortOrder.Desc,
            },
          ],
          searchAfter,
        }),
      )

      if (res.error) {
        notify({
          severity: 'error',
          message:
            res.error.message || 'There was an error fetching appointments',
        })
        return {
          data: [],
        }
      }

      return {
        data: res.data?.searchAppointments?.results || [],
        pagingToken: res.data?.searchAppointments?.searchAfter ?? undefined,
      }
    },
    [mode, code, dispatch, providerId, notify],
  )

  const ApptListItem = useMemo(
    () => getApptListItem(confirm, mode, providerId),
    [confirm, mode],
  )

  return (
    <Box
      sx={{
        overflowY: 'hidden',
        flex: '1 0 0px',
        height: '100%',
        display: 'flex',
      }}
    >
      <InfiniteScroll
        sx={{ flex: '1 0 0px' }}
        getData={fetch}
        getItemKey={(i) => i._id}
        ListItem={ApptListItem}
        endMessage={t(
          `SchedulingSidebar.${
            mode === 'future'
              ? 'allFutureAppointmentsLoaded'
              : 'allPastAppointmentsLoaded'
          }`,
          { code: t(`ServiceCategoryCodeShorthand.${code}`) },
        )}
      />
      <ConfirmationDialog />
    </Box>
  )
}

export default function AppointmentList(props: Omit<ApptListView, 'type'>) {
  const { mode, code, providerId } = props

  const { t } = useTranslation()
  const dispatch = useReduxDispatch()

  const { canCreateAppointment } =
    useGetMutationPermissionsForPMCalendar(providerId)

  const title =
    mode === 'future'
      ? 'SchedulingSidebar.appointmentListFutureTitle'
      : 'SchedulingSidebar.appointmentListPastTitle'

  const codeTitle = t(`ServiceCategoryCodeShorthand.${code}`)

  return (
    <>
      <WorkflowSidebarContent
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h6">
          {t(title, {
            code: codeTitle,
          })}
        </Typography>
        <Stack sx={{ flex: '1' }}>
          <Appts {...props} />
        </Stack>
      </WorkflowSidebarContent>
      <WorkflowSidebarActions>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            dispatch(
              actions.openView({
                type: 'appointmentList',
                mode: mode === 'future' ? 'past' : 'future',
                code,
                providerId,
              }),
            )
          }}
        >
          {mode === 'past' ? 'View Future' : 'View Past'}
        </Button>

        {canCreateAppointment && (
          <Button
            onClick={() => {
              dispatch(
                actions.openView({
                  type: 'appointmentForm',
                  mode: 'add',
                  code: ServiceCategoryCode.OutOfOffice,
                  providerId,
                }),
              )
            }}
            color="primary"
            sx={{
              ml: 1,
            }}
          >
            {t('SchedulingSidebar.addAppointment', {
              code: codeTitle,
            })}
          </Button>
        )}
      </WorkflowSidebarActions>
    </>
  )
}
