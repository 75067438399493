import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

export default function SwitchButton({
  value = false,
  color,
  handlerGetData,
  fieldClass,
  disabled,
}) {
  const sendData = (e) => {
    handlerGetData && handlerGetData(e)
  }

  return (
    <label
      className={`switch ${disabled ? 'disabled ' : ''}${
        !fieldClass ? '' : fieldClass
      }`}
    >
      <input
        type="checkbox"
        checked={value}
        onChange={sendData}
        disabled={disabled}
      />
      <span className="slider round" style={{ color: '' | color }} />
    </label>
  )
}

SwitchButton.propTypes = {
  value: PropTypes.bool,
  color: PropTypes.string,
}
