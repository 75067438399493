import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  AUTH_REDUCER_KEY,
  AuthSession,
  RootStateWithAuth,
} from '@valerahealth/redux-auth'
import { api as endpoints } from './generated'
import { handleGQLErrors } from '../../utils'

export const getBaseApi = (
  origin: string,
  getAccessToken: (state: any) => string | null | undefined,
  applyPermissionHeaders: (
    headers: Headers,
    session: AuthSession,
    endpoint: string,
  ) => Headers,
) => {
  const baseQuery = graphqlRequestBaseQuery({
    url: `${origin}/graphql`,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithAuth
      const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]

      if (isAuthenticated) {
        const accessToken = getAccessToken(state)
        headers.set('Authorization', `Bearer ${accessToken}`)
        applyPermissionHeaders(headers, session!, api.endpoint)
      }
      return headers
    },
    customErrors: handleGQLErrors,
  })
  return createApi({
    reducerPath: 'practiceMgrApi',
    keepUnusedDataFor: 120,
    tagTypes: [
      'provider',
      'insurancePlan',
      'allInsurancePlans',
      'allOutOfNetworkInsurancePlans',
      'activeInsurancePlans',
      'location',
      'ProviderLicenseRequests',
      'ProviderDocuments',
      'VerifiedProviderDocuments',
      'PayerEnrollments',
      'payers',
      'professionalHistories',
      'professionCategory',
      'providerCredential',
      'providerExternalAccount',
    ],
    baseQuery,
    endpoints,
  })
}

export type BaseApiType = ReturnType<typeof getBaseApi>
