import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Popup, Form as FormComponent } from '@valerahealth/ui-core'
import dStrings from '../../strings.json'
import { timezoneEnum } from '../utilities/enums'
import { setIntlPhoneNumber } from '@valerahealth/ui-components'

function EditUserProfilePopup({
  profile,
  handleSave,
  handleClose,
  disableSaveBtn,
  invalid = {},
}) {
  const [user, setUser] = useState(profile)
  const [errors, setErrors] = useState({})
  const [activeRecaptcha, setActiveRecaptcha] = useState(false)

  useEffect(() => {
    if (Object.keys(invalid).length !== 0) setActiveRecaptcha(false)
  }, [invalid])

  return (
    <Popup
      title={dStrings.editDetails}
      onClosed={handleClose}
      buttons={[
        {
          text: dStrings.cancel,
          onClick: handleClose,
          type: 'link',
        },
        {
          text: dStrings.save,
          disable: disableSaveBtn,
          onClick: () => {
            let hasError = false
            const _err = {}

            if (!setIntlPhoneNumber(user.phone)) {
              hasError = true
              _err.phone = true
            }

            if (!user.firstName) {
              hasError = true
              _err.firstName = true
            }

            if (!user.lastName) {
              hasError = true
              _err.lastName = true
            }

            if (hasError) {
              setActiveRecaptcha(false)
              setErrors(_err)
              return
            }

            setErrors({})
            setActiveRecaptcha(true)
          },
          type: 'primary',
        },
      ]}
    >
      <>
        <FormComponent
          withRecaptcha
          activeRecaptcha={activeRecaptcha}
          handleExecuteRecaptcha={(recaptchaValue) => {
            handleSave && handleSave(user, recaptchaValue)
          }}
          data={user}
          handleDataChange={(data) => {
            setUser(data)
          }}
          displayInvalid={
            Object.keys(invalid).length !== 0 ||
            Object.keys(errors).length !== 0
          }
          fields={[
            {
              key: 'firstName',
              text: dStrings.firstName,
              type: 'text',
              required: true,
              invalid: errors.firstName || invalid.firstName,
              invalidMessage:
                (errors.firstName && dStrings.requiredField) ||
                (invalid.firstName && dStrings.invalidFirstName),
            },
            {
              key: 'lastName',
              text: dStrings.lastName,
              type: 'text',
              required: true,
              invalid: errors.lastName || invalid.lastName,
              invalidMessage:
                (errors.lastName && dStrings.requiredField) ||
                (invalid.lastName && dStrings.invalidLastName),
            },
            {
              key: 'phone',
              text: dStrings.phone,
              placeholder: '+123456789',
              type: 'text',
              required: true,
              invalid: errors.phone || invalid.phone,
              invalidMessage:
                (errors.phone && dStrings.requiredField) ||
                (invalid.phone && dStrings.invalidPhone),
            },
            {
              key: 'gender',
              text: dStrings.gender,
              type: 'select',
              select: {
                m: dStrings.male,
                f: dStrings.female,
                o: dStrings.other,
              },
            },
            {
              key: 'email',
              text: dStrings.email,
              type: 'text',
              required: true,
              invalid: errors.email || invalid.email,
              invalidMessage:
                (errors.email && dStrings.requiredField) ||
                (invalid.email && dStrings.invalidMail),
            },
            {
              key: 'timeZone',
              text: dStrings.timezone,
              type: 'select',
              select: timezoneEnum,
            },
          ]}
        />
      </>
    </Popup>
  )
}

export default EditUserProfilePopup
