// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-careplan-popup {
  height: 300px;
}

.assign-careplan-popup input[type="text"] {
  width: 100%;
}

.assign-careplan-popup .check-list-container {
  margin-top: 20px;
  height: 210px;
  overflow: auto;
}
.assign-careplan-popup.bulk-state .check-list-container {
  height: 180px;
}
.assign-careplan-popup .loader {
  position: initial;
}
.assign-careplan-popup .check-list-element {
  max-width: 400px;
}
.plan-categorie {
  display: flex;
  margin-top: 5px;
  border-bottom: 1px solid #dadcdf;
}

.plan-categorie-element {
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  display: flex;
}
.plan-categorie-element .icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}
.plan-categorie-element.selected {
  border-bottom: 2px solid #317b8c;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/AssignCarePlanPopup/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,gCAAgC;AAClC","sourcesContent":[".assign-careplan-popup {\n  height: 300px;\n}\n\n.assign-careplan-popup input[type=\"text\"] {\n  width: 100%;\n}\n\n.assign-careplan-popup .check-list-container {\n  margin-top: 20px;\n  height: 210px;\n  overflow: auto;\n}\n.assign-careplan-popup.bulk-state .check-list-container {\n  height: 180px;\n}\n.assign-careplan-popup .loader {\n  position: initial;\n}\n.assign-careplan-popup .check-list-element {\n  max-width: 400px;\n}\n.plan-categorie {\n  display: flex;\n  margin-top: 5px;\n  border-bottom: 1px solid #dadcdf;\n}\n\n.plan-categorie-element {\n  margin-right: 10px;\n  padding: 10px;\n  cursor: pointer;\n  display: flex;\n}\n.plan-categorie-element .icon {\n  width: 14px;\n  height: 14px;\n  margin-right: 6px;\n}\n.plan-categorie-element.selected {\n  border-bottom: 2px solid #317b8c;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
