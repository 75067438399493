import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import DayRange from '../../../PlanTemplate/Content/Views/Timeline/DayRange'
import TimelineItem from '../../../PlanTemplate/Content/Views/Timeline/TimelineItem'
import DayCursor from '../../DayCursor'

const TimelineData = ({ data, filteredData, days, state }) => {
  const displayDateCursor = state === 'treatment'
  let cursorDisplayed = false
  const todayTime = new Date().getTime()

  const displayedData = filteredData || data
  return (
    displayedData &&
    Object.keys(displayedData).map((key, index) => {
      const timelineGroup = displayedData[key]
      return (
        <div key={index} className="timeline-view">
          <DayRange
            dayNum={days && days[key]}
            itemsNum={data[key].length}
            filtered={data[key].length !== timelineGroup.length}
            date={key}
            isPastDate={
              state === 'treatment' &&
              new Date(data[key][0].ts).getTime() <
                new Date().setHours(0, 0, 0, 0)
            }
          />
          <div className="timeline-item-container">
            {timelineGroup.map((timelineElement, index) => {
              const flag =
                displayDateCursor &&
                !cursorDisplayed &&
                new Date(timelineElement.ts).getTime() > todayTime

              if (flag) {
                cursorDisplayed = true
              }

              return (
                <div key={index} className="timeline-item-element-container">
                  {flag && <DayCursor />}
                  <TimelineItem
                    state={state}
                    timelineElement={timelineElement}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  )
}

TimelineData.propTypes = {
  data: PropTypes.object,
}

export default TimelineData
