export const BEGIN_API_CALL = 'BEGIN_API_CALL'
export const END_API_CALL = 'END_API_CALL'
export const API_CALL_ERROR = 'API_CALL_ERROR'
export const LOAD_PATIENTS_SUCCESS = 'LOAD_PATIENTS_SUCCESS'

// GLOBAL
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const LOAD_LANGUAGES = 'LOAD_LANGUAGES'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_SCHEDULE_DATES_SUCCESS =
  'EDIT_USER_SCHEDULE_DATES_SUCCESS'
export const EDIT_SETTINGS_SUCCESS = 'EDIT_SETTINGS_SUCCESS'
export const EDIT_NOTIFICATIONS_SETTINGS_SUCCESS =
  'EDIT_NOTIFICATIONS_SETTINGS_SUCCESS'
export const DISPLAY_CONFIRMATION = 'DISPLAY_CONFIRMATION'
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION'
export const REMOVE_USER_MESSAGE_POPUP = 'REMOVE_USER_MESSAGE_POPUP'
export const ADD_USER_MESSAGE_POPUP = 'ADD_USER_MESSAGE_POPUP'
export const CHANGE_PLAN_STATE = 'CHANGE_PLAN_STATE'
export const EDIT_ITEM = 'EDIT_ITEM'
export const CANCEL_EDIT_ITEM = 'CANCEL_EDIT_ITEM'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED'
export const HELP_ACTION = 'HELP_ACTION'
export const TOGGLE_CONFIRM_REDIRECTION = 'TOGGLE_CONFIRM_REDIRECTION'
export const LOAD_PROVIDER_CONFIGURATIONS = 'LOAD_PROVIDER_CONFIGURATIONS'
export const DISPLAY_EXPIRATION_TOKEN_POPUP = 'DISPLAY_EXPIRATION_TOKEN_POPUP'

// PLAN TEMPLATE
export const CREATE_PLAN_TEMPLATE_SUCCESS = 'CREATE_PLAN_TEMPLATE_SUCCESS'
export const DELETE_PLAN_TEMPLATE_SUCCESS = 'DELETE_PLAN_TEMPLATE_SUCCESS'
export const UPDATE_PLAN_TEMPLATE_SUCCESS = 'UPDATE_PLAN_TEMPLATE_SUCCESS'
export const PLAN_TEMPLATE_STARTED_SUCCESS = 'PLAN_TEMPLATE_STARTED_SUCCESS'
export const PLAN_TEMPLATE_STOPED_SUCCESS = 'PLAN_TEMPLATE_STOPED_SUCCESS'
export const LOAD_PLAN_BY_ID_SUCCESS = 'LOAD_PLAN_BY_ID_SUCCESS'
export const LOAD_PLAN_TEMPLATE_SUCCESS = 'LOAD_PLAN_TEMPLATE_SUCCESS'
export const LOAD_PLAN_TEMPLATES_TIMELINE_SUCCESS =
  'LOAD_PLAN_TEMPLATES_TIMELINE_SUCCESS'
export const PLAN_TEMPLATE_SEARCH = 'PLAN_TEMPLATE_SEARCH'
export const PLAN_TEMPLATE_SELECTED = 'PLAN_TEMPLATE_SELECTED'
export const PLAN_TEMPLATE_UNSELECTED = 'PLAN_TEMPLATE_UNSELECTED'
export const RESET_GO_TO_NEW_ID = 'RESET_GO_TO_NEW_ID'
export const RESET_GO_TO_PLAN_SELECTION = 'RESET_GO_TO_PLAN_SELECTION'
export const EDIT_PLAN_TEMPLATE = 'EDIT_PLAN_TEMPLATE'
export const CANCEL_EDIT_PLAN_TEMPLATE = 'CANCEL_EDIT_PLAN_TEMPLATE'
export const UPDATE_PLAN_TEMPLATE = 'UPDATE_PLAN_TEMPLATE'
export const ADD_PLAN_TEMPLATE = 'ADD_PLAN_TEMPLATE'
export const DELETE_PLAN_TEMPLATE = 'DELETE_PLAN_TEMPLATE'
export const CHANGE_VIEW = 'CHANGE_VIEW'
export const ADD_UPDATE_ITEM = 'ADD_UPDATE_ITEM'
export const SET_TRACK_SCHEDULE = 'SET_TRACK_SCHEDULE'
export const SET_PATH_NAME = 'SET_PATH_NAME'
export const DELETE_ITEM = 'DELETE_ITEM'
export const GET_PLAN_COUNT_SUCCESS = 'GET_PLAN_COUNT_SUCCESS'
export const CHANGE_SUB_MENU = 'CHANGE_SUB_MENU'

// TRACKS
export const CREATE_TRACK_SUCCESS = 'CREATE_TRACK_SUCCESS'
export const DELETE_TRACK_SUCCESS = 'DELETE_TRACK_SUCCESS'
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS'
export const EDIT_TRACK = 'EDIT_TRACK'
export const CANCEL_EDIT_TRACK = 'CANCEL_EDIT_TRACK'
export const ADD_TRACK = 'ADD_TRACK'

// TOOLS TEMPLATES
export const LOAD_QUESTIONNAIRES_TEMPLATES_SUCCESS =
  'LOAD_QUESTIONNAIRES_TEMPLATES_SUCCESS'
export const LOAD_CONSENT_FORM_TEMPLATES_SUCCESS =
  'LOAD_CONSENT_FORM_TEMPLATES_SUCCESS'
export const LOAD_MEDIA_ITEMS_TEMPLATES_SUCCESS =
  'LOAD_MEDIA_ITEMS_TEMPLATES_SUCCESS'
export const LOAD_QUESTIONNAIRE_SUCCESS = 'LOAD_QUESTIONNAIRE_SUCCESS'
export const LOAD_CONSENT_FORM_SUCCESS = 'LOAD_CONSENT_FORM_SUCCESS'
export const LOAD_MEDIA_ITEM_SUCCESS = 'LOAD_MEDIA_ITEM_SUCCESS'

// CASELOAD
export const CLEAR_CASELOAD = 'CLEAR_CASELOAD'
export const TREATMENT_SEARCH = 'TREATMENT_SEARCH'
export const CHANGE_SHOW_MENU_VALUE = 'CHANGE_SHOW_MENU_VALUE'
export const TOGGLE_FILTER_MENU = 'TOGGLE_FILTER_MENU'
export const CHANGE_CASELOAD_STATE = 'CHANGE_CASELOAD_STATE'
export const TEST_ERROR_DATADOG = 'TEST_ERROR_DATADOG'
export const TOGGLE_SELECT_TREATMENT_ROW = 'TOGGLE_SELECT_TREATMENT_ROW'
export const SELECT_ALL_TREATMENTS = 'SELECT_ALL_TREATMENTS'
export const UNSELECT_ALL_TREATMENTS = 'UNSELECT_ALL_TREATMENTS'
export const UNSELECT_ALL_SELECTED_TREATMENTS =
  'UNSELECT_ALL_SELECTED_TREATMENTS'
export const RESET_GO_TO_CASELOAD = 'RESET_GO_TO_CASELOAD'
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS'
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED'
export const LOADING_DATA_FOR_TREATMENT = 'LOADING_DATA_FOR_TREATMENT'
export const STOP_LOADING_DATA_FOR_TREATMENT = 'STOP_LOADING_DATA_FOR_TREATMENT'
export const PLAN_UNASSIGNED_SUCCESS = 'PLAN_UNASSIGNED_SUCCESS'
export const DISCHARGE_PATIENT = 'DISCHARGE_PATIENT'
export const RESTORE_PATIENT = 'RESTORE_PATIENT'
export const CANCEL_EDIT_PATIENT = 'CANCEL_EDIT_PATIENT'
export const REGISTER_NEW_PATIENT = 'REGISTER_NEW_PATIENT'

// TREATMENT
export const SELECT_TREATMENT = 'SELECT_TREATMENT'
export const UNSELECT_TREATMENT = 'UNSELECT_TREATMENT'
export const TOGGLE_RESTORE_POPUP = 'TOGGLE_RESTORE_POPUP'
export const SET_FILTER_TREATMENT = 'SET_FILTER_TREATMENT'
export const CLEAR_TREATMENT_ROOM = 'CLEAR_TREATMENT_ROOM'
export const LOAD_TREATMENT_SUCCESS = 'LOAD_TREATMENT_SUCCESS'
export const LOAD_TREATMENTS_SUCCESS = 'LOAD_TREATMENTS_SUCCESS'
export const LOAD_PENDING_TREATMENTS_SUCCESS = 'LOAD_PENDING_TREATMENTS_SUCCESS'
export const LOAD_PLAN_SUCCESS = 'LOAD_PLAN_SUCCESS'
export const REMOVE_PENDING_USER = 'REMOVE_PENDING_USER'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const ADD_TREATMENT_SUCCESS = 'ADD_TREATMENT_SUCCESS'
export const PENDING_PATIENT_REGISTERED = 'PENDING_PATIENT_REGISTERED'
export const LOAD_PROFILE_DATA = 'LOAD_PROFILE_DATA'
export const LOAD_PROFILE_APPOINTMENT = 'LOAD_PROFILE_APPOINTMENT'
export const GET_APPOINTMENT_DETAILS = 'GET_APPOINTMENT_DETAILS'
export const EDIT_APPOINTMENT_SUCCESS = 'EDIT_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS'
export const ADD_APPOINTMENT_SUCCESS = 'ADD_APPOINTMENT_SUCCESS'
export const LOAD_PROFILE_TIMELINE = 'LOAD_PROFILE_TIMELINE'
export const LOAD_MORE_PROFILE_TIMELINE = 'LOAD_MORE_PROFILE_TIMELINE'
export const LOAD_PROFILE_CARETEAM_SUCCESS = 'LOAD_PROFILE_CARETEAM_SUCCESS'
export const LOAD_PROFILE_ALERTS_SUCCESS = 'LOAD_PROFILE_ALERTS_SUCCESS'
export const RESOLVE_ALERTS_SUCCESS = 'RESOLVE_ALERTS_SUCCESS'
export const DELETE_CARE_TEAM_MEMBER = 'DELETE_CARE_TEAM_MEMBER'
export const UPDATE_CARE_TEAM = 'UPDATE_CARE_TEAM'
export const CHANGE_PCTM = 'CHANGE_PCTM'
export const SET_CHANNEL_SEEN = 'SET_CHANNEL_SEEN'
export const SET_PENDING_NOTIFICATION_SEEN = 'SET_PENDING_NOTIFICATION_SEEN'
export const ALL_MESSAGES_READED = 'ALL_MESSAGES_READED'
export const SET_MESSAGES_UNREADED = 'SET_MESSAGES_UNREADED'
export const RECEIVE_NEW_CHANNEL_MESSAGE = 'RECEIVE_NEW_CHANNEL_MESSAGE'
export const SEND_CHANNEL_MESSAGE = 'SEND_CHANNEL_MESSAGE'
export const LOAD_CHANNEL_MESSAGES = 'LOAD_CHANNEL_MESSAGES'
export const LOAD_TREATMENT_QUESTIONNAIRE_SUCCESS =
  'LOAD_TREATMENT_QUESTIONNAIRE_SUCCESS'
export const LOAD_TREATMENT_CONSENT_FORM_SUCCESS =
  'LOAD_TREATMENT_CONSENT_FORM_SUCCESS'
export const LOAD_TREATMENT_MEDIA_ITEM_SUCCESS = 'LOAD_TREATMENT_MEDIA_ITEM_SUCCESS'
export const SORT_PENDING_LIST = 'SORT_PENDING_LIST'
export const LOAD_SUPPORTIVE_CONTACT_SUCCESS = 'LOAD_SUPPORTIVE_CONTACT_SUCCESS'
export const CREATE_SUPPORTIVE_CONTACT_SUCCESS =
  'CREATE_SUPPORTIVE_CONTACT_SUCCESS'
export const UPDATE_SUPPORTIVE_CONTACT_SUCCESS =
  'UPDATE_SUPPORTIVE_CONTACT_SUCCESS'
export const DELETE_SUPPORTIVE_CONTACT_SUCCESS =
  'DELETE_SUPPORTIVE_CONTACT_SUCCESS'
export const LOAD_EMR_STATUS = 'LOAD_EMR_STATUS'
export const REGISTER_TO_EMR = 'REGISTER_TO_EMR'

// PROGRAM
export const LOAD_PROGRAM_CONFIGURATION_SUCCESS =
  'LOAD_PROGRAM_CONFIGURATION_SUCCESS'
export const LOAD_PROGRAM_CARETEAM_SUCCESS = 'LOAD_PROGRAM_CARETEAM_SUCCESS'
export const LOAD_REPORTS_CARETEAM_SUCCESS = 'LOAD_REPORTS_CARETEAM_SUCCESS'
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS'
export const CREATE_TASKS_SUCCESS = 'CREATE_TASKS_SUCCESS'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const DELETE_TASKS_SUCCESS = 'DELETE_TASKS_SUCCESS'
export const LOAD_PROGRAM_TAGS_SUCCESS = 'LOAD_PROGRAM_TAGS_SUCCESS'
export const CREATE_PROGRAM_TAG_SUCCESS = 'CREATE_PROGRAM_TAG_SUCCESS'

// SUPERVISION
export const LOAD_SUPERVISION_USERS_SUCCESS = 'LOAD_SUPERVISION_USERS_SUCCESS'

// PROVIDER
export const LOAD_CARE_COORDINATORS_SUCCESS = 'LOAD_CARE_COORDINATORS_SUCCESS'
export const EDIT_OOO = 'EDIT_OOO'
export const CANCEL_EDIT_OOO = 'CANCEL_EDIT_OOO'
