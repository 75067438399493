import { CareTeamMemberLegacy } from '@valerahealth/rtk-query'
import { DispatchType, GetStateType } from 'redux/store'
import * as types from './actionTypes'
import * as programAPI from '../../api/programAPI'
import { callApi } from './utilities'
import { getProfileName } from '../../components/utilities/treatments'

export const loadProgramProvidersSuccess = (
  careTeam: CareTeamMemberLegacy[],
) => {
  return {
    type: types.LOAD_PROGRAM_CARETEAM_SUCCESS,
    careteam:
      careTeam &&
      careTeam.sort((a, b) => {
        const aName = getProfileName(a)
        const bName = getProfileName(b)
        return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1
      }),
  }
}

export function loadProgramProviders(hideLoader?: boolean) {
  return (dispatch: DispatchType, getState: GetStateType) => {
    return callApi({
      name: 'loadProgramProviders',
      dispatch,
      hideLoader,
      call: () => {
        const programId = getState().auth.session?.user.programId
        return programAPI.getProgramProviders(programId)
      },
      success: (careTeam: CareTeamMemberLegacy[]) => {
        dispatch(loadProgramProvidersSuccess(careTeam))
      },
    })
  }
}

export function getReports(from: string, to: string) {
  return (dispatch: DispatchType, getState: GetStateType) => {
    return callApi({
      name: 'getReports',
      dispatch,
      call: () => {
        const programId = getState().auth.session?.user.programId
        return programAPI.getReports(programId, from, to)
      },
      success: (data) => {
        dispatch({
          type: types.LOAD_REPORTS_CARETEAM_SUCCESS,
          data,
        })
      },
    })
  }
}

export function loadProgramTags() {
  return (dispatch: DispatchType, getState: GetStateType) => {
    return callApi({
      name: 'loadProgramTags',
      dispatch,
      call: () => {
        const programId = getState().auth.session?.user.programId
        return programAPI.getProgramTags(programId)
      },
      success: (tags) => {
        dispatch({
          type: types.LOAD_PROGRAM_TAGS_SUCCESS,
          tags,
        })
      },
    })
  }
}

export function createProgramTag(tag: string) {
  return (dispatch: DispatchType, getState: GetStateType) => {
    return callApi({
      name: 'createProgramTag',
      dispatch,
      call: () => {
        const programId = getState().auth.session?.user.programId
        return programAPI.createProgramTag(programId, tag)
      },
      success: () => {
        dispatch({
          type: types.CREATE_PROGRAM_TAG_SUCCESS,
          tag,
        })
      },
    })
  }
}
