import * as types from './actionTypes'
import * as toolsTemplatesAPI from '../../api/toolsTemplatesAPI'
import { callApi } from './utilities'

export function questionnairesTemplatesLoadedSuccessfully(questionnaires) {
  return { type: types.LOAD_QUESTIONNAIRES_TEMPLATES_SUCCESS, questionnaires }
}

export function consentFormTemplatesLoadedSuccessfully(consentForms) {
  return { type: types.LOAD_CONSENT_FORM_TEMPLATES_SUCCESS, consentForms }
}

export function mediasTemplatesLoadedSuccessfully(medias) {
  return { type: types.LOAD_MEDIA_ITEMS_TEMPLATES_SUCCESS, medias }
}

export function loadQuestionnairesTemplates() {
  return function (dispatch) {
    return callApi({
      name: 'loadQuestionnairesTemplates',
      dispatch,
      call: () => {
        return toolsTemplatesAPI.getQuestionnairesTemplates()
      },
      success: (questionnaires) => {
        dispatch(
          questionnairesTemplatesLoadedSuccessfully(
            questionnaires.sort((a, b) => {
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            }),
          ),
        )
      },
    })
  }
}

export function loadConsentFormsTemplates() {
  return function (dispatch) {
    return callApi({
      name: 'loadConsentFormsTemplates',
      dispatch,
      call: () => {
        return toolsTemplatesAPI.getConsentFormsTemplates()
      },
      success: (consentForms) => {
        dispatch(
          consentFormTemplatesLoadedSuccessfully(
            consentForms.sort((a, b) => {
              return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
            }),
          ),
        )
      },
    })
  }
}

export function loadMediaTemplates() {
  return function (dispatch) {
    return callApi({
      name: 'loadMediaTemplates',
      dispatch,
      call: () => {
        return toolsTemplatesAPI.getMediaItemsTemplates()
      },
      success: (medias) => {
        dispatch(
          mediasTemplatesLoadedSuccessfully(
            medias.sort((a, b) => {
              return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
            }),
          ),
        )
      },
    })
  }
}

export function loadQuestionnaire(item, assigned) {
  return function (dispatch, getState) {
    return callApi({
      name: 'loadQuestionnaire',
      dispatch,
      call: () => {
        if (!item.assigned && !assigned) {
          return toolsTemplatesAPI.getQuestionnaire(item.id)
        }
        const treatments = getState().treatmentRoom
        return toolsTemplatesAPI.getTreatmentQuestionnaire(
          item.treatmentId || treatments.selectedId,
          item.id,
          treatments.byId[treatments.selectedId].patientId,
        )
      },
      success: (item) => {
        dispatch({
          type:
            !item.assigned && !assigned
              ? types.LOAD_QUESTIONNAIRE_SUCCESS
              : types.LOAD_TREATMENT_QUESTIONNAIRE_SUCCESS,
          item,
        })
      },
    })
  }
}

export function loadConsentForm(item, assigned) {
  return function (dispatch, getState) {
    return callApi({
      name: 'loadConsentForm',
      dispatch,
      call: () => {
        if (!item.assigned && !assigned) {
          return toolsTemplatesAPI.getConsentForm(
            item.info ? item.info.id : item.id,
          )
        }
        const treatments = getState().treatmentRoom
        return toolsTemplatesAPI.getTreatmentConsentForm(
          treatments.selectedId,
          item.id,
          treatments.byId[treatments.selectedId].patientId,
        )
      },
      success: (item) => {
        dispatch({
          type:
            !item.assigned && !assigned
              ? types.LOAD_CONSENT_FORM_SUCCESS
              : types.LOAD_TREATMENT_CONSENT_FORM_SUCCESS,
          item,
        })
      },
    })
  }
}

export function loadMediaItem(item, assigned) {
  return function (dispatch, getState) {
    return callApi({
      name: 'loadMediaItem',
      dispatch,
      call: () => {
        if (!item.assigned && !assigned) {
          return toolsTemplatesAPI.getMediaItem(item.id)
        }
        const treatments = getState().treatmentRoom
        return toolsTemplatesAPI.getTreatmentMediaItem(
          treatments.selectedId,
          item.id,
          treatments.byId[treatments.selectedId].patientId,
        )
      },
      success: (item) => {
        dispatch({
          type:
            !item.assigned && !assigned
              ? types.LOAD_MEDIA_ITEM_SUCCESS
              : types.LOAD_TREATMENT_MEDIA_ITEM_SUCCESS,
          item,
        })
      },
    })
  }
}
