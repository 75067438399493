import { useState } from 'react'
import LABELS from '@valerahealth/ui-translation/locales/en'
import { careManagerApi } from '@valerahealth/rtk-query'
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from '../../base'
import { CloseOutlined } from '../../icons'
import { useNotify } from '../Notifications'

const gridSpacing = 2

export type AppInviteFormProps = {
  phone: string
  email: string
  treatmentId: string
  onSuccess: () => void
  onCancel: () => void
}

export function AppInviteForm({
  phone,
  email,
  treatmentId,
  onSuccess,
  onCancel,
}: AppInviteFormProps) {
  const notify = useNotify()
  const [selection, setSelection] = useState<{
    email: boolean
    phone: boolean
  }>({ email: false, phone: false })
  const [sendInv, sendInvStatus] = careManagerApi.useSendAppInviteMutation()
  const handleSend = async () => {
    const data = await sendInv({
      treatmentId,
      method:
        selection.email && selection.phone
          ? 'all'
          : selection.email
          ? 'email'
          : 'sms',
    })
    if ('data' in data) {
      notify({
        severity: 'success',
        message: LABELS.PatientAppInviteForm.sendInvSuccess,
      })
      onSuccess()
    } else {
      const message = 'data' in data.error && data?.error.data?.message
      notify({
        severity: 'error',
        message: message || LABELS.PatientAppInviteForm.sendInvError,
      })
    }
  }
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selection.phone}
              onChange={() =>
                setSelection((state) => ({
                  phone: !state.phone,
                  email: state.email,
                }))
              }
            />
          }
          label={`Phone:  ${phone}`}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selection.email}
              onChange={() =>
                setSelection((state) => ({
                  phone: state.phone,
                  email: !state.email,
                }))
              }
            />
          }
          label={`Email:  ${email}`}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={gridSpacing}>
          <Button
            variant="outlined"
            color="error"
            sx={{ ml: 'auto' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            startIcon={
              sendInvStatus.isLoading ? (
                <CircularProgress color="inherit" />
              ) : undefined
            }
            disabled={
              sendInvStatus.isLoading || (!selection.email && !selection.phone)
            }
            onClick={handleSend}
          >
            Send
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

export type AppInvitePopupProps = AppInviteFormProps & {
  open: boolean
}

export function AppInvitePopup({ open, ...props }: AppInvitePopupProps) {
  return (
    <Dialog open={!!open} onClose={props.onCancel} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {LABELS.sendAppLink}
        <IconButton title="Close" onClick={props.onCancel}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>{open && <AppInviteForm {...props} />}</DialogContent>
    </Dialog>
  )
}
