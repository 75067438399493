// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment-popup {
  background: white;
}
.appointment-popup .popup-header .popup-header-content {
  white-space: nowrap;
  margin-right: 30px;
}
.appointment-popup .form-line {
  grid-template-columns: 30% 70%;
}
.remove-appointment-icon {
  cursor: pointer;
}
.appointment-popup .popup-header {
  position: relative;
}
.appointment-popup-title svg.remove-appointment-icon {
  position: absolute;
  right: 30px;
}
.dashboard-time-picker {
  width: 200px;
}
.appointment-popup .information-hover-container {
  margin-left: 10px;
}
.appointment-popup .dashboard-datepicker {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/AddEditAppointement/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;AACd","sourcesContent":[".appointment-popup {\n  background: white;\n}\n.appointment-popup .popup-header .popup-header-content {\n  white-space: nowrap;\n  margin-right: 30px;\n}\n.appointment-popup .form-line {\n  grid-template-columns: 30% 70%;\n}\n.remove-appointment-icon {\n  cursor: pointer;\n}\n.appointment-popup .popup-header {\n  position: relative;\n}\n.appointment-popup-title svg.remove-appointment-icon {\n  position: absolute;\n  right: 30px;\n}\n.dashboard-time-picker {\n  width: 200px;\n}\n.appointment-popup .information-hover-container {\n  margin-left: 10px;\n}\n.appointment-popup .dashboard-datepicker {\n  width: 200px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
