import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import dStrings from '../../../strings.json'
import { getPlanTemplateIcon, plansDetails } from '../../utilities/planTemplate'
import { loadPlanTemplates } from '../../../redux/actions/planTemplateActions'
import SelectedElement from '../SelectedElement'

function PlanSelect({
  styles,
  patientPlans,
  handleChange,
  planTemplates,
  loadPlanTemplates,
}) {
  const [plans, setPlans] = useState([])

  useEffect(() => {
    if (!planTemplates) loadPlanTemplates()
    else {
      const selectOptions = {}
      for (const key in planTemplates) {
        const plan = planTemplates[key]
        if (!selectOptions[plan.type])
          selectOptions[plan.type] = {
            label: plansDetails[plan.type].title,
            options: [],
          }

        selectOptions[plan.type].options.push({
          label: plan.name,
          value: plan.id,
          type: plan.type,
        })
      }

      const arr = []
      for (const k in selectOptions) {
        if (selectOptions.hasOwnProperty(k)) {
          arr.push(selectOptions[k])
        }
      }

      setPlans(arr)
    }
  }, [planTemplates])
  const { Option } = components

  function PlansOption(props) {
    return (
      <Option {...props}>
        <div className="plans-options-container">
          <input
            type="checkbox"
            checked={patientPlans && ~patientPlans.indexOf(props.data.value)}
            onChange={() => {}}
          />
          <div className="menu-list-item-icon">
            {getPlanTemplateIcon(props.data.type)}
          </div>
          <div className="plan-options-title">{props.data.label}</div>
        </div>
      </Option>
    )
  }

  return (
    <>
      <Select
        //   menuIsOpen={true}
        closeMenuOnSelect={false}
        styles={styles}
        onChange={(t) => {
          const plan = t[0].value
          handleChange(
            patientPlans
              ? !~patientPlans.indexOf(plan)
                ? [...patientPlans, plan]
                : patientPlans.filter((_id) => _id !== plan)
              : [plan],
          )
        }}
        components={{ Option: PlansOption }}
        placeholder={`<${dStrings.choosePlan}>`}
        menuPlacement="top"
        value={null}
        isMulti
        options={plans}
      />
      <section className="selected-element-container">
        {planTemplates &&
          patientPlans &&
          patientPlans.map((plan, i) => {
            return (
              <SelectedElement
                key={i}
                onDelete={() => {
                  handleChange(patientPlans.filter((t) => t != plan))
                }}
                placeholder={planTemplates[plan].name}
              >
                <span className="selected-plan-icon">
                  {getPlanTemplateIcon(planTemplates[plan].type)}
                </span>
                {planTemplates[plan].name}
              </SelectedElement>
            )
          })}
      </section>
    </>
  )
}

PlanSelect.propTypes = {
  displayFilterMenu: PropTypes.bool,
  selectedTreatments: PropTypes.array,
}

function mapStateToProps(state, ownProps) {
  return {
    planTemplates: state.planTemplates.byId,
    patientPlans: ownProps.patientPlans,
    styles: ownProps.styles,
    handleChange: ownProps.handleChange,
  }
}

const mapDispatchToProps = {
  loadPlanTemplates,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelect)
