import { ReactElement, ReactNode } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@valerahealth/ui-components'
import { Close } from '@valerahealth/ui-components/icons'
import LABELS from 'locales/en'
import { useReduxDispatch } from '../../redux/store'
import { cancelEditItem } from '../../redux/actions/globalActions'

export default function ItemPopup({
  title,
  headerActionButton,
  content,
}: {
  title: ReactElement | string
  headerActionButton?: ReactNode
  content: ReactNode | ReactNode[]
}) {
  const dispatch = useReduxDispatch()
  const handleClose = () => dispatch(cancelEditItem())
  return (
    <Dialog
      open
      maxWidth="md"
      PaperProps={{ sx: { minWidth: (theme) => theme.breakpoints.values.sm } }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title}
        {headerActionButton || (
          <IconButton onClick={handleClose} color="default">
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {LABELS.done}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
