import { ReactNode } from 'react'
import { WorkflowSidebar } from '@valerahealth/ui-components'
import {
  actions,
  AddScheduleView,
  useReduxDispatch,
  useReduxSelector,
} from '../reducer'
import { AddEditSchedule, SchedulesList } from '../components'
import { useTranslation } from '../locales'
import AppointmentList from '../components/AppointmentList'
import { AddEditAppointment } from '../components/AddEditAppointment'

export default function SchedulingSidebar() {
  const { t } = useTranslation()
  const view = useReduxSelector((state) => state.scheduling.view)

  let title: string = ''
  let children: ReactNode = null
  const dispatch = useReduxDispatch()

  // tried switch statement but it doesn't constraint the type in the case

  // Title
  if (view?.type === 'scheduleList' || view?.type === 'appointmentList') {
    title = t(`ServiceCategoryCode.${view.code}`)
  } else if (view?.type === 'appointmentForm' && view.mode === 'edit') {
    title = 'Edit Appointment'
  } else if (view?.type === 'appointmentForm' && view.mode === 'add') {
    title = 'Add Appointment'
  } else if (view?.type === 'scheduleForm' && view.mode === 'edit') {
    title = 'Edit Schedule'
  } else if (view?.type === 'scheduleForm' && view.mode === 'add') {
    title = 'Add Schedule'
  }

  //Content
  if (view?.type === 'appointmentList') {
    children = <AppointmentList {...view} />
  } else if (view?.type === 'scheduleList') {
    children = <SchedulesList {...view} />
  } else if (view?.type === 'scheduleForm' && view.mode === 'add') {
    children = (
      <AddEditSchedule
        serviceCategoryCode={(view as AddScheduleView).code}
        provider={view.provider}
      />
    )
  } else if (view?.type === 'scheduleForm' && view.mode === 'edit') {
    children = (
      <AddEditSchedule schedule={view.schedule} provider={view.provider} />
    )
  } else if (view?.type === 'appointmentForm') {
    children = <AddEditAppointment view={view} />
  }

  return (
    <WorkflowSidebar
      title={title}
      open={!!view}
      onClose={() => dispatch(actions.closeView())}
    >
      {children}
    </WorkflowSidebar>
  )
}
