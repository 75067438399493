import CONFIG, { getHeader, handleResponse, handleError } from './apiUtils'

const baseUrl = `https://${process.env.CARE_MANAGER_API_DOMAIN}/api`
const { signal } = CONFIG

export function getTreatment(id) {
  const url = `${baseUrl}/treatment/${id}`

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function assignPlansToSingleTreatment(treatmentId, templateIds) {
  return fetch(`${baseUrl}/treatment/${treatmentId}/plan/assign`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify({
      templateIds,
    }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getPlans(id) {
  return fetch(`${baseUrl}/treatment/${id}/plan`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getTreatmentPlanTimeline(
  treatmentId,
  planId,
  from,
  to,
  cycles,
) {
  return fetch(`${baseUrl}/treatment/${treatmentId}/plan/${planId}/timeline`, {
    method: 'GET',
    signal,
    headers: getHeader(),
    query: JSON.stringify({
      from,
      to,
      cycles,
    }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function unassignCarePlan(treatmentId, planId) {
  return fetch(`${baseUrl}/treatment/${treatmentId}/plan/${planId}/unassign`, {
    method: 'POST',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getProfile(id) {
  return fetch(`${baseUrl}/treatment/${id}/profile`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateProfile(id, data) {
  return fetch(`${baseUrl}/treatment/${id}/profile`, {
    method: 'PUT',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* CARE TEAM */
export function getCareTeam(id) {
  return fetch(`${baseUrl}/treatment/${id}/care-team`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateCareTeam(id, ctmIds) {
  return fetch(`${baseUrl}/treatment/${id}/care-team/ctm`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify({ ctmIds }),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

export function deleteCareTeamMember(id, ctmId) {
  return fetch(`${baseUrl}/treatment/${id}/care-team/ctm/${ctmId}`, {
    method: 'DELETE',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function changeCareTeamMemberRole(id, ctmId, forcePctmOOO) {
  return fetch(`${baseUrl}/treatment/${id}/care-team/ctm/${ctmId}`, {
    method: 'PUT',
    signal,
    headers: getHeader(),
    body: JSON.stringify({ forcePctmOOO }),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

/* CHANNEL */
export function loadChannelMessages(id, pageSize = 30, pageNumber = 1) {
  return fetch(
    `${baseUrl}/treatment/${id}/channel?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    {
      method: 'GET',
      signal,
      headers: getHeader(),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}

export function sendChannelMessage(id, data) {
  return fetch(`${baseUrl}/treatment/${id}/channel/send`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function setChannelSeen(id) {
  return fetch(`${baseUrl}/treatment/${id}/channel/seen`, {
    method: 'POST',
    signal,
    headers: getHeader(),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

export function setChannelUnseen(id, ids) {
  return fetch(`${baseUrl}/treatment/${id}/channel/unseen`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify({ ids }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function exportChannel(id, from, to) {
  return fetch(
    `${baseUrl}/treatment/${id}/channel/export?from=${from}&to=${to}`,
    {
      method: 'POST',
      signal,
      headers: getHeader(),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}

/* ALERTS */
export function getAlerts(id, status, bulkId) {
  let url = `${baseUrl}/treatment/${id}/alerts`

  if (status) {
    url += `?status=${status}`
  }

  if (bulkId) {
    url += `&bulkId=${bulkId}`
  }

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function resolveAlerts(id, data) {
  return fetch(`${baseUrl}/treatment/${id}/alerts/resolve`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* TIMELINE */
export const getTimeline = (id, from, to, filters) => {
  let url = `${baseUrl}/treatment/${id}/timeline`
  if (from || to) {
    url += `?from=${encodeURI(from)}&to=${encodeURI(to)}`
  }

  if (filters) {
    url += `&filter=${JSON.stringify({ domainEvents: filters })}`
  }

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* DATA */
export const getData = (id, from, to) => {
  let url = `${baseUrl}/treatment/${id}/data`
  if (from || to) {
    url += `?from=${encodeURI(from)}&to=${encodeURI(to)}`
  }

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* APPOINTMENT */
export const getAppointments = (id, from, to) => {
  let url = `${baseUrl}/treatment/${id}/appointment`

  if (from) {
    url += `?from=${from}&to=${to}`
  }

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getAppointment = (id, appointmentId) => {
  const url = `${baseUrl}/treatment/${id}/appointment/${appointmentId}`

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const editAppointment = (id, data, appointmentId) => {
  const url = `${baseUrl}/treatment/${id}/appointment/${appointmentId}`

  return fetch(url, {
    method: 'PUT',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const deleteAppointment = (id, appointmentId) => {
  const url = `${baseUrl}/treatment/${id}/appointment/${appointmentId}`

  return fetch(url, {
    method: 'DELETE',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const addAppointment = (id, pId, data) => {
  const url = `${baseUrl}/treatment/${id}/appointment`

  return fetch(url, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getAppointmentLink = (id) => {
  const url = `${baseUrl}/treatment/${id}/appointment/scheduleExternalLink`

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}
/* TASKS */
export const getTasks = (id) => {
  return fetch(`${baseUrl}/treatment/${id}/task`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const editTask = (id, data, taskId) => {
  return fetch(`${baseUrl}/treatment/${id}/task/${taskId}`, {
    method: 'PUT',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const deleteTask = (id, taskId) => {
  return fetch(`${baseUrl}/treatment/${id}/task/${taskId}`, {
    method: 'DELETE',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const createTask = (id, data) => {
  return fetch(`${baseUrl}/treatment/${id}/task`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* DISCHARGE */
export const dischargePatient = (id, data) => {
  return fetch(`${baseUrl}/treatment/${id}/discharge`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

export const restorePatient = (id) => {
  return fetch(`${baseUrl}/treatment/${id}/restore`, {
    method: 'POST',
    signal,
    headers: getHeader(),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

export const getMeetingRoom = (id) => {
  return fetch(`${baseUrl}/patient/${id}/telehealth`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* SUPPORTIVE CONTACT */
export const getSupportiveContacts = (id) => {
  return fetch(`${baseUrl}/treatment/${id}/supportive-contacts`, {
    method: 'GET',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const updateSupportiveContact = (id, contactId, data) => {
  return fetch(`${baseUrl}/treatment/${id}/supportive-contact/${contactId}`, {
    method: 'PUT',
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const deleteAdditionalContact = (id, contactId) => {
  return fetch(`${baseUrl}/treatment/${id}/supportive-contact/${contactId}`, {
    method: 'DELETE',
    headers: getHeader(),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

export const createAdditionalContact = (id, data) => {
  return fetch(`${baseUrl}/treatment/${id}/supportive-contact`, {
    method: 'POST',
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const sendRegistration = (id, method, email) => {
  return fetch(
    `${baseUrl}/treatment/${id}/send_registration?method=${method}`,
    {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify({ email }),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}

/* MEDICATION */
export const getTreatmentMedication = (id) => {
  return fetch(`${baseUrl}/medication/medicationRefill/${id}`, {
    method: 'GET',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

/* EMR */
export const getEMRStatus = (id) => {
  return fetch(`${baseUrl}/treatment/${id}/emr-status`, {
    method: 'GET',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const registerToEMR = (id) => {
  return fetch(`${baseUrl}/treatment/${id}/register-emr`, {
    method: 'POST',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}
