// default component
import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import { Lottie } from '@alfonmga/react-lottie-light-ts'
import loader from '../../images/valeraLoader.json'

function CallLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: JSON.parse(loader),
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  }

  return (
    <div className="loader">
      <Lottie
        config={{
          animationData: JSON.parse(loader),
          loop: true,
        }}
        height={36}
        width={34}
      />
    </div>
  )
}

CallLoader.propTypes = {
  patient: PropTypes.object,
}

export default CallLoader
