import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

function DayRange({ dayNum, itemsNum, filtered, date, isPastDate }) {
  return (
    <div className={`day-range${!isPastDate ? ' ' : ' past-event'}`}>
      <div className="day-num">
        {dayNum && <>Day {dayNum} - </>}
        {date}
      </div>
      <div className={`items-num ${!filtered ? ' ' : ' filtered'}`}>
        {itemsNum}
      </div>
    </div>
  )
}

DayRange.propTypes = {
  dayNum: PropTypes.number,
  itemsNum: PropTypes.number,
  date: PropTypes.string,
  filtered: PropTypes.bool,
}

export default DayRange
