import { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import {
  type FormControlProps,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material'
import { useTranslation } from '../../utils/hooks'
import { getErrorMessage, splitProps, useReadOnlyForm } from '../utils'
import { type BaseFormControlProps } from './index.types'

type YesNoButtonsProps = Omit<BaseFormControlProps, 'required' | 'validate'> &
  Omit<FormControlProps, 'children' | 'error' | 'focused' | 'required'> & {
    flexDirection?: 'column' | 'row'
    labelPlacement?: 'bottom' | 'top' | 'end' | 'start'
  } & {
    required?: boolean | string
  }

export function YesNoButtons({
  name,
  required,
  helperText,
  label,
  hideLabel,
  disabled,
  fullWidth,
  flexDirection = 'row',
  errorFieldName,
  labelPlacement = 'start',
  ...rest
}: YesNoButtonsProps) {
  const { registerProps, fieldProps } = useMemo(() => splitProps(rest), [rest])
  const { t } = useTranslation()
  const { readOnly } = useReadOnlyForm()
  return (
    <Controller
      name={name}
      rules={{
        ...registerProps,
        validate: {
          required: (val) => {
            const valid =
              !required || (val !== undefined && val !== null)
                ? true
                : typeof required === 'string'
                ? required
                : false
            return valid
          },
        },
      }}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => {
        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText

        return (
          <FormControl
            {...{
              ...fieldProps,
              error: !!error,
              required: !!required,
              disabled,
            }}
            sx={{
              width: fullWidth ? '100%' : 'auto',
              ...fieldProps?.sx,
            }}
          >
            <FormGroup
              sx={{
                flexDirection,
                flexWrap: ['wrap', 'nowrap'],
                alignItems: 'center',
              }}
            >
              {!hideLabel && (
                <FormLabel sx={{ flexGrow: 1 }}>{label}</FormLabel>
              )}
              <RadioGroup
                sx={{
                  flexShrink: 0,
                }}
                row
                value={value}
                onChange={(_v) => {
                  if (!readOnly) onChange(_v.target.value === 'true')
                }}
                {...rest}
              >
                <FormControlLabel
                  value
                  control={<Radio />}
                  label={t('yes')}
                  labelPlacement={labelPlacement}
                  sx={{
                    cursor: readOnly ? 'default' : 'pointer',
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('no')}
                  labelPlacement={labelPlacement}
                  sx={{
                    cursor: readOnly ? 'default' : 'pointer',
                  }}
                />
              </RadioGroup>
            </FormGroup>
            {errorOrHelper && <FormHelperText>{errorOrHelper}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}
