import React, { useState } from 'react'
import { Menu, MenuProps } from '@mui/material'

export type PopupMenuProps = {
  button: React.ReactElement
  children: React.ReactNode | React.ReactNode[]
  closeOnClick?: boolean
  onMenuOpened?: (event: React.MouseEvent<HTMLElement>) => void
  onMenuClosed?: (
    event: React.MouseEvent<HTMLElement>,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void
} & Omit<MenuProps, 'anchorEl' | 'open' | 'onClose'>

// clones an element with an onClick, which opens a menu, children are the content of the menu
export default function PopupMenu({
  button,
  children,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  keepMounted = true,
  closeOnClick = true,
  onMenuOpened,
  onMenuClosed,
  ...props
}: PopupMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    onMenuOpened?.(event)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {React.cloneElement(button, { onClick: handleMenu })}
      <Menu
        {...props}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        keepMounted={keepMounted}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(...args) => {
          handleClose()
          // MUI has a bad type value for the event... types it as {} instead of a proper event
          onMenuClosed?.(
            ...(args as [
              React.MouseEvent<HTMLElement>,
              'backdropClick' | 'escapeKeyDown',
            ]),
          )
        }}
        onClick={closeOnClick ? handleClose : undefined}
      >
        {children}
      </Menu>
    </>
  )
}
