import { memo } from 'react'
import { ExpectedHours, ServiceCategoryCode } from '@valerahealth/rtk-query'
import { isoTimeToMinutes } from '@valerahealth/ui-components/utils/date'
import {
  Box,
  LinearProgress,
  Stack,
  Typography,
} from '@valerahealth/ui-components'
import { useTranslation } from '../locales'

const displayHours = (minutes: number) => {
  return minutes % 60 === 0 ? minutes / 60 : (minutes / 60).toFixed(1)
}

export const patientHoursList = new Set([
  ServiceCategoryCode.Patient,
  ServiceCategoryCode.Intake,
  ServiceCategoryCode.Group,
])

export type ExpectedHoursProgressBarProps<
  T extends { startTime: string; endTime: string },
> = {
  // the schedule days for this service category
  intervals: T[]
  expectedHours: ExpectedHours
}

const ExpectedHoursProgressBar = memo(function ExpectedHoursProgressBar<
  T extends { startTime: string; endTime: string },
>({ intervals, expectedHours }: ExpectedHoursProgressBarProps<T>) {
  const { t } = useTranslation()

  const totalMinutes = intervals.reduce(
    (sum, { startTime, endTime }) =>
      sum + isoTimeToMinutes(endTime) - isoTimeToMinutes(startTime),
    0,
  )

  const value = (totalMinutes / expectedHours.weeklyMinutesTotal) * 100

  return (
    <Box>
      <Typography gutterBottom>
        {t('ServiceCategoryCode.expected', {
          serviceCategory: t(
            `ServiceCategoryCode.${
              patientHoursList.has(expectedHours.serviceCategory.code)
                ? ServiceCategoryCode.Patient
                : expectedHours.serviceCategory.code
            }`,
          ),
        })}
      </Typography>
      <Stack direction="row" gap={1} alignItems="center" flexWrap="nowrap">
        <LinearProgress
          value={value > 100 ? 100 : value}
          variant="determinate"
          color={value === 100 ? 'success' : value > 100 ? 'error' : 'primary'}
          sx={{ flexGrow: 1 }}
        />
        <Typography>
          {displayHours(totalMinutes)}/
          {displayHours(expectedHours.weeklyMinutesTotal)}{' '}
          {t('numberedHours', {
            count: expectedHours.weeklyMinutesTotal / 60,
          })}
        </Typography>
      </Stack>
    </Box>
  )
})

export default ExpectedHoursProgressBar
