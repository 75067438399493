import { avoidRedirectionReasonEnum } from '../../components/utilities/enums'
import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function globalReducer(state = initialState.global, action) {
  switch (action.type) {
    case types.DISPLAY_CONFIRMATION:
      return {
        ...state,
        confirmation: {
          display: true,
          callback: action.confirmation.callback,
          onCancel: action.confirmation.onCancel,
          btns: action.confirmation.btns,
          details: {
            ...initialState.global.confirmation.details,
            ...action.confirmation.details,
          },
        },
      }
    case types.HIDE_CONFIRMATION:
      return {
        ...state,
        confirmation: {
          display: false,
          callback: initialState.global.confirmation.callback,
          onCancel: undefined,
          details: initialState.global.confirmation.details,
        },
      }
    case types.EDIT_ITEM:
      return { ...state, editedItem: action.item }
    case types.CANCEL_EDIT_ITEM:
      return { ...state, editedItem: null }
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.data,
      }
    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        user: { ...state.user, profile: action.data },
      }
    case types.EDIT_USER_SCHEDULE_DATES_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: {
            ...state.user.notifications,
            outOfOffice: action.outOfOffice,
          },
        },
      }
    case types.LOAD_PROVIDER_CONFIGURATIONS:
      return {
        ...state,
        configurations: action.configurations,
      }
    case types.LOAD_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
      }
    case types.EDIT_SETTINGS_SUCCESS:
      return {
        ...state,
        user: action.data,
      }
    case types.EDIT_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        user: { ...state.user, notifications: action.data },
      }
    case types.CHANGE_PLAN_STATE:
      return { ...state, careplanState: action.state }
    case types.ADD_USER_MESSAGE_POPUP: {
      return displayMessage(state, action)
    }
    case types.REMOVE_USER_MESSAGE_POPUP: {
      const messageId = action.id
      return {
        ...state,
        userMessageArray: state.userMessageArray.filter(
          (item) => item.id !== messageId,
        ),
      }
    }
    case types.TOGGLE_CONFIRM_REDIRECTION:
      return {
        ...state,
        confirmRedirection: action.value,
        confirmRedirectionReason:
          (action.options && action.options.reason) ||
          avoidRedirectionReasonEnum.UNSAVE,
        confirmRedirectionOptions: action.options,
      }
    case types.EDIT_PLAN_TEMPLATE:
    case types.ADD_TRACK:
    case types.EDIT_TRACK:
      return {
        ...state,
        confirmRedirection: true,
      }
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        openSidebar: !state.openSidebar,
      }
    case types.CANCEL_EDIT_PLAN_TEMPLATE:
    case types.CANCEL_EDIT_TRACK:
    case types.UPDATE_TRACK_SUCCESS:
    case types.DELETE_TRACK_SUCCESS:
    case types.DELETE_PLAN_TEMPLATE_SUCCESS:
    case types.UPDATE_PLAN_TEMPLATE_SUCCESS:
    case types.CREATE_PLAN_TEMPLATE_SUCCESS:
      return {
        ...state,
        confirmRedirection: false,
      }
    case types.TEST_ERROR_DATADOG: {
      const a = state.toto.ok
      return state
    }
    case types.BEGIN_API_CALL:
      return { ...state, apiCallsInProgress: state.apiCallsInProgress + 1 }
    case types.END_API_CALL:
    case types.API_CALL_ERROR: {
      return { ...state, apiCallsInProgress: state.apiCallsInProgress - 1 }
    }
    default:
      return state
  }
}

const displayMessage = (state, action) => {
  const { messageItem } = action
  /**
   * invalid message will not be insert into message array
   * */
  if (!messageItem.text || !messageItem.type || !messageItem.id) return state
  const arr = []
  let flag = true
  for (let index = 0; index < state.userMessageArray.length; index++) {
    const element = state.userMessageArray[index]
    if (element.id !== messageItem.id) {
      arr.push({ ...element })
    } else {
      // if I want to add text in existing message
      arr.push(messageItem)
      flag = false
    }
  }

  if (flag) {
    arr.push(messageItem)
  }

  return { ...state, userMessageArray: arr }
}
