// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .pagination .right-hide {
    display: flex;
    align-items: center;
  }
  
  .pagination .right-hide input {
    width: 35px;
    text-align: center;
    margin: 0 10px;
    border-radius: 0;
    padding: 0;
  }
  .pagination .page-arrows {
    display: flex;
    margin: 0 5px 0 20px;
  }`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/Pagination/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,UAAU;EACZ;EACA;IACE,aAAa;IACb,oBAAoB;EACtB","sourcesContent":[".pagination {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 15px;\n  }\n  \n  .pagination .right-hide {\n    display: flex;\n    align-items: center;\n  }\n  \n  .pagination .right-hide input {\n    width: 35px;\n    text-align: center;\n    margin: 0 10px;\n    border-radius: 0;\n    padding: 0;\n  }\n  .pagination .page-arrows {\n    display: flex;\n    margin: 0 5px 0 20px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
