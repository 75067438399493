import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.less'
import { Provider as ReduxProvider } from 'react-redux'
import { initMuiLicenseKey } from '@valerahealth/ui-components/grid'
import { TranslationProvider } from '@valerahealth/ui-translation'
import baseTranslations from '@valerahealth/ui-translation/locales/en'
import {
  LocalizationProvider,
  theme,
  ThemeProvider,
  en as uiComponentLocalsEn,
  AuthMonitor,
  UI_COMPONENTS_LOCALS_NAMESPACE,
} from '@valerahealth/ui-components'
import {
  SCHEDULING_NAMESPACE,
  en as schedulingLocalesEn,
} from '@valerahealth/scheduling'
import { en as notesLocalesEn } from '@valerahealth/ui-notes'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './redux/store'
import DashboardApp from './components/DashboardApp'
import appTranslations from './locales/en'

initMuiLicenseKey()

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)

  root.render(
    <ThemeProvider theme={theme}>
      <TranslationProvider
        ns={[
          'appTranslations',
          'baseTranslations',
          UI_COMPONENTS_LOCALS_NAMESPACE,
          SCHEDULING_NAMESPACE,
        ]}
        defaultNS="appTranslations"
        fallbackNS="baseTranslations"
        staticResources={{
          en: {
            appTranslations,
            baseTranslations,
            ...uiComponentLocalsEn,
            ...notesLocalesEn,
            ...schedulingLocalesEn,
          },
        }}
      >
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider>
              <AuthMonitor>
                <Router>
                  <DashboardApp />
                </Router>
              </AuthMonitor>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </TranslationProvider>
    </ThemeProvider>,
  )
} else {
  console.error('Failed to find the root element.')
}
