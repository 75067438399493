import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

function Button({ text, id, onClick, type, disable, loading, style }) {
  let className = `button-new ${
    type === 'secondary'
      ? 'secondary-button'
      : type === 'primary'
      ? 'primary-button'
      : 'link-button'
  }`

  const localStyle = style || {}

  if (disable) {
    className += ' disable'
    return (
      <div id={id} className={className} style={localStyle}>
        {text}
      </div>
    )
  }
  if (loading) {
    className += ' disable'
    return (
      <div id={id} className={className} style={localStyle}>
        Loading...
      </div>
    )
  }

  return (
    <div id={id} className={className} onClick={onClick} style={localStyle}>
      {text}
    </div>
  )
}

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['', 'primary', 'secondary', 'link']).isRequired,
  disable: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
}

export default Button
