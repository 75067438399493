import { useMemo } from 'react'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { Apps as AppsIcon } from '@mui/icons-material'
import {
  Permission,
  selectUserPermissions,
  useReduxSelectorWithAuthState,
} from '@valerahealth/redux-auth'
import { useTranslation } from '../../utils/hooks'
import IconButton from '../../base/IconButton'
import Link from '../../base/Link'
import PopupMenu from '../../base/PopupMenu'

const appsConfig = [
  {
    url: `https://${process.env.CARE_MANAGER_UI_DOMAIN}`,
    name: 'careManager',
    iconUrl: 'https://cdn.valerahealth.com/images/logo/care-manager-logo.svg',
    permissions: [],
  },
  {
    url: `https://${process.env.PRACTICE_MANAGER_UI_DOMAIN}`,
    name: 'practiceManager',
    iconUrl:
      'https://cdn.valerahealth.com/images/logo/practice-manager-logo.svg',
    permissions: [Permission.Provider_Read],
  },
  {
    url: `https://${process.env.BACKOFFICE_UI_DOMAIN}`,
    name: 'backoffice',
    iconUrl: 'https://cdn.valerahealth.com/images/logo/backoffice-logo.svg',
    permissions: [
      Permission.Tenant_Create,
      Permission.Tenant_Update,
      Permission.Tenant_Read,
      Permission.Tenant_Delete,
    ],
  },
  {
    url: `https://${process.env.USER_ADMIN_UI_DOMAIN}`,
    name: 'userAdmin',
    iconUrl: 'https://cdn.valerahealth.com/images/logo/care-manager-logo.svg',
    permissions: [Permission.User_Read],
  },
]

export default function AppList() {
  const userPermissions = useReduxSelectorWithAuthState(selectUserPermissions)

  const apps = useMemo(
    () =>
      appsConfig.filter(({ url, permissions }) => {
        if (window.location.origin === url) return false
        return permissions.every((permission) =>
          userPermissions.includes(permission),
        )
      }),
    [userPermissions],
  )

  const [t] = useTranslation()

  return (
    <PopupMenu
      button={
        <IconButton
          title={t('appsList')}
          size="large"
          aria-label="valera apps"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <AppsIcon />
        </IconButton>
      }
    >
      {apps.map(({ name, iconUrl, url }, index) => (
        <MenuItem
          divider={index < apps.length - 1}
          component={Link}
          to={url}
          key={name}
        >
          <ListItemIcon>
            <img
              style={{ width: '1.5rem', height: 'auto' }}
              src={iconUrl}
              alt={t(`appName.${name}`)}
              title={t(`appName.${name}`)}
            />
          </ListItemIcon>
          <ListItemText>{t(`appName.${name}`)}</ListItemText>
        </MenuItem>
      ))}
    </PopupMenu>
  )
}
