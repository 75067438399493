export const dateFormatEnum = {
  MONTH_DAY_YEAR: 'MM/dd/yyyy',
  MONTH_DAY_YEAR_AND_TIME: 'MM/dd/yyyy, hh:mma',
  FULL_DATE: 'EEE, LLL d, yyyy',
  MONTH_DAY_YEAR_WORD: 'MMM d, yyyy',
  MONTH_DAY_YEAR_WORD_TIME: 'EEE, MMM d, h:mma',
  FULL_TIME: 'hh:mma',
  HOUR: 'ha',
  FULL_DATE_AND_TIME: 'EEE, LLL d, yyyy | hh:mma',
  DATE_BASIC: 'MM/dd/yyyy',
  DATE_BASIC_TIME: 'MM/dd/yyyy, hh:mm a',
}
