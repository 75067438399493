// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-list-container.select-list {
  padding: 5px 0;
  z-index: 1;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(114, 114, 114, 0.5);
}

.check-list-element {
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
}
.check-list-element label {
  cursor: pointer;
}
.select-list .check-list-element {
  padding: 15px;
}
.select-list .check-list-element:hover {
  background-color: #f1f3f4;
}
/* .check-list-element label { */
.check-list-element {
  cursor: pointer;
  /* display: block;
  height: 36px;
  line-height: 36px; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.check-list-element input {
  margin-right: 7px;
}
.check-list-element.clear-all {
  color: #4285F4;
}`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/CheckList/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,kBAAkB;EAClB,yBAAyB;EACzB,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;AACA,gCAAgC;AAChC;EACE,eAAe;EACf;;sBAEoB;EACpB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB","sourcesContent":[".check-list-container.select-list {\n  padding: 5px 0;\n  z-index: 1;\n  border-radius: 3px;\n  background-color: #ffffff;\n  box-shadow: 0 1px 5px 0 rgba(114, 114, 114, 0.5);\n}\n\n.check-list-element {\n  display: flex;\n  align-items: center;\n  height: 36px;\n  cursor: pointer;\n}\n.check-list-element label {\n  cursor: pointer;\n}\n.select-list .check-list-element {\n  padding: 15px;\n}\n.select-list .check-list-element:hover {\n  background-color: #f1f3f4;\n}\n/* .check-list-element label { */\n.check-list-element {\n  cursor: pointer;\n  /* display: block;\n  height: 36px;\n  line-height: 36px; */\n  width: 100%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.check-list-element input {\n  margin-right: 7px;\n}\n.check-list-element.clear-all {\n  color: #4285F4;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
