import CONFIG, { getHeader, handleResponse, handleError } from './apiUtils'
import { getUrlFromObject } from '../components/utilities'

const baseUrl = `https://${process.env.CARE_MANAGER_API_DOMAIN}/api/provider`
const { signal } = CONFIG

export function updateScheduleSettings(id, data) {
  return fetch(`${baseUrl}/${id}/settings/notifications`, {
    method: 'PUT',
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateSettings(id, data, recaptcha) {
  let url = `${baseUrl}/${id}/settings`

  if (recaptcha) {
    url += `?recaptcha=${recaptcha}`
  }

  return fetch(url, {
    method: 'PUT',
    headers: getHeader(),
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function loadPendingTreatment(id) {
  return fetch(`${baseUrl}/${id}/caseload/pending`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function removePendingTreatment(id, userId) {
  return fetch(`${baseUrl}/${id}/caseload/pending/${userId}`, {
    method: 'DELETE',
    headers: getHeader(),
  })
    .then((resp) => resp.text())
    .catch(handleError)
}

export function getTreatments({ id, options, pagination }) {
  let url = `${baseUrl}/${id}/caseload?`
  if (options) {
    url = getUrlFromObject(url, options)
  }

  if (pagination) {
    url = getUrlFromObject(url, pagination)
  }

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then((res) => {
      return handleResponse(res, true)
    })
    .catch(handleError)
}

export function loadCaseloadFilterNumbers(id) {
  return fetch(`${baseUrl}/${id}/caseload/numbers`, {
    method: 'GET',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function assignItems({ id, group, message, attachment }) {
  return fetch(`${baseUrl}/${id}/caseload/assign`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify({
      group,
      message,
      attachment,
    }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function assignPlans({ id, group, templateIds }) {
  return fetch(`${baseUrl}/${id}/caseload/assign/plan`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify({
      templateIds,
      group,
      type: 'plan',
    }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getNotifications(id, pagination) {
  return fetch(
    `${baseUrl}/${id}/caseload/notifications?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}&`,
    {
      method: 'GET',
      headers: getHeader(),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}

export function getSupervisorCaseload(id) {
  return fetch(`${baseUrl}/${id}/supervisor/caseload`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function loadOutOfOffice(id, filters) {
  let url = `${baseUrl}/${id}/out-of-office?`
  const params = filters && Object.keys(filters)

  if (params) {
    url = getUrlFromObject(url, filters)
  }
  return fetch(url, {
    method: 'GET',
    headers: getHeader(),
  })
    .then((res) => {
      return handleResponse(res, true)
    })
    .catch(handleError)
}

export function createOutOfOffice(id, data) {
  return fetch(`${baseUrl}/${id}/out-of-office`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateOutOfOffice(id, oooId, data) {
  return fetch(`${baseUrl}/${id}/out-of-office/${oooId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function deleteOutOfOffice(id, oooId) {
  return fetch(`${baseUrl}/${id}/out-of-office/${oooId}`, {
    method: 'DELETE',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}
