import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.less'
import { connect } from 'react-redux'
import {
  CheckList,
  ElementsFilter,
  Button,
  Form as FormComponent,
} from '@valerahealth/ui-core'
import { loadTreatments } from '../../../redux/actions/caseloadActions'
import { loadProgramProviders } from '../../../redux/actions/programActions'
import { addUserMessage } from '../../../redux/actions/globalActions'
import dStrings from '../../../strings.json'
import ClinicalIndicatorFilter from './clinicalIndicatorFilter'
import FilterPart from './FilterPart'
import { getProfileName } from '../../utilities/treatments'
import { genderEnum2 } from '../../utilities/enums'

function CaseloadFilters({
  clientFilters,
  programCareTeam,
  clinicalIndicators,
  loadTreatments,
  addUserMessage,
  loadProgramProviders,
  handleLoadTreatment,
}) {
  const [treatmentsFilters, setTreatmentsFilters] = useState([])
  const [carePlanFilters, setCarePlanFilters] = useState([])
  const [diagnosisFilters, setDiagnosisFilters] = useState([])
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [showMoreCareplans, setShowMoreCareplans] = useState(false)
  const [showMoreDiagnosis, setShowMoreDiagnosis] = useState(false)
  const [canLoad, setCanLoad] = useState(false)

  const getDefaultDemographics = () => {
    return {
      min: undefined,
      max: undefined,
      gender: undefined,
      zipCode: '',
    }
  }

  const getDefaultClinicalIndicators = () => {
    if (!clinicalIndicators) return []

    return clinicalIndicators.map((c) => {
      return {
        scoreChange: undefined,
        score: undefined,
        value1: '',
        value2: '',
      }
    })
  }

  const getClinicalIndicatorsData = () => {
    const arr = []
    for (let i = 0; i < indicators.length; i++) {
      const indicator = indicators[i]

      if (indicator.scoreChange || indicator.value1) {
        arr.push({
          questionnaireTemplateIds: clinicalIndicators[i].templateIds,
          scoreChange: indicator.scoreChange,
          score: {
            operator: indicator.score,
            value1: indicator.value1,
            value2: indicator.value2 || undefined,
          },
        })
      }
    }

    return arr.length ? arr : undefined
  }

  const getPatientActivityData = () => {
    if (patientActivity.status && !patientActivity.periodInDays) {
      return { error: dStrings.patientActivityNumError }
    }
    if (!patientActivity.status && patientActivity.periodInDays) {
      return { error: dStrings.patientActivityStatusError }
    }

    if (patientActivity.status && patientActivity.periodInDays)
      return patientActivity
    return undefined
  }

  const getDefaultPatientActivity = () => {
    return {
      status: undefined,
      periodInDays: undefined,
    }
  }

  const getDemographicData = () => {
    let flag
    const _demographics = {}
    if (demographics.min && demographics.max) {
      if (demographics.min > demographics.max) {
        return { error: dStrings.minMaxAgeError }
      }

      flag = true
      _demographics.age = {
        operator: 'between',
        value1: demographics.min,
        value2: demographics.max,
      }
    } else if (demographics.min) {
      flag = true
      _demographics.age = {
        operator: 'greater_than_or_equal',
        value1: demographics.min,
      }
    } else if (demographics.max) {
      flag = true
      _demographics.age = {
        operator: 'less_than_or_equal',
        value1: demographics.max,
      }
    }
    if (demographics.gender) {
      flag = true
      _demographics.gender = [demographics.gender]
    }
    if (demographics.zipCode && demographics.zipCode.length) {
      flag = true
      _demographics.address = [
        {
          zipCode: demographics.zipCode,
        },
      ]
    }

    return flag ? _demographics : undefined
  }

  const getCareTeamList = () => {
    if (!programCareTeam) return []

    return programCareTeam.allIds.map((ct) => {
      return {
        key: programCareTeam.byId[ct].id,
        title: getProfileName(programCareTeam.byId[ct]),
      }
    })
  }

  const [demographics, setDemographics] = useState(getDefaultDemographics)
  const [indicators, setIndicators] = useState(getDefaultClinicalIndicators)
  const [careTeamData, setCareTeamData] = useState({ role: '', ids: [] })
  const [careTeamList, setCareTeamList] = useState(getCareTeamList)
  const [patientActivity, setPatientActivity] = useState(
    getDefaultPatientActivity(),
  )

  useEffect(() => {
    if (!programCareTeam) loadProgramProviders(true)
    else setCareTeamList(getCareTeamList())
  }, [programCareTeam])

  useEffect(() => {
    if (!clientFilters) return

    const _treatmentsFilters = []
    const _carePlantFilters = []
    const _diagnosisFilter = []
    for (let i = 0; i < clientFilters.length; i++) {
      const element = clientFilters[i]
      if (element.parent === 'careplan') _carePlantFilters.push(element)
      else if (element.parent === 'diagnosis') _diagnosisFilter.push(element)
      else _treatmentsFilters.push(element)
    }

    setTreatmentsFilters(_treatmentsFilters)
    setCarePlanFilters(_carePlantFilters)
    setDiagnosisFilters(_diagnosisFilter)
  }, [clientFilters])

  useEffect(() => {
    setIndicators(getDefaultClinicalIndicators())
  }, [clinicalIndicators])

  const loadData = () => {
    let deleteTreatment = true
    const options = {
      treatments: {},
      carePlan: [],
      diagnosis: [],
    }

    treatmentsFilters.forEach((element) => {
      if (element.checked) deleteTreatment = false

      options.treatments[element.key] = element.checked
    })

    if (deleteTreatment) delete options.treatments

    for (let i = 0; i < carePlanFilters.length; i++) {
      const cp = carePlanFilters[i]
      if (cp.checked) options.carePlan.push(cp.key)
    }

    if (!options.carePlan.length) delete options.carePlan

    for (let i = 0; i < diagnosisFilters.length; i++) {
      const cp = diagnosisFilters[i]
      if (cp.checked) options.diagnosis.push(cp.key)
    }

    if (!options.diagnosis.length) delete options.diagnosis

    const demographicsData = getDemographicData()

    if (demographicsData) {
      if (demographicsData.error) {
        addUserMessage('error', demographicsData.error)
        return
      }

      options.demographics = demographicsData
    }

    const clinicalIndicatorData = getClinicalIndicatorsData()
    if (clinicalIndicatorData) {
      if (clinicalIndicatorData.error) {
        addUserMessage('error', clinicalIndicatorData.error)
        return
      }

      options.clinicalIndicators = clinicalIndicatorData
    }

    const patientActivityData = getPatientActivityData()
    if (patientActivityData) {
      if (patientActivityData.error) {
        addUserMessage('error', patientActivityData.error)
        return
      }

      options.patientActivity = patientActivityData
    }

    if (careTeamData.role && careTeamData.ids.length) {
      options.careTeam = careTeamData.ids.map((id) => {
        return {
          role: careTeamData.role,
          ctmId: id,
        }
      })
    }

    loadTreatments({ options })
    handleLoadTreatment()
  }

  useEffect(() => {
    if (canLoad) loadData()
  }, [canLoad, treatmentsFilters, carePlanFilters, diagnosisFilters])

  return (
    <div className="caseload-filters">
      <span className="title">{dStrings.filters}</span>
      {!!treatmentsFilters.length && (
        <FilterPart
          header={dStrings.notificationTypes}
          onClear={() => {
            setTreatmentsFilters(
              treatmentsFilters.map((t) => {
                return { ...t, checked: false }
              }),
            )
          }}
        >
          <CheckList
            list={treatmentsFilters}
            handleChange={(newTreatmentsFilters) => {
              setCanLoad(true)
              setTreatmentsFilters(newTreatmentsFilters)
            }}
          />
        </FilterPart>
      )}
      {!!carePlanFilters.length && (
        <FilterPart
          header={dStrings.assignedPopulationPlan}
          onClear={() => {
            setCarePlanFilters(
              carePlanFilters.map((t) => {
                return { ...t, checked: false }
              }),
            )
          }}
        >
          <CheckList
            list={
              !showMoreCareplans ? carePlanFilters.slice(0, 5) : carePlanFilters
            }
            handleChange={(newCareplanFilters) => {
              setCanLoad(true)
              setCarePlanFilters(newCareplanFilters)
            }}
          />
          {!showMoreCareplans ? (
            carePlanFilters.length > 5 && (
              <div
                className="link-click"
                onClick={() => {
                  setShowMoreCareplans(true)
                }}
              >
                {`${dStrings.moreCareplans} `}
                <span className="arrow-down" />
              </div>
            )
          ) : (
            <div
              className="link-click"
              onClick={() => {
                setShowMoreCareplans(false)
              }}
            >
              {`${dStrings.hideCareplans} `}
              <span className="arrow-top" />
            </div>
          )}
        </FilterPart>
      )}
      {!!diagnosisFilters.length && (
        <FilterPart
          header={dStrings.tags}
          onClear={() => {
            setDiagnosisFilters(
              diagnosisFilters.map((t) => {
                return { ...t, checked: false }
              }),
            )
          }}
        >
          <CheckList
            list={
              !showMoreDiagnosis
                ? diagnosisFilters.slice(0, 5)
                : diagnosisFilters
            }
            handleChange={(newDiagnosisFilters) => {
              setDiagnosisFilters(newDiagnosisFilters)
              setCanLoad(true)
            }}
          />
          {!showMoreDiagnosis ? (
            diagnosisFilters.length > 5 && (
              <div
                className="link-click"
                onClick={() => {
                  setShowMoreDiagnosis(true)
                }}
              >
                {`${dStrings.moreTags} `}
                <span className="arrow-down" />
              </div>
            )
          ) : (
            <div
              className="link-click"
              onClick={() => {
                setShowMoreDiagnosis(false)
              }}
            >
              {`${dStrings.hideTags} `}
              <span className="arrow-top" />
            </div>
          )}
        </FilterPart>
      )}
      <div className="border-separate-filters" />
      {showAdvancedFilter ? (
        <div className="server-filters">
          <div className="server-filter-note xsmall-font">
            {dStrings.serverFilterNote}
          </div>
          {!!carePlanFilters.length && (
            <FilterPart
              header={dStrings.Demographics}
              onClear={() => {
                setDemographics(getDefaultDemographics())
              }}
            >
              <FormComponent
                data={demographics}
                handleDataChange={setDemographics}
                fields={[
                  {
                    key: 'age',
                    text: dStrings.ageRange,
                    type: 'range',
                    range: {
                      from: {
                        type: 'number',
                        key: 'min',
                        max: demographics.max,
                        placeholder: dStrings.min,
                      },
                      word: dStrings.to,
                      to: {
                        type: 'number',
                        key: 'max',
                        min: demographics.min,
                        placeholder: dStrings.max,
                      },
                    },
                  },
                  {
                    key: 'gender',
                    text: dStrings.sex,
                    type: 'select',
                    emptyOption: `<${dStrings.choose}>`,
                    select: genderEnum2,
                  },
                  {
                    key: 'zipCode',
                    text: dStrings.zipCode,
                    type: 'text',
                    placeholder: 'Example 12345',
                  },
                ]}
              />
            </FilterPart>
          )}
          {!!indicators.length && (
            <FilterPart
              header={dStrings.clinicalIndicators}
              onClear={() => {
                setIndicators(getDefaultClinicalIndicators())
              }}
            >
              {clinicalIndicators.map((c, i) => {
                return (
                  <ClinicalIndicatorFilter
                    handleChange={(_indicatorData) => {
                      setIndicators(
                        indicators.map((indicator, index) => {
                          if (index !== i) return indicator
                          return _indicatorData
                        }),
                      )
                    }}
                    indicator={indicators[i]}
                    name={c.name}
                    key={i}
                  />
                )
              })}
            </FilterPart>
          )}
          {programCareTeam &&
            programCareTeam.allIds &&
            programCareTeam.allIds.length && (
              <FilterPart
                header={dStrings.CareTeam}
                onClear={() => {
                  setCareTeamData({ role: '', ids: [] })
                  setCareTeamList(getCareTeamList())
                }}
              >
                <div className="care-team-filter">
                  <select
                    value={careTeamData.role || ''}
                    onChange={(e) => {
                      setCareTeamData({
                        role: e.target.selectedOptions[0].value,
                        ids: careTeamData.ids,
                      })
                    }}
                  >
                    <option value>{`<${dStrings.choose}>`}</option>
                    <option value="pctm">{dStrings.PCTM}</option>
                    <option value="sctm">{dStrings.secondaryCTMember}</option>
                    <option value="ctm">{dStrings.all}</option>
                  </select>
                  {!!careTeamList.length && (
                    <ElementsFilter
                      originalList={careTeamList}
                      handleChange={(list) => {
                        setCareTeamData({ role: careTeamData.role, ids: list })
                      }}
                      textForAllSelected={dStrings.allCTMembers}
                    />
                  )}
                </div>
              </FilterPart>
            )}

          <FilterPart
            header={dStrings.patientActivity}
            onClear={() => {
              setPatientActivity(getDefaultPatientActivity())
            }}
          >
            <FormComponent
              data={patientActivity}
              handleDataChange={setPatientActivity}
              fields={[
                {
                  key: 'status',
                  text: dStrings.status,
                  type: 'select',
                  emptyOption: `<${dStrings.choose}>`,
                  select: {
                    active: dStrings.active,
                    inactive: dStrings.inactive,
                  },
                },
                {
                  key: 'periodInDays',
                  text: dStrings.inLast,
                  type: 'number',
                  min: 0,
                  endText: dStrings.day_s,
                },
              ]}
            />
          </FilterPart>
          <div className="server-filter-footer">
            <div
              className="toggle-advancer-filter link-click small-font"
              onClick={() => {
                setShowAdvancedFilter(false)
              }}
            >
              {dStrings.hideAdvancedFilter}
            </div>
            <Button type="primary" text={dStrings.apply} onClick={loadData} />
          </div>
        </div>
      ) : (
        <div
          className="toggle-advancer-filter link-click small-font"
          onClick={() => {
            setShowAdvancedFilter(true)
          }}
        >
          {dStrings.advancedFilter}
        </div>
      )}
    </div>
  )
}

CaseloadFilters.propTypes = {
  clientFilters: PropTypes.array,
  clinicalIndicators: PropTypes.array,
  programCareTeam: PropTypes.object,
  loadTreatments: PropTypes.func,
  loadProgramProviders: PropTypes.func,
  addUserMessage: PropTypes.func,
}

function mapStateToProps(state, ownProps) {
  return {
    clientFilters: state.caseload.filters.client,
    clinicalIndicators:
      state.program.configurations &&
      state.program.configurations.clinicalIndicators,
    programCareTeam: state.program.careTeam,
    handleLoadTreatment: ownProps.handleLoadTreatment,
  }
}

const mapDispatchToProps = {
  loadProgramProviders,
  loadTreatments,
  addUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseloadFilters)
