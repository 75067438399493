import dotProp from 'dot-prop-immutable'
import {
  getPlanTemplateDetails,
  getPlans,
  getTracksKey,
  getPlansKey,
} from '../../components/utilities/planTemplate'
import createReducer from './createReducer'
import { trackType } from '../../components/utilities/enums'
import { getTracksByItems } from '../../components/utilities'

function addPlanTemplate(state, action) {
  const templateId = `new_planTemplate_${
    state.planTemplates.nextPlanTemplateId || 1
  }`

  // State here is the entire combined state
  const updatedWithPlanState = dotProp.set(
    state,
    `planTemplates.byId.${templateId}`,
    {
      editMode: {
        id: templateId,
        name: getPlanTemplateDetails(action.planType).newTitle,
        type: action.planType,
        description: 'Add description',
        tracks: getTracksByItems([]).tracks,
      },
      id: templateId,
      name: getPlanTemplateDetails(action.planType).newTitle,
      type: action.planType,
      description: 'Add description',
      tracks: getTracksByItems([]).tracks,
    },
  )

  const updatedWithNewTemplatePlanId = dotProp.set(
    updatedWithPlanState,
    `planTemplates.allIds`,
    (allIds) => [templateId, ...allIds],
  )

  const updatedSelectedTemplatePlanId = dotProp.set(
    updatedWithNewTemplatePlanId,
    `planTemplates.selectedId`,
    templateId,
  )

  const updatedTemplatePlanId = dotProp.set(
    updatedSelectedTemplatePlanId,
    `planTemplates.nextPlanTemplateId`,
    state.planTemplates.nextPlanTemplateId
      ? state.planTemplates.nextPlanTemplateId + 1
      : 2,
  )

  const updatedWithGoToNewId = dotProp.set(
    updatedTemplatePlanId,
    `planTemplates.goToNewId`,
    templateId,
  )

  return updatedWithGoToNewId
}

function createPlan(state, action) {
  const tempState1 = dotProp.delete(
    state,
    `planTemplates.byId.${state.planTemplates.selectedId}`,
  )

  const tempState2 = dotProp.set(
    tempState1,
    `planTemplates.allIds`,
    (allIds) => [
      action.plan.id,
      ...allIds.filter((id) => id !== state.planTemplates.selectedId),
    ],
  )

  const tempState3 = dotProp.set(
    tempState2,
    `planTemplates.byId.${action.plan.id}`,
    { ...action.plan, ...getTracksByItems(action.plan.tracks) },
  )

  const tempState4 = dotProp.set(
    tempState3,
    `planTemplates.selectedId`,
    action.plan.id,
  )

  const tempState5 = dotProp.set(
    tempState4,
    `planTemplates.goToNewId`,
    action.plan.id,
  )

  return tempState5
}

function updatePlan(state, action) {
  const planKey = getPlansKey(state)
  const plans = getPlans(state)

  const tempState1 = dotProp.delete(
    state,
    `${planKey}.byId.${plans.selectedId}.editMode`,
  )

  const tempState2 = dotProp.delete(tempState1, 'caseload.allIds')

  return dotProp.set(tempState2, `${planKey}.byId.${plans.selectedId}`, {
    ...action.plan,
    ...getTracksByItems(action.plan.tracks),
  })
}

function addTrack(state) {
  const plans = getPlans(state)
  const templateId = plans.selectedId

  const planKey = getTracksKey(state)

  let tempState = state
  if (!plans.byId[templateId].tracks) {
    tempState = dotProp.set(state, `${planKey}`, {
      byId: {},
      allIds: [],
    })
  }

  const nextId = plans.byId[templateId].nextTrackId || 1
  const trackId = `newID_${nextId}`

  // State here is the entire combined state
  const updatedWithTracks = dotProp.set(
    tempState,
    `${planKey}.byId.${trackId}`,
    {
      editMode: {
        id: trackId,
        name: `New path ${nextId}`,
        type: trackType.ONETIME,
        isSingleItem: false,
        schedule: {},
        slots: [],
      },
      id: trackId,
      name: `New path ${nextId}`,
      type: trackType.ONETIME,
      isSingleItem: false,
      schedule: {},
      slots: [],
    },
  )

  const updatedWithNewTrackId = dotProp.set(
    updatedWithTracks,
    `${planKey}.multipleItemsIds`,
    (allIds) => [...allIds, trackId],
  )

  const updatedWithUpdatedTrackId = dotProp.set(
    updatedWithNewTrackId,
    planKey.replace('tracks', 'nextTrackId'), // small trick: replace tree.tracks by tree.nextTrackId.
    nextId + 1,
  )

  return updatedWithUpdatedTrackId
}

function editTrack(state, action) {
  const plans = getPlans(state)

  return dotProp.set(
    state,
    `${getTracksKey(state, action.trackId)}.editMode`,
    plans.byId[plans.selectedId].tracks.byId[action.trackId],
  )
}

function updateTracks(state, action) {
  const allTracks = getTracksByItems(action.tracks)

  return dotProp.set(state, getTracksKey(state), allTracks.tracks)
}

function deleteTrack(state, action) {
  const plans = getPlans(state)

  const templateId = plans.selectedId
  const tempState = state
  const { trackId } = action

  const key = !plans.byId[templateId].tracks.byId[trackId].isSingleItem
    ? 'multipleItemsIds'
    : 'singleItemsIds'

  const trackKey = getTracksKey(state)

  const tempState1 = dotProp.delete(tempState, `${trackKey}.byId.${trackId}`)

  const tempState2 = dotProp.set(tempState1, `${trackKey}.${key}`, (allIds) =>
    allIds.filter((id) => id !== trackId),
  )

  return tempState2
}

function cancelEditTracks(state, action) {
  return dotProp.delete(
    state,
    `${getTracksKey(state, action.trackId)}.editMode`,
  )
}

function cancelEditPlanTemplate(state) {
  const key = getPlansKey(state)
  const plans = getPlans(state)

  return dotProp.delete(state, `${key}.byId.${plans.selectedId}.editMode`)
}

function editPlan(state) {
  const plans = getPlans(state)
  return dotProp.set(
    state,
    `${getPlansKey(state)}.byId.${plans.selectedId}.editMode`,
    plans.byId[plans.selectedId],
  )
}

export const deleteTracks = (state) => {
  const plansKey = getPlansKey(state)
  const plans = getPlans(state)

  const tempState1 = dotProp.delete(
    state,
    `${plansKey}.byId.${plans.selectedId}`,
  )

  const tempState2 = dotProp.set(tempState1, `${plansKey}.allIds`, (ids) => {
    return ids.filter((id) => {
      return id != plans.selectedId
    })
  })

  const state2plans = getPlans(tempState2)
  if (!state2plans) return dotProp.delete(tempState2, `${plansKey}.selectedId`)

  const tempState3 = dotProp.delete(tempState2, 'caseload.allIds')
  const tempState4 = dotProp.delete(tempState3, 'caseload.treatments')

  if (state.global.careplanState)
    return dotProp.set(tempState4, `planTemplates.goToPlanSelection`, true)

  const tempState5 = dotProp.set(
    tempState4,
    `${plansKey}.selectedId`,
    state2plans.allIds && state2plans.allIds[0],
  )

  // delete allIds to force caseload reload

  return dotProp.set(
    tempState5,
    `${getPlansKey(state)}.goToNewId`,
    getPlans(tempState4).selectedId,
  )
}

function searchPlan(state, action) {
  const plans = getPlans(state)
  const key = getPlansKey(state)

  if (action.text && action.text.length) {
    const filteredState = dotProp.set(
      state,
      `${key}.filteredTemplates`,
      plans.allIds.filter((templateId) => {
        return plans.byId[templateId].name
          .toLowerCase()
          .includes(action.text.toLowerCase().trim())
      }),
    )

    return dotProp.set(filteredState, `${key}.searchText`, action.text)
  }
  const stateWithDeletedFilteredTemplates = dotProp.delete(
    state,
    `${key}.filteredTemplates`,
  )

  return dotProp.set(stateWithDeletedFilteredTemplates, `${key}.searchText`, '')
}

function resetGoToNewId(state) {
  return dotProp.delete(state, `${getPlansKey(state)}.goToNewId`)
}

function changeView(state, action) {
  return dotProp.set(state, `${getPlansKey(state)}.view`, action.view)
}

function selectPlan(state, action) {
  const planKey = getPlansKey(state)

  if (planKey) {
    const tempState1 = dotProp.set(
      state,
      `${planKey}.selectedId`,
      action.selectedId,
    )

    return dotProp.set(tempState1, `${planKey}.view`, 'plan')
  }

  return state
}

function addUpdateItem(state, action) {
  const { item } = action
  return dotProp.set(
    state,
    `${getTracksKey(state)}.byId.${action.trackId}.editMode.slots`,
    (slots) => {
      if (item.position) {
        return slots.map((s) => {
          if (s.position !== item.position) return s
          return item
        })
      }
      return [...slots, { ...item, position: slots.length + 1 }]
    },
  )
}

function setSchedule(state, action) {
  return dotProp.set(
    state,
    `${getTracksKey(state)}.byId.${action.trackId}.editMode`,
    (trackData) => {
      return { ...trackData, ...action.scheduleData }
    },
  )
}

function setPathName(state, action) {
  return dotProp.set(
    state,
    `${getTracksKey(state)}.byId.${action.pathId}.editMode.name`,
    action.name,
  )
}

function deleteItem(state, action) {
  return dotProp.set(
    state,
    `${getTracksKey(state)}.byId.${action.trackId}.editMode.slots`,
    (slots) => {
      const updatedSlots = []
      let elementFound
      for (let index = 0; index < slots.length; index++) {
        const element = slots[index]
        if (element.position !== action.position)
          updatedSlots.push({
            ...element,
            position: index + (elementFound ? 0 : 1),
          })
        else elementFound = true
      }

      return updatedSlots
    },
  )
}

const planReducer = createReducer(
  {},
  {
    ADD_PLAN_TEMPLATE: addPlanTemplate,
    CREATE_PLAN_TEMPLATE_SUCCESS: createPlan,
    UPDATE_PLAN_TEMPLATE_SUCCESS: updatePlan,
    DELETE_TRACK_SUCCESS: deleteTrack,
    EDIT_PLAN_TEMPLATE: editPlan,
    CANCEL_EDIT_PLAN_TEMPLATE: cancelEditPlanTemplate,
    DELETE_PLAN_TEMPLATE_SUCCESS: deleteTracks,
    PLAN_UNASSIGNED_SUCCESS: deleteTracks,
    PLAN_TEMPLATE_SEARCH: searchPlan,
    RESET_GO_TO_NEW_ID: resetGoToNewId,
    ADD_TRACK: addTrack,
    EDIT_TRACK: editTrack,
    CANCEL_EDIT_TRACK: cancelEditTracks,
    UPDATE_TRACK_SUCCESS: updateTracks,
    CHANGE_VIEW: changeView,
    PLAN_TEMPLATE_SELECTED: selectPlan,
    ADD_UPDATE_ITEM: addUpdateItem,
    SET_TRACK_SCHEDULE: setSchedule,
    SET_PATH_NAME: setPathName,
    DELETE_ITEM: deleteItem,
  },
)

export default planReducer
