import dotProp from 'dot-prop-immutable'
import { TreatmentStatusEnum, PatientStatus } from '@valerahealth/rtk-query'
import * as types from '../actions/actionTypes'
import initialState from './initialState'
import {
  getListByIdAndAllIds,
  getTracksByItems,
  MESSAGES_LENGTH,
} from '../../components/utilities'
import noteReducer from '../../components/TreatmentNotes/noteReducer'
import { merge } from 'lodash'

export default function treatmentRoomReducer(
  state = initialState.treatmentRoom,
  action,
) {
  switch (action.type) {
    case types.LOAD_TREATMENT_SUCCESS: {
      const { treatment } = action
      const current = dotProp.get(state, `byId.${treatment.id}`) || {}

      return dotProp.set(
        state,
        `byId.${treatment.id}`,
        merge(
          {},
          current,
          treatment.status === TreatmentStatusEnum.DISCHARGED
            ? {
                id: action.treatment.id,
                patientId: action.treatment.patientId,
                status: action.treatment.status,
                dischargeInfo: action.treatment.dischargeInfo,
                profile: action.treatment.profile,
                count: {
                  unreadMessages: 0,
                },
              }
            : treatment,
        ),
      )
    }
    case types.SELECT_TREATMENT: {
      return { ...state, selectedId: action.treatmentId }
    }
    case types.UNSELECT_TREATMENT:
      return { ...state, selectedId: undefined }
    case types.TOGGLE_RESTORE_POPUP:
      return {
        ...state,
        restorePopup: {
          display: action.display,
          details: action.details || undefined,
        },
      }
    case types.HELP_ACTION:
      return {
        ...state,
        help: !state.help,
      }
    case types.DISCHARGE_PATIENT: {
      const tempState = dotProp.set(
        state,
        `byId.${action.id}.profile.status`,
        PatientStatus.ClosedDischarged,
      )

      return dotProp.set(
        tempState,
        `byId.${action.id}.status`,
        TreatmentStatusEnum.DISCHARGED,
      )
    }
    case types.RESTORE_PATIENT: {
      const tempState = dotProp.delete(
        state,
        `byId.${action.id}.profile.status`,
      )
      return dotProp.set(
        tempState,
        `byId.${action.id}.status`,
        TreatmentStatusEnum.ACTIVE,
      )
    }
    case types.LOAD_PLAN_SUCCESS: {
      const { plansDetails } = state.byId[action.treatmentId] || {}
      const list = getListByIdAndAllIds(
        action.plans.map((t) => {
          return {
            ...t,
            ...getTracksByItems(t.tracks),
          }
        }),
      )

      return dotProp.set(state, `byId.${action.treatmentId}.plansDetails`, {
        ...plansDetails,
        ...list,
      })
    }
    case types.LOAD_TREATMENT_QUESTIONNAIRE_SUCCESS:
    case types.LOAD_TREATMENT_CONSENT_FORM_SUCCESS:
    case types.LOAD_TREATMENT_MEDIA_ITEM_SUCCESS:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.tools.${action.item.id}`,
        action.item,
      )
    case types.LOAD_CHANNEL_MESSAGES: {
      const messages = state.byId[action.id]?.channelMessages || []
      const tempState = dotProp.set(
        state,
        `byId.${action.id}.channelMessages`,
        [...action.messages, ...messages],
      )

      const tempState2 = dotProp.set(
        tempState,
        `byId.${action.id}.cannotLoadMore`,
        !action.messages.length || action.messages.length < MESSAGES_LENGTH,
      )

      const { channelPage } = tempState2.byId[action.id]
      const updatedChannel = channelPage ? channelPage + 1 : 2
      const tempState3 = dotProp.set(
        tempState2,
        `byId.${action.id}.channelPage`,
        updatedChannel,
      )

      return dotProp.set(
        tempState3,
        `byId.${action.id}.count.unreadMessages`,
        updatedChannel > 2
          ? 0
          : action.messages.filter((message) => {
              return (
                !message.viewedBy.length &&
                message.sender.id !== action.providerId
              )
            }).length,
      )
    }
    case types.SEND_CHANNEL_MESSAGE: {
      const { message, treatmentId, tempId, sender } = action.data

      if (
        !treatmentId ||
        !state.byId[treatmentId] ||
        !state.byId[treatmentId].channelMessages
      ) {
        return state
      }

      const messages = [
        ...state.byId[treatmentId].channelMessages,
        {
          domainEvent: 'chat_message_sent',
          id: tempId,
          message,
          sender,
          ts: new Date(),
          tempMessage: true,
        },
      ]

      return dotProp.set(state, `byId.${treatmentId}.channelMessages`, messages)
    }

    case types.RECEIVE_NEW_CHANNEL_MESSAGE: {
      const { channelItem, treatmentId } = action.message

      if (
        !treatmentId ||
        !state.byId[treatmentId] ||
        !state.byId[treatmentId].channelMessages ||
        state.byId[treatmentId].channelMessages.find((m)=>m.id === channelItem.id)
      )
        return state

      if (action.ownMessage && !channelItem.attachment) {
        return dotProp.set(
          state,
          `byId.${treatmentId}.channelMessages`,
          state.byId[treatmentId].channelMessages.map((message) => {
            if (message.id !== action.tempId) return message

            return channelItem
          }),
        )
      }

      const messages = [...state.byId[treatmentId].channelMessages, channelItem]

      const tempState = !channelItem.attachment
        ? state
        : dotProp.set(
            state,
            `byId.${treatmentId}.count.unreadMessages`,
            state.byId[treatmentId].count.unreadMessages + 1,
          )

      return dotProp.set(
        tempState,
        `byId.${treatmentId}.channelMessages`,
        messages,
      )
    }
    case types.UPDATE_PROFILE_SUCCESS:
      return dotProp.set(
        { ...state, editedPatient: undefined },
        `byId.${action.id}.profile`,
        {
          hospitalized: false,
          ...state.byId[action.id]?.profile,
          ...action.profile,
        },
      )
    case types.LOAD_PROFILE_SUCCESS:
      return dotProp.set(state, `byId.${action.id}.profile`, {
        hospitalized: false,
        ...state.byId[action.id]?.profile,
        ...action.profile,
      })
    case types.LOAD_PROFILE_CARETEAM_SUCCESS: {
      const pctm = action.careTeam.find((a) => a.role === 'pctm')
      return dotProp.set(state, `byId.${state.selectedId}`, {
        ...state.byId[state.selectedId],
        careTeam: action.careTeam.map((a) => a.id),
        pctm: pctm && pctm.id,
        careTeamProfiles: [...action.careTeam].sort((a, b) => {
          return a.role > b.role ? -1 : 1
        }),
      })
    }
    case types.UPDATE_CARE_TEAM: {
      const temp = dotProp.set(state, `byId.${state.selectedId}.careTeam`, [
        ...state.byId[state.selectedId].careTeam,
        ...action.ids,
      ])

      const temp2 = [
        ...state.byId[state.selectedId].careTeamProfiles,
        ...action.profiles,
      ]

      return dotProp.set(
        temp,
        `byId.${state.selectedId}.careTeamProfiles`,
        temp2,
      )
    }
    case types.DELETE_CARE_TEAM_MEMBER: {
      const temp = dotProp.set(
        state,
        `byId.${state.selectedId}.careTeam`,
        state.byId[state.selectedId].careTeam.filter((id) => id !== action.id),
      )

      return dotProp.set(
        temp,
        `byId.${state.selectedId}.careTeamProfiles`,
        state.byId[state.selectedId].careTeamProfiles.filter(
          (profile) => profile.id !== action.id,
        ),
      )
    }
    case types.CHANGE_PCTM: {
      return dotProp.set(state, `byId.${state.selectedId}.pctm`, action.ctmid)
    }
    case types.SET_CHANNEL_SEEN:
      if (
        !state.byId[action.treatmentId] ||
        !state.byId[action.treatmentId].channelMessages
      )
        return state
      return dotProp.set(
        state,
        `byId.${action.treatmentId}.count.unreadMessages`,
        0,
      )
    case types.ALL_MESSAGES_READED: {
      if (
        !state.byId[state.selectedId] ||
        !state.byId[state.selectedId].channelMessages
      )
        return state

      const tempState = state.byId[state.selectedId].channelMessages.map(
        (message) => {
          if (message.viewedBy && message.viewedBy.length) {
            return message
          }

          return {
            ...message,
            viewedBy: [{ ...action.viewer }],
            viewedByMe: true,
          }
        },
      )

      return dotProp.set(
        state,
        `byId.${state.selectedId}.channelMessages`,
        tempState,
      )
    }
    case types.SET_MESSAGES_UNREADED: {
      let flag = false
      const tempState = state.byId[state.selectedId].channelMessages.map(
        (message) => {
          if (flag)
            return {
              ...message,
              viewedBy:
                message.id !== action.data.userId ? [] : message.viewedBy,
            }

          if (message.id !== action.data.messageId)
            return { ...message, viewedByMe: true }

          flag = true
          return {
            ...message,
            viewedByMe: false,
          }
        },
      )

      return dotProp.set(
        state,
        `byId.${state.selectedId}.channelMessages`,
        tempState,
      )
    }
    case types.CANCEL_EDIT_PATIENT:
      return {
        ...state,
        editedPatient: undefined,
      }
    case types.REGISTER_NEW_PATIENT: {
      const patient = action.patient && {
        id: action.patient.id,
        patientId: action.patient.patientId,
        firstName: action.patient.firstName || '',
        lastName: action.patient.lastName || '',
        gender: action.patient.gender || '',
        picture: action.patient.picture || '',
        dateOfBirth: action.patient.dateOfBirth || '',
        address: action.patient.address || undefined,
        mrn: action.patient.mrn || '',
        phone: action.patient.phone || '',
        email: action.patient.email || '',
        patientMessaging:
          typeof action.patient.disableChat !== 'undefined'
            ? action.patient.disableChat
            : true,
        tags: [],
        pending: true,
      }

      return {
        ...state,
        editedPatient: patient || {
          firstName: '',
          lastName: '',
          gender: '',
          picture: '',
          dateOfBirth: '',
          address: undefined,
          mrn: '',
          phone: '',
          email: '',
          tags: [],
          phqScore: '',
          gad7Score: '',
          patientMessaging: !action.disableByDefault,
        },
      }
    }
    case types.LOAD_PROFILE_ALERTS_SUCCESS:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.alerts`,
        action.alerts,
      )
    case types.RESOLVE_ALERTS_SUCCESS: {
      const tempState = dotProp.set(
        state,
        `byId.${state.selectedId}.unresolvedAlertCount`,
        state.byId[state.selectedId].unresolvedAlertCount -
          action.alerts.length,
      )
      return dotProp.set(
        tempState,
        `byId.${state.selectedId}.alerts`,
        (alerts) =>
          alerts.map((alert) => {
            if (!~action.ids.indexOf(alert.id)) {
              return alert
            }
            return {
              ...action.alerts.find((a) => a.id === alert.id),
            }
          }),
      )
    }
    case types.NOTIFICATION_RECEIVED: {
      const { treatmentId } = action.data
      const data = action.data.channelItem
      if (
        !state.byId ||
        !state.byId[treatmentId] ||
        data.domainEvent !== 'alert_created'
      ) {
        return state
      }

      const alertNum = state.byId[treatmentId].unresolvedAlertCount || 0

      const tempState = dotProp.set(
        state,
        `byId.${treatmentId}.unresolvedAlertCount`,
        alertNum + 1,
      )

      // remove alerts to force system to reload them
      return dotProp.delete(tempState, `byId.${treatmentId}.alerts`)
    }
    case types.LOAD_PROFILE_DATA:
      return dotProp.set(state, `byId.${state.selectedId}.data`, action.data)
    case types.LOAD_PROFILE_TIMELINE:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.timeline`,
        action.timeline,
      )
    case types.LOAD_TASKS_SUCCESS: {
      return dotProp.set(state, `byId.${state.selectedId}.tasks`, action.tasks)
    }
    case types.UPDATE_TASK_SUCCESS:
      return dotProp.set(state, `byId.${state.selectedId}.tasks`, (list) =>
        list.map((item) => {
          if (item.id !== action.task.id) return item
          return action.task
        }),
      )
    case types.DELETE_TASKS_SUCCESS: {
      return dotProp.set(state, `byId.${state.selectedId}.tasks`, (list) =>
        list.filter((task) => task.id !== action.id),
      )
    }
    case types.CREATE_TASKS_SUCCESS:
      return dotProp.set(state, `byId.${state.selectedId}.tasks`, (list) => [
        action.task,
        ...list,
      ])
    case types.LOAD_SUPPORTIVE_CONTACT_SUCCESS: {
      return dotProp.set(
        state,
        `byId.${state.selectedId}.supportiveContacts`,
        action.contacts,
      )
    }
    case types.UPDATE_SUPPORTIVE_CONTACT_SUCCESS:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.supportiveContacts`,
        (list) =>
          list.map((item) => {
            if (item.id !== action.contact.id) return item
            return action.contact
          }),
      )
    case types.DELETE_SUPPORTIVE_CONTACT_SUCCESS: {
      return dotProp.set(
        state,
        `byId.${state.selectedId}.supportiveContacts`,
        (list) => list.filter((contact) => contact.id !== action.id),
      )
    }
    case types.CREATE_SUPPORTIVE_CONTACT_SUCCESS:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.supportiveContacts`,
        (list) => [action.contact, ...list],
      )
    case types.LOAD_PROFILE_APPOINTMENT: {
      const tempState = dotProp.set(
        state,
        `byId.${state.selectedId}.firstAppointmentDate`,
        action.date,
      )

      return dotProp.set(
        tempState,
        `byId.${state.selectedId}.appointments`,
        action.appointments,
      )
    }
    case types.GET_APPOINTMENT_DETAILS:
    case types.EDIT_APPOINTMENT_SUCCESS:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.appointments`,
        (list) =>
          list.map((item) => {
            if (item.id !== action.appointment.id) return item
            return action.appointment
          }),
      )
    case types.DELETE_APPOINTMENT_SUCCESS: {
      return dotProp.set(
        state,
        `byId.${state.selectedId}.appointments`,
        (list) => list.filter((app) => app.id !== action.id),
      )
    }
    case types.ADD_APPOINTMENT_SUCCESS:
      return dotProp.set(
        state,
        `byId.${state.selectedId}.appointments`,
        (list) => {
          const d = new Date(action.data.date).getTime()
          let index = list.length
          for (let i = 0; i < list.length; i++) {
            if (d < new Date(list[i].date).getTime()) {
              index = i
              break
            }
          }

          const nList = [...list]
          nList.splice(index, 0, action.data)
          return nList
        },
      )

    case types.LOAD_MORE_PROFILE_TIMELINE: {
      if (action.loadMore === 'past') {
        return dotProp.set(state, `byId.${state.selectedId}.timeline`, [
          ...action.timeline,
          ...state.byId[state.selectedId].timeline,
        ])
      }
      return dotProp.set(state, `byId.${state.selectedId}.timeline`, [
        ...state.byId[state.selectedId].timeline,
        ...action.timeline,
      ])
    }
    case types.CLEAR_TREATMENT_ROOM: {
      return dotProp.delete(state, `byId.${state.treatmentId}`)
    }
    case types.LOAD_EMR_STATUS: {
      return dotProp.set(
        state,
        `byId.${action.selectedId}.emrStatus`,
        action.status,
      )
    }
    case types.REGISTER_TO_EMR: {
      if (state.byId[action.treatmentId])
        return dotProp.set(
          state,
          `byId.${state.selectedId}.emrStatus`,
          action.status,
        )

      return state
    }
    default: {
      const temp = noteReducer(state, action)

      return temp || state
    }
  }
}
