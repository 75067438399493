import { useEffect, useState } from 'react'
import { DataGrid, GridActionsCellItem } from '@valerahealth/ui-components/grid'
import { Button, ElementsFilter, RangeDapicker } from '@valerahealth/ui-core'
import { connect, useSelector } from 'react-redux'
import dStrings from '../../strings.json'
import { buttonsTypesEnum, dateFormatEnum } from '../utilities/enums'
import RightArrowIcon from '../../images/right_arrow.svg'
import { getProfileName } from '../utilities/treatments'
import {
  addUserMessage,
  displayConfirmation,
  editOOO,
} from '../../redux/actions/globalActions'
import { getDefaultRangeDates } from '../AddEditProvider/domain'
import { format } from '@valerahealth/ui-components/utils/date'

const PROJECT_DISABLE_ADD_OOO = process.env.PROJECT_DISABLE_ADD_OOO === 'true'

function HistoricOOO({
  id,
  data,
  rowCount,
  user,
  providersList,
  handleDelete,
  editOOO,
  handleGetData,
}) {
  const getDefaultPagination = () => {
    const [sd, ed] = getDefaultRangeDates()
    const obj = {
      pageSize: 8,
      pageNumber: 1,
      startDate: sd,
      endDate: ed,
      coveringProviderId: undefined,
      status: undefined,
      order: {
        by: 'insurancePlansCount',
        direction: 'ascending',
      },
    }

    return obj
  }
  const { isWellness } = useSelector((state) => state.program.configurations)

  const [pagination, setPagination] = useState(getDefaultPagination)
  const [coveringList, setCoveringList] = useState([])

  useEffect(() => {
    setCoveringList(
      providersList
        ? providersList.map((provider) => ({
            key: provider.id,
            title: getProfileName(provider),
          }))
        : [],
    )
  }, [providersList])

  useEffect(() => {
    handleGetData(pagination)
  }, [pagination])

  const columns = [
    {
      field: 'startDate',
      flex: 1,
      headerName: dStrings.timePeriod,
      disableColumnMenu: true,
      renderCell: (value) => {
        const data = value.row
        const sd = format(data.startDate, dateFormatEnum.DATE_BASIC_TIME)
        const ed = format(data.endDate, dateFormatEnum.DATE_BASIC_TIME)

        const TimePeriod = (
          <div className="historic-ooo-dates">
            <div className="ooo-dates">{sd}</div>
            <RightArrowIcon />
            <div className="ooo-dates">{ed}</div>
          </div>
        )

        return TimePeriod
      },
    },
    {
      field: 'coveringProvider',
      disableColumnMenu: true,
      headerName: dStrings.coveringUser,
      flex: 1,
      renderCell: (value) => {
        const data = value.row

        if (data) {
          const n = getProfileName(data.coveringProvider)
          return <span>{n}</span>
        }
      },
    },
  ].concat(
    !(PROJECT_DISABLE_ADD_OOO && isWellness)
      ? {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (value) => {
            const params = value.row
            return params.status === 'upcoming'
              ? [
                  <GridActionsCellItem
                    key={1}
                    label={dStrings.editDetails}
                    onClick={() => {
                      editOOO({
                        id,
                        outOfOfficeData: params,
                        user,
                        handleChange: () => {
                          handleGetData(pagination)
                        },
                      })
                    }}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={2}
                    label={dStrings.deleteDates}
                    onClick={() => {
                      handleDelete(params)
                    }}
                    showInMenu
                  />,
                ]
              : []
          },
        }
      : [],
  )

  const [statusList] = useState([
    { key: 'past', title: dStrings.past },
    { key: 'upcoming', title: dStrings.upcoming },
  ])

  return (
    <div className="historic-ooo">
      <div className="ooo-header">
        <RangeDapicker
          start={pagination.startDate}
          end={pagination.endDate}
          handleSave={(_start, _end) => {
            setPagination({
              ...pagination,
              startDate: _start,
              endDate: _end,
              pageNumber: 1,
            })
          }}
        />
        <ElementsFilter
          originalList={statusList}
          handleChange={(list) => {
            setPagination({
              ...pagination,
              pageNumber: 1,
              status:
                list && list.length
                  ? list.length > 1
                    ? list
                    : list[0]
                  : undefined,
            })
          }}
          title={dStrings.status}
          hideSelected
        />
        {coveringList && coveringList.length > 0 && (
          <ElementsFilter
            originalList={coveringList}
            handleChange={(list) => {
              setPagination({
                ...pagination,
                pageNumber: 1,
                coveringProviderId: list[0],
              })
            }}
            title={dStrings.coveringUser}
            textForAllSelected=" "
            singleSelection
          />
        )}
        {isWellness && PROJECT_DISABLE_ADD_OOO ? null : (
          <Button
            onClick={() => {
              editOOO({
                id,
                user,
                handleChange: () => {
                  handleGetData(pagination)
                },
              })
            }}
            text={dStrings.addDates}
            type={buttonsTypesEnum.SECONDARY}
          />
          //test
        )}
      </div>
      <DataGrid
        autoHeight
        rows={data}
        columns={columns}
        pagination
        paginationMode="server"
        getRowClassName={(params) => {
          return params.status === 'past' ? 'past-ooo' : 'future-row'
        }}
        pageSizeOptions={[8, 10, 12]}
        paginationModel={{
          page: pagination.pageNumber - 1,
          pageSize: pagination.pageSize,
        }}
        rowCount={rowCount || 5}
        onPageChange={(pn) => {
          setPagination({
            ...pagination,
            pageNumber: pn + 1,
          })
        }}
        onPageSizeChange={(ps) => {
          setPagination({
            ...pagination,
            pageNumber: 1,
            pageSize: ps,
          })
        }}
        onSortModelChange={(newSortModel) => {
          const sort = newSortModel[0]
          setPagination({
            ...pagination,
            order: sort
              ? {
                  by: sort.field,
                  direction: sort.sort === 'asc' ? 'descending' : 'ascending',
                }
              : undefined,
          })
        }}
      />
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  }
}

const mapDispatchToProps = {
  displayConfirmation,
  editOOO,
  addUserMessage,
}

HistoricOOO.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricOOO)
