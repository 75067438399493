import { useEffect, useState } from 'react'
import { ProgramTag, careManagerApi } from '@valerahealth/rtk-query'
import {
  programIdSelector,
  useReduxSelectorWithAuthState,
} from '@valerahealth/redux-auth'
import { useNotify } from '../Notifications'
import {
  Combobox,
  filterComboboxOptions,
  type ComboboxProps,
  useFormContext,
} from '../../form'
import CreateProgramTagDialog from './CreateProgramTagDialog'

type ProgramTagType = ProgramTag & { inputValue?: string }

export type ProgramTagSelectProps = Omit<
  ComboboxProps<ProgramTagType, true>,
  | 'options'
  | 'getOptionLabel'
  | 'getOptionKey'
  | 'setValueAs'
  | 'loading'
  | 'filterOptions'
  | 'onChange'
  | 'parseValue'
>

const defaultTags: ProgramTagType[] = []

export function ProgramTagSelect(props: ProgramTagSelectProps) {
  const { name } = props
  const [showCreateDialog, setShowCreateDialog] = useState<string | null>(null)

  const programId = useReduxSelectorWithAuthState(programIdSelector)
  const { data, isLoading, isError } = careManagerApi.useGetProgramTagsQuery(
    programId,
    {
      selectFromResult: ({ isLoading, isError, data }) => ({
        isLoading,
        isError,
        data: data || defaultTags,
      }),
    },
  )
  const notify = useNotify()
  useEffect(() => {
    if (isError) {
      notify({ severity: 'warning', message: 'Failed to load program tags' })
    }
  }, [isError, notify])

  const methods = useFormContext<Record<string, string[] | null>>()

  return (
    <>
      <Combobox
        multiple
        options={data}
        getOptionLabel={(v) => v.name}
        getOptionKey={(v) => v.name}
        setValueAs={(v) =>
          v?.filter((v) => v.id !== '-1').map((v) => v.name) || []
        }
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loading={isLoading}
        filterOptions={(options, params) => {
          const filtered = filterComboboxOptions(options, params)
          if (params.inputValue !== '' && filtered.length === 0) {
            filtered.push({
              id: '-1',
              name: `Add "${params.inputValue}"`,
              inputValue: params.inputValue,
            })
          }
          return filtered
        }}
        onChange={(e, v) => {
          const newOption = v?.find((v) => !!v.inputValue)
          if (newOption?.inputValue) {
            setShowCreateDialog(newOption.inputValue)
          }
        }}
        parseValue={(value: string[] | null) =>
          data.filter((tag) => value?.includes(tag.name)) || null
        }
        {...props}
      />
      {showCreateDialog && (
        <CreateProgramTagDialog
          value={showCreateDialog}
          onValueAdded={(value) => {
            const values = methods.getValues(name)
            methods.setValue(name, [...(values || []), value], {
              shouldDirty: true,
            })
            setShowCreateDialog(null)
          }}
          onCancel={() => setShowCreateDialog(null)}
        />
      )}
    </>
  )
}
