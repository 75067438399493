import { callApi } from '../../utilities'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import { MESSAGES_LENGTH, generateID } from '../../../../components/utilities'

export function loadChannelMessages(id) {
  return (dispatch, getState) => {
    const state = getState()
    const tId = id || state.treatmentRoom.selectedId
    const channelPage =
      (state.treatmentRoom.byId[state.treatmentRoom.selectedId] &&
        state.treatmentRoom.byId[state.treatmentRoom.selectedId].channelPage) ||
      1

    return callApi({
      name: 'loadChannelMessages',
      dispatch,
      call: () => {
        return treatmentAPI.loadChannelMessages(
          tId,
          MESSAGES_LENGTH,
          channelPage,
        )
      },
      hideLoader: channelPage > 1,
      success: (messages) => {
        const providerId = state.auth.session?.user.careManagerId

        dispatch({
          type: types.LOAD_CHANNEL_MESSAGES,
          messages: messages
            .map((m) => ({
              ...m,
              viewedByMe: !!m.viewedBy?.find((v) => v.id === providerId),
            }))
            .reverse(),
          providerId,
          id: tId,
        })
      },
    })
  }
}

export function sendChannelMessage(data) {
  return (dispatch, getState) => {
    const state = getState()
    const treatmentId = state.treatmentRoom.selectedId
    const tempId = generateID()

    if (!data.attachment) {
      dispatch({
        type: types.SEND_CHANNEL_MESSAGE,
        data: {
          ...data,
          treatmentId,
          tempId,
          sender: {
            ...getState().global.user.profile,
          },
        },
      })
    }

    return callApi({
      name: 'sendChannelMessage',
      hideLoader: true,
      dispatch,
      call: () => {
        return treatmentAPI.sendChannelMessage(treatmentId, data)
      },
      success: (message) => {
        dispatch({
          type: types.RECEIVE_NEW_CHANNEL_MESSAGE,
          ownMessage: true,
          tempId,
          message: {
            channelItem: message,
            treatmentId,
          },
        })
      },
    })
  }
}

export function receiveNewChannelMessage(message) {
  return { type: types.RECEIVE_NEW_CHANNEL_MESSAGE, message }
}
