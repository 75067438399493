import {
  ConsentPacketInfoFragment,
  patientRegistrationApi,
} from '@valerahealth/rtk-query'

import { CircularProgress, Stack, MuiLink, Box, Typography } from '../base'
import { Check } from '../icons'
import { formatIsoDateTime } from '../utils/date'
import { useNotify } from './Notifications'
import { useConfirmationDialog } from './ConfirmationDialog'

const { useSendConsentPacketMutation } = patientRegistrationApi

export type TreatmentSendConsentPacketLinkProps = {
  fullConsentPacketSigned?: boolean | null
  consentPacketInfo?: ConsentPacketInfoFragment | null
  email: string
  treatmentId: string
  disabled?: boolean
}

export function TreatmentSendConsentPacketLink({
  email,
  treatmentId,
  consentPacketInfo,
  fullConsentPacketSigned,
  disabled,
}: TreatmentSendConsentPacketLinkProps) {
  const [sendConsentPacket, state] = useSendConsentPacketMutation()
  const notify = useNotify()

  // if it has not been signed  and we have an agreement id on file
  const isSendingReminder =
    !fullConsentPacketSigned && consentPacketInfo?.agreementId

  const { confirm, ConfirmationDialog } = useConfirmationDialog()

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        sx={{ height: '2rem' }}
      >
        <MuiLink
          sx={{ marginRight: 'auto' }}
          component="button"
          disabled={state.isLoading || disabled}
          onClick={async (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (state.isLoading) return
            if (fullConsentPacketSigned) {
              const confirmed = await confirm({
                header: 'Confirm Send Consent Packet',
                body: 'This patient has been marked as having signed the consent packet. Are you sure you want to send a new packet for signature?',
              })
              if (!confirmed) return
            }

            const res = await sendConsentPacket({
              treatmentId,
            })
            if ('error' in res) {
              notify({
                severity: 'error',
                message:
                  res.error.message ||
                  'An error occured while sending the consent packet link.',
              })
            } else {
              notify({
                severity: 'success',
                message: isSendingReminder
                  ? `A reminder to sign the consent packet was sent to ${email}`
                  : `A new consent packet was sent to ${email}`,
              })
            }
          }}
        >
          {isSendingReminder
            ? 'Send consent reminder via primary email'
            : 'Send consent via primary email'}
        </MuiLink>

        {state.isLoading ? (
          <CircularProgress size="1.5rem" />
        ) : (
          state.isSuccess && <Check color="success" />
        )}
      </Stack>
      {consentPacketInfo?.signedDate && fullConsentPacketSigned ? (
        <Typography sx={{ fontSize: 'inherit' }} component="span">
          {`Signed ${formatIsoDateTime(consentPacketInfo.signedDate)}`}
        </Typography>
      ) : consentPacketInfo?.sent ? (
        <Typography sx={{ fontSize: 'inherit' }} component="span">
          {`Sent ${formatIsoDateTime(consentPacketInfo.sent.date)}`}
        </Typography>
      ) : null}
      <ConfirmationDialog />
    </Box>
  )
}
