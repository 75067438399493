import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import Popup from '../Popup'

function Confirmation({ details, callback, onClosed, btns }) {
  const getButtons = () => {
    const btns = []
    if (!details.hideCancelBtn) {
      btns.push({
        text: details.cancelBtnText,
        type: 'link',
        onClick: () => {
          onClosed()
        },
      })
    }

    btns.push({
      text: details.confirmBtnText,
      type: 'primary',
      onClick: () => {
        onClosed()
        callback && callback()
      },
    })

    return btns
  }

  return (
    <Popup
      title={details.title}
      onClosed={onClosed}
      buttons={btns || getButtons()}
      className={details.popupClassName}
    >
      <div className="confirmation-popup">{details.content}</div>
    </Popup>
  )
}

Confirmation.propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  callback: PropTypes.func,
  onClosed: PropTypes.func,
  btns: PropTypes.array,
}

export default Confirmation
