import { useState } from 'react'
import {
  type GridValidRowModel,
  type DataGridProProps,
  DataGridPro,
  useGridApiRef,
  type GridEventListener,
  type GridSlotsComponentsProps,
  type GridInitialState,
} from '@mui/x-data-grid-pro'
import { PersistentGridView } from './reduxSlice'
import Toolbar from './Toolbar'
import { usePersistentGridInitialState } from './useGridViewParams'
import { GridViewsProps } from './GridViews'

export type PersistentDataGridProps<T extends GridValidRowModel> = Omit<
  DataGridProProps<T>,
  'slotProps'
> & {
  /* used as redux key, defaults to window.location.pathname, if more than one needed on a page pass a name */
  name?: string
  presetGridViews?: PersistentGridView
  slotProps?: GridSlotsComponentsProps & {
    gridViews?: Omit<
      GridViewsProps<T>,
      'apiRef' | 'gridName' | 'columns' | 'presetGridViews' | 'defaultGridState'
    >
  }
  /** if using a server side grid you need to pass both initial state (may have a view selected) and default grid state (state when no view is selected) */
  defaultGridState?: GridInitialState
}

export default function PersistentDataGrid<T extends GridValidRowModel>(
  props: PersistentDataGridProps<T>,
) {
  const {
    name = window.location.pathname,
    apiRef: apiRefOverride,
    columns,
    onRowClick,
    presetGridViews,
    initialState,
    defaultGridState = initialState,
    slots,
    slotProps: _componentsProps,
    ...rest
  } = props
  const { gridViews: gridViewsProps, ...slotProps } = _componentsProps || {}
  const internalApiRef = useGridApiRef()
  const apiRef = apiRefOverride || internalApiRef

  const [panelStatus, setPanelStatus] = useState<boolean>(false)
  const [menuStatus, setMenuStatus] = useState<boolean>(false)

  /** if user passed in their own intial state AND default state then this is not needed... user constructed initial state with this hook themself */
  const fallbackInitialState = usePersistentGridInitialState(
    name,
    defaultGridState,
    presetGridViews,
  )

  const onPreferencePanelOpen: GridEventListener<
    'preferencePanelOpen'
  > = () => {
    setPanelStatus(true)
  }
  const onPreferencePanelClose: GridEventListener<
    'preferencePanelClose'
  > = () => {
    setTimeout(() => setPanelStatus(false), 200) // wait for popup fades away animation to finish
  }
  const onMenuOpen: GridEventListener<'menuOpen'> = () => {
    setMenuStatus(true)
  }
  const onMenuClose: GridEventListener<'menuClose'> = () => {
    setTimeout(() => setMenuStatus(false), 200)
  }
  const popupStatus = panelStatus || menuStatus

  return (
    <DataGridPro
      showColumnVerticalBorder
      {...rest}
      onRowClick={popupStatus ? () => {} : onRowClick}
      onPreferencePanelOpen={onPreferencePanelOpen}
      onPreferencePanelClose={onPreferencePanelClose}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      apiRef={apiRef}
      columns={columns}
      slots={{ toolbar: Toolbar, ...slots }}
      initialState={
        initialState !== defaultGridState ? initialState : fallbackInitialState
      }
      slotProps={{
        ...slotProps,
        toolbar: {
          gridViewProps: {
            ...gridViewsProps,
            apiRef,
            gridName: name,
            columns,
            presetGridViews,
            defaultGridState,
          },
          ...slotProps?.toolbar,
        },
      }}
    />
  )
}
