import {
  fetchBaseQuery,
  createApi,
  FetchArgs,
  BaseQueryFn,
} from '@reduxjs/toolkit/query/react'

import { AUTH_REDUCER_KEY, RootStateWithAuth } from '@valerahealth/redux-auth'
import {
  AnswerOnFieldData,
  ClinicalNote,
  IncludeSectionData,
  ProgressNote,
  TemplateData,
  TemplateNote,
  CreateProgressNote,
  UpdateProgressNote,
  UpdatePsychNote,
  CreatePsychNote,
  PsychNote,
  ClinicalNoteType,
} from './noteApiService.types'
import { MedicalDiagnosis } from '../../shared/generated.types'

export * from './noteApiService.types'
export * from './constants'

export const noteApi = createApi({
  reducerPath: 'noteApi',
  keepUnusedDataFor: 120,
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${process.env.CARE_MANAGER_API_DOMAIN}/api/`,
    prepareHeaders: (headers, api) => {
      const accessToken = (api.getState() as RootStateWithAuth)[
        AUTH_REDUCER_KEY
      ].session?.idToken.jwt
      headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    },
    // https://stackoverflow.com/questions/70017789/react-redux-how-to-handle-errors-in-rtk-queries-mutation-typescript
  }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    { data: { message?: string } },
    {}
  >,
  tagTypes: [
    'Clinical-note',
    'Appointment',
    'PatientProfile',
    'Diagnosis',
    'Template',
  ],
  endpoints: (build) => ({
    getTemplates: build.query<TemplateNote[], { treatmentId: string }>({
      providesTags: (res) =>
        res?.map(({ id }) => ({ type: 'Template', id })) || [],
      query: ({ treatmentId }: { treatmentId: string }) => ({
        url: `treatment/${treatmentId}/clinical-note/template?type=progress`,
      }),
    }),
    getDiagnosis: build.query<MedicalDiagnosis[], void>({
      query: () => ({
        url: `code?type=ICD-10`,
      }),
    }),
    getNote: build.query<
      ClinicalNote,
      {
        treatmentId: string
        noteId: string
      }
    >({
      providesTags: (res, error, { noteId }) => {
        return [{ type: 'Clinical-note', noteId }]
      },
      query: ({ treatmentId, noteId }) => ({
        url: `treatment/${treatmentId}/clinical-note/${noteId}`,
      }),
    }),
    postPsychNote: build.mutation<
      PsychNote,
      { treatmentId: string; data: Omit<CreatePsychNote, 'type'> }
    >({
      query: ({ treatmentId, data }) => {
        return {
          url: `treatment/${treatmentId}/clinical-note`,
          body: {
            ...data,
            type: ClinicalNoteType.Psych,
          },
          method: 'POST',
        }
      },
    }),
    postProgressNote: build.mutation<
      ProgressNote,
      { treatmentId: string; data: Omit<CreateProgressNote, 'type'> }
    >({
      query: ({ treatmentId, data }) => {
        return {
          url: `treatment/${treatmentId}/clinical-note`,
          body: {
            ...data,
            type: ClinicalNoteType.Progress,
          },
          method: 'POST',
        }
      },
    }),
    putPsychNote: build.mutation<
      PsychNote,
      { treatmentId: string; noteId: string; data: UpdatePsychNote }
    >({
      invalidatesTags: (res, error, { noteId }) => [
        { type: 'Clinical-note', noteId },
      ],
      query: (params) => {
        return {
          url: `treatment/${params.treatmentId}/clinical-note/${params.noteId}`,
          body: params.data,
          method: 'PUT',
        }
      },
    }),
    putProgressNote: build.mutation<
      ProgressNote,
      { treatmentId: string; noteId: string; data: UpdateProgressNote }
    >({
      invalidatesTags: (res, error, { noteId }) => [
        { type: 'Clinical-note', noteId },
      ],
      query: (params) => {
        return {
          url: `treatment/${params.treatmentId}/clinical-note/${params.noteId}`,
          body: params.data,
          method: 'PUT',
        }
      },
    }),
    copyNote: build.mutation<
      ClinicalNote,
      { params: { treatmentId: string; noteId: string } }
    >({
      invalidatesTags: (
        res,
        error,
        {
          params: { noteId },
        }: { params: { treatmentId: string; noteId: string } },
      ) => [{ type: 'Clinical-note', noteId }],
      query: ({ params: { treatmentId, noteId } }) => {
        return {
          url: `treatment/${treatmentId}/clinical-note/${noteId}/copy`,
          method: 'POST',
        }
      },
    }),
    postTemplate: build.mutation<
      ClinicalNote,
      { treatmentId: string; noteId: string; data: TemplateData }
    >({
      invalidatesTags: (res, error, { noteId }) => [
        { type: 'Clinical-note', noteId },
      ],
      query: ({ treatmentId, noteId, data }) => {
        return {
          url: `treatment/${treatmentId}/clinical-note/${noteId}/edit-templates`,
          body: data,
          method: 'POST',
        }
      },
    }),
    includeSection: build.mutation<
      ProgressNote,
      { treatmentId: string; noteId: string; data: IncludeSectionData }
    >({
      invalidatesTags: (res, error, { noteId }) => [
        { type: 'Clinical-note', id: noteId },
      ],
      query: ({ treatmentId, noteId, data }) => {
        return {
          url: `treatment/${treatmentId}/clinical-note/${noteId}/include-sections`,
          body: data,
          method: 'POST',
        }
      },
    }),
    answerOnField: build.mutation<
      ProgressNote,
      { treatmentId: string; noteId: string; data: AnswerOnFieldData }
    >({
      // invalidating this as a tag causes too many note fetch calls
      query: ({ treatmentId, noteId, data }) => {
        return {
          url: `treatment/${treatmentId}/clinical-note/${noteId}/answer-on-field`,
          body: data,
          method: 'POST',
        }
      },
    }),
  }),
})

export type NoteApiType = typeof noteApi
