import { useState } from 'react'

import { ParsedTaskFragment } from '@valerahealth/rtk-query'
import {
  patientDisplayName,
  Mode,
  ReadWriteTaskForm,
  CenteredSpinner,
} from '@valerahealth/ui-components'
import { TreatmentRoomRouteContext } from 'components/utilities/treatments'

export default function Task({
  task,
  handleClose,
  routeContext: { patient, treatmentId },
  refetch = () => {},
}: {
  task: ParsedTaskFragment | null
  routeContext: Partial<TreatmentRoomRouteContext>
  handleClose?: () => void
  refetch?: Function
}) {
  const [mode, setMode] = useState<Mode>(task ? Mode.READ : Mode.WRITE)

  return !patient || !treatmentId ? (
    <CenteredSpinner />
  ) : (
    <ReadWriteTaskForm
      patient={{
        display: patientDisplayName(patient.demographics || {}),
        nextgenId: patient?.integration?.nextgenId,
        lumaId: patient?.integration?.lumaId,
        treatmentId,
        patientId: patient.demographics?.patientId || '',
      }}
      mode={mode}
      task={task}
      onEdit={() => setMode(Mode.WRITE)}
      onSaveSuccess={() => {
        if (handleClose) {
          handleClose()
        } else {
          setMode(Mode.READ)
        }
        refetch()
      }}
      onCancel={() => (handleClose ? handleClose() : setMode(Mode.READ))}
      onDeleteSuccess={() => refetch()}
    />
  )
}
