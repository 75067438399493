import { ReactNode } from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarProps as BaseGridToolbarProps,
} from '@mui/x-data-grid-pro'

import { Theme, Box } from '@mui/material'
import { useTranslation } from '../utils/hooks'

export type DisableFeatures = (
  | 'quickFilter'
  | 'filterButton'
  | 'columnsButton'
  | 'densitySelector'
  | 'exportButton'
)[]

export type GridToolbarProps = Omit<BaseGridToolbarProps, 'showQuickFilter'> & {
  disableFeatures?: DisableFeatures
  children?: ReactNode | ReactNode[]
}

export function GridToolbar({
  quickFilterProps,
  disableFeatures,
  children,
}: GridToolbarProps) {
  const [t] = useTranslation()
  return (
    <GridToolbarContainer
      sx={{
        gap: 1,
        backgroundColor: (theme) => theme.palette.action.hover,
        justifyItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      {!disableFeatures?.includes('quickFilter') && (
        <GridToolbarQuickFilter
          placeholder={t('search')}
          variant="outlined"
          size="small"
          sx={{
            order: 100,
            pb: 0,
            flex: '2 2 100%',
            maxWidth: '14rem',
            '& .MuiOutlinedInput-root': {
              pl: 1,
              pr: 0.5,
            },
          }}
          {...quickFilterProps}
        />
      )}
      {!disableFeatures?.includes('columnsButton') && (
        <GridToolbarColumnsButton
          disableElevation
          size="large"
          title={t('columns')}
          variant="text"
          sx={{
            minWidth: 'auto',
            color: (theme) => theme.palette.action.active,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            order: 200,
          }}
        />
      )}
      {!disableFeatures?.includes('filterButton') && (
        <GridToolbarFilterButton
          sx={{
            minWidth: 'auto',
            color: (theme) => theme.palette.action.active,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            order: 300,
          }}
          componentsProps={{
            button: {
              disableElevation: true,
              variant: 'text',
              size: 'large',
            },
          }}
        />
      )}

      {!disableFeatures?.includes('densitySelector') && (
        <GridToolbarDensitySelector
          disableElevation
          variant="text"
          size="large"
          sx={{
            minWidth: 'auto',
            color: (theme) => theme.palette.action.active,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            order: 400,
          }}
          title={t('density')}
        />
      )}
      <Box ml="auto" sx={{ order: 500 }} />

      {!disableFeatures?.includes('exportButton') && (
        <GridToolbarExport
          disableElevation
          sx={{
            minWidth: 'auto',
            color: (theme: Theme) => theme.palette.action.active,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
            order: 600,
          }}
          size="large"
          variant="text"
          title={t('export')}
        />
      )}
      {children}
    </GridToolbarContainer>
  )
}
