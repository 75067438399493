import CONFIG, { getHeader, handleResponse, handleError } from './apiUtils'

const { signal } = CONFIG

const baseUrl = `https://${process.env.CARE_MANAGER_API_DOMAIN}/api`

export const exportFitbit = () => {
  return fetch(`${baseUrl}/fitbit/data`, {
    method: 'GET',
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const exportScale = () => {
  return fetch(`${baseUrl}/scale/data`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getLanguages = () => {
  return fetch(`${baseUrl}/treatment/get-languages`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getStates = () => {
  return fetch(`${baseUrl}/state`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}
