import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import IconButton from '../IconButton'

function AddInputField({ text, onClick, disable }) {
  if (disable) {
    return (
      <div className="add-input-field-wrap disable-add">
        <IconButton type="plus" disable={disable} />
        <div>{text}</div>
      </div>
    )
  }
  return (
    <div className="add-input-field-wrap" onClick={onClick}>
      <IconButton type="plus" />
      <div>{text}</div>
    </div>
  )
}

AddInputField.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disable: PropTypes.bool,
}

export default AddInputField
