import {
  type ConsentForm,
  type MediaItem,
  type QuestionnaireSummary,
  TreatmentStatusEnum,
} from '@valerahealth/rtk-query'
import { GlobalItem, LegacyRootState } from 'redux/reducers/legacyRootState'
import IndividualIcon from '../../images/icn_individual plan.svg'
import IndividualPrivateIcon from '../../images/individual_private_plan.svg'
import PopulationIcon from '../../images/population_plan.svg'
import NoPopulationIcon from '../../images/noPopulationPlan.svg'
import NoTemplateIcon from '../../images/noTemplatePlan.svg'
import Message from '../../images/Message.svg'
import Reminder from '../../images/Reminder.svg'
import Sentiment from '../../images/Sentiment.svg'
import Questionnaire from '../../images/Questionnaire.svg'
import Article from '../../images/Article.svg'
import AudioFile from '../../images/Audio_File.svg'
import ConsentFormIcon from '../../images/consent_form.svg'
import Appointment from '../../images/Appointment.svg'
import Medication from '../../images/refill_request_icon_main.svg'
import { TempalteEnumValue, planType } from './enums'
import dStrings from '../../strings.json'

export const plansDetails = {
  population: {
    id: planType.POPULATION,
    title: dStrings.populationPlans,
    newTitle: dStrings.newPopulationPlan,
    description: dStrings.populationPlanDescription,
    icon: <PopulationIcon />,
    noPlanIcon: <NoPopulationIcon />,
    color: '#3ac696',
    createNewText: dStrings.createNewPopulationPlan,
  },
  individual_public: {
    id: planType.INDIVIDUAL_PUBLIC,
    title: dStrings.templatePlans,
    newTitle: dStrings.newTemplatePlan,
    description: dStrings.templatePlanDescription,
    icon: <IndividualIcon />,
    noPlanIcon: <NoTemplateIcon />,
    color: '#2E8EC0',
    createNewText: dStrings.createNewTemplatePlan,
  },
  individual_private: {
    id: planType.INDIVIDUAL_PRIVATE,
    title: dStrings.personnalAutomation,
    description: dStrings.personnalPlanDescription,
    icon: <IndividualPrivateIcon />,
    color: '#F2D349',
  },
}

export const getPlanTemplateIcon = (type: keyof typeof plansDetails) => {
  return plansDetails[type].icon
}

export const getAllPlanTemplatesDetails = () => {
  return [plansDetails.population, plansDetails.individual_public]
}

export const getPlanTemplateDetails = (type: keyof typeof plansDetails) => {
  return plansDetails[type]
}

export const getPlans = (state: any) => {
  return state.global.careplanState
    ? state.planTemplates
    : state.treatmentRoom.selectedId &&
        state.treatmentRoom.byId[state.treatmentRoom.selectedId].plansDetails
}

export const getReadOnly = (state: LegacyRootState) => {
  return !(
    state.global.careplanState ||
    (state.treatmentRoom.selectedId &&
      state.treatmentRoom.byId[state.treatmentRoom.selectedId]!.status !==
        TreatmentStatusEnum.DISCHARGED)
  )
}

export const getPlansKey = (state: LegacyRootState) => {
  if (state.global.careplanState) return `planTemplates`

  if (state.treatmentRoom.selectedId)
    return `treatmentRoom.byId.${state.treatmentRoom.selectedId}.plansDetails`
  return undefined
}

export const getTracksKey = (state: LegacyRootState, trackId: string) => {
  const plans = getPlans(state)
  const planId = plans.selectedId

  return `${getPlansKey(state)}.byId.${planId}.tracks${
    trackId ? `.byId.${trackId}` : ''
  }`
}

const toolTemplateIcon = {
  questionnaire: Questionnaire,
  reminder: Reminder,
  article: Article,
  audio_track: AudioFile,
  episode: AudioFile,
  message: Message,
  sentiment: Sentiment,
  consent_form: ConsentFormIcon,
  appointment: Appointment,
  medication: Medication,
}

export const getToolTemplateIcon = (type: keyof typeof toolTemplateIcon) => {
  const Icon = toolTemplateIcon[type]

  if (Icon) return <Icon />
  return <span />
}

export type NestedTemplateItem<T extends TempalteEnumValue> =
  | NonNullable<LegacyRootState['toolsTemplates'][T]['list']>['byId'][string]
  | undefined

export function getTemplate(
  toolTemplates: LegacyRootState['toolsTemplates'],
  item?: GlobalItem,
) {
  if (
    item &&
    [
      'questionnaire',
      'sentiment',
      'article',
      'audio_track',
      'consent_form',
    ].includes(item.type) &&
    item?.info.templateId
  ) {
    const result: NestedTemplateItem<typeof item.type> =
      toolTemplates[item.type].list?.byId[item.info.templateId]
    return result
  }
  return null
}

export const getTemplateTitle = (
  toolTemplates: LegacyRootState['toolsTemplates'],
  item?: GlobalItem,
) => {
  if (!toolTemplates || !item) return ''

  const template = getTemplate(toolTemplates, item)

  if (['questionnaire', 'sentiment'].indexOf(item.type) !== -1)
    return (template as QuestionnaireSummary).name

  if (['article', 'audio_track', 'consent_form'].indexOf(item.type) !== -1)
    return (template as MediaItem | ConsentForm).title

  if (item.type === 'message') return dStrings.message
}
