import { useState } from 'react'
// @ts-ignore
import { Popup, WarningInfo, RangeDapicker } from '@valerahealth/ui-core'
import dStrings from '../../../strings.json'
import './style.css'

interface ExportPopupProps {
  title: string
  warning: string
  onClosed: () => void
  startDate?: Date
  endDate?: Date
  handleSave: (start: Date, end: Date) => void
}

function ExportPopup({
  title,
  warning,
  onClosed,
  startDate,
  endDate,
  handleSave,
}: ExportPopupProps) {
  const today = new Date()
  const [start, setStart] = useState(
    startDate ||
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14),
  )
  const [end, setEnd] = useState(endDate || today)
  const [selected, setSelected] = useState('dates')

  return (
    <Popup
      title={title}
      onClosed={onClosed}
      className="export-popup-container"
      buttons={[
        {
          text: dStrings.cancel,
          onClick: onClosed,
          type: 'link',
        },
        {
          text: dStrings.export,
          onClick: () => {
            if (selected === 'dates') handleSave(start, end)
            else {
              handleSave(
                new Date(
                  today.getFullYear() - 10,
                  today.getMonth(),
                  today.getDate(),
                ),
                today,
              )
            }
          },
          type: 'primary',
        },
      ]}
      // added to pass ts check
      headerClass={undefined}
      hideHeader={undefined}
      parentSelector={undefined}
      overlayClassName={undefined}
      avoidCloseOnEsc={undefined}
      headerIcon={undefined}
    >
      <div id="export-popup">
        {warning && <WarningInfo message={warning} red />}
        <div className="export-radio-container">
          <input
            type="radio"
            name="export"
            id="dates"
            checked={selected === 'dates'}
            onChange={() => {
              setSelected('dates')
            }}
          />
          <label
            htmlFor="dates"
            className="export-label"
            style={{ marginLeft: '7px' }}
          >
            <RangeDapicker
              start={start}
              end={end}
              label={dStrings.exportDates}
              handleSave={(_start: Date, _end: Date) => {
                setStart(_start)
                setEnd(_end)
              }}
            />
          </label>
        </div>
        <div className="export-radio-container">
          <input
            type="radio"
            name="export"
            id="all"
            checked={selected === 'all'}
            onChange={() => {
              setSelected('all')
            }}
          />
          <label htmlFor="all" className="export-label">
            {dStrings.exportAll}
          </label>
        </div>
      </div>
    </Popup>
  )
}

export default ExportPopup
