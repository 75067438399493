import { configApi } from '@valerahealth/rtk-query'

export function useFeatureFlagNoStartSession() {
  const { hideStartSessionButton, error } = configApi.useGetProgramSettingsQuery(
    undefined,
    {
      selectFromResult: ({ data, error }) => ({
        hideStartSessionButton: data?.getProgramSettings.layout?.hideStartSessionButton,
        error,
      }),
    },
  )
  return !!hideStartSessionButton
}
