import { SyntheticEvent, useMemo, useState } from 'react'
import { Controller, type ControllerRenderProps } from 'react-hook-form'
import {
  AutocompleteChangeReason,
  SelectVariants,
  type TextFieldProps,
} from '@mui/material'
import { type BaseFormControlProps } from './index.types'
import { splitProps, getErrorMessage, useReadOnlyForm } from '../utils'
import { useTranslation } from '../../utils/hooks'
import {
  type SingleSelectProps,
  SingleSelect as BaseSelect,
  type ChipMultiSelectProps,
  ChipMultiSelect as ChipMultiSelectBase,
  Combobox as BaseCombobox,
  type ComboboxProps as BaseComboboxProps,
} from '../../base/Select'

export function Select<OptionType, Clearable extends boolean = false>({
  name,
  helperText,
  required,
  disabled,
  formControlProps,
  label,
  hideLabel,
  errorFieldName,
  readOnly,
  variant,
  ...rest
}: BaseFormControlProps &
  Omit<
    SingleSelectProps<OptionType, Clearable>,
    keyof ControllerRenderProps | 'required' | 'children' | 'variant'
  > & {
    variant?: SelectVariants
    children:
      | JSX.Element
      | JSX.Element[]
      /** For non primitive values you will need to control. MUI Select only works uncontrolled with primitives */
      | ((
          value: OptionType,
          onChange: (value: OptionType) => void,
        ) => JSX.Element | JSX.Element[])
  }) {
  const {
    registerProps,
    fieldProps: { children, ...fieldProps },
  } = useMemo(() => splitProps(rest), [rest])
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const isReadOnlyForm = useReadOnlyForm().readOnly
  const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

  return (
    <Controller
      name={name}
      rules={{ ...registerProps, required }}
      render={({
        field: { ref, value, onChange, ...controlFieldProps },
        fieldState: { error },
      }) => {
        const handleChange = (...event: Parameters<typeof onChange>) => {
          // if this is a single select, close the menu after changing a value
          if (open && !fieldProps.multiple) {
            setOpen(false)
          }
          onChange(...event)
        }

        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText

        return (
          <BaseSelect
            {...controlFieldProps}
            {...fieldProps}
            inputLabelProps={{
              required: !!required,
              ...fieldProps.inputLabelProps,
            }}
            formControlProps={{
              ...formControlProps,
              disabled,
              error: !!error,
            }}
            innerRef={ref}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            helperText={errorOrHelper}
            value={
              value === undefined ? (fieldProps.multiple ? [] : '') : value
            }
            onChange={handleChange}
            label={!hideLabel && label}
            readOnly={isReadOnly}
            variant={isReadOnly ? 'standard' : variant || 'outlined'}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                sx: {
                  maxHeight: '250px',
                },
              },
            }}
          >
            {typeof children === 'function'
              ? children(value, handleChange)
              : children}
          </BaseSelect>
        )
      }}
    />
  )
}

export function ChipMultiSelect<OptionType>({
  name,
  required,
  helperText,
  formControlProps,
  disabled,
  label,
  hideLabel = false,
  errorFieldName,
  options,
  parseValue,
  setValueAs,
  readOnly,
  ...rest
}: Omit<BaseFormControlProps, 'setValueAs'> &
  Omit<ChipMultiSelectProps<OptionType>, keyof ControllerRenderProps> & {
    /** converts values saved in the form to OptionTypes when they differ */
    parseValue?: (
      value: any[] | null | undefined,
      options: readonly OptionType[],
    ) => readonly OptionType[] | null
    setValueAs?: (value?: OptionType[] | null) => any
  }) {
  const { registerProps, fieldProps } = useMemo(() => splitProps(rest), [rest])
  const { t } = useTranslation()

  const isReadOnlyForm = useReadOnlyForm().readOnly
  const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

  return (
    <Controller
      name={name}
      rules={{ ...registerProps, required }}
      render={({
        field: { ref, onChange: _onChange, value, ...controlFieldProps },
        fieldState: { error },
      }) => {
        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText
        return (
          <ChipMultiSelectBase
            inputLabelProps={{
              required: !!required,
              ...fieldProps.inputLabelProps,
            }}
            formControlProps={{
              disabled,
              error: !!error,
              ...formControlProps,
            }}
            value={parseValue ? parseValue(value, options) : value}
            onChange={(values) => {
              return _onChange(setValueAs ? setValueAs(values) : values)
            }}
            options={options}
            {...fieldProps}
            {...controlFieldProps}
            label={!hideLabel && label}
            innerRef={ref}
            helperText={errorOrHelper}
            readOnly={isReadOnly}
            variant={isReadOnly ? 'standard' : 'outlined'}
          />
        )
      }}
    />
  )
}

export type ComboboxProps<
  OptionType,
  Multiple extends boolean | undefined = undefined,
> = Omit<BaseFormControlProps, 'setValueAs' | 'onChange'> &
  Omit<
    BaseComboboxProps<OptionType>,
    | keyof ControllerRenderProps
    | 'textFieldProps'
    | 'multiple'
    | 'onChange'
    | 'freeSolo'
  > & {
    multiple?: Multiple
    textFieldProps?: TextFieldProps
    /* takes a value and converts it to option type */
    parseValue?: (
      value: Multiple extends true ? any[] : any,
      options: readonly OptionType[],
    ) => Multiple extends true
      ? readonly OptionType[] | undefined
      : OptionType | null | undefined
    setValueAs?: (
      selectedOption:
        | (Multiple extends true ? OptionType[] : OptionType)
        | null,
    ) => Multiple extends true ? any[] : any
    /* return void if you merely want to listen in on change events to create a side effect else return a value to override what would have been set as the value */
    onChange?: (
      event: SyntheticEvent<Element, Event>,
      v: (Multiple extends true ? OptionType[] : OptionType) | null,
      reason: AutocompleteChangeReason,
    ) => void | (Multiple extends true ? OptionType[] : OptionType) | null
  }

export function Combobox<
  OptionType,
  Multiple extends boolean | undefined = undefined,
>({
  name,
  required,
  helperText,
  label,
  hideLabel,
  textFieldProps,
  parseValue,
  options,
  setValueAs,
  onChange,
  errorFieldName,
  readOnly,
  ...rest
}: ComboboxProps<OptionType, Multiple>) {
  const { registerProps, fieldProps } = useMemo(() => splitProps(rest), [rest])
  const { t } = useTranslation()

  const isReadOnlyForm = useReadOnlyForm().readOnly
  const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

  return (
    <Controller
      name={name}
      rules={{ ...registerProps, required }}
      render={({
        field: { value: rawValue, ref, onChange: _onChange, ...field },
        fieldState: { error },
      }) => {
        const value = fieldProps.multiple ? rawValue || [] : rawValue
        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText
        return (
          <BaseCombobox
            {...fieldProps}
            {...field}
            options={options}
            onChange={(e, v, r) => {
              const result = onChange?.(e, v as any, r)
              return _onChange(
                setValueAs
                  ? setValueAs(
                      result === undefined
                        ? (v as Multiple extends true
                            ? OptionType[]
                            : OptionType)
                        : result,
                    )
                  : v,
              )
            }}
            innerRef={ref}
            value={parseValue ? parseValue(value, options) : value}
            label={hideLabel ? undefined : label}
            readOnly={isReadOnly}
            textFieldProps={{
              helperText: errorOrHelper,
              error: !!error,
              InputLabelProps: {
                required: !!required,
                ...textFieldProps?.InputLabelProps,
              },
              variant: isReadOnly ? 'standard' : undefined,
              ...textFieldProps,
            }}
          />
        )
      }}
    />
  )
}
