import { useMemo, type ReactElement } from 'react'
import { Typography, Box } from '@mui/material'
import {
  Permission,
  useReduxSelectorWithAuthState,
  hasAnyPermission,
  hasAllPermissions,
  selectIsAuthorizedOwn,
} from '@valerahealth/redux-auth'
import { useTranslation } from '../../utils/hooks'

export type ProtectedProps = {
  children: ReactElement
  /* allows you to specify more than one permission required for accessing a route */

  permissionRequired?: Permission | Permission[]
  /* when permissionRequired is an array, whether the user needs all permissions specified or just one, defaults to all */
  multipleLogic?: 'all' | 'any'
  /** depricated, used for legacy security checks */
  isAuthorized?: boolean | null | undefined
  /** depricated, used for legacy security checks */
  unauthorizedMessage?: string
} & (
  | {
      id: string
      idType: 'providerId' | 'careManagerId'
      /** convenient own permission check */
      ownPermissionRequired: Permission | Permission[]
    }
  | {
      id?: never
      idType?: never
      ownPermissionRequired?: never
    }
)

const defaultPermissionRequired: Permission[] = []

function Protected({
  children,
  permissionRequired = defaultPermissionRequired,
  multipleLogic = 'all',
  isAuthorized,
  unauthorizedMessage,
  id,
  idType,
  ownPermissionRequired,
}: ProtectedProps) {
  const [t] = useTranslation()

  const perms = useMemo(() => {
    return Array.isArray(permissionRequired)
      ? permissionRequired
      : [permissionRequired]
  }, [permissionRequired])

  const ownPermitted = useReduxSelectorWithAuthState((state) => {
    if (!id) return false
    return selectIsAuthorizedOwn(
      state,
      ownPermissionRequired,
      id,
      idType,
      multipleLogic,
    )
  })

  const permitted = useReduxSelectorWithAuthState((state) => {
    return multipleLogic === 'all'
      ? hasAllPermissions(state, perms)
      : hasAnyPermission(state, perms)
  })

  if (isAuthorized === false || !(ownPermitted || permitted)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexGrow="1"
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            {t('Protected.title')}
          </Typography>
          <Typography gutterBottom>{t('Protected.message')}</Typography>
          <Typography gutterBottom>
            {perms.length
              ? t('Protected.description', { Permission: perms.join(', ') })
              : unauthorizedMessage}
          </Typography>
        </Box>
      </Box>
    )
  }

  return children
}

export default Protected
