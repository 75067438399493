import { useMemo, useState } from 'react'
import {
  Tab,
  Tabs,
  ListItemText,
  MenuItem,
  ListItemIcon,
  Box,
  IconButton,
  AppList,
  UserAccount,
  Navbar,
  NavbarLogo,
  Link,
  PopupMenu,
  Stack,
  FormControlLabel,
  Collapse,
  PatientTypeAhead,
  TaskNotification,
} from '@valerahealth/ui-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { FormProvider, useForm } from '@valerahealth/ui-components/form'
import { shallowEqual } from 'react-redux'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  KeyboardArrowDown as ArrowDropDownIcon,
  Check,
  Settings,
  Search,
} from '@valerahealth/ui-components/icons'
import {
  selectCanUpdateOwnProviderProfile,
  selectUser,
} from '@valerahealth/redux-auth'
import { useReduxSelector, useReduxDispatch } from 'redux/store'
import {
  isWellnessSelector,
  mainNavigationLayoutSelector,
} from 'redux/selectors'
import { useCanViewCalendar } from 'components/Calendar'
import { caseloadStateEnum, planType, routesEnum } from '../utilities/enums'
import { toggleCaseloadState } from '../../redux/actions/caseloadActions'
import Notifications from './Notifications'

const Menu = () => {
  const location = useLocation()
  const dispatch = useReduxDispatch()
  const { t } = useTranslation()
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const canUpdateOwnProviderProfile = useReduxSelector(
    selectCanUpdateOwnProviderProfile,
  )
  const methods = useForm({
    defaultValues: { patientSearch: null },
  })

  const canViewCalendar = useCanViewCalendar()

  const cognitoUser = useReduxSelector(selectUser)

  const isWellness = useReduxSelector(isWellnessSelector)
  const { user } = useReduxSelector((state) => state.global)
  const subMenu = useReduxSelector(
    (state) => state.planTemplates.subMenu || planType.POPULATION,
  )

  const [caseloadState] = useReduxSelector(
    (state) => {
      const { caseload } = state

      return [caseload.caseloadState] as const
    },
    { equalityFn: shallowEqual },
  )

  const navigate = useNavigate()

  const tabValue = useMemo(() => {
    return Object.values(routesEnum).find((baseRoute) =>
      location.pathname.startsWith(baseRoute),
    )
  }, [location])

  const {
    calendarHidden,
    caseloadHidden,
    caseloadLeadsHidden,
    patientsHidden,
    tasksHidden,
    tracksHidden,
    supervisionHidden,
    myPracticeHidden,
  } = useReduxSelector(mainNavigationLayoutSelector)

  return (
    <Navbar
      position="static"
      sx={{
        color: (theme) => theme.palette.secondary.contrastText,
        backgroundColor: (theme) => theme.palette.secondary.dark,
        height: '62px',
        // zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <NavbarLogo
        appName={t('appName.careManager')}
        imgUrl="https://cdn.valerahealth.com/images/logo/care-manager-logo.svg"
      />
      <Tabs
        value={tabValue}
        sx={(theme) => ({
          ml: '70px',
          mr: 'auto',
          '.MuiTab-root': {
            opacity: 1,
          },
          '.Mui-selected': {
            fontWeight: '900',
          },
          '.MuiTabs-indicator': {
            backgroundColor: theme.palette.info.main,
            height: '3px',
            cursor: 'pointer',
            bottom: '3px',
          },
        })}
        textColor="inherit"
        role="navigation"
        id="navigation"
      >
        {process.env.PROJECT_PROVIDER_CALENDAR &&
          canViewCalendar &&
          !calendarHidden && (
            <Tab
              component={Link}
              to={routesEnum.CALENDAR}
              value={routesEnum.CALENDAR}
              label={t('myCalendar')}
            />
          )}

        {!caseloadHidden && (
          <Tab
            component={Link}
            to={routesEnum.CASELOAD}
            value={routesEnum.CASELOAD}
            onClick={(e) => {
              if (
                // dont run if a child popup menu item was clicked
                !document
                  .getElementById('caseload-popup-menu')
                  ?.contains(e.target as Node) &&
                caseloadState !== caseloadStateEnum.ACTIVE
              )
                dispatch(toggleCaseloadState(caseloadStateEnum.ACTIVE))
            }}
            label={
              <Stack
                flexDirection="row"
                flexWrap="nowrap"
                alignItems="center"
                gap={0.5}
              >
                {t('caseload')}
                <PopupMenu
                  id="caseload-popup-menu"
                  onMenuOpened={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                  }}
                  onMenuClosed={(e) => {
                    // user closed the menu without clicking on a menu option, want to prevent navigation
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  button={<ArrowDropDownIcon />}
                >
                  <MenuItem
                    onClick={() => {
                      if (caseloadState !== caseloadStateEnum.ACTIVE)
                        dispatch(toggleCaseloadState(caseloadStateEnum.ACTIVE))
                    }}
                  >
                    <ListItemText>
                      <ListItemIcon>
                        {caseloadState === caseloadStateEnum.ACTIVE && (
                          <Check fontSize="small" />
                        )}
                      </ListItemIcon>
                      {t(caseloadStateEnum.ACTIVE)}
                    </ListItemText>
                  </MenuItem>

                  {!caseloadLeadsHidden && (
                    <MenuItem
                      onClick={() => {
                        if (caseloadState !== caseloadStateEnum.LEAD)
                          dispatch(toggleCaseloadState(caseloadStateEnum.LEAD))
                      }}
                    >
                      <ListItemText>
                        <ListItemIcon>
                          {caseloadState === caseloadStateEnum.LEAD && (
                            <Check fontSize="small" />
                          )}
                        </ListItemIcon>
                        {t(caseloadStateEnum.LEAD)}
                      </ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={() => {
                      if (caseloadState !== caseloadStateEnum.DISCHARGE)
                        dispatch(
                          toggleCaseloadState(caseloadStateEnum.DISCHARGE),
                        )
                    }}
                  >
                    <ListItemText>
                      <ListItemIcon>
                        {caseloadState === caseloadStateEnum.DISCHARGE && (
                          <Check fontSize="small" />
                        )}
                      </ListItemIcon>
                      {t(caseloadStateEnum.DISCHARGE)}
                    </ListItemText>
                  </MenuItem>
                </PopupMenu>
              </Stack>
            }
          />
        )}

        {!patientsHidden && (
          <Tab
            component={Link}
            label={t('patients')}
            to={routesEnum.PATIENT}
            value={routesEnum.PATIENT}
          />
        )}

        {!tasksHidden && (
          <Tab
            component={Link}
            label={
              <Box display="flex">
                {t('tasks')}
                {user?.profile.id && (
                  <TaskNotification userId={user?.profile.id} />
                )}
              </Box>
            }
            to={routesEnum.TASKS}
            value={routesEnum.TASKS}
          />
        )}

        {!tracksHidden && (
          <Tab
            component={Link}
            label={t('tracks')}
            to={`${routesEnum.TRACKS}/${subMenu}`}
            value={routesEnum.TRACKS}
          />
        )}

        {!supervisionHidden && user?.role === 'Supervisor' && (
          <Tab
            component={Link}
            label={t('supervision')}
            to={routesEnum.SUPERVISION}
            value={routesEnum.SUPERVISION}
          />
        )}

        {!myPracticeHidden && canUpdateOwnProviderProfile && !!cognitoUser && (
          <Tab
            component={Link}
            to={`https://${process.env.PRACTICE_MANAGER_UI_DOMAIN}/providers/${
              cognitoUser.careManagerId
            }-${encodeURIComponent(
              `${cognitoUser.firstName} ${cognitoUser.lastName}`,
            )}`}
            target="_blank"
            label={t('myPractice')}
          />
        )}
      </Tabs>

      <FormProvider {...methods}>
        <Box
          sx={{
            ml: 'auto',
          }}
          display="flex"
          flexDirection="row"
        >
          <FormControlLabel
            checked={showSearch}
            onClick={() => {
              setShowSearch((v) => !v)
            }}
            control={
              <IconButton
                sx={{
                  color: (theme) => theme.palette.background.default,
                }}
              >
                <Search />
              </IconButton>
            }
            label=""
          />
          <Collapse orientation="horizontal" in={showSearch}>
            <Box display="flex" flexDirection="row">
              <PatientTypeAhead
                size="small"
                label={t('Search Patients')}
                name="patientSearch"
                getLink={(patient) =>
                  `/caseload/treatment/${patient.treatmentId}`
                }
                textFieldProps={{
                  focused: false,
                  sx: {
                    width: '300px',
                    mr: '4px',
                    ml: '-2px',
                    backgroundColor: (theme) => theme.palette.background.paper,
                    '& legend': { display: 'none' },
                    '& .MuiInputLabel-shrink': {
                      opacity: 0,
                      transition: 'all 0.2s ease-in',
                    },
                  },
                }}
              />
            </Box>
          </Collapse>
        </Box>
      </FormProvider>

      <UserAccount
        canEditLegalName={!isWellness}
        canViewSupportLinks={isWellness}
      >
        <MenuItem
          onClick={() => {
            navigate('/profile')
          }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>{t('settings')}</ListItemText>
        </MenuItem>
      </UserAccount>

      <Notifications />

      <AppList />
    </Navbar>
  )
}

export default Menu
