import { ReactNode, useState } from 'react'
import { useTranslation } from '../../../utils/hooks'
import {
  Drawer,
  Stack,
  Typography,
  IconButton,
  Box,
  SxProps,
  Button,
} from '../../../base'
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '../../../icons'
import { Theme } from '../../../theme'

export type WorkflowSidebarProps = {
  title: ReactNode
  collapseTitle?: ReactNode
  onClose: () => void
  children: ReactNode | ReactNode[]
  width?: string | number
  hideBackdrop?: boolean
  disableEnforceFocus?: boolean
  open?: boolean
  sx?: SxProps<Theme>
}

export default function WorkflowSidebar({
  title,
  collapseTitle = title,
  onClose,
  children,
  width = '30rem',
  open = true,
  hideBackdrop = true,
  disableEnforceFocus = true,
  sx,
}: WorkflowSidebarProps) {
  const { t } = useTranslation()
  const [expandSidebar, setExpandSidebar] = useState(true)

  return (
    <Drawer
      open={open}
      sx={{
        width: expandSidebar ? width : '0px',
        left: 'auto',
        ...sx,
      }}
      disableEnforceFocus={disableEnforceFocus}
      anchor="right"
      hideBackdrop={hideBackdrop}
      PaperProps={{
        sx: {
          width: expandSidebar ? width : '0px',
          overflow: 'visible',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100vh',
        },
      }}
    >
      <Button
        size="small"
        color="secondary"
        sx={{
          boxShadow: 'none',
          position: 'absolute',
          left: 0,
          top: 0,
          transform: 'translate(-100%,-100%) rotate(-90deg)',
          transformOrigin: 'bottom right',
          borderRadius: '5px 5px 0px 0px',
          whiteSpace: 'nowrap',
          lineHeight: 1.5,
          fontSize: '.675rem',
          textTransform: 'none',
        }}
        onClick={() => {
          setExpandSidebar(!expandSidebar)
        }}
        endIcon={expandSidebar ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      >
        {collapseTitle}
      </Button>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Typography variant="h5">{title}</Typography>
        <IconButton title={t('close')} onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Stack sx={{ flex: '1', overflowY: 'auto' }}>{children}</Stack>
    </Drawer>
  )
}
