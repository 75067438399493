import dotProp from 'dot-prop-immutable'
import * as types from '../actions/actionTypes'
import initialState from './initialState'
import { getListByIdAndAllIds } from '../../components/utilities'

export default function toolsTemplatesReducer(
  state = initialState.toolsTemplates,
  action,
) {
  switch (action.type) {
    case types.LOAD_QUESTIONNAIRES_TEMPLATES_SUCCESS: {
      const questionnaires = []
      const sentiments = []

      for (let i = 0; i < action.questionnaires.length; i++) {
        if (action.questionnaires[i].type === 'Questionnaire')
          questionnaires.push(action.questionnaires[i])
        else if (action.questionnaires[i].type === 'Sentiment')
          sentiments.push(action.questionnaires[i])
      }

      const tempState1 = dotProp.set(
        state,
        'questionnaire.list',
        getListByIdAndAllIds(questionnaires),
      )

      return dotProp.set(
        tempState1,
        'sentiment.list',
        getListByIdAndAllIds(sentiments),
      )
    }
    case types.LOAD_CONSENT_FORM_TEMPLATES_SUCCESS:
      return dotProp.set(
        state,
        `consent_form.list`,
        getListByIdAndAllIds(action.consentForms),
      )
    case types.LOAD_QUESTIONNAIRE_SUCCESS:
      if (action.item.appData.type === 'Questionnaire')
        return dotProp.set(state, `questionnaire.list.byId.${action.item.id}`, {
          ...action.item,
          type: 'Questionnaire',
        })

      return dotProp.set(state, `sentiment.list.byId.${action.item.id}`, {
        ...action.item,
        type: 'Sentiment',
      })

    case types.LOAD_CONSENT_FORM_SUCCESS:
      return dotProp.set(state, `consent_form.list.byId.${action.item.id}`, {
        ...action.item,
      })
    case types.LOAD_MEDIA_ITEMS_TEMPLATES_SUCCESS: {
      const articles = []
      const audioTrack = []

      for (let i = 0; i < action.medias.length; i++) {
        if (action.medias[i].category === 'article')
          articles.push(action.medias[i])
        else if (action.medias[i].category === 'audio_track')
          audioTrack.push(action.medias[i])
      }

      const tempState1 = dotProp.set(
        state,
        `article.list`,
        getListByIdAndAllIds(articles),
      )

      return dotProp.set(
        tempState1,
        `audio_track.list`,
        getListByIdAndAllIds(audioTrack),
      )
    }
    default:
      return state
  }
}
