import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
} from '@valerahealth/ui-components'
import {
  Switch,
  ChipMultiSelect,
  DatePicker,
  Select,
  TextField,
  YesNoButtons,
  useFormContext,
} from '@valerahealth/ui-components/form'
import { ExpandMore } from '@valerahealth/ui-components/icons'
import {
  Field,
  type TemplateSection as TemplateSectionType,
} from '@valerahealth/rtk-query'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from '../locales'

interface FieldSectionProps {
  field: Field | null | undefined
}

const NoteField = ({ field }: FieldSectionProps) => {
  if (!field || !field.type) return null

  switch (field.type) {
    case 'free_text': {
      return (
        <TextField
          placeholder={field.name}
          label={field.name}
          required={field.required}
          name={field.id}
          multiline
          variant="standard"
          sx={{ minWidth: '12rem', flex: 1 }}
        />
      )
    }
    case 'single_select': {
      return (
        <Select
          variant="standard"
          label={field.name}
          name={field.id}
          required={field.required}
          formControlProps={{ sx: { minWidth: '12rem', flex: 1 } }}
        >
          {field.options.map((a) => {
            const stringValue = String(a)
            return <MenuItem value={stringValue}>{stringValue}</MenuItem>
          })}
        </Select>
      )
    }
    case 'multi_select': {
      return (
        <ChipMultiSelect
          variant="standard"
          label={field.name}
          // @ts-expect-error options doesn't handle unions and gets confused. In reality chip multi select will work with an array of numbers
          options={field.options}
          name={field.id}
          required={field.required}
          formControlProps={{ sx: { minWidth: '12rem', flex: 1 } }}
        />
      )
    }
    case 'check_box': {
      return (
        <Switch
          required={field.required}
          name={field.id}
          variant="standard"
          label={field.name}
          sx={{ minWidth: '12rem', flex: 1 }}
        />
      )
    }
    case 'yes_no': {
      return (
        <YesNoButtons
          flexDirection="row"
          name={field.id}
          label={field.name}
          sx={{ minWidth: '20rem', flex: 1 }}
        />
      )
    }
    case 'date_picker': {
      return (
        <DatePicker
          name={field.id}
          label={field.name}
          required={field.required}
          textFieldProps={{
            sx: { minWidth: '12rem', flex: 1 },
          }}
          format="MMM d, yyyy"
          openOnClick
        />
      )
    }
    default:
      return null
  }
}

interface FieldGroupSectionProps {
  group: Field[]
}

function NoteFieldGroup({ group }: FieldGroupSectionProps) {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="end"
      flexWrap="wrap"
      justifyContent="stretch"
    >
      {group.map((field) => {
        return <NoteField key={field.id} field={field} />
      })}
    </Stack>
  )
}

function NoteSubSection({ section }: NoteSectionProps) {
  const { t } = useTranslation()

  const { watch } = useFormContext()
  const displaySection = watch(`section_${section.id}`)

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          backgroundColor: 'rgba(127, 171, 255, 0.06)',
          padding: '7px',
        }}
      >
        {!section.required && <Switch name={`section_${section.id}`} />}
        {section.title}
        {!section.required && (
          <Typography color="text.secondary">{t('optional')}</Typography>
        )}
      </Stack>
      {displaySection &&
        section.items.map((sectionItem, key) => {
          if (sectionItem.itemType === 'field') {
            return <NoteField key={sectionItem.id} field={sectionItem} />
          }
          if (sectionItem.itemType === 'field_group') {
            return (
              // field_group does not have an id so we have to rely on index order
              // eslint-disable-next-line
              <NoteFieldGroup key={key} group={sectionItem.fields} />
            )
          }
          return null
        })}
    </>
  )
}

interface NoteSectionProps {
  section: TemplateSectionType
}

export default function NoteSection({ section }: NoteSectionProps) {
  const { t } = useTranslation()
  return (
    <Accordion
      disableGutters
      sx={{
        borderRadius: '0.25rem',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          background: 'rgba(127, 171, 255, 0.06)',
        }}
        expandIcon={<ExpandMore />}
      >
        {section.title}
        {!section.required && (
          <Typography sx={{ marginLeft: '12px', opacity: 0.6 }}>
            {t('optional')}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
        }}
      >
        {section.items.map((sectionItem, key) => {
          if (sectionItem.itemType === 'field') {
            return <NoteField key={sectionItem.id} field={sectionItem} />
          }
          if (sectionItem.itemType === 'section') {
            return <NoteSubSection key={sectionItem.id} section={sectionItem} />
          }
          return (
            // field_group does not have an id so we have to rely on index order
            // eslint-disable-next-line
            <NoteFieldGroup key={key} group={sectionItem.fields} />
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}
