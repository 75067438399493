// default component
import React from 'react'
import PropTypes from 'prop-types'
import dStrings from '../../../strings.json'

function FilterPart({ header, onClear, children }) {
  return (
    <div className="filter-part">
      <div className="filter-part-header">
        <div className="filter-part-title">{header}</div>
        <div className="filter-part-clear link-click" onClick={onClear}>
          {dStrings.clear}
        </div>
      </div>
      <div className="filter-part-body">{children}</div>
    </div>
  )
}

FilterPart.propTypes = {
  header: PropTypes.string,
  onClear: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default FilterPart
