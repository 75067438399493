import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  type RootStateWithAuth,
  AUTH_REDUCER_KEY,
  Permission,
} from '@valerahealth/redux-auth'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import {
  createService,
  initApplyPermissionheaders,
  type EndpointPermissions,
} from '../../utils'
import { api as endpoints } from './generated'

const endpointPermissions: EndpointPermissions = {
  revokeUser: Permission.User_Update,
  removeUser: Permission.User_Delete,
  restoreUser: Permission.User_Update,
  getAllUsers: Permission.User_Read,
  getUser: Permission.User_Read,
  getUserByCareManagerId: Permission.User_Read,
  userSignUpWithGoogle: Permission.User_CreateWithgoogle,
  userSignUp: Permission.User_Create,
  updateUser: Permission.User_Update,
  describeUsersByCognitoId: Permission.User_Describe,
  describeUsersByProviderId: Permission.User_Describe,
  describeUsersByCareManagerId: Permission.User_Describe,
}

const userAdminApiService = createService(({ origin, getAccessToken }) => {
  const applyPermissionHeaders = initApplyPermissionheaders(endpointPermissions)

  const api = createApi({
    reducerPath: 'userAdminApi',
    keepUnusedDataFor: 120,
    tagTypes: ['user', 'searchUser'],
    endpoints,
    baseQuery: graphqlRequestBaseQuery({
      url: `${origin}/graphql`,
      customErrors: (error) => {
        console.error(error.response)
        const e = error.response.errors?.[0] as any
        const response: ErrorResponse = {
          name: e?.errorType || '',
          message: e?.message || '',
          stack: error.stack || '',
        }
        return response
      },
      prepareHeaders: (headers, api) => {
        const state = api.getState() as RootStateWithAuth
        const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]

        if (isAuthenticated) {
          const accessToken = getAccessToken(state)
          headers.set('Authorization', `Bearer ${accessToken}`)
          applyPermissionHeaders(headers, session!, api.endpoint)
        }

        return headers
      },
    }),
  })

  api.enhanceEndpoints({
    endpoints: {
      revokeUser: {
        invalidatesTags: (res) => {
          const id = res?.revokeUser?.careManager.id
          return id ? ['searchUser', { type: 'user', id }] : []
        },
      },
      removeUser: {
        invalidatesTags: (res) => {
          const id = res?.removeUser?.careManager.id
          return id ? ['searchUser', { type: 'user', id }] : []
        },
      },
      restoreUser: {
        invalidatesTags: (res) => {
          const id = res?.restoreUser?.careManager.id
          return id ? ['searchUser', { type: 'user', id }] : []
        },
      },
      getAllUsers: {
        providesTags: ['searchUser'],
      },
      getUser: {
        providesTags: (res) => {
          const id = res?.getUser?.careManager.id
          return id ? [{ type: 'user', id }] : []
        },
      },
      userSignUpWithGoogle: {
        invalidatesTags: (res) => {
          const id = res?.signUpGoogleUser?.careManager.id
          return id ? [{ type: 'searchUser' }] : []
        },
      },
      userSignUp: {
        invalidatesTags: (res) => {
          const id = res?.signUpUser?.careManager.id
          return id ? [{ type: 'searchUser' }] : []
        },
      },
      updateUser: {
        invalidatesTags: (res) => {
          const id = res?.updateUser?.careManager.id
          return id ? [{ type: 'searchUser' }, { type: 'user', id }] : []
        },
      },
    },
  })
  return api
})

export const userAdminApi = userAdminApiService({
  origin: `https://${process.env.USER_ADMIN_API_DOMAIN}`,
})

export type UserAdminAPIType = typeof userAdminApi
