import { useMemo } from 'react'
import {
  referenceDataApi,
  type ReferenceListValue,
} from '@valerahealth/rtk-query'
import { Combobox, type ComboboxProps } from './controls'

const emptyReferral: ReferenceListValue[] = []

type ReferralSourceSelectProps = Omit<
  ComboboxProps<ReferenceListValue>,
  | 'options'
  | 'getOptionKey'
  | 'getOptionLabel'
  | 'setValueAs'
  | 'parseValue'
  | 'loading'
> & {
  /** allows to filter the list if needed before passing options to Combobox */
  filterList?: (list: ReferenceListValue[]) => ReferenceListValue[]
}

/** MAKE SURE THE REFERENCE DATA API IS ADDED TO YOUR REDUX STORE */
export const ReferralSourceSelect = ({
  filterList,
  ...props
}: ReferralSourceSelectProps) => {
  const { referralSources, isLoading } =
    referenceDataApi.useGetReferralSourcesQuery(undefined, {
      selectFromResult: ({ isLoading, data }) => ({
        referralSources: data || emptyReferral,
        isLoading,
      }),
    })

  const options = useMemo(
    () => filterList?.(referralSources) || referralSources,
    [referralSources, filterList],
  )

  return (
    <Combobox
      {...props}
      options={options}
      getOptionKey={(v) => v.value}
      getOptionLabel={(v) => v.label}
      setValueAs={(v) => v?.value}
      parseValue={(v: string | null, options) =>
        v
          ? {
              label: options.find((r) => r.value === v)?.label || v,
              value: v,
            }
          : null
      }
      isOptionEqualToValue={(o, v) => o.value === v.value}
      disabled={props.disabled || isLoading}
      loading={isLoading}
    />
  )
}
