import {
  createContext,
  type ComponentType,
  useContext,
  type MutableRefObject,
  useRef,
} from 'react'

export enum EnhancedSortModes {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Count = 'count',
  // if there are other sorting algorithms we want to use in the future we can add them here.
}

export type EnhancedSortModel = Record<string, EnhancedSortModes | undefined>
export type EnhancedSortRef = MutableRefObject<EnhancedSortModel>

export const EnhancedSortContext = createContext<EnhancedSortRef>({
  current: {},
})

/** used to get the whole state and setState func */
export const useEnhancedSortRef = () => useContext(EnhancedSortContext)

/** used to set the value for a specific column */
export const useSetEnhancedSortColValue = (colName: string) => {
  const ref = useEnhancedSortRef()
  return (value: EnhancedSortModes) => {
    ref.current[colName] = value
  }
}

export function withEnhancedSort<T extends {}>(Component: ComponentType<T>) {
  // Creating the inner component. The calculated Props type here is the where the magic happens.
  function ComponentWithSort(props: T) {
    const ref = useRef<EnhancedSortModel>({})
    return (
      <EnhancedSortContext.Provider value={ref}>
        <Component {...props} />
      </EnhancedSortContext.Provider>
    )
  }
  return ComponentWithSort
}
