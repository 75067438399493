import { createContext, ReactNode, useContext, useMemo } from 'react'
import { CalendarProviderType } from '../reducer'

const Context = createContext<{
  provider: CalendarProviderType
} | null>(null)

Context.displayName = 'ProviderCalendarContext'

/* allows a redux like experience for all the scheduling components */

export const useCalendarContext = () => useContext(Context)!
export const useProvider = () => useContext(Context)!.provider

export type ProviderCalendarContextProps = {
  // only including the properties we need in the calendar to make thise more durable
  provider: CalendarProviderType
  children: ReactNode | ReactNode[]
}

/** provides the context necessary to share data between components for a Provider's calendar, including state, actions (already wrapped with dispatch), the provider that is being viewed, and the practiceMgrGQLApi */
export default function ProviderCalendarContext({
  provider,
  children,
}: ProviderCalendarContextProps) {
  const value = useMemo(
    () => ({
      provider,
    }),
    [provider],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}
