export const REGISTER_TREATMENT_ERROR_CODE = {
  PHONE_ALREADY_EXISTS_OTHER_CLINIC: 'already_exists_in_different_clinic',
  PHONE_ALREADY_EXISTS_SAME_CLINIC: 'already_exists_in_same_clinic',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
} as const

export type RegisterTreatmentErrorCode =
  (typeof REGISTER_TREATMENT_ERROR_CODE)[keyof typeof REGISTER_TREATMENT_ERROR_CODE]
