import { ReactElement, cloneElement, useCallback, useState } from 'react'
import { PatientStatus, careManagerApi } from '@valerahealth/rtk-query'
import { CloseOutlined } from '@mui/icons-material'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Typography,
  Stack,
  Button,
  ButtonLink,
  CircularProgress,
} from '../../base'
import { useNotify } from '../Notifications'

const gridSpacing = 1.5

const { useLazyGetMeetingRoomQuery } = careManagerApi

export type StartPatientVideoSessionProps = {
  videoSessionURL?: string | null
  children: ReactElement
  patientStatus: PatientStatus
  patientId: string
  treatmentId: string
  enablePatientStatusCheck: boolean
  /** props injected into child component if video button is enabled */
  enabledProps?: Record<string, any>
  /** props injected into child component if video button is disabled */
  disabledProps?: Record<string, any>
}

const enablePrompt = process.env.ENABLE_START_VIDEO_SESSION_PROMPT === 'true'

export const StartPatientVideoSession = ({
  videoSessionURL,
  children,
  patientId,
  treatmentId,
  patientStatus,
  enablePatientStatusCheck,
  enabledProps,
  disabledProps,
}: StartPatientVideoSessionProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const notify = useNotify()
  const [t] = useTranslation()
  const cmLinkPrefix =
    window.location.host === process.env.CARE_MANAGER_UI_DOMAIN
      ? ''
      : `https://${process.env.CARE_MANAGER_UI_DOMAIN}`

  const [getMeetingRoom, getMeetingRoomStatus] = useLazyGetMeetingRoomQuery()

  const handleStartCall = useCallback(async () => {
    if (videoSessionURL) {
      const win = window.open(videoSessionURL, '_blank')
      if (win) win.focus()
      return
    }
    const res = await getMeetingRoom(patientId)
    if ('data' in res && res.data) {
      const { roomId, sessionId, participantId } = res.data
      const url = process.env.USE_NEW_TELEHEALTH_LINKS
        ? `${
            process.env.TELEHEALTH_UI_DOMAIN.startsWith('localhost')
              ? 'http'
              : 'https'
          }://${
            process.env.TELEHEALTH_UI_DOMAIN
          }/provider/room/${encodeURIComponent(
            roomId,
          )}/waiting/${encodeURIComponent(sessionId)}`
        : `https://${process.env.TELEHEALTH_UI_DOMAIN}?${encodeURI(
            JSON.stringify({
              roomId,
              sessionId,
              participantId,
              treatmentId,
            }),
          )}`
      const win = window.open(url, '_blank')
      if (win) {
        win.focus()
      }
    } else {
      notify({
        severity: 'error',
        message:
          (res.error && 'data' in res.error && res.error.data.message) ||
          'An error occured while initializing a video session',
      })
      console.error(res.error)
    }
  }, [patientId, getMeetingRoom, notify, videoSessionURL, treatmentId])

  const handleClick = useCallback(() => {
    if (enablePatientStatusCheck && patientStatus !== PatientStatus.Active) {
      setModalOpen(true)
    } else {
      handleStartCall()
    }
  }, [handleStartCall, patientStatus, enablePatientStatusCheck])

  const buttonProps = {
    onClick: enablePrompt ? handleClick : handleStartCall,
    ...(getMeetingRoomStatus.isLoading ||
    patientStatus === PatientStatus.ClosedDischarged
      ? { ...disabledProps, disabled: true }
      : { ...enabledProps, disabled: false }),
  }

  return (
    <>
      {cloneElement(children, buttonProps)}
      <Dialog
        keepMounted={false}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          Start Video Call
          <IconButton title="Close" onClick={() => setModalOpen(false)}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Typography>
                Current Patient Status:{' '}
                <strong>{t(`PatientStatus.${patientStatus}`)}</strong>.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Patients should have an <strong>Active</strong> status before
                starting a video session. Would you like to switch their status
                to <strong> Active</strong>?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" gap={gridSpacing}>
                <Button
                  variant="outlined"
                  sx={{
                    ml: 'auto',
                    visibility:
                      patientStatus === PatientStatus.AwaitingInitial
                        ? 'visible'
                        : 'hidden',
                  }}
                  onClick={handleStartCall}
                  startIcon={
                    getMeetingRoomStatus.isLoading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : undefined
                  }
                >
                  NO, KEEP STATUS AND START CALL
                </Button>
                <ButtonLink
                  to={`${cmLinkPrefix}/caseload/treatment/${treatmentId}/basic-info?autoFocusStatus=1`}
                  onClick={() => setModalOpen(false)}
                >
                  YES, SWITCH TO ACTIVE
                </ButtonLink>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
