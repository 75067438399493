import { Grid } from '@valerahealth/ui-components'
import {
  Switch,
  ControlledTextField,
  IntlPhoneInput,
} from '@valerahealth/ui-components/form'
import { useTranslation } from '@valerahealth/ui-translation'
import { useSelector } from 'react-redux'
import { LegacyRootState } from 'redux/reducers/legacyRootState'

const AppUsage = () => {
  const { t } = useTranslation()
  const disableChatOptions = useSelector(
    (state: LegacyRootState) =>
      !state.program.configurations?.disableChat?.active,
  )
  const isAdmin = useSelector(
    (state: LegacyRootState) => state.global?.user?.permissions?.isAdmin,
  )

  return (
    <Grid container spacing={3} flexDirection="row">
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={6}>
          <Switch
            name="useMobileApp"
            fullWidth
            variant="standard"
            sx={{ marginLeft: '12px' }}
            label={t('usingValeraApp')}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField
            disabled={!isAdmin}
            // required
            minLength={8}
            maxLength={50}
            fullWidth
            label={t('username')}
            name="username"
          />
        </Grid>
      </Grid>
      {!disableChatOptions && (
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <Switch
              name="patientMessaging"
              fullWidth
              variant="standard"
              sx={{ marginLeft: '12px' }}
              label={t('valeraMessaging')}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={3} xs={12}>
        <Grid item xs={6}>
          <IntlPhoneInput
            required
            fullWidth
            label={t('signInPhone')}
            name="phone"
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField
            fullWidth
            required
            label={t('signInEmail')}
            name="email"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AppUsage
