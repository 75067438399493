// default component
import React from 'react'
import PropTypes from 'prop-types'
import defaultAvatar from '../../../images/user_avatar.jpg'
import logo from '../../../images/logo.jpg' // do not remove this line. This is for service worker!!!!!

function ProfilePicture({ treatment, keyWord = 'profile', id }) {
  return (
    <span className="profile-picture-container">
      <img
        id={id || ''}
        src={
          (treatment && treatment[keyWord] && treatment[keyWord].picture) ||
          defaultAvatar
        }
        onError={(e) => {
          e.target.onerror = null
          e.target.src = defaultAvatar
        }}
      />
    </span>
  )
}

ProfilePicture.propTypes = {
  treatment: PropTypes.object,
  id: PropTypes.string,
  keyWord: PropTypes.string,
}

export default ProfilePicture
