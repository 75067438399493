import { PatientLegalSex } from '@valerahealth/rtk-query'
import dStrings from '../../strings.json'

export const frequency = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
} as const

export const trackType = {
  ONETIME: 'one_time',
  CYCLIC: 'cyclic',
} as const

export const planType = {
  INDIVIDUAL_PUBLIC: 'individual_public',
  INDIVIDUAL_PRIVATE: 'individual_private',
  POPULATION: 'population',
} as const

export const messageType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
} as const

export const messageChatType = {
  CTM: 'CTM',
  System: 'System',
} as const

export const caseloadStateEnum = {
  ACTIVE: 'active',
  LEAD: 'leads',
  DISCHARGE: 'discharge',
} as const

export const dateFormatEnum = {
  MONTH_DAY: 'MM/dd',
  FULL_DATE: 'EEE, LLL d, yyyy',
  MONTH_DAY_YEAR_WORD: 'MMM d, yyyy',
  DAY_MONTH_WORD_TIME: 'EEE, MMM d, h:mma',
  MONTH_DAY_YEAR_WORD_TIME: 'MMMM d, yyyy, h:mma',
  FULL_TIME: 'hh:mma',
  HOUR: 'ha',
  FULL_DATE_AND_TIME: 'EEE, LLL d, yyyy | hh:mma',
  SMALL_DATE_BASIC: 'MM/dd/yy',
  DATE_BASIC: 'MM/dd/yyyy',
  DATE_BASIC_TIME: 'MM/dd/yyyy, hh:mm a',
} as const

export const domainEventEnum = {
  APPOINTMENT_ACCEPTED: 'appointment_accepted',
  APPOINTMENT_DECLINED: 'appointment_declined',
  APPOINTMENT_RESCHEDULED: 'appointment_reschedule_requested',
  PATIENT_REQUEST_SENT: 'patient_request_sent',
} as const

export const genderEnum = {
  [PatientLegalSex.M]: dStrings.male,
  [PatientLegalSex.F]: dStrings.female,
} as const

export const genderEnum2 = {
  [PatientLegalSex.M]: dStrings.male,
  [PatientLegalSex.F]: dStrings.female,
  [PatientLegalSex.O]: dStrings.other,
} as const

export const avoidRedirectionReasonEnum = {
  UNSAVE: 'unsave',
  NO_TRACK: 'no_track',
  PROVIDER: 'provider',
  NOTE: 'note',
} as const

export const resetPswdState = {
  RESET: 'reset',
  FORGOT: 'forgot',
} as const

export const notifyByEnum = {
  EMAIL: 'email',
  SMS: 'sms',
  ALL: 'all',
} as const

export const timezoneEnum = {
  'America/New_York': dStrings.ET,
  'America/Chicago': dStrings.CT,
  'America/Phoenix': dStrings.AT,
  'America/Denver': dStrings.MT,
  'America/Los_Angeles': dStrings.PT,
  'US/Alaska': dStrings.AlaskaT,
  'US/Hawaii': dStrings.HT,
} as const

export const timezoneEnum2 = {
  'America/New_York': dStrings.Eastern,
  'America/Chicago': dStrings.Central,
  'America/Phoenix': dStrings.Mountain,
  'America/Denver': dStrings.Mountain,
  'America/Los_Angeles': dStrings.Pacific,
  'US/Pacific': dStrings.Pacific,
  'US/Alaska': dStrings.Alaska,
  'US/Hawaii': dStrings.Hawaii,
  'US/Central': dStrings.Central,
  'US/Eastern': dStrings.Eastern,
  'US/Arizona': dStrings.Mountain,
  'US/Mountain': dStrings.Mountain,
  'Asia/Jerusalem': dStrings.Jerusalem,
} as const

export const defaultTimeZone = 'America/New_York' as const

export const pageSize = {
  CASELOAD: 30,
  LOGS: 30,
  NOTIFICATIONS: 30,
  PROVIDER: 30,
} as const

export const buttonsTypesEnum = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LINK: 'link',
} as const

export const threeStateEnum = {
  CHECKED: 1,
  UNCHECKED: 2,
  INDETERMINATE: -1,
} as const

export const additionalContactRelationEnum = {
  'Supportive Contact': 'Supportive Contact',
  parent_guardian: 'Parent/Guardian',
  'Minor Patient': 'Minor Patient',
  'Additional Patient Contact': 'Additional Patient Contact',
  Other: 'Other',
} as const

export const errorsEnum = {
  AUTHENTICATION: 'Invalid authentication',
} as const

export const noteTypeEnum = {
  progress: 'progress',
  psychotherapy: 'psychotherapy',
} as const

export const contactUseList = ['personal', 'work', 'emergency'] as const

export const templateEnum = {
  article: 'article',
  questionnaire: 'questionnaire',
  reminder: 'reminder',
  audio_track: 'audio_track',
  episode: 'episode',
  message: 'message',
  sentiment: 'sentiment',
  consent_form: 'consent_form',
  appointment: 'appointment',
  medication: 'medication',
} as const

export type TempalteEnumValue = (typeof templateEnum)[keyof typeof templateEnum]

export const lineOfBusinessEnum = {
  COMMERCIAL: 'COMMERCIAL',
  MEDICARE: 'MEDICARE',
  MEDICAID: 'MEDICAID',
} as const

export const routesEnum = {
  CASELOAD: '/caseload',
  PATIENT: '/patients',
  TASKS: `/tasks`,
  SUPERVISION: '/supervision',
  CALENDAR: '/calendar',
  TRACKS: '/templates',
} as const
