import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Badge,
  bindPopper,
  bindToggle,
  Box,
  Fade,
  IconButton,
  LinearProgress,
  Paper,
  Popper,
  PopupState,
} from '@valerahealth/ui-components'
import { Notifications as NotificationsIcon } from '@valerahealth/ui-components/icons'
import { useGetNotificationsForSignedInUser } from 'components/PatientSearch/useGetNotifications'
import { CMChannelItemOverview } from '@valerahealth/rtk-query'
import { careManagerEnhancedApi } from 'api/careManagerAPIEnhanced'
import ProfilePicture from '../../common/Picture'
import { getMessageContent } from '../../common/ChannelEvents'
import { getMessageDateTime, getProfileName } from '../../utilities/treatments'
import t from '../../../locales/en'

function NotificationChannelEvent({
  messages,
}: {
  messages: CMChannelItemOverview[]
}) {
  const [show, setShow] = useState(messages.length <= 2)
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        padding: '12px',
        borderRadius: '5px',
        marginTop: '15px',
      }}
    >
      {messages.length > 2 && (
        <Box
          sx={{ textAlign: 'center' }}
          onClick={() => {
            setShow(!show)
          }}
        >
          <span />
        </Box>
      )}
      {show &&
        messages.map((message) => {
          return (
            <Box
              key={message.ts}
              sx={{
                maxWidth: '400px',
                maxHeight:'100px',
                overflowY: 'auto',
                background: (theme) => theme.palette.grey[200],
                padding: '8px',
                borderRadius: '9px 9px 9px 9px',
                minHeight: '50px',
                position: 'relative',
                mb:'4px',
              }}
            >
              <div className="message-line">
                <div className="message-text">{getMessageContent(message)}</div>
                <div className="message-time xsmall-font">
                  {getMessageDateTime(message.ts)}
                </div>
              </div>
            </Box>
          )
        })}
    </Box>
  )
}

function NotificationsCount({ count }: { count: number }) {
  if (count > 1) {
    // eslint-disable-next-line
    const parts = t.hasNewNotifications.split('${count}')
    return (
      <>
        {' '}
        {parts[0]} <b>{count}</b> {parts[1]}{' '}
      </>
    )
  }
  // eslint-disable-next-line
  const parts = t.hasNewNotification.split('${count}')
  return (
    <>
      {' '}
      {parts[0]} <b>{count}</b> {parts[1]}{' '}
    </>
  )
}

function Notifications() {
  const [setChannelSeen, setChannelSeenRes] =
    careManagerEnhancedApi.useSetChannelSeenMutation()
  const {
    treatmentNotifications: notifications,
    isLoading: isLoadingNotifications,
  } = useGetNotificationsForSignedInUser()
  const isLoading = isLoadingNotifications || setChannelSeenRes.isLoading
  const notificationsCount = notifications.length

  return (
    <PopupState variant="popover" popupId="nav-notifications">
      {(popupState) => (
        <Box>
          <IconButton
            disabled={!notificationsCount}
            size="large"
            color="inherit"
            sx={{ ml: 1, mr: 1 }}
            {...bindToggle(popupState)}
          >
            <Badge
              badgeContent={notificationsCount < 10 ? notificationsCount : '9+'}
              color="success"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popper
            placement="bottom-end"
            sx={{ zIndex: '999' }}
            {...bindPopper(popupState)}
            transition
          >
            {({ TransitionProps }) =>
              notifications && notifications.length ? (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Box
                      display="flex"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.background.paper,
                        color: (theme) => theme.palette.grey[600],
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {`${t.notifications} (${notifications.length})`}
                      <IconButton onClick={popupState.close} />
                    </Box>
                    <Box
                      sx={{
                        maxHeight: 'calc(100vh - 200px)',
                        overflow: 'auto',
                      }}
                    >
                      {notifications.map((notification, index) => {
                        return (
                          <NavLink
                            style={{
                              padding: '16px',
                              display: 'flex',
                            }}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${notification.id}${index}`}
                            to={`/caseload/treatment/${notification.id}/timeline`}
                          >
                            <Box
                              display="contents"
                              onClick={() => {
                                popupState.toggle()
                                if (!notification.id) {
                                  console.error(
                                    'undefined comes from notification-element',
                                  )
                                }
                                setChannelSeen({ treatmentId: notification.id })
                              }}
                            >
                              <Box sx={{ width: '100%', padding: '0 15px' }}>
                                <Box
                                  display="flex"
                                  sx={{
                                    justifyContent: 'space-between',
                                    alignItems: 'baseline',
                                  }}
                                >
                                  <Box>
                                    <b>
                                      {getProfileName(notification.profile)}
                                    </b>{' '}
                                    <NotificationsCount
                                      count={notification.unread?.length || 0}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.primary.light,
                                      fontWeight: '500',
                                      whiteSpace: 'nowrap',
                                      ml:'6px'
                                    }}
                                    onClick={(e) => {
                                      // preventDefault avoid link to redirect but onClick (on top) is called
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setChannelSeen({
                                        treatmentId: notification.id,
                                      })
                                      if (notifications.length < 2) {
                                        popupState.close()
                                      }
                                    }}
                                  >
                                    {t.markAsRead}
                                  </Box>
                                </Box>
                                {notification.unread && (
                                  <NotificationChannelEvent
                                    messages={notification.unread}
                                  />
                                )}
                              </Box>
                            </Box>
                          </NavLink>
                        )
                      })}
                      {isLoading && <LinearProgress />}
                    </Box>
                  </Paper>
                </Fade>
              ) : null
            }
          </Popper>
        </Box>
      )}
    </PopupState>
  )
}

export default Notifications
