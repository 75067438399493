import dotProp from 'dot-prop-immutable'
import * as types from './noteTypes'

const noteReducer = (state, action) => {
  switch (action.type) {
    case types.LOAD_NOTES_SUCCESS: {
      const tempState = dotProp.set(state, `byId.${state.selectedId}.notes`, [
        ...action.notes,
      ])

      return dotProp.set(
        tempState,
        `byId.${state.selectedId}.notesCount`,
        action.notesCount,
      )
    }
    case types.LOAD_SINGLE_NOTE_SUCCESS: {
      let notes = state.byId[state.selectedId].notes || []
      notes = [...notes, action.note]
      return dotProp.set(state, `byId.${state.selectedId}.notes`, notes)
    }

    case types.UPDATE_NOTE_SUCCESS:
      if (!state.byId[state.selectedId].notes) return state

      return dotProp.set(state, `byId.${state.selectedId}.notes`, (list) =>
        list.map((item) => {
          if (item.id !== action.note.id) return item
          return action.note
        }),
      )
    // case types.DELETE_NOTES_SUCCESS: {
    //   // debugger;
    //   return dotProp.set(state, `byId.${state.selectedId}.notes`, (list) =>
    //     {return list.filter((note) => note.id !== action.id)}
    //   );
    // }
    // case types.CREATE_NOTES_SUCCESS: {
    //   const notes = state.byId[state.selectedId].notes || [];
    //   return dotProp.set(state, `byId.${state.selectedId}.notes`, [
    //     action.note,
    //     ...notes
    //   ]);
    // }
    case types.COPY_NOTE_SUCCESS: {
      const notes = state.byId[state.selectedId].notes || []
      let index = -1
      for (let i = 0; i < notes.length; i++) {
        if (notes[i].id === action.copiedId) {
          index = i + 1
          break
        }
      }

      const tempState = dotProp.set(state, `byId.${state.selectedId}.notes`, [
        ...notes.slice(1, index),
        action.note,
        ...notes.slice(index),
      ])

      return dotProp.set(
        tempState,
        `byId.${state.selectedId}.notesCount`,
        state.byId[state.selectedId].notesCount + 1,
      )
    }
    default:
      break
  }
}

export default noteReducer
