/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react'
import { ValuesType } from 'utility-types'
import { useSelector } from 'react-redux'
import {
  TaskAnnotationFragment,
  TaskFragment,
  TaskStatus,
  careManagerApi,
  taskApi,
  ParsedTaskFragment,
  practiceMgrApi,
  PatientPreferencesField,
} from '@valerahealth/rtk-query'
import LABELS from '@valerahealth/ui-translation/locales/en'
import { useTranslation } from '../../../utils'
import {
  LinearProgress,
  Chip,
  FormControl,
  MenuItem,
  Typography,
  TypographyProps,
  CircularProgress,
  SingleSelect,
  Comment,
  IconButton,
  Box,
  Divider,
  Autocomplete,
  TextField,
  Grid,
  IconButtonProps,
} from '../../../base'
import {
  Combobox,
  Select,
  useFormContext,
} from '../../../form'
import {
  isPast,
  isToday,
  strToDate,
} from '../../../utils/date'
import { Add } from '../../../icons'
import { IValueOption } from '../../../grid'
import { ColumnKey, TaskFormType } from './types'
import { Mode } from '../constants'
import { usePreferencePreset } from './usePreferencePreset'
import { useNotify } from '../../Notifications'
import { useConfirmationDialog } from '../../ConfirmationDialog'
import { DeleteIcon } from '../../../icons/exports'
import { SearchAvailability, usePatientPreferenceValueOptions } from '../../PatientPreferences'

const PAT_PREF_FIELD_LABELS = {
  [PatientPreferencesField.serviceType]: LABELS.serviceType,
  [PatientPreferencesField.insurancePlan]: LABELS.insurancePlan,
  [PatientPreferencesField.locations]: LABELS.location,
  [PatientPreferencesField.legalSex]: LABELS.legalSex,
  [PatientPreferencesField.genderIdentity]: LABELS.genderIdentity,
  [PatientPreferencesField.race]: LABELS.race,
  [PatientPreferencesField.ethnicity]: LABELS.ethnicity,
  [PatientPreferencesField.languages]: LABELS.language,
  [PatientPreferencesField.spirituality]: LABELS.spirituality,
  [PatientPreferencesField.clinicalAges]: LABELS.clinicalAge,
  [PatientPreferencesField.clinicalSpecialties]: LABELS.clinicalSpecialty,
  [PatientPreferencesField.clinicalModalities]: LABELS.clinicalModality,
  [PatientPreferencesField.communityIdentity]: LABELS.communityIdentity,
  [PatientPreferencesField.time]: LABELS.time,
  [PatientPreferencesField.duration]: LABELS.duration,
  [PatientPreferencesField.dayOfWeek]: LABELS.dayOfWeek,
  [PatientPreferencesField.dayPart]: LABELS.dayPart,
}

const { useAggregatesCMPractitionerQuery } = careManagerApi

const taskStatusStyle = {
  p: 0,
  m: 0,
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '.MuiInputBase-root': {
    margin: 0,
    padding: 0,
  },
  '.MuiSelect-icon': {
    right: '0px !important',
  },
  '.MuiInputBase-input': {
    p: '0px 1.5rem 0px 0px !important',
  },
}

export const itemStyle: TypographyProps = {
  sx: {
    color: (theme) => theme.palette.secondary.dark,
    fontSize: '12px',
    lineHeight: '20px',
  },
} as const
export const valueStyle: TypographyProps = {
  sx: {
    color: (theme) => theme.palette.secondary.light,
    fontSize: '12px',
    lineHeight: '20px',
  },
} as const
const options = [
  TaskStatus.ToDo,
  TaskStatus.Completed,
  TaskStatus.Void,
] as const
export const TaskStatusChip = ({
  status,
  dueDate,
}: {
  status: TaskStatus
  dueDate?: Date | null
}): JSX.Element => {
  const [t] = useTranslation()
  const overDue = dueDate ? isPast(dueDate) && !isToday(dueDate) : false
  let statusDisplay = status
  if (statusDisplay === TaskStatus.ToDo && overDue) {
    statusDisplay = TaskStatus.Overdue
  }
  if (statusDisplay === TaskStatus.Overdue && !overDue) {
    statusDisplay = TaskStatus.ToDo
  }
  return (
    <Chip
      color={
        statusDisplay === TaskStatus.Completed
          ? 'success'
          : statusDisplay === TaskStatus.Overdue
          ? 'error'
          : statusDisplay === TaskStatus.ToDo
          ? 'warning'
          : statusDisplay === TaskStatus.Void
          ? 'default'
          : 'secondary'
      }
      sx={{ height: '24px' }}
      label={t(`task.taskStatus.${statusDisplay}`)}
    />
  )
}

export const TypographyTemplate = ({
  item,
  value,
  props,
  addNewLine = false,
}: {
  item: string
  value: string
  props?: { itemProps?: TypographyProps; valueProps?: TypographyProps }
  addNewLine?: boolean
}) => (
  <>
    <Typography
      display="inline"
      {...itemStyle}
      {...props?.itemProps}
    >{`${item}: `}</Typography>
    {addNewLine && <br />}
    <Typography
      display="inline"
      {...valueStyle}
      {...props?.valueProps}
    >{`${value}`}</Typography>
  </>
)

export const CommentTypography = ({
  comments,
}: {
  comments: TaskFragment['comments']
}): JSX.Element => {
  const { t } = useTranslation()
  const commentList = React.useMemo(
    () =>
      comments
        ?.filter((c) => c.text && c.text.trim().length) // remove text that contains only strings
        ?.map((c: TaskAnnotationFragment) => (
          <Box key={c.time}>
            <Divider />
            <Comment
              text={c.text}
              author={c.authorReference.display}
              date={new Date(c.time)}
            />
          </Box>
        ))
        .reverse(),
    [comments],
  )
  return (
    <>
      <Typography {...itemStyle}>{`${t('comments')}: `}</Typography>
      {commentList}
    </>
  )
}

export const TaskStatusSelectRead = ({
  task,
}: {
  task: Pick<TaskFragment, 'status' | 'dueDate' | '_id'>
}): JSX.Element => {
  const [t] = useTranslation()
  const { setValue } = useFormContext()
  const notify = useNotify()
  const [updateTask, { isLoading, data: updatedData }] =
    taskApi.useUpdateTaskMutation()

  const { status, dueDate } =
    (updatedData?._id === task._id && updatedData) || task
  const handleChange = (value: TaskStatus) => async () => {
    try {
      const res = await updateTask({
        content: { id: task?._id || '', status: value },
      })
      if ('error' in res) throw new Error()
      setValue('status', value)
      notify({
        message: t('task.success_task_save'),
        severity: 'success',
      })
    } catch (e) {
      notify({
        message: t('task.fail_change_status'),
        severity: 'error',
      })
    }
  }

  return (
    <FormControl size="small">
      {isLoading ? (
        <CircularProgress size="1.6rem" />
      ) : (
        <SingleSelect
          value={status}
          renderValue={() => (
            <TaskStatusChip
              status={status}
              dueDate={strToDate(dueDate) || new Date()}
            />
          )}
          sx={taskStatusStyle}
        >
          {options.map((o) => (
            <MenuItem key={o} value={o} id={o} onClick={handleChange(o)}>
              {t(`task.taskStatus.${o}`)}
            </MenuItem>
          ))}
        </SingleSelect>
      )}
    </FormControl>
  )
}

export const AssignedCombobox = ({
  required,
  enableDefualtAssignToSelf = true,
}: {
  required?: string | boolean
  enableDefualtAssignToSelf?: boolean
}): JSX.Element => {
  const [t] = useTranslation()
  const assigneeId = useSelector(
    (state: any) => state?.auth?.session?.user?.careManagerId || state?.global?.user?.profile?.id || ''
  ) as string
  const { watch, setValue } = useFormContext()
  const assignee = watch(ColumnKey.assignee)
  const { data, isLoading } = careManagerApi.useGetAllUsersQuery({})
  const options = useMemo(
    () =>
      (data || [])
        .filter(
          (user) => !user.accessStatus || user.accessStatus === 'restored',
        )
        .map((user) => ({
          _id: user.profile.id,
          display:
            user.profile.displayName ||
            `${user.profile.firstName} ${user.profile.lastName}`,
        }))
        .sort((a, b) => {
          const one = a.display.toLowerCase()
          const two = b.display.toLowerCase()
          return one > two ? 1 : one === two ? 0 : -1
        }),
    [data],
  )
  useEffect(() => {
    if (enableDefualtAssignToSelf && !assignee && options) {
      const defaultOption = options.find((o) => o._id === assigneeId)
      setValue(ColumnKey.assignee, defaultOption)
    }
  }, [options, assignee, assigneeId, setValue, enableDefualtAssignToSelf])

  return (
    <Combobox
      label={t('Assign to')}
      name={ColumnKey.assignee}
      fullWidth
      disabled={isLoading || !options}
      options={options || []}
      isOptionEqualToValue={(o, v) => o._id === v._id}
      getOptionLabel={(option) => option.display}
      getOptionKey={(option) => option._id}
      required={required}
      disableClearable
      loading={isLoading}
      size="small"
    />
  )
}

export const TaskStatusSelectWrite = ({
  task,
}: {
  task: Pick<TaskFragment, 'status'> | null
}): JSX.Element => {
  const [t] = useTranslation()
  const { watch } = useFormContext()
  const dueDate = watch(ColumnKey.dueDate)
  const status = task?.status || TaskStatus.ToDo
  return (
    <Select
      name={ColumnKey.status}
      value={status}
      defaultValue={TaskStatus.ToDo}
      renderValue={(selected: TaskStatus) => (
        <TaskStatusChip status={selected} dueDate={dueDate} />
      )}
      sx={taskStatusStyle}
    >
      {options.map((o) => (
        <MenuItem key={o} value={o} id={o}>
          {t(`task.taskStatus.${o}`)}
        </MenuItem>
      ))}
    </Select>
  )
}

export const DeleteButton = ({
  task,
  onDeleteSuccess,
  ...rest
}: {
  task: Pick<TaskFragment, '_id'>
  onDeleteSuccess?: (task: ParsedTaskFragment) => void
} & IconButtonProps) => {
  const [t] = useTranslation()
  const notify = useNotify()
  const { confirm, ConfirmationDialog: DeleteConfirmationDialog } =
    useConfirmationDialog()
  const [deleteTask, { isLoading }] = taskApi.useDeleteTaskMutation()
  const handleDelete = async () => {
    const confirmed = await confirm({
      header: t('Delete Task'),
      body: t('Are you sure you want to delete this task?'),
      confirmLabel: t('Yes'),
      cancelLabel: t('No'),
    })
    if (confirmed) {
      const res = await deleteTask({ _id: task?._id || '' })
      if ('error' in res) {
        notify({
          message: res.error.message || t('task.fail_delete_task'),
          severity: 'error',
        })
        return
      }
      notify({ message: t('task.success_delete_task'), severity: 'success' })
      onDeleteSuccess?.(res.data)
    }
  }
  return (
    <>
      <IconButton
        title="Delete"
        onClick={task ? handleDelete : undefined}
        disabled={isLoading}
        {...rest}
      >
        {isLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
      </IconButton>
      <DeleteConfirmationDialog keepMounted />
    </>
  )
}

/*
  Patient Preference Panel
*/

export const PatientPreferencePanel = ({
  mode,
  isNew = false,
  currentSelectTreatmentId,
}: {
  mode: Mode
  isNew?: boolean
  currentSelectTreatmentId?: string | undefined
}) => {
  const skipGenerateDefaults = !isNew || mode === Mode.READ
  const { watch, setValue } = useFormContext<TaskFormType>()
  const currentPreferences = watch(ColumnKey.patientPreferences)
  const [valuedPreferencesKeys, unvaluedPreferenceKeys] = useMemo(() => {
    const selected = Object.entries(currentPreferences)
      .filter(([, value]) => !!value?.length)
      .map(([key]) => key) as (keyof typeof currentPreferences)[]

    const unselected = Object.values(PatientPreferencesField)
      .filter(
        (v) =>
          !selected.includes(v) &&
          // we are going to get this from the task.serviceType instead
          v !== PatientPreferencesField.serviceType,
      )
      .sort((a, b) =>
        PAT_PREF_FIELD_LABELS[a].localeCompare(PAT_PREF_FIELD_LABELS[b]),
      )
    return [selected, unselected]
  }, [currentPreferences])
  const skipReferenceQueries =
    mode === Mode.READ && !valuedPreferencesKeys.length

  const [t] = useTranslation()
  const { locations, insurancePlans, isLoadingPMAggs } =
    practiceMgrApi.useGetPMAggregatesQuery(undefined, {
      skip: skipReferenceQueries,
      selectFromResult: ({ data, isLoading }) => ({
        locations: data?.getLocations,
        insurancePlans: data?.getInsurancePlans,
        isLoadingPMAggs: isLoading,
      }),
    })
  const { data: cmAggs, isLoading: isLoadingCMAggs } =
    useAggregatesCMPractitionerQuery(undefined, {
      skip: skipReferenceQueries,
      selectFromResult: ({ data, isLoading }) => ({
        data,
        isLoading,
      }),
    })

  const {
    isLoading: isLoadingPreset,
    patientPreferences: presetPatientPreferences,
  } = usePreferencePreset({
    treatmentId: currentSelectTreatmentId,
    skip: skipGenerateDefaults,
  })

  // SET PREFERENCES IF WE HAVE LOADED THEM
  useEffect(() => {
    if (!skipGenerateDefaults && presetPatientPreferences) {
      setValue('patientPreferences', presetPatientPreferences)
    }
  }, [presetPatientPreferences, setValue, skipGenerateDefaults])

  const options = usePatientPreferenceValueOptions(
    cmAggs,
    locations,
    insurancePlans,
  )

  const [newPrefState, setNewPrefState] = useState<{
    key: ValuesType<typeof PatientPreferencesField> | null
    value: IValueOption<string | number> | null
  }>({
    key: null,
    value: null,
  })

  const isLoading = isLoadingPreset || isLoadingPMAggs || isLoadingCMAggs

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        sx={{ ...{ mt: '-4px', mb: '8px' }, ...itemStyle.sx }}
      >
        {mode === Mode.WRITE ? (
          <Typography sx={itemStyle.sx}>{t('patientPreferences')}</Typography>
        ) : (
          <>
            <Typography sx={{ ...{ mr: 'auto' }, ...itemStyle.sx }}>
              {t('patientPreferences')}
            </Typography>
            <SearchAvailability
              serviceType={watch('serviceType')}
              patientPreferences={currentPreferences}
            />
          </>
        )}
      </Box>

      <Grid container spacing={2}>
        {mode === Mode.WRITE && (
          <>
            <Grid item xs={6}>
              <Autocomplete
                onChange={(event, key) => {
                  setNewPrefState({
                    key,
                    value: null,
                  })
                }}
                value={newPrefState.key}
                options={unvaluedPreferenceKeys}
                getOptionLabel={(o) => PAT_PREF_FIELD_LABELS[o]}
                renderInput={(params) => <TextField {...params} label="Data" />}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="row">
                <Autocomplete
                  fullWidth
                  value={newPrefState.value}
                  options={
                    newPrefState.key ? options.map[newPrefState.key] : []
                  }
                  onChange={(event, value) => {
                    setNewPrefState((v) => ({
                      ...v,
                      value,
                    }))
                  }}
                  disabled={!newPrefState.key || isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        ...(isLoading
                          ? {
                              InputProps: {
                                ...params.InputProps,
                                endAdornment: <CircularProgress size={20} />,
                              },
                            }
                          : null),
                      }}
                      label="Value"
                    />
                  )}
                  size="small"
                />
                <IconButton
                  disabled={!newPrefState.key || !newPrefState.value}
                  onClick={() => {
                    const { key, value } = newPrefState
                    if (!key || !value) return
                    setValue(
                      ColumnKey.patientPreferences,
                      {
                        ...currentPreferences,
                        [key]: [value.value],
                      },
                      { shouldDirty: true },
                    )
                    setNewPrefState({
                      key: null,
                      value: null,
                    })
                  }}
                >
                  <Add />
                </IconButton>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12} display="flex" gap={0.5} flexWrap="wrap">
          {isLoading && valuedPreferencesKeys.length ? (
            <LinearProgress sx={{ flex: '1 1 100%', width: '100%' }} />
          ) : (
            valuedPreferencesKeys.map((key) => {
              const value = currentPreferences[key]?.[0]!
              return (
                <Chip
                  size="small"
                  key={key}
                  label={`${PAT_PREF_FIELD_LABELS[key]}: ${options.getLabel(
                    key,
                    value,
                  )}`}
                  onDelete={
                    mode === Mode.WRITE
                      ? () => {
                          const { [key]: omit, ...prefs } = currentPreferences
                          setValue(ColumnKey.patientPreferences, prefs, {
                            shouldDirty: true,
                          })
                        }
                      : undefined
                  }
                />
              )
            })
          )}
        </Grid>
      </Grid>
    </>
  )
}
