// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information-text {
  display: none;
  border: 1px solid #ccc;
  padding: 5px;
  position: absolute;
  border: 1px solid #dadcdf;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(114, 114, 114, 0.5);
  z-index: 1;
  width: 200px;
}

.information-hover-container:hover .information-text {
  display: block;
}

.information-hover-container {
  position: relative;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/InformationHover/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,gDAAgD;EAChD,UAAU;EACV,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".information-text {\n  display: none;\n  border: 1px solid #ccc;\n  padding: 5px;\n  position: absolute;\n  border: 1px solid #dadcdf;\n  border-radius: 3px;\n  background-color: #ffffff;\n  box-shadow: 0 4px 8px 0 rgba(114, 114, 114, 0.5);\n  z-index: 1;\n  width: 200px;\n}\n\n.information-hover-container:hover .information-text {\n  display: block;\n}\n\n.information-hover-container {\n  position: relative;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
