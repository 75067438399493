import { filterTimeOptions } from '../../utils/data'
import { generateTimeOptions, TimeOption } from '../../utils/date'
import { Combobox, ComboboxProps } from './Select'

type TimePickerProps = Omit<
  ComboboxProps<TimeOption, false>,
  'filterOptions' | 'getOptionLabel' | 'isOptionEqualToValue' | 'options'
> & {
  // defaults to every 15 minute options but can pass in custom options if desired
  options?: TimeOption[]
}

export function TimePicker({
  options = generateTimeOptions(15),
  ...rest
}: TimePickerProps) {
  return (
    <Combobox
      getOptionLabel={(o) => o.display}
      isOptionEqualToValue={(o, v) => o.iso === v.iso}
      options={options}
      filterOptions={filterTimeOptions}
      {...rest}
    />
  )
}
