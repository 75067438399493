import { errorsEnum } from '../components/utilities/enums'

import store from '../redux/store'

export async function handleResponse(response, includeHeader = false) {
  if (response.ok) {
    if (response.status !== 204) {
      const data = await response.json()

      if (!includeHeader) return data

      const header = {}
      for (const pair of response.headers.entries()) {
        header[pair[0]] = pair[1]
      }

      return { data, header }
    }
    return response.text()
  }
  if (response.status === 401) {
    throw new Error(errorsEnum.AUTHENTICATION)
  }
  if (~[400, 401, 403, 404].indexOf(response.status)) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text()
    throw new Error(error)
  }

  throw new Error('Network response was not ok.')
}

export function handleError(error) {
  // eslint-disable-next-line no-console
  console.warn(`API call failed. ${error}`)
  throw error
}

export function handleApiFailed(res) {}

const controller = new AbortController()
const { signal } = controller
let flag = false

document.addEventListener('keydown', (event) => {
  if (event.ctrlKey) {
    if (flag) {
      if (event.key === 'y') {
        console.log('abort fetch calls')
        console.log('flag false')
        controller.abort()
        flag = false
      }
    } else if (event.key === 'x') {
      console.log('flag true')
      flag = true
    } else flag = false
  }
})

export const getHeader = () => ({
  Authorization: `Bearer ${store.getState().auth.session?.idToken.jwt}`,
  'Content-Type': 'application/json',
})

export default {
  signal,
}
