import * as types from './actionTypes'
import { callApi, getValidationError } from './utilities'
import {
  getUserId,
  setUserId,
  removeCallDetails,
  setRoleId,
} from '../../api/storageConfig'
import dStrings from '../../strings.json'
import * as providerAPI from '../../api/providerAPI'
import { getMediaItem } from '../../api/toolsTemplatesAPI'
import { openContent } from '../../components/utilities'
import { getLanguages } from '../../api/globalAPI'
import { templateEnum } from '../../components/utilities/enums'
import { getAppointment } from '../../api/treatmentAPI'
import { handleApiFailed } from '../../api/apiUtils'

export function toggleConfirmRedirection(value, options) {
  return { type: types.TOGGLE_CONFIRM_REDIRECTION, value, options }
}

export function toggleSidebar() {
  return { type: types.TOGGLE_SIDEBAR }
}

export function displayConfirmation(confirmation) {
  return { type: types.DISPLAY_CONFIRMATION, confirmation }
}

export function hideConfirmation() {
  return { type: types.HIDE_CONFIRMATION }
}

export function deleteUserMessage(id) {
  return { type: types.REMOVE_USER_MESSAGE_POPUP, id }
}

export function editItem(item) {
  const defaultAction = { type: types.EDIT_ITEM, item }

  return (dispatch, getState) => {
    const state = getState()

    if (item.type === templateEnum.appointment) {
      const isExternal =
        state.program.configurations?.appointments?.scheduleAppointmentUrl ===
        'external'
      const { id } = item.info

      if (!isExternal || !id) {
        dispatch(defaultAction)
        return
      }

      getAppointment(state.treatmentRoom.selectedId, id)
        .then((app) => {
          const link = app.drChronoLink
          if (link) openContent(link)
          else {
            dispatch(defaultAction)
          }
        })
        .catch(handleApiFailed)
    } else if (
      !~[
        templateEnum.article,
        templateEnum.audio_track,
        templateEnum.episode,
      ].indexOf(item.type)
    ) {
      dispatch(defaultAction)
    } else if (item.type === 'article') {
      const media =
        getState().toolsTemplates[item.type].list.byId[item.info.templateId]
      if (!media) {
        dispatch(addUserMessage('error', dStrings.errorOccurred))
        return
      }
      openContent(media.contentUrl)
    } else {
      return getMediaItem(item.info.templateId)
        .then((res) => {
          if (res && res.internalContent && res.internalContent.mediaUrl) {
            openContent(res.internalContent.mediaUrl)
          } else {
            dispatch(addUserMessage('error', dStrings.errorOccurred))
          }
        })
        .catch((err) => {
          dispatch(addUserMessage('error', dStrings.errorOccurred))
        })
    }
  }
}

export function testErrorDatadog() {
  return { type: types.TEST_ERROR_DATADOG }
}

export function cancelEditItem() {
  return { type: types.CANCEL_EDIT_ITEM }
}

export function helpAction() {
  return { type: types.HELP_ACTION }
}

export function resetGoToNewId() {
  return { type: types.RESET_GO_TO_NEW_ID }
}

export function resetGoToPlanSelection() {
  return { type: types.RESET_GO_TO_PLAN_SELECTION }
}

export function addUserMessageItem(messageItem) {
  return { type: types.ADD_USER_MESSAGE_POPUP, messageItem }
}

export function setPlanState(state) {
  return { type: types.CHANGE_PLAN_STATE, state }
}

export function addUserMessage(type, text, id, pin, onClosed) {
  const messageItem = {
    type,
    text,
    pin,
    id: id || `user_message_id${new Date().getTime()}`,
    onClosed,
  }

  return (dispatch) => {
    dispatch(addUserMessageItem(messageItem))
    if (!pin) {
      setTimeout(() => {
        dispatch(deleteUserMessage(messageItem.id))
      }, 5000)
    }
  }
}

export const editSettings = (data, cb, errorCb, recaptcha) => {
  return function (dispatch) {
    return callApi({
      name: 'editSettings',
      dispatch,
      successMessage: dStrings.settingsSuccessfullyUpdated,
      call: () => {
        return providerAPI.updateSettings(getUserId(), data, recaptcha)
      },
      success: (data) => {
        cb && cb()
        dispatch({
          type: types.EDIT_SETTINGS_SUCCESS,
          data,
        })
      },
      error: (res) => {
        const obj = res && res.message && JSON.parse(res.message)
        let message = dStrings.errorOccurred
        let key

        if (obj) {
          if (obj.message === 'patient_already_registered') {
            message = dStrings.patientAlreadyRegistered
          } else if (obj.message === 'already_exists') {
            message = dStrings.already_exists
          } else {
            key = getValidationError(obj)
          }
        }

        errorCb && errorCb(key)
        if (!key) dispatch(addUserMessage('error', message, null, true))
      },
    })
  }
}

export const editScheduleDate = ({ dates, cb, errorCb, id }) => {
  return function (dispatch) {
    return callApi({
      name: 'editScheduleDate',
      dispatch,
      successMessage: dStrings.profileSuccessfullyUpdated,
      call: () => {
        return providerAPI.updateScheduleSettings(id || getUserId(), dates)
      },
      success: (notifications) => {
        dispatch({
          type: types.EDIT_USER_SCHEDULE_DATES_SUCCESS,
          outOfOffice: notifications.outOfOffice,
        })

        cb && cb()
      },
      error: (err) => {
        if (err && err.message) {
          errorCb && errorCb(JSON.parse(err.message))
        }
      },
    })
  }
}

export const loadLanguages = () => {
  return function (dispatch) {
    return callApi({
      name: 'loadLanguages',
      dispatch,
      hideLoader: true,
      call: () => {
        return getLanguages()
      },
      success: (languages) => {
        dispatch({
          type: types.LOAD_LANGUAGES,
          languages,
        })
      },
    })
  }
}

export function editOOO(editedOOO) {
  return {
    type: types.EDIT_OOO,
    editedOOO,
  }
}

export function cancelEditOOO() {
  return {
    type: types.CANCEL_EDIT_OOO,
  }
}
