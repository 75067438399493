import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { InsuranceOrdinality } from '@valerahealth/rtk-query'
import {
  TextField as MuiTextField,
  TextFieldProps,
  ArrowDropDownIcon,
  formatCurrency,
} from '@valerahealth/ui-components'
import { Block } from '@mui/icons-material'
import { Star as StarIcon } from '@valerahealth/ui-components/icons'
import { useTranslation } from '../../locales'
import {
  FormPatientApptMeta,
  useFormPatientApptMeta,
} from './patientApptHelpers'
import {
  PatientAppointmentMetaReturnType,
  PatientAppointmentMetaPatientInsurance,
} from '../PatientAppointmentMeta/hooks'

const TextField = (textFieldProps: TextFieldProps) => {
  const { inputProps, value } = textFieldProps
  return (
    <MuiTextField
      variant="standard"
      fullWidth
      {...textFieldProps}
      inputProps={{
        readOnly: true,
        ...inputProps,
      }}
      value={value || ' '}
    />
  )
}

type AppointmentBillingAccordionProps = Pick<
  PatientAppointmentMetaReturnType,
  'creditCardOnFile'
> &
  PatientAppointmentMetaPatientInsurance & {
    type?: InsuranceOrdinality
  }
const AppointmentBillingAccordion = ({
  type,
  planName,
  eligibilityVerified,
  billingProvider,
  creditCardOnFile,
  coPay,
  coinsurance,
  familyDeductible,
  deductible,
  isHighDeductible,
  outOfNetwork,
}: AppointmentBillingAccordionProps) => {
  const { t } = useTranslation()
  return (
    <Accordion defaultExpanded={type === InsuranceOrdinality.Primary}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Stack direction="row" alignItems="center" gap={1}>
          {type === InsuranceOrdinality.Primary && (
            <Tooltip title="Primary Insurance">
              <StarIcon fontSize="small" />
            </Tooltip>
          )}
          {outOfNetwork && (
            <Tooltip title="Out of Network">
              <Block fontSize="small" />
            </Tooltip>
          )}
          <Box>
            <Typography sx={{ mr: 'auto' }}>{planName}</Typography>
            {!eligibilityVerified && (
              <Typography
                fontSize="0.6rem"
                color={(theme) => theme.palette.warning.main}
              >
                Not Verified
              </Typography>
            )}
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          justifyContent="space-between"
          alignItems="top"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              label={t('billingProvider')}
              value={outOfNetwork ? '' : billingProvider?.name}
              error={!outOfNetwork && !billingProvider?.isActive}
              helperText={
                !outOfNetwork &&
                !!billingProvider?.name &&
                !billingProvider.isActive
                  ? 'Billing provider is not active with insurance plan.'
                  : ''
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t('coPay')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              error={!creditCardOnFile}
              helperText={!creditCardOnFile ? 'Credit Card Missing' : undefined}
              value={
                coPay &&
                formatCurrency(coPay, {
                  trailingZeroDisplay: 'stripIfInteger',
                  currencySign: undefined,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('coinsurance')}
              value={
                coinsurance === 0
                  ? '0 %'
                  : coinsurance
                  ? `${coinsurance} %`
                  : null
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t('deductible')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              error={!!isHighDeductible}
              helperText={isHighDeductible ? 'High Deductible' : undefined}
              value={
                deductible &&
                formatCurrency(deductible?.toString(), {
                  trailingZeroDisplay: 'stripIfInteger',
                  currencySign: undefined,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('familyDeductible')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={
                familyDeductible &&
                formatCurrency(familyDeductible?.toString(), {
                  trailingZeroDisplay: 'stripIfInteger',
                  currencySign: undefined,
                })
              }
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const AppointmentBillingContent = ({
  selfPay,
  providerSupervisor,
  patientInsurance,
  creditCardOnFile,
}: FormPatientApptMeta) => {
  const { t } = useTranslation()

  return (
    <Stack gap={2} p={2}>
      <TextField
        label={t('paymentType')}
        value={selfPay ? 'Self-Pay' : 'Insurance'}
      />
      {selfPay ? (
        <TextField label={t('supervisor')} value={providerSupervisor} />
      ) : (
        <>
          {!patientInsurance ||
            (Object.keys(patientInsurance).length === 0 && (
              <Typography>
                The Patient is missing insurance information.
              </Typography>
            ))}
          <Box>
            {/** Primary */}
            {patientInsurance?.[InsuranceOrdinality.Primary] && (
              <AppointmentBillingAccordion
                {...{
                  ...patientInsurance[InsuranceOrdinality.Primary],
                  creditCardOnFile,
                  type: InsuranceOrdinality.Primary,
                }}
              />
            )}
            {/** Secondary */}
            {patientInsurance?.[InsuranceOrdinality.Secondary] && (
              <AppointmentBillingAccordion
                {...{
                  ...patientInsurance[InsuranceOrdinality.Secondary],
                  creditCardOnFile,
                  type: InsuranceOrdinality.Secondary,
                }}
              />
            )}
            {/** Tertiary */}
            {patientInsurance?.[InsuranceOrdinality.Tertiary] && (
              <AppointmentBillingAccordion
                {...{
                  ...patientInsurance[InsuranceOrdinality.Tertiary],
                  creditCardOnFile,
                  type: InsuranceOrdinality.Tertiary,
                }}
              />
            )}
          </Box>
        </>
      )}
    </Stack>
  )
}

const AppointmentBilling = () => {
  const meta = useFormPatientApptMeta()

  const { treatmentId, providerId, appointmentDate, isAnyLoading } = meta

  if (!treatmentId || !providerId || !appointmentDate)
    return (
      <Typography m={2}>
        Please select Patient name, start date, and provider name to see billing
        details.
      </Typography>
    )

  return isAnyLoading ? (
    <LinearProgress sx={{ m: 1 }} />
  ) : (
    <AppointmentBillingContent {...meta} />
  )
}

export default AppointmentBilling
