import {
  LoadPatientBilling,
  patientRegistrationApi,
} from '@valerahealth/rtk-query'
import { TFunction, useTranslation } from '@valerahealth/ui-translation'
import { useMemo } from 'react'

const { useLoadPatientBillingQuery } = patientRegistrationApi
const checkVerified = (
  billingData: LoadPatientBilling | undefined,
  t: TFunction,
): { verified: boolean; message: string } => {
  if (!billingData)
    return { verified: false, message: t('Error loading billing information.') }
  const billing = billingData.loadPatientBilling
  if (billing?.selfPay) return { verified: true, message: 'Self-Pay.' }
  if (!billingData.loadPatientBilling)
    return { verified: false, message: t('Billing information not found.') }
  const message = []
  if (!billing?.fullConsentPackedSigned) message.push(t('Consent Form'))
  if (!billing?.creditCardOnFile) message.push(t('Credit Card'))
  if (!billing?.insurances || !billing?.insurances[0]!.eligibilityVerified)
    message.push(t('Insurance'))
  if (message.length === 0) return { verified: true, message: 'Verified!' }
  return { verified: false, message: `${message.join(', ')} Not Verified` }
}

export function usePatientVerification(treatmentId?: string | null) {
  const { t } = useTranslation()
  const { data: billingData, isLoading: isBillingLoading } =
    useLoadPatientBillingQuery(
      { input: { treatmentId } },
      { skip: !treatmentId },
    )
  const { verified, message } = useMemo(
    () => checkVerified(billingData, t),
    [billingData, t],
  )
  const isWarning = treatmentId && !verified && !isBillingLoading

  return { billingData, isBillingLoading, verified, message, isWarning }
}
