import { AnyAction } from 'redux'
import dotProp from 'dot-prop-immutable'
import { Treatment } from '@valerahealth/rtk-query'
import * as types from '../actions/actionTypes'
import initialState from './initialState'
import {
  getListByIdAndAllIds,
  getNotificationMessage,
  searchInCaseload,
} from '../../components/utilities'
import * as treatmentUtils from '../../components/utilities/treatments'
import { setCaseloadMask } from '../../api/storageConfig'
import { caseloadStateEnum, pageSize } from '../../components/utilities/enums'
import { CaseLoadNotification } from './legacyRootState'

export default function caseloadReducer(
  state = initialState.caseload,
  action: AnyAction,
) {
  switch (action.type) {
    case types.LOAD_TREATMENTS_SUCCESS: {
      let treatments
      if (action.pagination.pageNumber === 1) {
        treatments = action.treatments
      } else if (state.caseloadState === caseloadStateEnum.ACTIVE) {
        if (state.allTreatmentsAreSelected) {
          treatments = (state.treatments || []).concat([
            ...action.treatments.map((t: Treatment) => {
              return {
                ...t,
                selected: true,
              }
            }),
          ])
        } else {
          treatments = [...(state.treatments || []), ...action.treatments]
        }
      } else {
        treatments = [
          ...(state.discharged?.treatments || []),
          ...action.treatments,
        ]
      }

      const commonData = {
        pagination: action.pagination,
        filters: {
          ...state.filters,
          server: action.options,
        },
        treatmentCount: action.treatmentCount,
        cannotLoadMore: action.cannotLoadMore,
        allTreatmentsAreSelected:
          action.pagination.pageNumber === 1
            ? false
            : state.allTreatmentsAreSelected,
      }

      if (state.caseloadState === caseloadStateEnum.ACTIVE) {
        return {
          ...state,
          treatments,
          ...getListByIdAndAllIds(treatments),
          ...commonData,
          selectedTreatments:
            action.pagination.pageNumber === 1 ? [] : state.selectedTreatments,
        }
      }
      return {
        ...state,
        discharged: {
          treatments,
          ...getListByIdAndAllIds(treatments),
        },
        ...commonData,
      }
    }
    case types.DISCHARGE_PATIENT: {
      const tempState = dotProp.delete(state, `byId.${action.id}`)
      const tempState2 = dotProp.set(tempState, `allIds`, (ids: string[]) =>
        ids && ids.length ? ids.filter((id) => id !== action.id) : undefined,
      )

      return {
        ...tempState2,
        // goToCaseload: true,
        searchText: '',
      }
    }
    case types.RESET_GO_TO_CASELOAD:
      return {
        ...state,
        goToCaseload: false,
      }
    case types.RESTORE_PATIENT: {
      const copy = JSON.parse(JSON.stringify(state))
      delete copy.byId
      delete copy.allIds
      delete copy.discharged
      return copy
    }
    case types.LOAD_PENDING_TREATMENTS_SUCCESS:
      return {
        ...state,
        pending: getListByIdAndAllIds(action.treatments),
      }
    case types.REMOVE_PENDING_USER: {
      const tempState = dotProp.delete(state, `pending.byId.${action.id}`)
      const tempState2 = dotProp.set(
        tempState,
        `pending.allIds`,
        (ids: string[]) => ids.filter((id) => id !== action.id),
      )

      return {
        ...tempState2,
      }
    }
    case types.SET_FILTER_TREATMENT: {
      if (!action.loadingMore)
        return {
          ...state,
          filters: {
            ...state.filters,
            client: treatmentUtils.getFilters(action.numbers),
            server: state.filters.server || {},
          },
          searchText: '',
        }

      return state
    }
    case types.TREATMENT_SEARCH: {
      if (state.caseloadState !== caseloadStateEnum.LEAD) {
        return {
          ...state,
          searchText: action.text,
        }
      }
      return {
        ...state,
        searchText: action.text,
        pending: {
          ...state.pending,
          displayedIds: action.text.length
            ? searchInCaseload(state.pending, action.text)
            : undefined,
        },
      }
    }
    case types.PENDING_PATIENT_REGISTERED: {
      const tempState = dotProp.set(state, `pending.allIds`, (ids: string[]) =>
        ids.filter((id) => id !== action.id),
      )

      const tempState2 = dotProp.set(
        tempState,
        `notifications.list`,
        (ids: CaseLoadNotification[]) =>
          ids.filter((notification) => notification.id !== action.id),
      )

      return dotProp.delete(tempState2, `pending.byId.${action.id}`)
    }
    case types.SORT_PENDING_LIST: {
      let asc = true
      if (action.key === state.sortKey) asc = !state.sortDirection
      return {
        ...state,
        sortKey: action.key,
        sortDirection: asc,
        pending: {
          ...state.pending,
          allIds: (state.pending?.allIds || [])
            .map((a) => a)
            .sort((id1, id2) => {
              let a
              let b
              if (asc) {
                a = state.pending?.byId?.[id1]?.[
                  action.key as keyof Treatment
                ] as string
                b = state.pending?.byId?.[id2]?.[
                  action.key as keyof Treatment
                ] as string
              } else {
                b = state.pending?.byId?.[id1]?.[
                  action.key as keyof Treatment
                ] as string
                a = state.pending?.byId?.[id2]?.[
                  action.key as keyof Treatment
                ] as string
              }

              if (a < b) return -1
              if (a > b) return 1
              return 0
            }),
        },
      }
    }
    case types.TOGGLE_FILTER_MENU:
      return { ...state, displayFilterMenu: !state.displayFilterMenu }
    case types.SET_PENDING_NOTIFICATION_SEEN:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: state.notifications.list?.filter(
            (n) => n.id !== action.treatmentId,
          ),
        },
      }
    case types.SET_CHANNEL_SEEN: {
      let updatedById = {
        ...state.byId,
      }

      if (state.byId && state.byId[action.treatmentId]) {
        updatedById = {
          ...updatedById,
          [action.treatmentId]: {
            ...updatedById[action.treatmentId],
            channel: { count: 0, unread: [] },
          },
        }
      }

      return {
        ...state,
        byId: updatedById,
        notifications: {
          ...state.notifications,
          list: state.notifications.list
            ? state.notifications.list.filter(
                (n) => n.id !== action.treatmentId,
              )
            : undefined,
        },
      }
    }
    case types.CHANGE_SHOW_MENU_VALUE: {
      const showValues = {
        ...state.showValues,
        [action.showMenuKey]:
          !state.showValues[
            action.showMenuKey as keyof typeof state.showValues
          ],
      }

      setCaseloadMask(showValues)

      return {
        ...state,
        showValues,
      }
    }
    case types.RESOLVE_ALERTS_SUCCESS:
      if (state.byId && state.byId[action.treatmentId]) {
        return dotProp.set(
          state,
          `byId.${action.treatmentId}.unresolvedAlertCount`,
          state.byId?.[action.treatmentId]?.unresolvedAlertCount ||
            0 - action.ids.length,
        )
      }

      return state
    case types.LOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          pagination: action.pagination,
          cannotLoadMore: action.cannotLoadMore,
          list:
            state.notifications.list && action.nextPage
              ? [...state.notifications.list, ...action.notifications]
              : action.notifications,
        },
      }
    case types.LOADING_DATA_FOR_TREATMENT: {
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          [action.treatmentId]: true,
        },
      }
    }
    case types.STOP_LOADING_DATA_FOR_TREATMENT: {
      return {
        ...state,
        loadingData: {
          ...state.loadingData,
          [action.treatmentId]: false,
        },
      }
    }
    case types.NOTIFICATION_RECEIVED: {
      const { treatmentId } = action.data
      const data = action.data.channelItem

      let flag = false
      const addedUnread = {
        domainEvent: data.domainEvent,
        name: treatmentUtils.getProfileName(data.sender),
        text: getNotificationMessage(data),
        ts: data.ts,
        custom: data.attachment?.item?.custom,
      }

      const notifications = state.notifications.list
        ? state.notifications.list.map((notification) => {
            if (notification.id !== treatmentId) return notification

            flag = true
            return {
              ...notification,
              count: notification.count + 1,
              unread: [addedUnread, notification.unread[0]],
            }
          })
        : []

      // notifications dont yet exist for a given treatment
      if (!flag) {
        const t = state.byId && state.byId[treatmentId]
        if (!t) {
          // important
          if (!action.treatment) {
            return state
          }
        }

        const profile = t?.profile || action.treatment?.profile
        notifications.unshift({
          count: 1,
          id: treatmentId,
          profile,
          unread: [addedUnread],
          unresolvedAlertCount:
            t?.unresolvedAlertCount || action.treatment?.unresolvedAlertCount,
        })
      }

      const temp = {
        ...state,
        notifications: {
          ...state.notifications,
          list: notifications,
        },
      }

      let tempState
      let tempState2
      if (action.treatment) {
        tempState = dotProp.set(temp, `byId.${treatmentId}`, action.treatment)
        tempState2 = dotProp.set(tempState, `allIds`, [
          action.treatment.id,
          ...(state.allIds || []),
        ])
      } else {
        tempState = dotProp.set(
          temp,
          `byId.${treatmentId}`,
          (treatment: Treatment) => {
            return {
              ...treatment,
              id: treatment.id || treatmentId,
              channel: treatment.channel
                ? {
                    count: (treatment.channel.count || 0) + 1,
                    unread: [addedUnread, treatment.channel.unread],
                  }
                : {
                    count: 1,
                    unread: [addedUnread],
                  },
            }
          },
        )

        // set treatment on top
        tempState2 = dotProp.set(tempState, `allIds`, (ids?: string[]) => {
          if (!Array.isArray(ids)) return [treatmentId]

          const updated = ids.filter((id) => id !== treatmentId)
          return [treatmentId, ...updated]
        })
      }

      if (data.domainEvent === 'alert_created') {
        let alertNum
        if (state.byId?.[treatmentId]) {
          alertNum = state.byId[treatmentId]!.unresolvedAlertCount || 0
        } else {
          alertNum = action.treatment.unresolvedAlertCount || 0
        }

        return dotProp.set(
          tempState2,
          `byId.${treatmentId}.unresolvedAlertCount`,
          alertNum + 1,
        )
      }

      return tempState2
    }
    case types.SELECT_ALL_TREATMENTS:
      return treatmentUtils.toggleSelectTreatment(state, true)
    case types.UNSELECT_ALL_TREATMENTS:
      return treatmentUtils.toggleSelectTreatment(state, false)
    case types.UNSELECT_ALL_SELECTED_TREATMENTS:
      return {
        ...treatmentUtils.toggleSelectTreatment(state, false),
        selectedTreatments: [],
      }
    case types.CHANGE_CASELOAD_STATE:
      return {
        ...state,
        caseloadState: action.value,
        displayFilterMenu: false,
        searchText: '',
        cannotLoadMore: false,
        allIds: undefined,
        byId: undefined,
        treatments: undefined,
        discharged: undefined,
        filters: {
          client: undefined,
          server: undefined,
        },
        pagination: {
          pageSize: pageSize.CASELOAD,
          pageNumber: 1,
          // sort: {
          //   by: "last_name",
          //   order: "ascending"
          // }
        },
        pending: state.pending
          ? {
              ...state.pending,
              displayedIds: undefined,
            }
          : undefined,
      }
    case types.TOGGLE_SELECT_TREATMENT_ROW: {
      if (state.caseloadState === caseloadStateEnum.ACTIVE) {
        const tempState = dotProp.set(
          state,
          `byId.${action.id}.selected`,
          !state.byId?.[action.id]!.selected,
        )

        const selectedTreatments = tempState.selectedTreatments || []
        const isSelected = tempState.byId[action.id].selected

        const tempState2 = {
          ...tempState,
          treatments: tempState.treatments.map(
            (t: Treatment & { selected?: boolean }) => {
              if (t.id !== action.id) return t

              return {
                ...t,
                selected: isSelected,
              }
            },
          ),
        }

        let excluded = tempState2.excluded || []

        if (isSelected) {
          if (tempState2.allTreatmentsAreSelected && tempState2.excluded) {
            excluded = tempState2.excluded?.filter(
              (a: string) => a !== action.id,
            )
          }

          return {
            ...tempState2,
            selectedTreatments: [...selectedTreatments, action.id],
            excluded,
          }
        }

        if (tempState2.allTreatmentsAreSelected) {
          excluded = [...excluded, action.id]
        }

        return {
          ...tempState2,
          // allTreatmentsAreSelected: false,
          selectedTreatments: selectedTreatments.filter((t: string) => {
            return t !== action.id
          }),
          excluded,
        }
      }
      const tempState = dotProp.set(
        state,
        `discharged.byId.${action.id}.selected`,
        !state.discharged?.byId?.[action.id]!.selected,
      )

      return dotProp.set(tempState, `selectedDischargedTreatments`, () => {
        const arr = []
        const ids = tempState.discharged.allIds
        for (let index = 0; index < ids.length; index++) {
          const element = tempState.discharged.byId[ids[index]]
          if (element.selected) arr.push(ids[index])
        }

        return arr
      })
    }
    case types.UPDATE_PROFILE_SUCCESS:
      if (state.discharged) {
        return dotProp.set(state, `discharged.byId.${action.id}.profile`, {
          firstName: action.profile.firstName,
          lastName: action.profile.lastName,
          picture: state.discharged?.byId?.[action.id]?.profile?.picture,
        })
      }
      return state

    case types.ADD_TREATMENT_SUCCESS: {
      if (!state.allIds) {
        return state
      }

      return {
        ...state,
        allIds: [...state.allIds, action.treatment.id],
        byId: {
          ...state.byId,
          [action.treatment.id]: action.treatment,
        },
      }
    }
    case types.REGISTER_TO_EMR:
      if (state.byId?.[action.treatmentId]) {
        return dotProp.set(state, `byId.${action.treatmentId}`, {
          ...state.byId[action.treatmentId],
          emrStatus: action.status,
        })
      }
      return state

    case types.CLEAR_CASELOAD:
      return initialState.caseload
    default:
      return state
  }
}
