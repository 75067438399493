import { useMemo } from 'react'
import {
  practiceMgrApi,
  ProviderMinimalFragment,
} from '@valerahealth/rtk-query'
import { MenuItem, ListItemText, Checkbox } from '@mui/material'
import { providerToFullName } from '../../utils'
import { Combobox, ValidationRule, type ComboboxProps } from '../controls'
import { useTranslation } from '../../utils/hooks'

export type ProviderSelectProps<
  Multiple extends boolean | undefined = undefined,
> = {
  name: string
  label: string
  multiple?: Multiple
  filterOptions?: (data: ProviderMinimalFragment[]) => ProviderMinimalFragment[]
  sortOptions?: (
    a: ProviderMinimalFragment,
    b: ProviderMinimalFragment,
  ) => number
  getOptionLabel?: ((option: ProviderMinimalFragment) => string) | undefined
  required?: string | ValidationRule<boolean>
  isLoading?: boolean
} & Pick<
  ComboboxProps<ProviderMinimalFragment, Multiple>,
  | 'setValueAs'
  | 'parseValue'
  | 'fullWidth'
  | 'disabled'
  | 'textFieldProps'
  | 'helperText'
  | 'renderOption'
  | 'readOnly'
>

export const defaultProviderSelectSortFn = (
  a: ProviderMinimalFragment,
  b: ProviderMinimalFragment,
) => {
  const one =
    a.display?.expandedName?.toLowerCase() || a.firstName?.toLowerCase() || ''
  const two =
    b.display?.expandedName?.toLowerCase() || b.firstName?.toLowerCase() || ''
  return one > two ? 1 : one === two ? 0 : -1
}

export default function ProviderSelect<
  Multiple extends boolean | undefined = undefined,
>({
  name,
  label,
  multiple,
  filterOptions = (data) => data || [],
  sortOptions = defaultProviderSelectSortFn,
  required,
  parseValue,
  fullWidth = true,
  getOptionLabel,
  isLoading,
  ...rest
}: ProviderSelectProps<Multiple>) {
  const getAllPracticeManagerProviders =
    practiceMgrApi.useGetProvidersMinimalQuery()
  const [allOptions, filteredOptions]: [
    ProviderMinimalFragment[],
    ProviderMinimalFragment[],
  ] = useMemo(() => {
    const data: ProviderMinimalFragment[] =
      getAllPracticeManagerProviders?.data?.getProviders || []
    const prefilteredOptions = filterOptions(data)
      .slice()
      .slice()
      .sort(sortOptions)
    return [data, prefilteredOptions]
  }, [
    filterOptions,
    sortOptions,
    getAllPracticeManagerProviders?.data?.getProviders,
  ])

  const [t] = useTranslation()

  const isOptionsLoading = getAllPracticeManagerProviders?.isLoading || false
  const isError = getAllPracticeManagerProviders?.isError || false

  return (
    <Combobox
      fullWidth={fullWidth}
      label={label}
      name={name}
      options={filteredOptions}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={(provider) =>
        (getOptionLabel && getOptionLabel(provider)) ||
        providerToFullName(provider)
      }
      isOptionEqualToValue={(option, value) => option._id === value._id}
      parseValue={
        // ensures that if a value gets filtered out as a selectable option, they still displayed.
        parseValue ? (value) => parseValue(value, allOptions) : undefined
      }
      required={required}
      loading={isLoading || isOptionsLoading}
      renderOption={(spread, option, { selected }) => (
        <MenuItem {...spread} key={option._id} selected={selected}>
          {multiple && (
            <Checkbox key={option._id} readOnly checked={selected} />
          )}
          <ListItemText key={option._id} primary={providerToFullName(option)} />
        </MenuItem>
      )}
      disableClearable={false}
      {...rest}
      helperText={isError ? t('optionsLoadFail') : rest.helperText}
    />
  )
}
