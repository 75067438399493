import {
  reducerPath,
  type AuthState,
  Permission,
  type AuthSession,
} from '@valerahealth/redux-auth'
import { type EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  type BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query'
import { type SerializedError } from '@reduxjs/toolkit'
import { GraphQLError, type DocumentNode } from 'graphql'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { ClientError } from 'graphql-request'

export type ServiceIntantiationParameters = {
  /** the environment specific origin to interact with. I.E. provider-api.rnd.valerahealth.com */
  origin: string
  /** takes the root redux state and returns the access token, default value assumes you're using redux-auth state, but can be overriden if we want to use this in an app that stores an access token differently */
  getAccessToken?: (state: any) => string | null | undefined
}

export function sortByStateCodeCompareFn<
  T extends { name: string; stateCode?: string | null },
>(a: T, b: T): number {
  return (
    (a.stateCode || '').localeCompare(b.stateCode || '', 'en', {
      sensitivity: 'base',
    }) || a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
  )
}

export function sortByStateCode<
  T extends { name: string; stateCode?: string | null },
>(plans: T[]): T[] {
  return plans.sort(sortByStateCodeCompareFn)
}

/* returns a function that expects an {origin, getAccessToken} argument. This package is environment agnositc so when we use in API in an application, the applicaiton will need to provide the environment specific values. */
export function createService<T>(
  createApiParameters: (params: Required<ServiceIntantiationParameters>) => T,
) {
  return (args: ServiceIntantiationParameters) =>
    createApiParameters({
      ...args,
      getAccessToken:
        args.getAccessToken ||
        ((state: any) =>
          (state as Record<typeof reducerPath, AuthState>).auth.session
            ?.accessToken.jwt),
    })
}

export type BuildType = EndpointBuilder<
  BaseQueryFn<
    {
      document: string | DocumentNode
      variables?: any
    },
    unknown,
    ErrorResponse,
    Partial<Pick<ClientError, 'request' | 'response'>>,
    {}
  >,
  never,
  never
>

/* expected export from graphql-codegen */
export const api = {
  injectEndpoints<T>({ endpoints }: { endpoints: (build: BuildType) => T }) {
    return endpoints
  },
}

type ErrorType =
  | FetchBaseQueryError
  | SerializedError
  | {
      data: {
        message?: string
      }
    }

export const processError = ({ error }: { error: ErrorType }) => {
  if (
    'data' in error &&
    typeof error.data === 'object' &&
    error.data &&
    'message' in error.data &&
    typeof error.data.message === 'string'
  ) {
    return error.data.message
  }
  if ('status' in error) {
    return error.status
  }
  if ('message' in error) {
    return error.message
  }
  if ('code' in error) {
    return error.code
  }
  return undefined
}

/** used in local development to mock an API call */
export function mockFetch<T>(data: T, wait = 500) {
  return new Promise<
    | {
        data?: T
      }
    | {
        error: FetchBaseQueryError | SerializedError
      }
  >((res) => {
    setTimeout(() => {
      res({ data })
    }, wait)
  })
}

export type EndpointPermissions = Record<
  string,
  Permission | Permission[] | undefined
>

export function initApplyPermissionheaders(
  /** each endpoint has a permission that allows one to act on anyones data, for example Provider_update would allow a user to update any providers data,
   *  example for that would be {updateProvider: Permissions.Provider_update}. If the user does not have this permission we include the id token in the request headers to
   *  the backend can do additional validation to see if the user should be able to use run the query */
  endpointPermissions: EndpointPermissions,
) {
  return (headers: Headers, session: AuthSession, endpoint: string) => {
    const permsForEndpoint = endpointPermissions[endpoint]
    let permCheck = false
    if (
      permsForEndpoint &&
      Array.isArray(permsForEndpoint) &&
      permsForEndpoint.every((perm) => session.user.permissions.includes(perm))
    ) {
      permCheck = true
    } else if (
      permsForEndpoint &&
      !Array.isArray(permsForEndpoint) &&
      session.user.permissions.includes(permsForEndpoint)
    ) {
      permCheck = true
    }

    if (!permCheck) {
      headers.set('x-id-token', session.idToken.jwt)
    }
    return headers
  }
}

/** https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library */
export function parseJwt<SuccessReturnType = any>(
  token?: string | null,
): SuccessReturnType | null {
  if (!token) return null
  try {
    return JSON.parse(
      decodeURIComponent(
        window
          .atob(token.split('.')[1]!.replace(/-/g, '+').replace(/_/g, '/'))
          .split('')
          .map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
          })
          .join(''),
      ),
    )
  } catch (e) {
    console.log(e)
    return null
  }
}

// be careful not to import that function with the same name from lodash. that one works for js regexp, not ES regexp.
export function escapeRegExp(s?: string) {
  if (!s) return ''
  return s.replace(/[.+<>{}[\]*()?"~&|@#\\]/g, '\\$&')
}

// for search operator, ES uses standard analyzer by default for string query,
// standard analyzer itself by default ignores all special characters in text before parsing
// them into pieces. Based on how standard analyzer works, it's better just to ignore all reserved chars rather than escape them.
export function removeSpecialCharForStringQuery(s?: string, r?: string) {
  if (!s) return ''
  return s.replace(/[+\-@'=&|><!(){}[\]^"~*?:\\/]/g, r || ' ')
}

// If for some reason, we have to perverse special character while searching,
// use escapeStringQuery and remember to set analyzer to 'whitespace' or whatever your customized one.
// Reference docs about analyzer
// https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-analyzers.html
export function escapeStringQuery(s?: string) {
  if (!s) return ''
  return s.replace(/[+\-@'=&|!(){}[\]^"~*?:\\/]/g, '\\$&').replace(/[><]/g, '') // <> those two can never be escaped due to ES restriction
}

export const GQLErrorCodes = [
  'ServerErrorException',
  'UnauthorizedException',
  'BadRequestException',
  'NotFoundException',
  'ServerBusyException',
] as const

export type GQLErrorCode = (typeof GQLErrorCodes)[number]

export interface GQLServerErrorResponse extends ErrorResponse {
  name: GQLErrorCode
}
export type GQLErrorResponse =
  | GQLServerErrorResponse
  | SerializedError
  | undefined

const checkCode = (code: any): code is GQLErrorCode =>
  GQLErrorCodes.some((v): v is GQLErrorCode => v === code)

export const handleGQLErrors = (error: ClientError) => {
  console.error(error.response)
  const e = error.response.errors?.[0] as
    | (GraphQLError & {
        errorType?: string
      })
    | undefined
  const errorType = e?.errorType
  const message = e?.message ?? ''

  const response: GQLServerErrorResponse = {
    name: checkCode(errorType)
      ? errorType
      : // Graqhql itself will throw validation errors on schema without an errorType
      ['invalid value', 'FieldUndefined'].some((v) => message.includes(v)) ||
        errorType === 'ValidationError' // from zod/joi
      ? 'BadRequestException'
      : 'ServerErrorException',
    message,
    stack: error.stack ?? '',
  }
  return response
}
