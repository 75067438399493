import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react'

export type ReferenceListValue = {
  label: string
  value: string
}

export const referenceDataApi = createApi({
  reducerPath: 'referenceDataApi',
  keepUnusedDataFor: 86400, // 1 day
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cdn.valerahealth.com/assets',
  }),
  endpoints: (build) => ({
    getReferralSources: build.query<ReferenceListValue[], void>({
      query: () => ({ url: '/referral-sources.json' }),
    }),
  }),
})

export type ReferenceDataApiTYpe = typeof referenceDataApi
