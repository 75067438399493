import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Popup,
  Form as FormComponent,
  InformationHover,
} from '@valerahealth/ui-core'
import {
  toZonedTime,
  fromZonedTime,
  isSameDay,
  SYSTEM_TIMEZONE,
} from '@valerahealth/ui-components/utils/date'
import Trash from '../../../images/trash.svg'
import dStrings from '../../../strings.json'
import { getProfileName, getTreatment } from '../../utilities/treatments'
import './style.css'
import {
  addAppointment,
  editAppointment,
  deleteAppointment,
  getSupportiveContacts,
} from '../../../redux/actions/treatmentRoom'
import { getAppointment } from '../../../api/treatmentAPI'
import {
  addUserMessage,
  displayConfirmation,
} from '../../../redux/actions/globalActions'
import { handleApiFailed } from '../../../api/apiUtils'

function AddEditAppointement({
  profileName,
  readOnly,
  appointment,
  supportiveContacts,
  editAppointment,
  addAppointment,
  treatmentId,
  treatment,
  deleteAppointment,
  onClosed,
  displayConfirmation,
  addUserMessage,
  getSupportiveContacts,
}) {
  const [today, setToday] = useState()
  // const [contactsList, setContactsList] = useState();
  // const [selectedContacts, setSelectedContact] = useState();

  const getReminderSC = (app) => {
    if (
      supportiveContacts &&
      supportiveContacts.length &&
      app.supportiveContacts &&
      app.supportiveContacts.length
    ) {
      const existingsSCIds = supportiveContacts.map((c) => c.id)
      for (let i = 0; i < app.supportiveContacts.length; i++) {
        if (~existingsSCIds.indexOf(app.supportiveContacts[i])) return true
      }
    }

    return false
  }

  useEffect(() => {
    setToday(new Date())

    if (appointment.id) {
      getAppointment(treatmentId, appointment.id)
        .then((appData) => {
          const date = new Date(appData.date)

          const time = date
            ? `${date.getHours()}:${date.getMinutes()}`
            : undefined

          setAppointmentData({
            ...appData,
            date,
            start: time,
            reminderToSC: getReminderSC(appData),
          })
        })
        .catch(handleApiFailed)
    }
  }, [])

  useEffect(() => {
    if (!supportiveContacts) getSupportiveContacts(treatmentId)
  }, [supportiveContacts])

  const newState = !appointment.id
  const [appointmentData, setAppointmentData] = useState({
    ...appointment,
    reminderToSC: getReminderSC(appointment),
  })
  const [displayError, setDisplayError] = useState(false)

  const isDataInvalid = () => {
    return (
      !appointmentData.date ||
      !appointmentData.start ||
      !appointmentData.duration ||
      !appointmentData.location ||
      !appointmentData.description
    )
  }

  // const { Option } = components;
  // const ContactsOption = (props) => {
  //   return (
  //     <Option {...props}>
  //       <div className="plans-options-container">
  //         <input
  //           type="checkbox"
  //           checked={selectedContacts[props.data.value]}
  //           onChange={() => {}}
  //         />
  //         <div className="menu-list-item-icon">{props.data.picture}</div>
  //         <div className="plan-options-title">{props.data.label}</div>
  //       </div>
  //     </Option>
  //   );
  // };

  const getFields = () => {
    const fields = [
      {
        key: 'date',
        placeholder: dStrings.meetingDay,
        text: dStrings.date,
        disabled: readOnly,
        type: 'date',
        min: today,
        required: true,
      },
      {
        key: 'start',
        text: dStrings.startTime,
        disabled: readOnly,
        type: 'time',
        options: {
          minTime:
            appointmentData.date instanceof Date &&
            isSameDay(appointmentData.date, today) &&
            today,
        },
        required: true,
        endText: <InformationHover text={dStrings.appTimezone} />,
      },
      {
        key: 'duration',
        text: dStrings.duration,
        disabled: readOnly,
        type: 'number',
        placeholder: dStrings.durationInMinutes,
        min: 5,
        required: true,
      },
      {
        key: 'location',
        text: dStrings.location,
        disabled: readOnly,
        type: 'text',
        placeholder: dStrings.meetingPlace,
        required: true,
      },
      {
        key: 'person',
        text: dStrings.person,
        disabled: readOnly,
        type: 'text',
        placeholder: dStrings.personMeeting,
      },
      {
        key: 'clinicName',
        text: dStrings.clinicName,
        disabled: readOnly,
        type: 'text',
        placeholder: dStrings.whatIsClinicName,
      },
      {
        key: 'description',
        text: dStrings.description,
        disabled: readOnly,
        type: 'textarea',
        placeholder: dStrings.appointAbout,
        required: true,
      },
    ]

    if (supportiveContacts && supportiveContacts.length) {
      fields.push({
        key: 'reminderToSC',
        text: dStrings.sendReminderToAC,
        disabled: readOnly,
        type: 'switch',
      })
    }

    return fields
  }

  return (
    <Popup
      className="appointment-popup"
      onClosed={onClosed}
      title={
        !readOnly ? (
          <span className="appointment-popup-title">
            {(newState
              ? dStrings.scheduleNewAppointmentFor
              : dStrings.editAppointmentFor) + profileName}{' '}
            {!newState && (
              <Trash
                className="remove-appointment-icon"
                onClick={() => {
                  displayConfirmation({
                    details: {
                      title: dStrings.removeAppointment,
                      content: (
                        <div style={{ width: '330px' }}>
                          {dStrings.deleteAppointmentConfirmation}
                        </div>
                      ),
                    },
                    callback: () => {
                      deleteAppointment(appointmentData.id).then(() => {
                        onClosed && onClosed()
                      })
                    },
                  })
                }}
              />
            )}{' '}
          </span>
        ) : (
          dStrings.appointmentDetails
        )
      }
      buttons={
        !readOnly
          ? [
              {
                text: dStrings.cancel,
                onClick: onClosed,
                type: 'link',
              },
              {
                text: newState ? dStrings.send : dStrings.update,
                onClick: () => {
                  if (isDataInvalid()) {
                    setDisplayError(true)
                    return
                  }

                  const time = appointmentData.start.split(':')
                  let d = new Date(appointmentData.date)
                  d.setHours(time[0], time[1], 0, 0)

                  if (d < new Date()) {
                    addUserMessage('error', dStrings.pastDateError)
                    return
                  }

                  const treatmentTZ = treatment?.profile?.timeZone

                  if (treatmentTZ && treatmentTZ !== SYSTEM_TIMEZONE) {
                    d = fromZonedTime(d, treatmentTZ)
                  }

                  if (newState)
                    addAppointment(
                      {
                        description: appointmentData.description,
                        date: d.toISOString(),
                        duration: appointmentData.duration,
                        location: appointmentData.location,
                        person: appointmentData.person || undefined,
                        clinicName: appointmentData.clinicName || undefined,
                        supportiveContacts:
                          appointmentData.reminderToSC && supportiveContacts
                            ? supportiveContacts.map((contact) => contact.id)
                            : undefined,
                      },
                      () => {
                        onClosed && onClosed()
                      },
                    )
                  else
                    editAppointment(
                      {
                        description: appointmentData.description,
                        date: d.toISOString(),
                        duration: appointmentData.duration,
                        location: appointmentData.location,
                        clinicName: appointmentData.clinicName || undefined,
                        person: appointmentData.person || undefined,
                        timeZone: appointmentData.timeZone || SYSTEM_TIMEZONE,
                        supportiveContacts: appointmentData.reminderToSC
                          ? supportiveContacts.map((contact) => contact.id)
                          : undefined,
                      },
                      appointmentData.id,
                    )
                      .then(() => {
                        onClosed && onClosed()
                      })
                      .catch(handleApiFailed)
                },
                type: 'primary',
              },
            ]
          : [
              {
                text: dStrings.ok,
                onClick: onClosed,
                type: 'primary',
              },
            ]
      }
    >
      <FormComponent
        data={appointmentData}
        displayInvalid={displayError}
        handleDataChange={(newData) => {
          setAppointmentData(newData)
        }}
        fields={getFields()}
      />
    </Popup>
  )
}

AddEditAppointement.propTypes = {}

function mapStateToProps(state, ownProps) {
  const treatment = getTreatment(state) || {}

  const { appointment } = ownProps
  let date = appointment.date ? new Date(appointment.date) : undefined

  if (appointment.timeZone && appointment.timeZone !== SYSTEM_TIMEZONE) {
    date = toZonedTime(date, appointment.timeZone)
  }

  const time = date ? `${date.getHours()}:${date.getMinutes()}` : undefined

  return {
    treatmentId: treatment.id,
    supportiveContacts: treatment.supportiveContacts,
    profileName: getProfileName(treatment.profile),
    readOnly: ownProps.readOnly,
    appointment: !Object.keys(appointment).length
      ? {}
      : {
          ...appointment,
          date,
          start: time,
        },
    onClosed: ownProps.onClosed,
    treatment,
  }
}

const mapDispatchToProps = {
  editAppointment,
  addAppointment,
  deleteAppointment,
  displayConfirmation,
  addUserMessage,
  getSupportiveContacts,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAppointement)
