import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Popup } from '@valerahealth/ui-core'
import AddEditItem from '../AddEditItem'
import dStrings from '../../../strings.json'

function EditItemPopup({
  item,
  bulkAction,
  handleClose,
  handleSave,
  title = dStrings.editItem,
  saveText,
  disableType,
}) {
  const [data, setData] = useState(
    item || {
      actionItem: {
        type: undefined,
        info: { templateId: undefined, text: '' },
      },
    },
  )

  return (
    <Popup
      title={title}
      onClosed={handleClose}
      buttons={[
        {
          text: 'Cancel',
          type: 'link',
          onClick: () => {
            handleClose()
          },
        },
        {
          text: saveText,
          type: 'primary',
          onClick: () => {
            handleSave({ ...data, position: item ? item.position : 0 })
          },
        },
      ]}
    >
      <AddEditItem
        item={data.actionItem}
        bulkAction={bulkAction}
        disableType={disableType}
        handleSaveItem={(itemData) => {
          setData(itemData)
        }}
      />
    </Popup>
  )
}

EditItemPopup.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  saveText: PropTypes.string,
  disableType: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
}

export default EditItemPopup
