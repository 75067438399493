import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Slide } from '@mui/material'
import { SaveButton, type SaveButtonProps } from './SaveButton'

type FloatingSaveButtonProps = {
  boxSx?: any
} & SaveButtonProps

export function FloatingSaveButton({
  sx,
  boxSx,
  ...props
}: FloatingSaveButtonProps) {
  const { formState } = useFormContext()

  return (
    <Slide
      direction="up"
      in={!!Object.keys(formState?.dirtyFields || {}).length}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          zIndex: (theme) => theme.zIndex.fab,
          position: 'fixed',
          bottom: {
            xs: '1rem',
            sm: '3rem',
          },
          right: {
            xs: '1rem',
            sm: '3rem',
          },
          ...boxSx,
        }}
      >
        <SaveButton
          {...props}
          sx={{
            boxShadow: (theme) => theme.shadows[10],
            ...sx,
          }}
        />
      </Box>
    </Slide>
  )
}
