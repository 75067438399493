import { useState } from 'react'
import Select from 'react-select'
import dStrings from '../../../strings.json'
import SelectedElement from '../SelectedElement'

function DropdownIndicator() {
  return <span className="arrow-down dropdown-indicator" />
}

function getNoOptionMessage({ createTag, tagSearchValue, patientTags }) {
  return (
    <div className="no-diagnosis-container">
      <div className="no-diagnosis">{dStrings.noExistingTag}</div>
      {!!tagSearchValue.length && (
        <div
          className="create-new-diagnosis link-click small-font"
          onClick={() => {
            createTag(tagSearchValue)
            handleChange(
              patientTags ? [...patientTags, tagSearchValue] : [tagSearchValue],
            )
          }}
        >
          {dStrings.createNewTag}
          {' "'}
          <b>{tagSearchValue}</b>"
        </div>
      )}
    </div>
  )
}

function TagsSelect({
  styles,
  patientTags,
  handleChange,
  programTags,
  createTag,
}) {
  const [tagSearchValue, setTagSearchValue] = useState('')
  return (
    <div>
      <Select
        //   menuIsOpen={true}
        styles={styles}
        components={{ DropdownIndicator }}
        menuPlacement="top"
        closeMenuOnSelect={false}
        onChange={(t) => {
          if (!t || !t.length) return

          const tag = t[0].label
          const index = patientTags && patientTags.indexOf(tag)
          handleChange(
            patientTags
              ? index === -1
                ? [...patientTags, tag]
                : patientTags.filter((a) => a.label !== t[0].label)
              : [tag],
          )
        }}
        noOptionsMessage={() =>
          getNoOptionMessage({
            createTag,
            tagSearchValue,
            patientTags,
          })
        }
        placeholder={`<${dStrings.typeChooseTag}>`}
        onInputChange={(e) => {
          setTagSearchValue(e)
        }}
        value={null}
        isMulti
        options={
          programTags &&
          programTags.map((d, i) => {
            return {
              value: i,
              label: d,
            }
          })
        }
      />
      <section className="selected-element-container">
        {patientTags &&
          patientTags.map((tag, i) => {
            return (
              <SelectedElement
                key={i}
                onDelete={() => {
                  handleChange(patientTags.filter((t) => t !== tag))
                }}
                placeholder={tag}
              >
                {tag}
              </SelectedElement>
            )
          })}
      </section>
    </div>
  )
}

TagsSelect.propTypes = {}

export default TagsSelect
