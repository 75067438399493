// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-menu {
  display: flex;
  align-items: center;
}
.show-menu-icon-and-show {
  display: flex;
  align-items: center;
  min-width: 210px;
  align-self: baseline;
}
.show-menu-element {
  margin: 4px 0px 4px 10px;
  padding: 3px;
  padding-right: 10px;
  border: 1px solid #dadcdf;
  border-radius: 12px;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  font-size: 13px;
}
.show-menu-element.selected {
  border-color: #317b8c;
}
.show-menu .options-menu {
  width: 290px;
}

.sort-item-popup .option-item-icon {
  margin-right: 9px;
}

span.show-element-icon {
  margin-right: 6px;
}
.show-menu-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Caseload/Header/ShowMenu/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,wBAAwB;EACxB,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".show-menu {\n  display: flex;\n  align-items: center;\n}\n.show-menu-icon-and-show {\n  display: flex;\n  align-items: center;\n  min-width: 210px;\n  align-self: baseline;\n}\n.show-menu-element {\n  margin: 4px 0px 4px 10px;\n  padding: 3px;\n  padding-right: 10px;\n  border: 1px solid #dadcdf;\n  border-radius: 12px;\n  cursor: pointer;\n  white-space: nowrap;\n  display: inline-block;\n  font-size: 13px;\n}\n.show-menu-element.selected {\n  border-color: #317b8c;\n}\n.show-menu .options-menu {\n  width: 290px;\n}\n\n.sort-item-popup .option-item-icon {\n  margin-right: 9px;\n}\n\nspan.show-element-icon {\n  margin-right: 6px;\n}\n.show-menu-container {\n  display: flex;\n  flex-wrap: wrap;\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
