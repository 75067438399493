/* eslint no-unused-vars: "off" */
import React from 'react'
import { connect } from 'react-redux'
import MessageItem from './messageItem'
import * as globalAction from '../../../redux/actions/globalActions'
import './style.less'
import PropTypes from 'prop-types'
/**
 * Every item has {type: "{success/warning}", text: "..."}
 * */
function UserMessages({ messageItems, deleteMessageItem }) {
  if (!messageItems || !messageItems.length) {
    return <div />
  }
  return (
    <div className="user-message-pipe">
      {messageItems.length &&
        messageItems.map((item) => (
          <MessageItem
            type={item.type}
            text={item.text}
            key={item.id}
            id={item.id}
            deleteItem={() => {
              item.onClosed && item.onClosed()
              deleteMessageItem(item.id)
            }}
          />
        ))}
    </div>
  )
}

UserMessages.propTypes = {
  messageItems: PropTypes.array,
  deleteMessageItem: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    messageItems: state.global.userMessageArray,
  }
}

const mapDispatchToProps = {
  deleteMessageItem: globalAction.deleteUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMessages)
