import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableCell from './tableCell'
import { tableEditMode } from './table.domain'
import { useOptionsContext } from './optionsContext'
import OptionsMenu from '../OptionsMenu'
// import OptionMenuButton from "../OptionMenuButton/index";

export default function TableRow({
  data,
  header,
  editMode,
  handleDataChange,
  orderKey,
}) {
  const [editRow, setEditRow] = useState(editMode)
  const options = useOptionsContext()

  const handleChange = (key, value) => {
    if (editMode === tableEditMode.TABLE) {
      const newData = { ...data }
      newData[key] = value
      handleDataChange(newData)
    } else if (editMode === tableEditMode.ROW) {
      // TODO: implement
      setEditRow(!editRow)
    }
  }

  return (
    <tr
      className={options.rowClass || 'basic-row'}
      onClick={() => {
        options.onRowClick && options.onRowClick(data)
      }}
    >
      {header.map((h, index) => {
        return (
          <TableCell
            key={index}
            data={data}
            orderKey={orderKey}
            editMode={editMode && editRow}
            handleChange={handleChange}
            columnHeader={h}
          />
        )
      })}
      {options.rowMenu && (
        <td
          className="menu-cell basic-cell"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <OptionsMenu fields={options.rowMenu} data={data} />
        </td>
      )}
    </tr>
  )
}

TableRow.propTypes = {
  editMode: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  data: PropTypes.object.isRequired,
  header: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  orderKey: PropTypes.string,
}
