import React from 'react'
import Image404 from '../images/img_404.svg'

function PageNotFound() {
  return (
    <div className="page-not-found-container">
      <div className="page-not-found">
        <Image404 />
      </div>
    </div>
  )
}

export default PageNotFound
