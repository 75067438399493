import { ReactNode } from 'react'
import {
  checkPermission,
  Permission,
  selectUser,
} from '@valerahealth/redux-auth'
import { AppointmentFragment } from '@valerahealth/rtk-query'
import { FormProvider, useForm } from '@valerahealth/ui-components/form'
import { useReduxSelector } from '../../reducer'
import { FormType } from './formType'

export const AppointmentFormProvider = ({
  defaultValues,
  appointment,
  children,
}: {
  defaultValues: FormType
  appointment?: AppointmentFragment | null | undefined
  children: ReactNode
}) => {
  const canCreateAny = useReduxSelector((state) =>
    checkPermission(state, Permission.Appointment_Create),
  )
  const ownProviderId = useReduxSelector(selectUser)?.providerId || null
  const methods = useForm<FormType>({
    defaultValues: {
      ...defaultValues,
      // if editing an existing keep provider the same, if adding and you have the ability to create an appointment for any user use the default, else you only have create_own to have made it this far and use the users own provider id.
      providerId:
        appointment || canCreateAny ? defaultValues.providerId : ownProviderId,
    },
  })

  return <FormProvider {...methods}>{children}</FormProvider>
}
