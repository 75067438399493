import { forwardRef } from 'react'
import { styled, type Theme } from '@mui/material/styles'
import BaseSwitch from '@mui/material/Switch'
import { type SwitchProps as MuiSwitchProps } from '@mui/material/Switch'

export { default as switchClasses } from '@mui/material/Switch/switchClasses'
export * from '@mui/material/Switch/switchClasses'

const StyledSwitch = styled(BaseSwitch)(({ theme }: { theme: Theme }) => ({
  '& .MuiSwitch-thumb': {
    color: theme.palette.error.main,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.error.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    '& .MuiSwitch-thumb': {
      color: theme.palette.success.main,
    },
    '&+.MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))

export type SwitchProps = MuiSwitchProps & {
  colored?: boolean
}

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ colored, ...props }: SwitchProps, ref) => {
    return colored ? (
      <StyledSwitch {...props} ref={ref} />
    ) : (
      <BaseSwitch {...props} ref={ref} />
    )
  },
)

export default Switch
