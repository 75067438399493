import { callApi, getErrorMessage } from '../../utilities'
import { addUserMessage } from '../../globalActions'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import dStrings from '../../../../strings.json'
import { parseIntlPhoneNumber } from '@valerahealth/ui-components'

export const editAdditionalContact = (data, id, cb, silent) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'editAdditionalContact',
      hideLoader: silent,
      dispatch,
      call: () => {
        return treatmentAPI.updateSupportiveContact(
          getState().treatmentRoom.selectedId,
          id,
          data,
        )
      },
      successMessage: dStrings.contactUpdated,
      success: (contact) => {
        if (!silent)
          dispatch({
            type: types.UPDATE_SUPPORTIVE_CONTACT_SUCCESS,
            contact: { ...contact, phone: parseIntlPhoneNumber(contact.phone) },
          })

        cb?.()
      },
      error: (e) => {
        const key = getErrorMessage(e)
        const message =
          (key && key.message && dStrings.default[key.message]) ||
          dStrings.errorOccurred
        dispatch(addUserMessage('error', message))
      },
    })
  }
}

export const deleteAdditionalContact = (id) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'deleteAdditionalContact',
      dispatch,
      call: () => {
        return treatmentAPI.deleteAdditionalContact(
          getState().treatmentRoom.selectedId,
          id,
        )
      },
      successMessage: dStrings.contactDeleted,
      success: () => {
        dispatch({
          type: types.DELETE_SUPPORTIVE_CONTACT_SUCCESS,
          id,
        })
      },
    })
  }
}

export const createAdditionalContact = (data, cb) => {
  return (dispatch, getState) => {
    const state = getState()
    const { selectedId } = state.treatmentRoom
    return callApi({
      name: 'createAdditionalContact',
      dispatch,
      call: () => {
        return treatmentAPI.createAdditionalContact(selectedId, data)
      },
      successMessage: dStrings.contactCreated,
      success: (contact) => {
        dispatch({
          type: types.CREATE_SUPPORTIVE_CONTACT_SUCCESS,
          contact: { ...contact, phone: parseIntlPhoneNumber(contact.phone) },
        })

        cb?.()
      },
      error: (e) => {
        const key = getErrorMessage(e)
        const message =
          (key && key.message && dStrings.default[key.message]) ||
          dStrings.errorOccurred
        dispatch(addUserMessage('error', message))
      },
    })
  }
}

export const getSupportiveContacts = (treatmentId) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'getSupportiveContacts',
      dispatch,
      call: () => {
        return treatmentAPI.getSupportiveContacts(
          treatmentId || getState().treatmentRoom.selectedId,
        )
      },
      hideLoader: true,
      success: (contacts) => {
        console.log('contacts', contacts)
        dispatch({
          type: types.LOAD_SUPPORTIVE_CONTACT_SUCCESS,
          contacts: contacts.map((contact) => ({
            ...contact,
            phone: parseIntlPhoneNumber(contact.phone),
          })),
        })
      },
    })
  }
}
