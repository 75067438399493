import React, { useEffect, useState } from 'react'
import './style.less'
import { Popup } from '@valerahealth/ui-core'
import { getAlerts } from '../../../api/treatmentAPI'
import dStrings from '../../../strings.json'
import TreatmentAlertMessage from '../../TreatmentAlerts/TreatmentAlertMessage'
import { format } from '@valerahealth/ui-components/utils/date'

function ResolvedAlertsPopup({ treatmentId, itemId, handleClose }) {
  const [alerts, setAlerts] = useState()

  useEffect(() => {
    getAlerts(treatmentId, 'resolved', itemId).then((res) => {
      setAlerts(res)
    })
  }, [])

  if (!alerts) return <div />
  return (
    <Popup
      className="resolve-alerts-popup"
      onClosed={handleClose}
      title={
        alerts.length > 1
          ? // eslint-disable-next-line
            dStrings.resolvedAlerts.replace('${count}', alerts.length)
          : dStrings.resolvedAlert
      }
      buttons={[
        {
          text: dStrings.done,
          type: 'primary',
          onClick: handleClose,
        },
      ]}
    >
      <div className="resolved-alert-popup">
        <div className="resolved-date xsmall-font">
          {`${dStrings.resolvedOn} ${format(
            new Date(alerts[0].ts),
            'EEEE, MMM d, hh:mm a',
          )}`}
        </div>
        <div className="resolved-reason">
          <b>{dStrings.resolveReason}: </b>
          {alerts[0].resolve.description}{' '}
        </div>
        <div className="resolve-list">
          {alerts.map((alert) => {
            return (
              <TreatmentAlertMessage
                alert={alert}
                key={`${alert.ts}_${alert.title}`}
                displayEye
              />
            )
          })}
        </div>
      </div>
    </Popup>
  )
}

export default ResolvedAlertsPopup
