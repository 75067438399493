/**
 *       <div className="add-exception-popup">
        <FormComponent
          fields={[
            {
              text: "Status",
              key: "Status",
              type: "select",
              select: {
                true: "Open",
                false: "Closed"
              },
            },
            {
              text: "Text",
              key: "ClosedMessage",
              type: "text",
            },
            {
              key: "isDayRange",
              text: "Day Range",
              type: "switch",
            },
            {
              key: "days",
              text: "Day range",
              type: "fromTo"
            }
          ]}
        />
      </div>
 */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SwitchButton from '../SwitchButton'
import './style.css'
import DatePicker from '../DatePicker'
import dStrings from '../../strings.json'
import TimePicker from '../TimePicker'
import ReCAPTCHA from 'react-google-recaptcha'

export const FORM_TYPES = {
  text: 'text',
  number: 'number',
  select: 'select',
  date: 'date',
  time: 'time',
  range: 'range',
  switch: 'switch',
  textarea: 'textarea',
  special: 'special',
  title: 'title',
  checkbox: 'checkbox',
}

export default function FormComponent({
  fields,
  data = {},
  handleDataChange,
  displayInvalid = false,
  withRecaptcha,
  activeRecaptcha,
  handleExecuteRecaptcha,
  handleOnExpired,
}) {
  const recaptchaRef = useRef()
  const captchaKey = process.env.PROJECT_HIDDEN_CAPTCHA_KEY

  useEffect(() => {
    if (!activeRecaptcha) {
      recaptchaRef && recaptchaRef.current && recaptchaRef.current.reset()
      return
    }

    recaptchaRef.current.execute()
  }, [activeRecaptcha])

  const changeData = (key, value) => {
    const newData = { ...data }
    newData[key] = value

    handleDataChange(newData)
  }

  const getRangeType = (field) => {
    /**
     *     {
      key: "days",
      text: "Day range",
      type: "range",
      range: {
        from: {
          type: "date"
        },
        word: "to",
        to: {
          type: "date"
        }
      }
    },
     */
    return (
      <div className="form-range">
        <div className="form-range-part">{getInput(field.range.from)}</div>
        <div className="form-range-link">{field.range.word}</div>
        <div className="form-range-part">{getInput(field.range.to)}</div>
      </div>
    )
  }

  const isFieldInvalid = (field) => {
    if (!field.required && !field.invalid) return false

    if (field.required) {
      if (field.type === FORM_TYPES.number) {
        if (data[field.key] == undefined) {
          // do not add a third "="
          return true
        }

        return false
      }

      if (field.type === FORM_TYPES.date) {
        if (data[field.key] instanceof Date) {
          return isNaN(data[field.key])
        }

        if (data[field.key] === null) return true

        const d = new Date(data[field.key])
        return isNaN(d)
      }

      if (
        !data[field.key] ||
        !data[field.key].length ||
        !data[field.key].trim()
      ) {
        return true
      }
    }

    return field.invalid
  }

  function InvalidField({ message }) {
    return (
      <div className="invalid-message small-font">
        {message || dStrings.requiredField}
      </div>
    )
  }

  const getInput = (field, invalid) => {
    let fieldClass = invalid ? 'invalid-field' : ''

    if (field.fieldClass) {
      fieldClass += ` ${field.fieldClass}`
    }

    switch (field.type) {
      case FORM_TYPES.text:
        return (
          <input
            type={field.type}
            value={data[field.key] || ''}
            disabled={field.disabled}
            className={fieldClass}
            // defaultValue={data[field.key]}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(field.key, e.target.value)
              field.onChange && field.onChange(e.target.value)
            }}
          />
        )
      case FORM_TYPES.number:
        return (
          <input
            type={field.type}
            value={data[field.key]}
            disabled={field.disabled}
            className={fieldClass}
            min={field.min}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(
                field.key,
                e.target.value && e.target.value.length
                  ? parseInt(e.target.value)
                  : null,
              )

              field.onChange && field.onChange(parseInt(e.target.value))
            }}
          />
        )
      case FORM_TYPES.select: {
        const options = Object.keys(field.select)
        return (
          <select
            value={data[field.key] || ''}
            disabled={field.disabled}
            className={fieldClass}
            onChange={(e) => {
              changeData(field.key, e.target.selectedOptions[0].value)
              field.onChange &&
                field.onChange(e.target.selectedOptions[0].value)
            }}
          >
            {field.emptyOption && (!field.required || !data[field.key]) && (
              <option value>{field.emptyOption}</option>
            )}
            {options.map((o, i) => {
              return (
                <option key={i} value={o}>
                  {field.select[o]}
                </option>
              )
            })}
          </select>
        )
      }

      case FORM_TYPES.textarea:
        return (
          <textarea
            value={data[field.key]}
            disabled={field.disabled}
            rows={field.rows || '4'}
            cols={field.cols || '50'}
            className={fieldClass}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(field.key, e.target.value)
              field.onChange && field.onChange(e.target.value)
            }}
          />
        )
      case FORM_TYPES.date:
        return (
          <DatePicker
            onChange={(date) => {
              changeData(field.key, date)
            }}
            value={
              data[field.key] instanceof Date
                ? data[field.key]
                : data[field.key] && new Date(data[field.key])
            }
            options={field.options}
            disabled={field.disabled}
            maxDate={field.max}
            minDate={field.min}
            className={fieldClass}
          />
          // <DatePicker
          //   locale="en"
          //   selected={
          //     data[field.key] instanceof Date
          //       ? data[field.key]
          //       : data[field.key] && new Date(data[field.key])
          //   }
          //   disabled={field.disabled}
          //   placeholderText={field.placeholder}
          //   showTimeInput={field.displayTime}
          //   className={fieldClass + " dashboard-datepicker"}
          //   calendarClassName="dashboard-datepicker-calendar"
          //   dateFormat={"MM/dd/yyyy" + (!field.displayTime ? "" : " hh:mm a")}
          //   maxDate={field.max}
          //   minDate={field.min}
          //   onChange={(date) => {
          //     changeData(field.key, date);
          //     // setEndDate(date)
          //   }}
          // />
        )
      case FORM_TYPES.checkbox:
        return (
          <input
            type={field.type}
            checked={data[field.key]}
            disabled={field.disabled}
            className={fieldClass}
            // defaultValue={data[field.key]}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(field.key, e.target.checked)
              field.onChange && field.onChange(e.target.checked)
            }}
          />
        )
      // <input
      //   type="date"
      //   defaultValue={data[field.key]}
      //   max={field.max}
      //   min={field.min}
      //   className={fieldClass}
      //   onChange={(e) => {
      //     changeData(field.key, e.target.value);
      //   }}
      // />
      case FORM_TYPES.time:
        return (
          <TimePicker
            className={fieldClass}
            disabled={field.disabled}
            onChange={(time) => {
              changeData(field.key, time)
              field.onChange && field.onChange(time)
            }}
            value={data[field.key]}
          />
        )
      case FORM_TYPES.switch:
        return (
          <SwitchButton
            value={data[field.key]}
            disabled={field.disabled}
            fieldClass={fieldClass}
            handlerGetData={(e) => {
              changeData(field.key, e.target.checked)
              field.onChange && field.onChange(e.target.checked)
            }}
          />
        )
      case FORM_TYPES.range: {
        return getRangeType(field)
      }
      case FORM_TYPES.special:
        return field.component
      default:
        break
    }
  }

  const displayContent = (field, invalid) => {
    const clickable =
      [FORM_TYPES.checkbox, FORM_TYPES.switch].indexOf(field.type) !== -1

    let handleOnClick

    if (clickable) {
      handleOnClick = () => {
        if (field.disabled) return

        const val = !data[field.key]
        changeData(field.key, val)
        field.onChange && field.onChange(val)
      }
    }

    const text = (
      <div
        className={`form-line-text text-field${clickable ? ' clickable' : ''}`}
        onClick={() => {
          handleOnClick && handleOnClick()
        }}
      >
        {field.text}
        {typeof field.text === 'string' && field.required ? (
          <span style={{ color: '#D24667' }}>*</span>
        ) : (
          ''
        )}
      </div>
    )

    const input = (
      <div
        className={`form-line-text form-input-container${
          !field.endText ? '' : ' form-line-end-text'
        }`}
      >
        {/* <DisplayedInput field={field} /> */}
        <div className="form-input">
          {getInput(field, invalid)}
          {invalid && <InvalidField message={field.invalidMessage} />}
        </div>
        {field.endText ? (
          <span className="end-text"> {field.endText}</span>
        ) : (
          ''
        )}
      </div>
    )

    if (!field.inverse) {
      return (
        <>
          {text}
          {input}
        </>
      )
    }

    return (
      <>
        {input}
        {text}
      </>
    )
  }

  return (
    <form>
      {withRecaptcha && (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={captchaKey}
          onChange={() => {
            handleExecuteRecaptcha(recaptchaRef.current.getValue())
          }}
          onExpired={() => {
            handleOnExpired && handleOnExpired()
          }}
        />
      )}
      <div className="form-comp">
        {fields
          .filter((a) => {
            return !a.getVisible || a.getVisible(data)
          })
          .map((field, index) => {
            const invalid = displayInvalid && isFieldInvalid(field)

            return (
              <React.Fragment key={index}>
                {field.type !== FORM_TYPES.title ? (
                  <div
                    className={`form-line ${
                      !field.lineClass ? '' : field.lineClass
                    }${!field.inverse ? '' : ' inversed'}`}
                    key={index}
                  >
                    {displayContent(field, invalid)}
                  </div>
                ) : (
                  <div
                    className={`form-line ${
                      !field.lineClass ? '' : field.lineClass
                    }`}
                    id={field.id ? field.id : undefined}
                  >
                    {field.text}
                  </div>
                )}
                {field.border && <div className="form-border-bottom" />}
              </React.Fragment>
            )
          })}
      </div>
    </form>
  )
}

FormComponent.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.oneOf(Object.keys(FORM_TYPES)),
      placeholder: PropTypes.string,
      fieldClass: PropTypes.string,
      disabled: PropTypes.bool,
      invalid: PropTypes.bool,
      invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      required: PropTypes.bool,
      displayTime: PropTypes.bool, // for date type
      select: PropTypes.object,
      onChange: PropTypes.func,
      range: PropTypes.shape({
        from: PropTypes.shape({
          type: PropTypes.string,
        }),
        word: PropTypes.string, // word between two fields
        to: PropTypes.shape({
          type: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
  data: PropTypes.object,
  handleDataChange: PropTypes.func,
  handleOnExpired: PropTypes.func,
}
