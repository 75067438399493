// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timepicker {
    display: flex;
    align-items: center;
    border: 1px solid #dadcdf;
    border-radius: 5px;
    width: 200px;
    padding-left: 4px;
}

.timepicker.disabled {
  pointer-events: none;
}
.timepicker input.timepicker-input,
.timepicker select {
  border: none;
  outline: none;
  padding: 0;
  background-color: inherit;
  color: inherit;
}

.timepicker input::-webkit-outer-spin-button,
.timepicker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timepicker .timepicker-hours {
  text-align: right;
  margin-right: 5px;
}
.timepicker .timepicker-minutes {
  text-align: left;
  margin-left: 5px;
}
.timepicker span.close-btn {
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}
.timepicker input.timepicker-input {
  width: 20px;
}

.timepicker select {
  width: 42px !important;
}
`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/TimePicker/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;EACE,oBAAoB;AACtB;AACA;;EAEE,YAAY;EACZ,aAAa;EACb,UAAU;EACV,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".timepicker {\n    display: flex;\n    align-items: center;\n    border: 1px solid #dadcdf;\n    border-radius: 5px;\n    width: 200px;\n    padding-left: 4px;\n}\n\n.timepicker.disabled {\n  pointer-events: none;\n}\n.timepicker input.timepicker-input,\n.timepicker select {\n  border: none;\n  outline: none;\n  padding: 0;\n  background-color: inherit;\n  color: inherit;\n}\n\n.timepicker input::-webkit-outer-spin-button,\n.timepicker input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.timepicker .timepicker-hours {\n  text-align: right;\n  margin-right: 5px;\n}\n.timepicker .timepicker-minutes {\n  text-align: left;\n  margin-left: 5px;\n}\n.timepicker span.close-btn {\n  margin-left: auto;\n  margin-right: 10px;\n  cursor: pointer;\n}\n.timepicker input.timepicker-input {\n  width: 20px;\n}\n\n.timepicker select {\n  width: 42px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
