import {
  type GridFilterInputValueProps,
  GridSingleSelectColDef,
  gridColumnLookupSelector,
} from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from '../../utils/hooks'
import { Combobox } from '../../base'

const getOptionLabel = (option: any) => {
  if (Array.isArray(option)) {
    return option
      .map((o) =>
        typeof o === 'object' && o !== null && 'label' in o
          ? (o.label as string)
          : String(o || ''),
      )
      .join(', ')
  }
  return typeof option === 'object' && option !== null && 'label' in option
    ? (option.label as string)
    : String(option || '')
}

export default function GridFilterComboBox(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef, apiRef, ...rest } = props
  const { valueOptions } = gridColumnLookupSelector(apiRef)[
    item.field
  ] as GridSingleSelectColDef

  const multiple = item.operator === 'isAnyOf'
  const defaultValue = useMemo(() => {
    return multiple ? [] : null
  }, [multiple])

  // for multiple, even when you pass the value as an array to applyValue, when its a single array item it pulls the value out of the array
  const value = useMemo(
    () =>
      !item.value
        ? defaultValue
        : multiple && !Array.isArray(item.value)
        ? [item.value]
        : item.value,
    [item.value, defaultValue, multiple],
  )

  const { t } = useTranslation()

  const minWidth = useMemo(() => {
    if (multiple) return '7em'
    const label = getOptionLabel(value)
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) context.font = '500 16px Noto Sans'
    const width = context?.measureText(label).width || 16 * 4
    return `${width + 16 * 4}px`
  }, [multiple, value])

  return (
    <Combobox
      sx={
        !multiple
          ? {
              minWidth,
              flex: '0 1 0px',
            }
          : {
              maxWidth: '30em',
              minWidth,
            }
      }
      label={t('gridFilterInputLabel')}
      multiple={multiple}
      value={value}
      innerRef={focusElementRef}
      onChange={(e, value) => {
        applyValue({
          ...item,
          value,
        })
      }}
      disableCloseOnSelect={multiple}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => {
        return typeof option === 'object' && 'value' in option
          ? option.value === value?.value
          : option === value
      }}
      options={
        Array.isArray(valueOptions)
          ? valueOptions
          : typeof valueOptions === 'function'
          ? valueOptions({
              field: item.field,
            })
          : []
      }
      ChipProps={{
        size: 'small',
        variant: 'outlined',
      }}
      textFieldProps={{
        variant: 'standard',
        ...rest,
      }}
    />
  );
}
