/* eslint import/no-named-as-default: "off" */
import dotProp from 'dot-prop-immutable'
import * as types from '../actions/actionTypes'
import initialState from './initialState'
import {
  getListByIdAndAllIds,
  getTracksByItems,
} from '../../components/utilities'

export default function planTemplateReducer(
  state = initialState.planTemplates,
  action,
) {
  switch (action.type) {
    case types.GET_PLAN_COUNT_SUCCESS:
      return dotProp.set(state, `byId.${state.selectedId}.count`, action.count)
    // case types.ADD_UPDATE_ITEM:
    //   return dotProp.set(state, `byId.${state.selectedId}.tracks.byId.${action.trackId}.slots`, )
    case types.LOAD_PLAN_TEMPLATE_SUCCESS:
      return {
        ...state,
        ...getListByIdAndAllIds(action.plans),
      }
    case types.LOAD_PLAN_BY_ID_SUCCESS:
      return dotProp.set(state, `byId.${action.plan.id}`, {
        ...state.byId[action.plan.id],
        ...action.plan,
        ...getTracksByItems(action.plan.tracks),
      })
    case types.RESET_GO_TO_PLAN_SELECTION:
      return {
        ...state,
        goToPlanSelection: undefined,
      }
    case types.PLAN_TEMPLATE_UNSELECTED:
      return {
        ...state,
        selectedId: undefined,
      }
    case types.CHANGE_SUB_MENU:
      return {
        ...state,
        subMenu: action.subMenu,
        selectedId: undefined,
      }
    default:
      return state
  }
}
