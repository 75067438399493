import { createSelector } from '@reduxjs/toolkit'
import {
  Permission,
  checkIsUserAProvider,
  hasAnyPermission,
} from '@valerahealth/redux-auth'
import {
  CenteredSpinner,
  Paper,
  Protected,
  Typography,
} from '@valerahealth/ui-components'
import { ProviderCalendar as SchedulingProviderCalendar } from '@valerahealth/scheduling'
import { RootState, useReduxSelector } from 'redux/store'
import { practiceMgrApi } from '@valerahealth/rtk-query'
import PageNotFound from './PageNotFound'

const calendarPermissions = [
  Permission.Appointment_Read,
  Permission.Appointment_ReadOwn,
  Permission.Schedule_ReadOwn,
  Permission.Schedule_Read,
]

/** checks if user is a provider and if they have any of the necessary permissions to read appointments or schedules */
export const selectCanViewCalendar = createSelector(
  checkIsUserAProvider,
  (state: RootState) => hasAnyPermission(state, calendarPermissions),
  (isProvider, hasPerm) => isProvider && hasPerm,
)
export const useCanViewCalendar = () => {
  return useReduxSelector(selectCanViewCalendar)
}

function Calendar() {
  const providerId = useReduxSelector(
    (state) => state.auth.session?.user.providerId!,
  )
  const { provider, isSuccess, isLoading, error } =
    practiceMgrApi.useGetProviderQuery(
      { id: providerId },
      {
        selectFromResult: ({ data, isSuccess, isLoading, error }) => ({
          provider: data?.getProvider,
          isLoading,
          isSuccess,
          error,
        }),
      },
    )

  return (
    <Paper
      sx={{
        p: 2,
        m: 2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {provider && <SchedulingProviderCalendar provider={provider} />}
      {isSuccess && !provider && <PageNotFound />}
      {error && (
        <Typography p={4} textAlign="center" color="error">
          {error.message || 'There was an error loading provider data.'}
        </Typography>
      )}
      {isLoading && <CenteredSpinner />}
    </Paper>
  )
}

export default function ProviderCalendar() {
  const isAuthorized = useCanViewCalendar()
  return (
    <Protected isAuthorized={isAuthorized}>
      <Calendar />
    </Protected>
  )
}
