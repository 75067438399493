import { useState } from 'react'
import { type GridRenderCellParams } from '@mui/x-data-grid-pro'
import { AddOutlined, RemoveOutlined } from '../../icons'
import { Typography, Chip, Stack, SxProps } from '../../base'
import { type Theme } from '../../theme'
import { useTranslation } from '../../utils/hooks'

export const ExpandCollapseCell = ({
  values,
  sx,
}: {
  values: string[]
  sx?: SxProps<Theme>
}) => {
  const { t } = useTranslation()
  const [expand, setExpand] = useState(false)
  const renderValues = expand || values.length < 3 ? values : values.slice(0, 1)

  const moreCount = values.length > 2 ? values.length - 1 : 0

  return (
    <Stack alignItems="flex-start" sx={sx}>
      {renderValues.map((v) => (
        <Typography gutterBottom key={v} sx={{ fontSize: 'inherit' }}>
          {v}
        </Typography>
      ))}
      {moreCount > 0 && (
        <Chip
          sx={{ flexGrow: 0 }}
          size="small"
          icon={
            expand ? (
              <RemoveOutlined
                color="primary"
                sx={{ fontSize: '0.875rem', mr: 0 }}
              />
            ) : (
              <AddOutlined
                color="primary"
                sx={{ fontSize: '0.875rem', mr: 0 }}
              />
            )
          }
          onClick={(e) => {
            setExpand(!expand)
            e.stopPropagation()
          }}
          label={expand ? t('collapse') : `${moreCount} ${t('more')}`}
          variant="outlined"
          clickable
        />
      )}
    </Stack>
  )
}

export const renderExpandCollapseCell = (
  params: GridRenderCellParams<any, string[] | null | undefined>,
) => {
  return <ExpandCollapseCell values={params.value || []} />
}
