import { createService } from '../../utils'
import { getBaseApi } from './baseApi'
import enhanceEndpoints from './enhanceApi'

const patientRegistrationGQLService = createService(
  ({ origin, getAccessToken }) => {
    const api = getBaseApi(origin, getAccessToken)
    return enhanceEndpoints(api)
  },
)

export const patientRegistrationApi = patientRegistrationGQLService({
  origin: `https://${process.env.PATIENT_REGISTRATION_API_DOMAIN}`,
})

export type PatientRegistrationApiType = typeof patientRegistrationApi
