import { Typography } from '@valerahealth/ui-components'
import { useReduxSelector, useReduxDispatch } from 'redux/store'
import LABELS from 'locales/en'
import { cancelEditItem } from '../../redux/actions/globalActions'
import AddEditAppointement from '../common/AddEditAppointement'
import MedicationPopup from '../MedicationPopup'
import ItemPopup from './ItemPopup'
import ViewConsentForm from './ViewConsentForm'
import ViewQuestionnaire from './ViewQuestionnaire'

export default function ViewItemPopup() {
  const dispatch = useReduxDispatch()
  const item = useReduxSelector((state) => state.global.editedItem)
  const handleClose = () => dispatch(cancelEditItem())

  switch (item?.type) {
    case 'appointment':
      return (
        <AddEditAppointement
          readOnly
          appointment={item.info}
          onClosed={handleClose}
        />
      )
    case 'medication':
      return <MedicationPopup itemId={item.info.id} handleClose={handleClose} />
    case 'reminder':
    case 'message':
      return (
        <ItemPopup
          title={LABELS[item.type]}
          content={<Typography>{item.info.text}</Typography>}
        />
      )
    case 'consent_form':
      return <ViewConsentForm item={item} />
    case 'questionnaire':
    case 'sentiment':
      return <ViewQuestionnaire item={item} />
    default:
      // article, audio_track, episode caught in thunk and opens content in new tab
      return null
  }
}
