import {
  AppointmentParticipantTypeCode,
  AppointmentStatus,
  AppointmentType,
  DayOfWeek,
  LocationFragment,
  MinimalSearchablePatientFrag,
  ReoccuranceTemplateFragment,
  ReoccuranceTemplateInput,
  ReoccuranceType,
  ResourceType,
  ServiceCategoryCode,
  ServiceTypeCode,
  SessionType,
} from '@valerahealth/rtk-query'
import {
  dateTimeTupleToDate,
  isBefore,
  TimeOption,
  add,
  toZonedTime,
  formatIsoTime,
  isoTimeToMinutes,
  endOfDay,
  minutesToIsoTime,
  startOfDay,
  fromZonedTime,
} from '@valerahealth/ui-components/utils/date'
import { DeepNonNullable, Overwrite } from 'utility-types'
import { CANCELED_APPOINTMENT_STATUSES_SET } from '../../utilities'

type ReoccuranceFields = {
  interval: number
  endDate: Date | null
  dayOfWeek: Array<DayOfWeek>
}

export type FormType = {
  serviceType: ServiceTypeCode | null
  patient: MinimalSearchablePatientFrag | null
  providerId: string | null
  location: LocationFragment | null
  channelType: SessionType
  addressUrl: string
  startDate: Date | null
  startTime: TimeOption | null
  minutesDuration: number | null
  timezone: string
  comment: string
  status: AppointmentStatus | null
  cancelationReason: { reason: string; detail?: string } | null
  reoccuranceInfo: ReoccuranceFields | null
}

// the field that the reoccurance form piece cares about
export type ReoccuranceFormTypeSlice = Pick<
  FormType,
  'reoccuranceInfo' | 'startTime' | 'minutesDuration' | 'timezone'
>

export type SubmittedReoccuranceFormTypeSlice = Pick<
  SubmittedFormType,
  'reoccuranceInfo' | 'startTime' | 'minutesDuration' | 'timezone'
>

export type TimeSelectionFormTypeSlice = Pick<
  FormType,
  'minutesDuration' | 'startTime'
>

type RequiredFields =
  | 'serviceType'
  | 'patient'
  | 'providerId'
  | 'location'
  | 'channelType'
  | 'startDate'
  | 'startTime'
  | 'minutesDuration'
  | 'timezone'

export type SubmittedReoccuranceInfo = DeepNonNullable<ReoccuranceFields>

export type SubmittedFormType = Overwrite<
  MakeKeysNonNullable<FormType, RequiredFields>,
  {
    reoccuranceInfo: SubmittedReoccuranceInfo | null
  }
>

export const formatCancelationReasonTypeToString = (
  c?: { reason: string; detail?: string } | null,
) => {
  if (!c) return ''
  return `${c.reason}${c.detail?.trim() ? ` - ${c.detail.trim()}` : ''}`
}

export const formatStringToCancelationReasonType = (
  s?: string | null,
): {
  reason: string
  detail?: string
} | null => {
  if (typeof s === 'string') s = s.trim()
  if (!s) return null
  if (s.includes(' - ')) {
    const [reason, detail] = s.split(' - ')
    return { reason: reason!, detail }
  }
  return { reason: s }
}

export const formToReoccuranceTemplate = (
  {
    timezone,
    startTime,
    minutesDuration,
    reoccuranceInfo,
  }: MakeKeysNonNullable<SubmittedReoccuranceFormTypeSlice, 'reoccuranceInfo'>,
  startDate: Date,
): ReoccuranceTemplateInput => {
  return {
    timezone,
    planningHorizon: {
      startDate: fromZonedTime(startOfDay(startDate), timezone).toISOString(),
      endDate: fromZonedTime(
        endOfDay(reoccuranceInfo.endDate),
        timezone,
      ).toISOString(),
    },
    reoccuranceType: ReoccuranceType.Weekly,
    weeklyTemplate: [
      {
        dayTimes: reoccuranceInfo.dayOfWeek.map((dayOfWeek) => ({
          dayOfWeek,
          startTime: startTime.iso,
          endTime: minutesToIsoTime(
            isoTimeToMinutes(startTime.iso) + minutesDuration,
          ),
        })),
        interval: reoccuranceInfo.interval,
        offset: 0,
      },
    ],
  }
}

// maps form values to that needed by the API
export const formToAppointment = (val: SubmittedFormType) => {
  const {
    patient,
    providerId,
    location,
    channelType,
    addressUrl,
    startDate,
    startTime,
    minutesDuration,
    comment,
    serviceType,
    timezone,
    reoccuranceInfo,
    status: _status,
    cancelationReason,
  } = val

  const startDateTime = dateTimeTupleToDate(startDate, startTime, timezone)
  const endDateTime = add(startDateTime, { minutes: minutesDuration })

  const status =
    _status ||
    (isBefore(endDateTime, new Date())
      ? AppointmentStatus.Fulfilled
      : AppointmentStatus.Booked)

  const reoccuranceTemplate: ReoccuranceTemplateInput | null =
    reoccuranceInfo &&
    formToReoccuranceTemplate(
      {
        reoccuranceInfo,
        timezone,
        startTime,
        minutesDuration,
      },
      startDate,
    )

  return {
    appointmentType: AppointmentType.Routine,
    serviceCategory: ServiceCategoryCode.Patient,
    serviceType,
    startDate: startDateTime.toISOString(),
    endDate: endDateTime.toISOString(),
    minutesDuration,
    status,
    virtualService: {
      channelType,
      addressUrl: addressUrl || null,
    },
    reoccuranceTemplate,
    participants: [
      {
        actor: {
          _id: providerId,
          type: ResourceType.Practitioner,
        },
        required: true,
        type: AppointmentParticipantTypeCode.PrimaryPerformer,
      },
      {
        actor: {
          _id: patient.treatmentId,
          type: ResourceType.Patient,
        },
        required: true,
        type: AppointmentParticipantTypeCode.Patient,
      },
      {
        actor: {
          _id: location.id,
          type: ResourceType.Location,
        },
        required: true,
      },
    ],
    note: comment.trim() || null,
    cancelationReason:
      cancelationReason && CANCELED_APPOINTMENT_STATUSES_SET.has(status)
        ? formatCancelationReasonTypeToString(cancelationReason)
        : null,
  }
}

/**
 *  in the AddEditPatientAppointment form we will ignore the returned startTime and minutesDuration since we will get those from the appointment itself, but when editing the reoccurance tempalte itself we will want those values.
 */
export const reoccuranceTemplateToForm = ({
  timezone,
  planningHorizon: { endDate },
  ...rest
}: ReoccuranceTemplateFragment): ReoccuranceFormTypeSlice => {
  const { interval, dayTimes } = rest.weeklyTemplate?.[0] || {}

  // right now we dont support different day times per day so just grab times from the first
  const { startTime, endTime } = dayTimes?.[0] || {}

  const minutesDuration =
    (startTime &&
      endTime &&
      isoTimeToMinutes(endTime) - isoTimeToMinutes(startTime)) ||
    null

  return {
    startTime: startTime
      ? {
          iso: startTime,
          display: formatIsoTime(startTime),
        }
      : null,
    timezone,
    minutesDuration,
    reoccuranceInfo: {
      dayOfWeek: dayTimes?.map((v) => v.dayOfWeek) || [],
      // convert to local date
      endDate: endDate ? toZonedTime(endDate, timezone) : null,
      interval: interval || 1,
    },
  }
}
