import React from 'react'
import PropTypes from 'prop-types'
import './style.less'
import { IconButton } from '@valerahealth/ui-core'

function SelectedElement({ onDelete, placeholder, children }) {
  return (
    <div
      className="selected-element"
      style={{
        padding: '5px 10px',
      }}
    >
      <span className="selected-element-title" title={placeholder}>
        {children}
      </span>
      <IconButton onClick={onDelete} type="close" />
    </div>
  )
}

SelectedElement.propTypes = {}

export default SelectedElement
