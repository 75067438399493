import { ReactNode, useState } from 'react'
import {
  Avatar,
  Box,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  DialogContent,
  Dialog,
  Divider,
} from '@mui/material'
import {
  Logout,
  AccountCircle,
  FavoriteBorderOutlined,
  EmailOutlined,
  Badge,
  Close,
} from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { AuthState, Permission } from '@valerahealth/redux-auth'
import { Stack, SxProps } from '@mui/system'
import PopupMenu from '../../base/PopupMenu'
import IconButton from '../../base/IconButton'
import { useAuthMonitorLogout } from '../AuthMonitor'
import { useTranslation } from '../../utils/hooks'
import Link from '../../base/Link'
import UserAccountForm from './UserAccountForm'

type State = {
  auth: AuthState
}

export type UserAccountProps = {
  canEditLegalName?: boolean
  canViewSupportLinks?: boolean
  children?: ReactNode | ReactNode[]
  sx?: SxProps
}

export default function UserAccount({
  canEditLegalName,
  canViewSupportLinks,
  children,
  sx,
}: UserAccountProps) {
  const session = useSelector((state: State) => state.auth.session)
  const canUpdateOwn =
    session?.user.permissions.includes(Permission.User_UpdateOwn) ||
    session?.user.permissions.includes(Permission.User_Update)

  const logout = useAuthMonitorLogout()

  const [t] = useTranslation()

  const [isUserFormOpen, toggleUserForm] = useState(false)

  return (
    session && (
      <Box display="flex" flexWrap="nowrap" alignItems="center" sx={sx}>
        <Typography>
          {t('UserAccount.introduction', { name: session.user.firstName })}
        </Typography>
        <PopupMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          button={
            <IconButton title={t('UserAccount.iconTitle')} color="inherit">
              <Avatar
                src={session.user.picture}
                sx={{
                  bgcolor: 'transparent',
                  width: '2rem',
                  height: '2rem',
                  color: 'inherit',
                }}
              >
                <AccountCircle
                  color="inherit"
                  sx={{ width: '100%', height: '100%' }}
                />
              </Avatar>
            </IconButton>
          }
        >
          <Stack p={1.5} gap={1} alignItems="center">
            <Avatar
              src={session.user.picture}
              sx={{
                width: '4rem',
                height: '4rem',
                boxShadow: (theme) => theme.shadows[3],
              }}
            >
              <AccountCircle sx={{ width: '75%', height: '75%' }} />
            </Avatar>
            <Typography>
              {session.user.firstName} {session.user.lastName}
            </Typography>
          </Stack>
          <Divider />
          {children}
          {canUpdateOwn && (
            <MenuItem onClick={() => toggleUserForm(true)}>
              <ListItemIcon>
                <Badge />
              </ListItemIcon>
              <ListItemText>
                {t('UserAccount.accountFormMenuItemLabel')}
              </ListItemText>
            </MenuItem>
          )}
          {canViewSupportLinks && (
            <MenuItem
              component={Link}
              to="mailto:provider-support@valerahealth.com"
              target="_blank"
            >
              <ListItemIcon>
                <EmailOutlined />
              </ListItemIcon>
              <ListItemText>{t('emailSupport')}</ListItemText>
            </MenuItem>
          )}
          {canViewSupportLinks && (
            <MenuItem
              component={Link}
              to={process.env.SUPPORT_SERVICE_URL}
              target="_blank"
            >
              <ListItemIcon>
                <FavoriteBorderOutlined />
              </ListItemIcon>
              <ListItemText>{t('support')}</ListItemText>
            </MenuItem>
          )}

          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>{t('signOut')}</ListItemText>
          </MenuItem>
        </PopupMenu>

        <Dialog
          open={isUserFormOpen}
          onClose={() => toggleUserForm(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {t('UserAccount.formTitle')}
            <IconButton onClick={() => toggleUserForm(false)}>
              <Close />
            </IconButton>{' '}
          </DialogTitle>
          <DialogContent sx={{ paddingTop: 1 }}>
            <UserAccountForm
              user={session.user}
              accessToken={session.accessToken.jwt}
              canEditLegalName={canEditLegalName}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
  )
}
