import { marked } from 'marked'
import { GlobalItem } from 'redux/reducers/legacyRootState'
import { ConsentFormStatusValue, careManagerApi } from '@valerahealth/rtk-query'
import { Box, CenteredSpinner, Typography } from '@valerahealth/ui-components'
import {
  CheckCircleOutlined,
  Visibility,
  VisibilityOff,
} from '@valerahealth/ui-components/icons'
import { format } from '@valerahealth/ui-components/utils/date'
import LABELS from 'locales/en'
import { useReduxSelector } from 'redux/store'
import { dateFormatEnum } from 'components/utilities/enums'
import ItemPopup from './ItemPopup'

type Props = {
  item: GlobalItem
}

const ConsentIcon = ({ status }: { status: ConsentFormStatusValue }) => {
  switch (status) {
    case 'pending':
      return <VisibilityOff />
    case 'seen':
      return <Visibility />
    case 'agreed':
      return <CheckCircleOutlined color="success" />
    default:
      return null
  }
}

export default function ViewConsentForm({ item }: Props) {
  const treatmentId = useReduxSelector(
    (state) => state.treatmentRoom.selectedId || '',
  )
  const patientId = useReduxSelector(
    (state) =>
      state.treatmentRoom.byId[state.treatmentRoom.selectedId || '']
        ?.patientId!,
  )
  const consentFormRes = careManagerApi.useGetConsentFormQuery(
    // code triggering global items is super messy and it mixes up the IDs, often passing the id as templateId
    { id: item.info.templateId || item.info.id },
    { skip: item.assigned },
  )
  const assignedConsentFormRes = careManagerApi.useGetTreatmentConsentFormQuery(
    {
      // item does not always consistently have treatmentId, fallback to the selected treatment id if not present event though its not a great pattern
      treatmentId,
      patientId,
      assignedConsentFormId: item.info.id,
    },
    { skip: !item.assigned || !treatmentId },
  )

  //if we jump to another treatment id we dont want to show the old treatments cached response
  const isLoading =
    assignedConsentFormRes.isFetching || consentFormRes.isFetching
  const error = assignedConsentFormRes.error || consentFormRes.error
  const assignedConsent = assignedConsentFormRes.data
  const data = assignedConsentFormRes.data || consentFormRes.data

  // order is important of this or statement
  const dateOfStatus = assignedConsent?.agreedAt || assignedConsent?.seenAt

  return (
    <ItemPopup
      title={
        data
          ? `${data.title}${data.aliasName ? ` (${data.aliasName})` : ''}`
          : 'Loading...'
      }
      content={
        isLoading ? (
          <Box minHeight="10rem">
            <CenteredSpinner />
          </Box>
        ) : error ? (
          <Typography color="error">
            Failed to load {LABELS[item.type]}
          </Typography>
        ) : data ? (
          <>
            {assignedConsent?.status && (
              <Typography
                color="GrayText"
                variant="subtitle2"
                gutterBottom
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="middle"
              >
                <ConsentIcon status={assignedConsent.status} />
                {LABELS.ConsentStatus[assignedConsent.status]}
                {dateOfStatus &&
                  ` - ${format(
                    new Date(dateOfStatus),
                    dateFormatEnum.FULL_DATE_AND_TIME,
                  )}`}
              </Typography>
            )}
            {data.body && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: marked.parse(data.body),
                }}
              />
            )}
          </>
        ) : null
      }
    />
  )
}
