import { type DateHeaderProps } from 'react-big-calendar'
import { isSameDay, isFirstDayOfMonth, format } from 'date-fns'
import { Button, Typography } from '../../base'

export function CalendarMonthDateHeader({
  date,
  label,
  onDrillDown,
  isOffRange,
}: DateHeaderProps) {
  const isToday = isSameDay(new Date(), date)
  return (
    <Typography
      variant="subtitle2"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        gap: 0.25,
        opacity: isOffRange ? 0.4 : 1,
        my: 0.5,
      }}
    >
      {isFirstDayOfMonth(date) ? `${format(date, 'MMM')} ` : null}
      <Button
        onClick={onDrillDown}
        variant={isToday ? 'contained' : 'text'}
        sx={{
          color: isToday ? 'primary' : 'inherit',
          minWidth: 0,
          padding: 0,
          width: '1.75rem',
          height: '1.75rem',
          borderRadius: '100%',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {label}
      </Button>
    </Typography>
  )
}
