// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-item-container {
  background-color: #f8f9f9;
  width: 500px;
  border-radius: 5px;
}
.timeline-item-left {
  width: 260px;
}
.timeline-item-element {
  padding: 10px 16px 0px;
  display: flex;
  position: relative;
}

.timeline-item-element-container:not(:last-child) .timeline-item {
  border-bottom: 1px solid #dadcdf;
}
.timeline-item {
  width: 100%;
  display: flex;
  padding-bottom: 16px;
}
.cursor-ball {
  width: 15px;
  height: 15px;
  background-color: #e2cee2;
  position: absolute;
  left: -7.5px;
  border-radius: 15px;
}
.timeline-item-title-container {
  display: flex;
}
.timeline-item-title {
  margin-left: 10px;
}
.timeline-item-icon {
  min-width: 12px !important;
  height: 12px !important;
}
.timeline-item-details {
  width: 100%;
  margin-left: 50px;
}
.timeline-item-view {
  cursor: pointer;
}

.timeline-item-details .timeline-item-type {
  margin-top: 10px;
}

.timeline-item-time {
  font-weight: bold;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/PlanTemplate/Content/Views/Timeline/TimelineItem/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;AACA;EACE,WAAW;EACX,aAAa;EACb,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,0BAA0B;EAC1B,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".timeline-item-container {\n  background-color: #f8f9f9;\n  width: 500px;\n  border-radius: 5px;\n}\n.timeline-item-left {\n  width: 260px;\n}\n.timeline-item-element {\n  padding: 10px 16px 0px;\n  display: flex;\n  position: relative;\n}\n\n.timeline-item-element-container:not(:last-child) .timeline-item {\n  border-bottom: 1px solid #dadcdf;\n}\n.timeline-item {\n  width: 100%;\n  display: flex;\n  padding-bottom: 16px;\n}\n.cursor-ball {\n  width: 15px;\n  height: 15px;\n  background-color: #e2cee2;\n  position: absolute;\n  left: -7.5px;\n  border-radius: 15px;\n}\n.timeline-item-title-container {\n  display: flex;\n}\n.timeline-item-title {\n  margin-left: 10px;\n}\n.timeline-item-icon {\n  min-width: 12px !important;\n  height: 12px !important;\n}\n.timeline-item-details {\n  width: 100%;\n  margin-left: 50px;\n}\n.timeline-item-view {\n  cursor: pointer;\n}\n\n.timeline-item-details .timeline-item-type {\n  margin-top: 10px;\n}\n\n.timeline-item-time {\n  font-weight: bold;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
