import { useState } from 'react'
import { InputAdornment } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import IconButton from '../../base/IconButton'
import { TextField, type TextFieldProps } from './TextField'

export type PasswordTextFieldProps = Omit<
  TextFieldProps,
  'type' | 'InputProps'
> & {
  InputProps?: Omit<TextFieldProps['InputProps'], 'endAdornment'>
}

export function PasswordTextField({
  InputProps,
  ...props
}: PasswordTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
    />
  )
}
