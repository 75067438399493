import { useMemo } from 'react'
import {
  Business,
  ContentPaste,
  MoreVert,
  PendingOutlined,
  Repeat,
  RepeatOne,
} from '@mui/icons-material'
import Draggable from 'react-draggable'
import {
  isSameDay,
  format,
  differenceInDays,
  formatInTimeZone,
  formatDuration,
  isFuture,
} from '@valerahealth/ui-components/utils/date'
import {
  Paper,
  Box,
  Typography,
  Link,
  Button,
  IconButton,
  IconButtonLink,
  Divider,
  EditIcon,
  DeleteIcon,
  CopyButton,
  PopupMenu,
  MenuItem,
  ListItemText,
  Tooltip,
  NexGenIcon,
  Stack,
} from '@valerahealth/ui-components'
import {
  LumaIcon,
  StartPatientVideoSession,
  useConfirmationDialog,
  useNotify,
} from '@valerahealth/ui-components/features'
import {
  AppointmentParticipantTypeCode,
  AppointmentStatus,
  DayOfWeek,
  PatientSummaryFrag,
  SessionType,
  patientSearchApi,
  schedulingApi,
  DAY_TO_NUMBER,
  AppointmentIdentifierSource,
} from '@valerahealth/rtk-query'
import { TFunction } from '@valerahealth/ui-translation'
import { useCalendarContext } from '@valerahealth/ui-components/features/Calendar'
import { useFeatureFlagNoStartSession } from '@valerahealth/ui-components/utils/hooks/useFeatureFlagNoStartSession'
import {
  getAppointmentLocation,
  getApptIdentifierDetails,
  getDrChronoAppointmentURL,
  getDrChronoClinicalNoteURL,
  isAppointmentStatusDisabled,
} from '../../utilities/utilities'
import {
  CANCELED_APPOINTMENT_STATUSES_SET,
  DRCHRONO_ICON_LINK,
  EDIT_APPOINTMENT_STATUSES,
  EMPHASIZED_APPOINTMENT_STATUSES,
  NON_PATIENT_APPOINTMENT_SERVICE_TYPES,
} from '../../utilities/constants'
import { AppointmentEventType } from './Calendar.type'
import { useTranslation } from '../../locales'
import {
  CloseIcon,
  PersonIcon,
  VideocamIcon,
  AppointmentStatusIcon,
} from '../../utilities/Icons'
import { getAppointmentStatusColor } from '../../utilities/colors'
import { useGetPermissions } from '../../hooks'
import { useCancelledStatusReasonPopup } from './useCancelledStatusReasonPopup'
import { useReduxDispatch, actions } from '../../reducer'
import { AppointmentWarnings } from './AppointmentWarnings'

const { useDeleteAppointmentMutation } = schedulingApi
const { useDescribePatientsByTreatmentIdQuery } = patientSearchApi

export interface AppointmentDetailsProps {
  event: AppointmentEventType
  onClose: () => void
}

const generateDateText = (eventStart?: Date, eventEnd?: Date) =>
  isSameDay(eventStart!, eventEnd!)
    ? `${format(eventStart!, 'PPPP')} | ${format(eventStart!, 'p')}-${format(
        eventEnd!,
        'p',
      )}`
    : `${format(eventStart!, 'PPPPp')} - ${format(eventEnd!, 'PPPPp')}`

export const WeekDaySortComparator = (
  v1: (typeof DayOfWeek)[keyof typeof DayOfWeek],
  v2: (typeof DayOfWeek)[keyof typeof DayOfWeek],
) => {
  return (DAY_TO_NUMBER[v1] ?? 9999) - (DAY_TO_NUMBER[v2] ?? 9999)
}

const IconForChannelType = ({
  channelType,
  t,
}: {
  channelType?: SessionType
  t: TFunction
}) => {
  let icon = null
  const hideStartSessionButton = useFeatureFlagNoStartSession()
  switch (channelType) {
    case SessionType.GoogleMeet:
      icon = hideStartSessionButton ? null : (
        <img
          src="https://cdn.valerahealth.com/images/google-meet-icon.svg"
          alt={t(`SessionType.${SessionType.GoogleMeet}`)}
        />
      )
      break
    case SessionType.ValeraVideo:
      icon = hideStartSessionButton ? null : (
        <VideocamIcon color="action" sx={{ justifySelf: 'center' }} />
      )
      break

    case SessionType.InPerson:
      icon = (
        <Business
          sx={{
            color: (theme) => theme.palette.action.active,
          }}
        />
      )
      break

    case SessionType.Other:
      icon = (
        <PendingOutlined
          sx={{
            color: (theme) => theme.palette.action.active,
          }}
        />
      )
      break
    default:
      break
  }

  return icon
}

const ButtonForChannelType = ({
  channelType,
  addressUrl,
  patientData,
  apptLocation,
  t,
}: {
  channelType?: SessionType
  addressUrl?: string | null
  patientData: PatientSummaryFrag
  apptLocation: string
  t: TFunction
}) => {
  let button = null
  const hideStartSessionButton = useFeatureFlagNoStartSession()
  switch (channelType) {
    case SessionType.GoogleMeet:
    case SessionType.ValeraVideo:
      button = !hideStartSessionButton ? (
        <StartPatientVideoSession
          videoSessionURL={addressUrl}
          enablePatientStatusCheck
          patientId={patientData.patientId}
          treatmentId={patientData.treatmentId}
          patientStatus={patientData.status}
        >
          <Button sx={{ mr: 'auto' }}>
            {channelType === SessionType.GoogleMeet
              ? t(`SessionType.${SessionType.GoogleMeet}`)
              : t(`startSession`)}
          </Button>
        </StartPatientVideoSession>
      ) : null
      break

    case SessionType.InPerson:
    case SessionType.Other:
      button = (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography fontSize="0.875rem" textTransform="uppercase">
            {channelType === SessionType.InPerson
              ? t(`SessionType.${SessionType.InPerson}`)
              : t(`SessionType.${SessionType.Other}`)}
          </Typography>
          <Typography>-</Typography>
          <Typography fontSize="0.7rem" sx={{ ml: 1 }}>
            {channelType === SessionType.InPerson ? apptLocation : addressUrl}
          </Typography>
        </Box>
      )
      break

    default:
      break
  }

  return button
}

const ContentForChannelType = ({
  channelType,
  addressUrl,
  t,
}: {
  channelType?: SessionType
  addressUrl?: string | null
  t: TFunction
}) => {
  let content = null

  switch (channelType) {
    case SessionType.GoogleMeet:
    case SessionType.ValeraVideo:
      content = (
        <>
          <Typography
            sx={{
              fontSize: '0.65rem',
            }}
          >
            {addressUrl?.split('https://')[1]}
          </Typography>
          {addressUrl && (
            <CopyButton
              onClick={() => navigator.clipboard.writeText(addressUrl!)}
              title={t('copyURL')}
              iconProps={{
                fontSize: 'small',
              }}
            />
          )}
        </>
      )
      break

    default:
      break
  }

  return content
}

export function AppointmentDetails({
  event,
  onClose,
}: AppointmentDetailsProps) {
  const { t } = useTranslation()
  const apptPermissions = useGetPermissions(
    event.resource?._appointment.participants.find(
      (p) => p.type?.code === AppointmentParticipantTypeCode.PrimaryPerformer,
    )?.actor._id || '-1',
    'providerId',
  )

  const { confirm, ConfirmationDialog } = useConfirmationDialog()
  const [deleteAppt, deleteStatus] = useDeleteAppointmentMutation()
  const dispatch = useReduxDispatch()

  const { selectCalendarState } = useCalendarContext()
  const { timezone } = selectCalendarState()

  const notify = useNotify()

  const resource = event.resource!
  const { _appointment } = resource
  const { virtualService, occuranceChanged } = _appointment

  const [lumaApptDetails, DrChornoApptDetails, NextgenIdDetails] =
    getApptIdentifierDetails(_appointment)([
      AppointmentIdentifierSource.LumaApptId,
      AppointmentIdentifierSource.DrChrono,
      AppointmentIdentifierSource.NextgenId,
    ])

  const { isAppt: isLumaAppt, apptId: lumaApptId } = lumaApptDetails!
  const { isAppt: isDrChronoAppt } = DrChornoApptDetails!

  const [reccuringApptInfo, apptToEndWeekInfo] = useMemo(() => {
    const { startDate, reoccuranceTemplate, occuranceIndex } = _appointment
    const weeklyTemplate = reoccuranceTemplate?.weeklyTemplate?.[0]

    if (!weeklyTemplate) return []

    const {
      timezone,
      planningHorizon: { endDate },
      lastOccuranceIndex,
    } = reoccuranceTemplate

    const { interval, dayTimes } = weeklyTemplate

    const days = endDate
      ? differenceInDays(new Date(endDate), new Date(startDate))
      : 9999
    const weeks = Math.ceil(days / 7)

    const apptToEndWeekInfo =
      days === 0
        ? 'Appointment series ending today.'
        : days <= 14
        ? `Appointment series ending within ${formatDuration(
            days >= 7 ? { weeks } : { days },
            { format: [days >= 7 ? 'weeks' : 'days'] },
          )}`
        : ''

    const seriesDescription = `${
      interval === 1 ? 'Weekly' : `Every ${interval} Weeks`
    } on ${new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    }).format(dayTimes.map((x) => t(`DayOfWeek.${x.dayOfWeek}`)))} until ${
      endDate ? formatInTimeZone(endDate, timezone, 'MMMM d, yyyy') : ''
    }${
      // occuranceIndex can be -1 in some edge cases where the initial appt to create a series does not match the series template (i.e. appt created on monday but series is for tuesdays) This appts essentially gets created as a 'occuraceChanged' appointment and isn't included in the index counts
      Number.isSafeInteger(occuranceIndex) &&
      occuranceIndex >= 0 &&
      Number.isSafeInteger(lastOccuranceIndex)
        ? ` - (${occuranceIndex + 1}/${lastOccuranceIndex + 1})`
        : ''
    }`

    return [seriesDescription, apptToEndWeekInfo]
  }, [t, _appointment])

  const treatmentId = resource.patient?._id
  const describePatientRes = useDescribePatientsByTreatmentIdQuery(
    { treatmentIds: treatmentId ? [treatmentId] : [] },
    {
      skip: !treatmentId,
      selectFromResult: ({ data }) => ({
        data: data?.describePatientsByTreatmentId?.[0],
      }),
    },
  )

  const appointmentEmrId = resource.emrId

  const isCanceled =
    resource.status !== AppointmentStatus.Rescheduled && resource.isCanceled

  const IsPatientAppt =
    resource.serviceType &&
    !NON_PATIENT_APPOINTMENT_SERVICE_TYPES.includes(resource.serviceType)
  const apptStatusColor = getAppointmentStatusColor(resource.status)

  const apptLocation: string = useMemo(
    () => getAppointmentLocation(_appointment)?.display || '',
    [_appointment],
  )

  const dateText = useMemo(
    () => generateDateText(event.start, event.end),
    [event.start, event.end],
  )

  const handleEditAppt = () => {
    dispatch(
      actions.openView({
        type: 'appointmentForm',
        mode: 'edit',
        appointment: _appointment,
        timezone,
      }),
    )
    onClose()
  }

  const [updateAppointment, updateAppointmentStatus] =
    schedulingApi.useUpdateAppointmentMutation()

  const appointmentStatusChangeHandler = async (
    apptStatus: AppointmentStatus,
  ) => {
    if (resource.id) {
      const res = await updateAppointment({
        id: resource.id,
        content: CANCELED_APPOINTMENT_STATUSES_SET.has(apptStatus)
          ? {
              status: apptStatus,
            }
          : {
              status: apptStatus,
              cancelationReason: null,
            },
      })
      if ('error' in res) {
        notify({
          message: res.error.message || t('api.appointment.saveFailure'),
          severity: 'error',
        })
        return false
      }
      notify({
        message: t(`api.appointment.saveSuccess`),
        severity: 'success',
      })

      // Closing the appointment details modal
      onClose()
    }
  }

  const handleDeleteNonPatientAppt = async () => {
    const confirmed = await confirm({
      header: t('deleteConfirmation'),
      body: t('SchedulingSidebar.confirmDeleteApptBody'),
      cancelLabel: t('no'),
      confirmLabel: t('yes'),
      confirmButtonColor: 'primary',
    })
    if (!confirmed) return
    const res = await deleteAppt({ id: resource.id })
    if ('error' in res) {
      notify({
        severity: 'error',
        message: res.error.message || t('serverError.500'),
      })
    } else {
      notify({
        severity: 'success',
        message: t('api.appointment.deleteSuccess'),
      })
      dispatch(actions.closeView())
    }
  }

  const {
    isLoadingConfig,
    cancelationReasonState,
    setCancelationReason,
    cancelationConfirmationDialog,
  } = useCancelledStatusReasonPopup({
    onCancel: onClose,
    onConfirm: async () => {
      if (resource.id) {
        const res = await updateAppointment({
          id: resource.id,
          content: {
            status: cancelationReasonState.status,
            cancelationReason: `${cancelationReasonState.reason}${
              cancelationReasonState.detail.trim()
                ? ` - ${cancelationReasonState.detail.trim()}`
                : ''
            }`,
          },
        })
        if ('error' in res) {
          notify({
            message: res.error.message || t('api.appointment.saveFailure'),
            severity: 'error',
          })
          setCancelationReason((c) => ({ ...c, error: true }))
          return false
        }
        notify({
          message: t(`api.appointment.saveSuccess`),
          severity: 'success',
        })

        // Closing the appointment details modal
        onClose()
      }
    },
  })

  // need the permit permission
  const canEditPatientAppt =
    IsPatientAppt &&
    process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS &&
    apptPermissions.canUpdateAppointment
  const canEditAdminAppt =
    !IsPatientAppt && apptPermissions.canUpdateAppointment

  return (
    <>
      <Draggable
        handle=".appointmentDetail-draggableBox"
        cancel=".appointmentDetail-undraggableBox"
      >
        <Paper
          sx={{
            minWidth: '356px',
            maxWidth: '100%',
            height: 'auto',
          }}
        >
          <Box
            className="appointmentDetail-draggableBox"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: `0.25rem 0.25rem 0.25rem 0.75rem`,
              alignItems: 'center',
              cursor: 'grab',
              '&:active:hover': {
                cursor: 'grabbing',
              },
              gap: 1,
            }}
          >
            <AppointmentStatusIcon
              status={resource.status}
              color={apptStatusColor}
            />

            {EMPHASIZED_APPOINTMENT_STATUSES.includes(resource.status) && (
              <Typography
                color={apptStatusColor && `${apptStatusColor}.main`}
                fontWeight="bold"
              >
                {t(`AppointmentStatus.${resource.status}`)}
              </Typography>
            )}
            <Box
              className="appointmentDetail-undraggableBox"
              sx={{
                cursor: 'default',
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Stack flexDirection="row" alignItems="center" gap={1} mr={2}>
                {isDrChronoAppt && appointmentEmrId && (
                  <IconButtonLink
                    sx={{ mr: 0.5 }}
                    disableRipple
                    to={getDrChronoAppointmentURL({
                      appointmentEmrId,
                    })}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <img
                      src={DRCHRONO_ICON_LINK}
                      alt="DrChrono"
                      height="24px"
                      width="24px"
                      style={{ borderRadius: '4px' }}
                    />
                  </IconButtonLink>
                )}

                {isLumaAppt && lumaApptId && (
                  <LumaIcon
                    lumaApptId={lumaApptId}
                    iconStyle={{ height: 20 }}
                  />
                )}

                {NextgenIdDetails?.apptId && (
                  <NexGenIcon
                    alt="Appointment linked to NextGen"
                    title={`Appointment linked to NextGen - ID ${NextgenIdDetails?.apptId}`}
                  />
                )}
              </Stack>

              {(canEditAdminAppt || canEditPatientAppt) && (
                <IconButton
                  title="Edit"
                  size="small"
                  onClick={handleEditAppt}
                  disabled={
                    deleteStatus.isLoading || updateAppointmentStatus.isLoading
                  }
                >
                  <EditIcon />
                </IconButton>
              )}

              {apptPermissions.canDeleteAppointment &&
                (!IsPatientAppt ||
                  process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS) && (
                  <>
                    <IconButton
                      title="Delete"
                      size="small"
                      onClick={handleDeleteNonPatientAppt}
                      disabled={deleteStatus.isLoading}
                      isLoading={deleteStatus.isLoading}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <ConfirmationDialog />
                  </>
                )}

              {canEditPatientAppt && (
                <PopupMenu
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  button={
                    <IconButton
                      title="Edit Status"
                      size="small"
                      sx={{
                        color: (theme) => theme.palette.action.active,
                      }}
                      isLoading={updateAppointmentStatus.isLoading}
                    >
                      <MoreVert />
                    </IconButton>
                  }
                >
                  {EDIT_APPOINTMENT_STATUSES.map((value) =>
                    !CANCELED_APPOINTMENT_STATUSES_SET.has(value) ? (
                      <MenuItem
                        key={value}
                        disabled={
                          value === resource.status ||
                          isAppointmentStatusDisabled(resource.start, value)
                        }
                        onClick={() => {
                          appointmentStatusChangeHandler(value)
                        }}
                      >
                        <ListItemText>
                          {t(`AppointmentStatus.${value}`)}
                        </ListItemText>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={value}
                        disabled={
                          value === resource.status ||
                          (isAppointmentStatusDisabled(resource.start, value) &&
                            isLoadingConfig)
                        }
                        onClick={() => {
                          setCancelationReason((r) => ({
                            ...r,
                            open: true,
                            status: value,
                          }))
                        }}
                      >
                        <ListItemText>
                          {t(`AppointmentStatus.${value}`)}
                        </ListItemText>
                      </MenuItem>
                    ),
                  )}
                </PopupMenu>
              )}

              <IconButton title="Close" onClick={onClose} size="small">
                <CloseIcon color="action" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          {
            /** CANCELATION REASON **/
            CANCELED_APPOINTMENT_STATUSES_SET.has(_appointment.status) && (
              <Typography variant="body1" sx={{ ml: '1rem' }}>
                {_appointment.cancelationReason
                  ? `${_appointment.cancelationReason}`
                  : ''}
              </Typography>
            )
          }

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              rowGap: 1,
              columnGap: 1.5,
              p: 2,
            }}
          >
            {/** PATIENT */}
            {IsPatientAppt && (
              <>
                <PersonIcon color="action" sx={{ justifySelf: 'center' }} />
                {treatmentId ? (
                  <Link
                    to={`https://${process.env.CARE_MANAGER_UI_DOMAIN}/caseload/treatment/${treatmentId}`}
                    target="_blank"
                    sx={{
                      textDecorationLine: isCanceled ? 'line-through' : 'none',
                    }}
                    color="inherit"
                    underline="hover"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    {resource.patient?.display ||
                      describePatientRes.data?.display.expandedName}
                  </Link>
                ) : (
                  <Typography
                    sx={{
                      textDecorationLine: isCanceled ? 'line-through' : 'none',
                    }}
                  >
                    {resource.patient?.display ||
                      describePatientRes.data?.display.expandedName}
                  </Typography>
                )}
              </>
            )}

            {/** SERVICE TYPE */}
            <Box
              sx={{
                width: '1rem',
                height: '1rem',
                justifySelf: 'center',
                backgroundColor: resource.palette.topColor,
              }}
            />
            <Typography>{resource.serviceTypeTitle}</Typography>

            {/** Details */}
            <Box sx={{ gridColumn: 2 }}>
              {/** LOCATION */}
              {resource.location?.display && (
                <Typography
                  variant="body2" // date
                  gutterBottom
                >
                  {resource.location?.display}
                </Typography>
              )}

              {/** TIME OF APPOINTMENT */}
              <Typography
                variant="body2" // date
                gutterBottom
              >
                {dateText}
              </Typography>
            </Box>

            {/** APPT SERIES INFO */}
            {reccuringApptInfo && (
              <>
                <Box mt={-1.25}>
                  {occuranceChanged ? (
                    <Tooltip title="This appointment was edited and differs from the series">
                      <RepeatOne color="primary" />
                    </Tooltip>
                  ) : (
                    <Repeat color="primary" />
                  )}
                </Box>
                <Box mt={-1.25}>
                  <Typography variant="body2">{reccuringApptInfo}</Typography>
                  {apptToEndWeekInfo && (
                    <Typography
                      color={(theme) => theme.palette.error.main}
                      variant="body2"
                    >
                      {apptToEndWeekInfo}
                    </Typography>
                  )}
                </Box>
              </>
            )}

            {/** LINK TO START VIDEO */}
            {resource.status === AppointmentStatus.Booked &&
              IsPatientAppt &&
              describePatientRes.data && (
                <>
                  <IconForChannelType
                    t={t}
                    channelType={virtualService?.channelType}
                  />
                  <ButtonForChannelType
                    channelType={virtualService?.channelType}
                    addressUrl={virtualService?.addressUrl}
                    patientData={describePatientRes.data}
                    apptLocation={apptLocation}
                    t={t}
                  />
                </>
              )}

            {describePatientRes.data &&
              [SessionType.GoogleMeet, SessionType.ValeraVideo].includes(
                virtualService?.channelType!,
              ) && (
                <Box
                  sx={{
                    gridColumn: 2,
                    gap: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '5px',
                  }}
                >
                  <ContentForChannelType
                    channelType={virtualService?.channelType}
                    addressUrl={virtualService?.addressUrl}
                    t={t}
                  />
                </Box>
              )}

            {IsPatientAppt && appointmentEmrId && (
              <>
                <Divider sx={{ gridColumnStart: 'span 2', my: 1 }} />
                <ContentPaste color="action" sx={{ justifySelf: 'center' }} />
                <Link
                  to={getDrChronoClinicalNoteURL(appointmentEmrId)}
                  target="_blank"
                  underline="hover"
                  sx={{
                    color: (theme) => theme.palette.info.main,
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  DrChrono Clinical Note
                </Link>
              </>
            )}
            {IsPatientAppt &&
              resource.status === AppointmentStatus.Booked &&
              isFuture(resource.end) && (
                <Box sx={{ gridColumnStart: 'span 2', maxWidth: '26rem' }}>
                  <Divider sx={{ my: 1 }} />
                  <AppointmentWarnings
                    providerId={resource.primaryProvider?._id}
                    treatmentId={resource.patient?._id}
                    appointmentDate={resource.start}
                  />
                </Box>
              )}
          </Box>
        </Paper>
      </Draggable>
      {cancelationConfirmationDialog}
    </>
  )
}
