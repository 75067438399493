import { useMemo, useState } from 'react'
import { DeepNonNullable } from 'utility-types'
import { CloseOutlined, Edit, ErrorOutline } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useConfirmationDialog,
  useNotify,
  MenuItem,
} from '@valerahealth/ui-components'
import {
  getTimezoneValues,
  toZonedTime,
  startOfDay,
} from '@valerahealth/ui-components/utils/date'
import {
  ReoccuranceTemplateFragment,
  schedulingApi,
  ServiceTypeCode,
} from '@valerahealth/rtk-query'
import { SaveButton, useForm } from '@valerahealth/ui-components/form'
import { FormProvider, Select } from '@valerahealth/ui-components/form/controls'
import { useTranslation } from '@valerahealth/ui-translation'
import { actions, useReduxDispatch } from '../../reducer'
import {
  RecurringAppointmentFormPart,
  TimeSelectionFormPart,
} from './FormParts'
import {
  formToReoccuranceTemplate,
  ReoccuranceFormTypeSlice,
  reoccuranceTemplateToForm,
} from './formType'
import { handleAppointmentWarningConflicts } from './patientApptHelpers'

const timezoneOptions = getTimezoneValues()

const EditAppointmentSeriesDialog = ({
  reoccuranceTemplate,
  serviceType,
}: {
  serviceType: ServiceTypeCode
  reoccuranceTemplate: ReoccuranceTemplateFragment
}) => {
  const [t] = useTranslation()
  const notify = useNotify()
  const dispatch = useReduxDispatch()
  const { confirm, ConfirmationDialog } = useConfirmationDialog()
  const [isOpen, setIsOpen] = useState(false)
  const {
    id,
    timezone,
    planningHorizon: { startDate },
  } = reoccuranceTemplate
  const startDateTz = useMemo(() => {
    return startDate ? toZonedTime(startDate, timezone) : startOfDay(new Date())
  }, [startDate, timezone])
  const defaultValues = useMemo(
    () => reoccuranceTemplateToForm(reoccuranceTemplate),
    [reoccuranceTemplate],
  )
  const methods = useForm<ReoccuranceFormTypeSlice>({
    defaultValues,
  })

  const [updateTemplate, updateTemplateRes] =
    schedulingApi.useUpdateReoccuranceTemplateMutation()

  const onSubmit = async (
    values: DeepNonNullable<ReoccuranceFormTypeSlice>,
  ) => {
    const content = formToReoccuranceTemplate(values, startDateTz)
    const res = await updateTemplate({
      id,
      content,
    })

    if ('error' in res) {
      notify({
        severity: 'error',
        message:
          res.error.message ||
          'An error occured while saving the Appointment Series',
      })
      return
    }

    const { warnings } = res.data.updateReoccuranceTemplate

    if (warnings) {
      await handleAppointmentWarningConflicts(
        warnings,
        values.timezone,
        confirm,
      )
    } else {
      notify({
        severity: 'success',
        message: 'The appointment series was successfully updated.',
      })
    }

    setIsOpen(false)
    dispatch(actions.closeView())
  }

  return (
    <>
      <Button
        size="small"
        startIcon={<Edit />}
        variant="outlined"
        onClick={() => setIsOpen(true)}
      >
        Edit Appt Series
      </Button>
      <Dialog fullWidth maxWidth="sm" open={isOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Stack>Edit Appointment Series</Stack>

          <IconButton
            aria-label="close"
            onClick={() => setIsOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.stopPropagation()
              methods.handleSubmit((values) =>
                onSubmit(values as DeepNonNullable<ReoccuranceFormTypeSlice>),
              )(e)
            }}
          >
            <DialogContent
              dividers
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <ErrorOutline
                  fontSize="small"
                  sx={{
                    color: (theme) => theme.palette.warning.light,
                  }}
                />
                <Typography
                  fontSize="small"
                  color={(theme) => theme.palette.warning.light}
                >
                  All uncompleted appointments in the series will be updated.
                </Typography>
              </Stack>
              <Select label={t('timezone')} name="timezone" fullWidth>
                {timezoneOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <TimeSelectionFormPart serviceType={serviceType} />
              <RecurringAppointmentFormPart startDate={startDateTz} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                color="inherit"
                size="large"
                autoFocus
                onClick={() => setIsOpen(false)}
                sx={{
                  mr: 2,
                }}
              >
                {t('cancel')}
              </Button>
              <SaveButton
                isError={updateTemplateRes.isError}
                isSuccess={updateTemplateRes.isSuccess}
              />
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      <ConfirmationDialog />
    </>
  )
}

export default EditAppointmentSeriesDialog
