import { callApi, getValidationError } from '../../utilities'
import { addUserMessage } from '../../globalActions'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import dStrings from '../../../../strings.json'
import { registerNewTreatment } from '../../../../api/programAPI'
import { loadTreatments } from '../../caseloadActions'
import { parseIntlPhoneNumber } from '@valerahealth/ui-components'

export function updatePatientSuccess(profile, treatmentId) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    profile,
    id: treatmentId,
  }
}

export function selectTreatment(treatmentId) {
  return { type: types.SELECT_TREATMENT, treatmentId }
}

export function unselectTreatment(treatment) {
  return { type: types.UNSELECT_TREATMENT, treatment }
}

export function addPatientSuccess(treatment, phone) {
  return {
    type: types.ADD_TREATMENT_SUCCESS,
    treatment: {
      ...treatment,
      profile: {
        ...treatment.profile,
        phone,
      },
    },
  }
}

export function pendingPatientRegistered(patientId) {
  return {
    type: types.PENDING_PATIENT_REGISTERED,
    id: patientId,
  }
}
export function toggleRestoreTreatmentPopup(display, details) {
  return { type: types.TOGGLE_RESTORE_POPUP, display, details }
}

export function cancelEditPatient() {
  return { type: types.CANCEL_EDIT_PATIENT }
}

export const updatePatient = ({
  patientData,
  /** required on updateProfile, optional on registerPatient */
  treatmentId, //
  /** only called on registerNewPatient */
  cb,
  /** optional, patient may be new **/
  pendingPatientId,
  errorCb,
}) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'updatePatient',
      dispatch,
      call: () => {
        if (treatmentId) {
          return treatmentAPI.updateProfile(treatmentId, patientData)
          /** example res
             {
                "profileId": "657b68d70fb467000134b7ca",
                "firstName": "Flub",
                "lastName": "Bub",
                "gender": "m",
                "genderIdentity": "male",
                "phone": "+12539999999",
                "email": "Flub.Bub@valerahealth.com",
                "primaryPhone": "+12539999999",
                "primaryEmail": "Flub.Bub@valerahealth.com",
                "dateOfBirth": "1990-10-10T00:00:00.000Z",
                "diagnosis": [],
                "disableChat": false,
                "addressComponents": {},
                "status": "active",
                "preferredLanguage": "ar",
                "languages": [
                    "en"
                ],
                "username": "+12539999999_3",
                "timezone": "America/New_York",
                "hospitalized": false
            }
           */
        }
        return registerNewTreatment(
          getState().auth.session?.user.programId,
          patientData,
          pendingPatientId,
        )
        /**  example res
         {
            "id": "657b68d7f1f5c037975ac00f",
            "status": "active",
            "patientId": "657b68d70fb467000134b7ca",
            "profile": {
                "firstName": "Flub",
                "lastName": "Bub"
            },
            "channel": {
                "count": 0,
                "unread": []
            },
            "clinicalIndicators": [],
            "plans": [],
            "unresolvedAlertCount": 0,
            "lastActivity": null,
            "diagnosis": [],
            "isCTM": true,
            "emrStatus": {
                "isIntegrationActive": true
            }
        }
         */
      },
      error: (errorObj) => {
        const obj = errorObj && errorObj.message && JSON.parse(errorObj.message)
        let message = dStrings.errorOccurred
        let key

        if (obj) {
          if (obj.message === 'patient_already_registered') {
            message = dStrings.patientAlreadyRegistered
          } else if (obj.message === 'already_exists') {
            message = dStrings.phoneExist
          } else if (obj.error === 'username_already_exists') {
            message = dStrings.usernameExist
          } else if (obj.error === 'already_exists_in_same_clinic') {
            key = 'already_exists_in_same_clinic'
          } else if (obj.error === 'already_exists_in_different_clinic') {
            key = 'already_exists_in_different_clinic'
          } else {
            key = getValidationError(obj)
            if (key) message = dStrings.invalidField
          }
        }

        if (errorCb) {
          errorCb(key, message)
        }

        if (!key) {
          dispatch(addUserMessage('error', message, null, true))
        }
      },
      success: (
        /** this is either a treatment response or profile, depending on if we registered a new patient */
        treatmentOrPatientProfile,
      ) => {
        let { phone, primaryPhone } = treatmentOrPatientProfile
        try {
          phone = parseIntlPhoneNumber(treatmentOrPatientProfile.phone)
          primaryPhone = parseIntlPhoneNumber(
            treatmentOrPatientProfile.primaryPhone,
          )
        } catch (error) {
          //
        }

        if (treatmentId) {
          dispatch(
            updatePatientSuccess(
              {
                ...treatmentOrPatientProfile,
                phone,
                primaryPhone,
                device: patientData.device,
              },
              treatmentId,
            ),
          )
          dispatch(
            addUserMessage('success', dStrings.patientSuccessfullyUpdated),
          )
        } else {
          dispatch(addPatientSuccess(treatmentOrPatientProfile, phone))

          if (pendingPatientId)
            dispatch(pendingPatientRegistered(pendingPatientId))

          dispatch(
            addUserMessage(
              'success',
              pendingPatientId
                ? dStrings.patientSuccessfullyRegistered
                : dStrings.patientSuccessfullyAdded,
            ),
          )

          cb?.(treatmentOrPatientProfile)
          dispatch({ type: types.CANCEL_EDIT_PATIENT })
        }
      },
    })
  }
}

export function restorePatient(id, cb) {
  return (dispatch, getState) => {
    const treatmentId = id || getState().treatmentRoom.selectedId
    return callApi({
      name: 'restorePatient',
      dispatch,
      call: () => {
        return treatmentAPI.restorePatient(treatmentId)
      },
      success: (res) => {
        if (res) {
          const r = JSON.parse(res)
          if (r.statusCode === 400) {
            if (r && r.message === 'cm_not_authorized') {
              dispatch(
                addUserMessage('error', dStrings.cm_not_authorized_restore),
              )
            } else {
              dispatch(addUserMessage('error', dStrings.errorOccurred))
            }
          }
        } else {
          cb?.()
          dispatch({ type: types.RESTORE_PATIENT, id: treatmentId })
          dispatch(loadTreatments({}))
          dispatch(addUserMessage('success', dStrings.patientsRestoredSucess))
        }
      },
    })
  }
}

export function addNewPatient(patient) {
  return (dispatch, getState) => {
    let disableByDefault
    try {
      disableByDefault =
        getState().program.configurations.disableChat.disableByDefault
    } catch (error) {
      disableByDefault = false
    }

    return dispatch({
      type: types.REGISTER_NEW_PATIENT,
      patient,
      disableByDefault,
    })
  }
}
