import React from 'react'
import PropTypes from 'prop-types'
import { getToolTemplateTitle } from '../../../utilities'
import dStrings from '../../../../strings.json'
// import "./style.css";

function PlanTimelineItemDetails({
  ToolIcon,
  toolsTemplates,
  timelineElement,
  plans,
}) {
  const getPlanTimelineTrackName = () => {
    const tracks =
      plans[timelineElement.planId].tracks.byId[timelineElement.trackId]
    if (tracks.isSingleItem) return dStrings.scheduledItems
    return tracks.name
  }

  return (
    <div className="timeline-item-details">
      <div className="timeline-item-title-container">
        <div className="timeline-item-icon">{ToolIcon && <ToolIcon />}</div>
        <div className="timeline-item-title">
          {getToolTemplateTitle(toolsTemplates, timelineElement.item)}
        </div>
      </div>
      <div className="timeline-item-type xsmall-font">
        {getPlanTimelineTrackName()}
      </div>
    </div>
  )
}

PlanTimelineItemDetails.propTypes = {
  patient: PropTypes.object,
}

export default PlanTimelineItemDetails
