// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#export-popup {
  width: 450px;
}

#export-popup .warning-info {
  margin-bottom: 10px;
}
.export-radio-container:not(:last-child) {
  margin-bottom: 10px;
}
.export-popup-container {
  background: #ffffff;
}

.export-popup-container .popup-body {
  padding-top: 0px;
}
#export-popup .range-datepicker .range-datepicker-popup {
  max-height: calc(100vh - 420px);
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/ExportPopup/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,+BAA+B;EAC/B,2BAA2B;EAC3B,6BAA6B;AAC/B","sourcesContent":["#export-popup {\n  width: 450px;\n}\n\n#export-popup .warning-info {\n  margin-bottom: 10px;\n}\n.export-radio-container:not(:last-child) {\n  margin-bottom: 10px;\n}\n.export-popup-container {\n  background: #ffffff;\n}\n\n.export-popup-container .popup-body {\n  padding-top: 0px;\n}\n#export-popup .range-datepicker .range-datepicker-popup {\n  max-height: calc(100vh - 420px);\n  overflow-y: auto !important;\n  overflow-x: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
