import { callApi } from '../../utilities'
import { addUserMessage } from '../../globalActions'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import dStrings from '../../../../strings.json'
import { planType } from '../../../../components/utilities/enums'

export function loadPlans(tid) {
  return (dispatch, getState) => {
    const treatmentId = tid || getState().treatmentRoom.selectedId
    return callApi({
      name: 'loadPlans',
      dispatch,
      call: () => {
        return treatmentAPI.getPlans(treatmentId)
      },
      success: (plans) => {
        dispatch({
          type: types.LOAD_PLAN_SUCCESS,
          plans: plans
            .sort((a, b) => {
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            })
            .map((p) => {
              if (p.type !== planType.INDIVIDUAL_PRIVATE) return p
              return { ...p, name: dStrings.personnalAutomation }
            }),
          treatmentId,
        })
      },
    })
  }
}

export const unassignCarePlan = (planId) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'unassignCarePlan',
      dispatch,
      call: () => {
        const treatments = getState().treatmentRoom
        return treatmentAPI.unassignCarePlan(treatments.selectedId, planId)
      },
      success: () => {
        dispatch({ type: types.PLAN_UNASSIGNED_SUCCESS })
        dispatch(addUserMessage('success', dStrings.plansUnassigned))
      },
    })
  }
}
