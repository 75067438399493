import { PatientSearchGrid } from '@valerahealth/ui-components'
import { useGetNotificationsForSignedInUser } from './useGetNotifications'

export function CMPatientSearchGrid() {
  const { idsWithTreatmentNotificationArray, idsWithTreatmentNotificationMap } =
    useGetNotificationsForSignedInUser()

  return (
    <PatientSearchGrid
      formatPatientLink={(treatmentId) => `/caseload/treatment/${treatmentId}/basic-info`}
      idsWithTreatmentNotificationArray={idsWithTreatmentNotificationArray}
      idsWithTreatmentNotificationMap={idsWithTreatmentNotificationMap}
    />
  )
}
