/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import { GridLogicOperator } from '@mui/x-data-grid-pro'
// old v5
import { type GridInitialStatePro as GridInitialStateV5 } from '@mui/x-data-grid-pro-v5/models/gridStatePro'
import { type GridLinkOperator } from '@mui/x-data-grid-pro-v5'

import storage from 'redux-persist/lib/storage'
import {
  type PersistMigrate,
  persistReducer,
  createMigrate,
  MigrationManifest,
} from 'redux-persist'
import { type EnhancedSortModel } from '../enhancedSort'

export const PERSISTENT_DATA_GRID_REDUCER_KEY = 'persistentDataGrid'

type PersistentGridSettingsMUIGridV5 = {
  enhancedSortModel?: EnhancedSortModel
  gridState: GridInitialStateV5
  // used for storing one off values with grid views
  additionalMeta?: Record<string, any> | null
}
type PersistentGridViewMUIGridV5 = Record<
  string, // view name
  PersistentGridSettingsMUIGridV5 // settings for view
>

type PersistentDataGridStateMUIGridV5 = Record<
  string, // grid name
  {
    defaultView: string | null // key of a view to have as default
    views: PersistentGridViewMUIGridV5
  }
>

export type PersistentGridSettings = {
  enhancedSortModel?: EnhancedSortModel
  gridState: GridInitialStatePro
  // used for storing one off values with grid views
  additionalMeta?: Record<string, any> | null
}

export type PersistentGridView = Record<
  string, // view name
  PersistentGridSettings // settings for view
>

export type PersistentDataGridState = Record<
  string, // grid name
  {
    defaultView: string | null // key of a view to have as default
    views: PersistentGridView
  }
>

// IMPORTANT -- we need to keep a history of past state types if we make any breaking changes so we can build migrations
export type PersistentDataGridStateV1 = Record<
  string,
  Record<string, PersistentGridSettingsMUIGridV5 & { isDefault?: boolean }>
>
export type PersistentDataGridStateV2 = PersistentDataGridStateMUIGridV5
export type PersistentDataGridStateV3 = PersistentDataGridState

export type UpsertViewPayload = {
  gridName: string
  viewName: string
  isDefault?: boolean
  viewSettings: PersistentGridSettings
}

const initialStateMUIGridV5: PersistentDataGridStateMUIGridV5 = {}
const initialState: PersistentDataGridState = {}

const persistentDataGrid = createSlice({
  name: PERSISTENT_DATA_GRID_REDUCER_KEY,
  initialState,
  reducers: {
    setDefaultView: (
      state,
      {
        payload: { gridName, viewName },
      }: PayloadAction<{ gridName: string; viewName: string | null }>,
    ) => {
      // initialize state if this is the first view getting saved
      if (!state[gridName]) state[gridName] = { defaultView: null, views: {} }
      state[gridName]!.defaultView = viewName
    },
    // will create a view if the viewName does not already exist
    upsertView: (
      state,
      {
        payload: { gridName, viewName, viewSettings, isDefault },
      }: PayloadAction<UpsertViewPayload>,
    ) => {
      // initialize state if this is the first view getting saved
      if (!state[gridName]) state[gridName] = { defaultView: null, views: {} }

      state[gridName]!.views[viewName] = viewSettings

      if (isDefault) {
        // make sure all others are false if this will be the default
        state[gridName]!.defaultView = viewName
      }
    },
    deleteView: (
      state,
      {
        payload: { gridName, viewName },
      }: PayloadAction<{ gridName: string; viewName: string }>,
    ) => {
      if (state[gridName]!.defaultView === viewName) {
        state[gridName]!.defaultView = null
      }
      delete state[gridName]!.views[viewName]
    },
    // allows one to partially update an existing view, including the view name
    updateView: (
      state,
      {
        payload: { gridName, viewName, updates },
      }: PayloadAction<{
        gridName: string
        viewName: string
        updates: {
          name?: string
          isDefault?: boolean
        } & Partial<PersistentGridSettings>
      }>,
    ) => {
      const { name, isDefault, ...rest } = updates

      const gridState = state[gridName]!
      const updatedValue = {
        ...gridState.views[viewName]!,
        ...rest,
      }

      if (name) {
        gridState.views[name] = updatedValue
        delete gridState.views[viewName]
      } else {
        gridState.views[viewName] = updatedValue
      }
      if (isDefault) {
        state[gridName]!.defaultView = name || viewName
      } else {
        state[gridName]!.defaultView = ''
      }
    },
  },
})

export const persistentDataGridActions = persistentDataGrid.actions

const persistGridConfig = {
  key: PERSISTENT_DATA_GRID_REDUCER_KEY,
  version: 4,
  storage,
}

const persistentMuiGridStateV5ToV6 = ({
  gridState,
  ...rest
}: PersistentGridSettingsMUIGridV5): PersistentGridSettings => {
  const filtermodel = gridState?.filter?.filterModel
  const pagination = gridState?.pagination
  return {
    ...rest,
    gridState: {
      ...gridState,
      filter: {
        filterModel: {
          logicOperator:
            filtermodel?.linkOperator === 'or'
              ? GridLogicOperator.Or
              : GridLogicOperator.And,
          quickFilterValues: filtermodel?.quickFilterValues,
          quickFilterLogicOperator:
            filtermodel?.quickFilterLogicOperator === 'or'
              ? GridLogicOperator.Or
              : GridLogicOperator.And,
          items:
            filtermodel?.items?.map((o) => ({
              value: o.value,
              id: o.id,
              field: o.columnField,
              operator: o.operatorValue || '',
            })) || [],
        },
      },
      pagination: {
        paginationModel: {
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        },
      },
    },
  }
}

export const persistentDataGridMigrationManifest: MigrationManifest = {
  // @ts-expect-error migrations are poorly typed
  3: (
    statev1: PersistMigrate & PersistentDataGridStateV1,
  ): PersistentDataGridStateV2 => {
    try {
      const { _persist, ...grids } = statev1
      const state = Object.fromEntries(
        Object.entries(grids).map(([grid, views]) => {
          const viewEntries = Object.entries(views)
          const defaultView =
            viewEntries.find(([, { isDefault }]) => isDefault)?.[0] ?? null
          return [
            grid,
            {
              // change grid state from views to an object with {views, defaultView}
              defaultView,
              views: Object.fromEntries(
                // remove is default from the view level object
                viewEntries.map(([name, { isDefault, ...rest }]) => [
                  name,
                  rest,
                ]),
              ),
            },
          ]
        }),
      )
      return state
    } catch (e) {
      console.log('failed to migrate grid state')
      console.error(e)
      return initialStateMUIGridV5
    }
  },
  // @ts-expect-error migrations are poorly typed
  4: (
    statev2: PersistMigrate & PersistentDataGridStateV2,
  ): PersistentDataGridStateV3 => {
    try {
      const { _persist, ...grids } = statev2
      const state = Object.fromEntries(
        Object.entries(grids).map(([grid, views]) => {
          const viewEntries = Object.entries(views)
          return [
            grid,
            {
              // change grid state from views to an object with {views, defaultView}
              ...Object.fromEntries(
                // remove is default from the view level object
                viewEntries.map(([name, s]) =>
                  typeof s === 'object'
                    ? s
                      ? [
                          name,
                          Object.fromEntries(
                            Object.entries(s).map(([o, n]) => [
                              o,
                              persistentMuiGridStateV5ToV6(n),
                            ]),
                          ),
                        ]
                      : [name, s]
                    : [name, s],
                ),
              ),
            },
          ]
        }),
      )
      return state
    } catch (e) {
      console.log('failed to migrate grid state')
      console.error(e)
      return initialState
    }
  },
}

/** handles the redux-persist configuration, if you want to configure yourself use the exported reducer directly */
const persistedReducer = (persistConfig: {
  /** Important! You must change the version if you create a breaking data change with a datagrid, else the app will crash for users with persisted data */
  version: number
  /** You should provide a migration function when you change the version else the user will loose their saved views, any ui components implemented migrations are expored as  persistentDataGridMigrationManifest. if you define your own application migrations, you need to make sure they are compatible with current ui-component migrations */
  migrate?: MigrationManifest
}) => {
  const migrations = createMigrate(
    {
      ...persistentDataGridMigrationManifest,
      ...persistConfig.migrate,
    },
    { debug: true },
  )
  return persistReducer(
    {
      ...persistGridConfig,
      ...persistConfig,
      version:
        persistConfig.version > persistGridConfig.version
          ? persistConfig.version
          : persistGridConfig.version,
      migrate: migrations,
    },
    persistentDataGrid.reducer,
  )
}

export const persistentDataGridReducer = (
  ...args: Parameters<typeof persistedReducer>
) => ({
  [persistentDataGrid.name]: persistedReducer(...args),
})

export type RootStateWithPersistentGrid = {
  [PERSISTENT_DATA_GRID_REDUCER_KEY]: PersistentDataGridState
}
const selectPersistentGridBase = (state: RootStateWithPersistentGrid) =>
  state[PERSISTENT_DATA_GRID_REDUCER_KEY]

const defaultObj: {
  defaultView: string | null // key of a view to have as default
  views: PersistentGridView
} = {
  views: {},
  defaultView: null,
}
export const selectPersistentGridViewSettings = createSelector(
  selectPersistentGridBase,
  (state: RootStateWithPersistentGrid, gridName: string) => gridName,
  (baseState, gridName) => baseState[gridName] || defaultObj, // initializes an empty object if we dont have anything saved for the grid
)

export const selectPersistentGridDefaultView = createSelector(
  selectPersistentGridViewSettings,
  (grid) => grid.defaultView,
)

export const selectPersistentGridView = createSelector(
  selectPersistentGridViewSettings,
  (
    state: RootStateWithPersistentGrid,
    gridName: string,
    viewName?: string | null,
  ) => viewName,
  (settings, view) => {
    return view ? settings.views[view]! || null : null
  },
)

export const selectPersistentGridViewList = createSelector(
  selectPersistentGridViewSettings,
  ({ views }) =>
    Object.entries(views).map(([name, rest]) => ({
      name,
      ...rest,
    })),
)
