import {
  GridFilterItem,
  GridFilterOperator,
  getGridSingleSelectOperators as muiGetGridSingleSelectOperators,
} from '@mui/x-data-grid-pro'
import GridComboboxFilterInput from './GridComboboxFilterInput'

const getString = (val: string | { value: string; label: string }) => {
  if (typeof val === 'string') return val
  if (typeof val === 'object' && 'label' in val) return val.label
  return val
}
let _selectOperators: GridFilterOperator[]
export default function getGridSingleSelectOperators() {
  if (!_selectOperators)
    _selectOperators = muiGetGridSingleSelectOperators().map((operator) => {
      return {
        ...operator,
        InputComponent: GridComboboxFilterInput,
        getValueAsString: (val: GridFilterItem['value']) => {
          if (Array.isArray(val)) return val.map((v) => getString(v)).join(', ')
          return getString(val)
        },
      }
    })
  return _selectOperators
}
