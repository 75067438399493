import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './style.less'
import { connect } from 'react-redux'
import { Permission, checkPermission } from '@valerahealth/redux-auth'
import { SearchInput } from '@valerahealth/ui-core'
import { Button } from '@valerahealth/ui-components/base'
import { RegisterTreatmentButton } from '@valerahealth/ui-components'
import { useReduxSelector } from '../../../redux/store'
import FilterIcon from '../../../images/filter.svg'
import SelectedFilterIcon from '../../../images/filter_selected.svg'
import {
  loadTreatments,
  searchTextInTreatment,
  toggleFilterMenu,
} from '../../../redux/actions/caseloadActions'
import ShowMenu from './ShowMenu'
import dStrings from '../../../strings.json'
import { caseloadStateEnum } from '../../utilities/enums'

function CaseloadHeader({
  displayFilterMenu,
  caseloadState,
  searchText,
  searchTextInTreatment,
  loadTreatments,
  toggleFilterMenu,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [canLoad, setCanLoad] = useState(false)

  const canAddPatient = useReduxSelector((state) =>
    checkPermission(state, Permission.Patient_Create),
  )

  useEffect(() => {
    if (!canLoad) return

    const delayDebounceFn = setTimeout(() => {
      loadTreatments({
        search: searchTerm,
        hideLoader: true,
      })
    }, 300)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [canLoad, searchTerm])

  return (
    <div
      className={
        caseloadState === caseloadStateEnum.ACTIVE
          ? 'caseload-header'
          : 'caseload-discharge-header'
      }
    >
      {caseloadState === caseloadStateEnum.ACTIVE ? (
        <>
          <div className="caseload-header-left-side">
            <div
              className="filter-icon-container"
              onClick={toggleFilterMenu}
              style={{ cursor: 'pointer' }}
            >
              {displayFilterMenu ? <SelectedFilterIcon /> : <FilterIcon />}
            </div>
            <SearchInput
              value="" // searchText
              handleChange={(newSearchValue) => {
                // searchTextInTreatment(newSearchValue)
                setCanLoad(true)
                setSearchTerm(newSearchValue)
              }}
            />
          </div>
          <ShowMenu />
          {canAddPatient && (
            <RegisterTreatmentButton
              formatSuccessRoute={(treatmentId) =>
                `/caseload/treatment/${treatmentId}/basic-info`
              }
            >
              <Button
                color="primary"
                variant="contained"
                sx={{
                  marginLeft: 'auto',
                  marginRight: '32px',
                  whiteSpace: 'nowrap',
                  alignSelf: 'baseline',
                }}
              >
                {dStrings.addNewPatient}
              </Button>
            </RegisterTreatmentButton>
          )}
        </>
      ) : (
        <>
          <div className="discharge-header">
            {caseloadState === caseloadStateEnum.DISCHARGE
              ? dStrings.dischargedPatients
              : dStrings.pendingPatients}
          </div>
          <SearchInput
            value={searchText}
            resetValue={!searchText.length}
            handleChange={(newSearchValue) => {
              searchTextInTreatment(newSearchValue)

              if (caseloadState === caseloadStateEnum.DISCHARGE) {
                setCanLoad(true)
                setSearchTerm(newSearchValue)
              }
            }}
          />
        </>
      )}
    </div>
  )
}

CaseloadHeader.propTypes = {
  displayFilterMenu: PropTypes.bool,
  searchText: PropTypes.string,
  searchTextInTreatment: PropTypes.func,
  toggleFilterMenu: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    caseloadState: state.caseload.caseloadState,
    displayFilterMenu: state.caseload.displayFilterMenu,
    searchText: state.caseload.searchText,
  }
}

const mapDispatchToProps = {
  searchTextInTreatment,
  toggleFilterMenu,
  loadTreatments,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseloadHeader)
