import React from 'react'
import PropTypes from 'prop-types'
import { useOptionsContext } from './optionsContext'

import IconArrowDown from './icons/icon_arrow_down.svg'

export default function TableHeader({
  header,
  handleOrderBy,
  orderKey,
  ascOrder,
}) {
  const options = useOptionsContext()

  const renderCell = (hData, index) => {
    let headerClass = ''
    if (orderKey === hData.key) {
      headerClass += ' ordered-cell'
    }
    if (hData.sortable) {
      headerClass += ' sortable-cell'
    }

    return (
      <th
        key={index}
        className={headerClass}
        onClick={!hData.sortable ? undefined : () => handleOrderBy(hData.key)}
      >
        {hData.name}
        {hData.sortable && (
          <span
            className={`header-sort-arrow ${
              orderKey === hData.key && !ascOrder ? '' : 'rotate-arrow'
            }`}
          >
            <IconArrowDown />
          </span>
        )}
      </th>
    )
  }

  return (
    <tr className={options.headerClass || 'basic-header'}>
      {header.map((h, index) => {
        return renderCell(h, index)
      })}
      {options.rowMenu && (
        <th className={options.headerClass || 'basic-header'} />
      )}
    </tr>
  )
}

TableHeader.propTypes = {
  header: PropTypes.array.isRequired,
  handleOrderBy: PropTypes.func,
}
