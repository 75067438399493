import { components } from 'react-select'
import ProfilePicture from '../common/Picture'
import { getProfileName } from '../utilities/treatments'
import NAIcon from '../../images/NA_icon.png'
import dStrings from '../../strings.json'

const { Option } = components

export function ProfilesOptions({ data, ...rest }) {
  const { value } = data || {}
  if (value !== null) {
    return (
      <Option
        {...{
          data,
          ...rest,
        }}
      >
        <div className="multi-options-container">
          <div className="multi-options-icon">
            <ProfilePicture treatment={data} />
          </div>
          <div className="multi-options-title">
            {getProfileName(data.profile) +
              (data.profile.licenseType ? `, ${data.profile.licenseType}` : '')}
          </div>
        </div>
      </Option>
    )
  }

  return (
    <Option {...{ data, ...rest }}>
      <div className="multi-options-container">
        <div className="multi-options-icon">
          <span className="profile-picture-container">
            <img id="na_icon" alt="N/A" src={NAIcon} />
          </span>
        </div>
        <div className="multi-options-title">{dStrings.NA}</div>
      </div>
    </Option>
  )
}

export const getSingleValue = (list) => {
  function SV({ children, ...props }) {
    if (!list)
      return (
        <components.SingleValue {...props}>{children}</components.SingleValue>
      )

    const selected = list && list.find((p) => p.id === props.data.value)

    if (selected) {
      const profile = selected.profile ? selected.profile : selected
      return (
        <components.SingleValue {...props}>
          {getProfileName(profile) +
            (profile.licenseType ? `, ${profile.licenseType}` : '')}
        </components.SingleValue>
      )
    }

    return (
      <components.SingleValue {...props}>{dStrings.NA}</components.SingleValue>
    )
  }

  return SV
}
