import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { AUTH_REDUCER_KEY, RootStateWithAuth } from '@valerahealth/redux-auth'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import {
  type GetList,
  StandardListItemFrag,
} from '../../shared/generated.types'
import { api as endpoints, GetListDocument } from './generated'

export type ListKey = 'dischargeReasons' | 'CMS Taxonomies'

export type GetListMapValue = {
  list: Array<StandardListItemFrag> | null
  /** a map of {value:label} to easily provide labels to keys */
  map: Record<string, string>
}
export type GetListMap = Record<ListKey, GetListMapValue | null>

const baseApi = createApi({
  reducerPath: 'configApi',
  keepUnusedDataFor: 14400, // 4 hours this api will rarely be updated, also recommended to include with redux persist,
  tagTypes: ['config'],
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.CONFIG_API_DOMAIN}/graphql`,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithAuth
      const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]
      if (isAuthenticated) {
        headers.set('Authorization', `Bearer ${session?.accessToken.jwt}`)
      }
      return headers
    },
    customErrors: (error) => {
      console.error(error.response)
      const e = error.response.errors?.[0] as any
      const response: ErrorResponse = {
        name: e?.errorType || '',
        message: e?.message || '',
        stack: error.stack || '',
      }
      return response
    },
  }),
  endpoints,
})

export const configApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getListMap: build.query<GetListMap, ListKey[]>({
      query: (keys) => ({
        document: GetListDocument,
        variables: { keys },
      }),
      transformResponse: (result: GetList): GetListMap =>
        Object.fromEntries(
          result.getList.map(({ key, list = null }) => [
            key,
            {
              list,
              map: Object.fromEntries(
                list?.map(({ value, label }) => [value, label]) || [],
              ),
            },
          ]),
        ) as GetListMap,
    }),
  }),
})
