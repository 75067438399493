import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './style.less'
import { Button, RangeDapicker, ElementsFilter } from '@valerahealth/ui-core'
import { getTimeline, loadPlans } from '../../redux/actions/treatmentRoom'
import { getTimelineByDay } from '../utilities'
import TimelineData from '../common/Timeline/TimelineData'
import dStrings from '../../strings.json'
import { loadPlanTemplates } from '../../redux/actions/planTemplateActions'

function TreatmentTimeline({
  timelineData,
  treatmentId,
  getTimeline,
  loadPlans,
  loadPlanTemplates,
  plansInstance,
  plansTemplate,
}) {
  const [data, setData] = useState()
  const [showMoreFilter, setShowMoreFilter] = useState(false)

  const getStartDate = () => {
    const today = new Date()
    const nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 14,
    )
    return nextweek
  }

  const getEndDate = () => {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
  }

  const getList = () => {
    return [
      {
        title: `${dStrings.CareTeam}:`,
        list: [
          {
            key: 'ctm_added',
            title: dStrings.ctmAdded,
          },
          {
            key: 'ctm_removed',
            title: dStrings.ctmRemoved,
          },
          {
            key: 'pctm_changed',
            title: dStrings.pctmChanged,
          },
        ],
      },
      {
        title: `${dStrings.assessments}:`,
        list: [
          {
            key: 'questionnaire_assigned',
            title: dStrings.sent,
          },
          {
            key: 'questionnaire_completed',
            title: dStrings.filledOut,
          },
        ],
      },
      {
        title: `${dStrings.article}:`,
        list: [
          {
            key: 'article_assigned',
            title: dStrings.sent,
          },
          {
            key: 'article_viewed',
            title: dStrings.read,
          },
        ],
      },
      {
        title: `${dStrings.audioTrack}:`,
        list: [
          {
            key: 'audio_track_assigned',
            title: dStrings.sent,
          },
          {
            key: 'audio_track_viewed',
            title: dStrings.read,
          },
        ],
      },
      {
        title: `${dStrings.sentiment}:`,
        list: [
          {
            key: 'sentiment_assigned',
            title: dStrings.sent,
          },
          {
            key: 'sentiment_completed',
            title: dStrings.filledOut,
          },
        ],
      },
      {
        title: `${dStrings.consent_form}:`,
        list: [
          {
            key: 'consent_form_assigned',
            title: dStrings.sent,
          },
          {
            key: 'consent_form_agreed',
            title: dStrings.filledOut,
          },
        ],
      },
      {
        title: `${dStrings.message}:`,
        list: [
          {
            key: 'message_sent',
            title: dStrings.sent,
          },
          {
            key: 'message_read',
            title: dStrings.read,
          },
        ],
      },
    ]
  }

  const getHiddenList = () => {
    return [
      {
        title: `${dStrings.tracks}:`,
        list: [
          {
            key: 'plan_assigned',
            title: dStrings.assigned,
          },
          {
            key: 'plan_unassigned',
            title: dStrings.unassigned,
          },
        ],
      },
      {
        title: `${dStrings.Alerts}:`,
        list: [
          {
            key: 'alert_created',
            title: dStrings.created,
          },
          {
            key: 'alert_resolved',
            title: dStrings.resolved,
          },
        ],
      },
      {
        title: `${dStrings.administration}:`,
        list: [
          {
            key: 'patient_registered',
            title: dStrings.registered,
          },
          {
            key: 'treatment_discharged',
            title: dStrings.discharged,
          },
          {
            key: 'treatment_restored',
            title: dStrings.restored,
          },
        ],
      },
      {
        title: `${dStrings.video}:`,
        list: [
          {
            key: 'video_session_terminated',
            title: dStrings.completed,
          },
          {
            key: 'missed_video_call',
            title: dStrings.missed,
          },
        ],
      },
      {
        title: `${dStrings.goalReminder}:`,
        list: [
          {
            key: 'reminder_assigned',
            title: dStrings.sent,
          },
          {
            key: 'reminder_acknowledged',
            title: dStrings.acknowledged,
          },
        ],
      },
      {
        title: `${dStrings.appointments}:`,
        list: [
          {
            key: 'appointment_suggested',
            title: dStrings.sent,
          },
          {
            key: 'appointment_accepted',
            title: dStrings.accepted,
          },
          {
            key: 'appointment_declined',
            title: dStrings.declined,
          },
          {
            key: 'appointment_deleted',
            title: dStrings.deleted,
          },
          {
            key: 'appointment_reschedule_requested',
            title: dStrings.rescheduleRequest,
          },
          {
            key: 'appointment_reminder_sent',
            title: dStrings.reminderSent,
          },
        ],
      },
    ]
  }

  const [startDate, setStartDate] = useState(getStartDate)
  const [endDate, setEndDate] = useState(getEndDate)
  const [filtersList, setFiltersList] = useState(getList)
  const [hiddensList, setHiddensList] = useState(getHiddenList)
  const [filterData, setFilterData] = useState({})
  const [filterList, setFilterList] = useState([])

  useEffect(() => {
    const keys = Object.keys(filterData)

    if (keys.length) {
      let filters = []
      for (const key in filterData) {
        filters = [...filters, ...filterData[key]]
      }

      setFilterList(filters)
      getTimeline(startDate.toISOString(), endDate.toISOString(), null, filters)
    }
  }, [filterData])

  useEffect(() => {
    if (!timelineData && treatmentId)
      getTimeline(startDate.toISOString(), endDate.toISOString())
  }, [treatmentId])

  useEffect(() => {
    if (treatmentId) {
      if (!plansInstance) loadPlans()
      if (!plansTemplate) loadPlanTemplates()
    }
  }, [treatmentId])

  useEffect(() => {
    if (timelineData) {
      const returnedData = getTimelineByDay([...timelineData], plansInstance)
      setData(returnedData.data)
    }
  }, [timelineData])

  return (
    <div id="treatment-timeline">
      <div className="timeline-header">
        <RangeDapicker
          start={startDate}
          end={endDate}
          handleSave={(_start, _end) => {
            setStartDate(_start)
            setEndDate(_end)
            getTimeline(
              _start.toISOString(),
              _end.toISOString(),
              null,
              filterList,
            )
          }}
        />
        <div className="filter-container">
          {!showMoreFilter &&
            filtersList
              .sort((a, b) =>
                a.title > b.title ? 1 : a.title === b.list ? 0 : -1,
              )
              .map((elem, index) => {
                return (
                  <ElementsFilter
                    key={index}
                    headerClass={
                      !showMoreFilter &&
                      index === filtersList.length - 1 &&
                      'last-path-filter'
                    }
                    title={elem.title}
                    originalList={elem.list}
                    handleChange={(_filterData) => {
                      setFilterData({
                        ...filterData,
                        [elem.title]: _filterData,
                      })
                    }}
                  />
                )
              })}
          {!showMoreFilter && (
            <div
              className="link-click"
              onClick={() => {
                setShowMoreFilter(!showMoreFilter)
              }}
            >
              + {dStrings.more}
            </div>
          )}
          {showMoreFilter &&
            [...filtersList, ...hiddensList]
              .sort((a, b) =>
                a.title > b.title ? 1 : a.title === b.list ? 0 : -1,
              )
              .map((elem, index) => {
                return (
                  <ElementsFilter
                    key={index}
                    headerClass={
                      index === filtersList.length - 1 && 'last-path-filter'
                    }
                    title={elem.title}
                    originalList={elem.list}
                    handleChange={(_filterData) => {
                      setFilterData({
                        ...filterData,
                        [elem.title]: _filterData,
                      })
                    }}
                  />
                )
              })}
          <div
            className="link-click"
            onClick={() => {
              setFiltersList(getList())
              setHiddensList(getHiddenList())
              setFilterData({ [`${dStrings.CareTeam}:`]: [] })
            }}
          >
            {dStrings.clearAllFilters}
          </div>
        </div>
      </div>
      <div
        className={`treatment-timeline-list ${
          showMoreFilter ? 'show-filtered' : ''
        }`}
      >
        {timelineData && (
          <Button
            style={{
              marginBottom: '12px',
              width: '510px',
              marginLeft: '200px',
            }}
            text={dStrings.load2PastWeeks}
            type="primary"
            onClick={() => {
              const d = new Date(startDate.getTime())
              d.setDate(d.getDate() - 14)
              getTimeline(
                d.toISOString(),
                startDate.toISOString(),
                'past',
                filterList,
              )
              setStartDate(d)
            }}
          />
        )}
        {data &&
          (Object.keys(data).length ? (
            <TimelineData state="treatment" data={data} />
          ) : (
            <div className="no-timeline">{dStrings.noTimeline}</div>
          ))}
        {timelineData && (
          <Button
            style={{ marginTop: '20px', width: '510px', marginLeft: '200px' }}
            text={dStrings.load1futureWeek}
            type="primary"
            onClick={() => {
              const d = new Date(endDate.getTime())
              d.setDate(d.getDate() + 7)
              getTimeline(
                endDate.toISOString(),
                d.toISOString(),
                'future',
                filterList,
              )
              setEndDate(d)
            }}
          />
        )}
      </div>
    </div>
  )
}

TreatmentTimeline.propTypes = {
  timelineData: PropTypes.array,
  treatmentId: PropTypes.string,
  getTimeline: PropTypes.func,
  loadPlans: PropTypes.func,
  loadPlanTemplates: PropTypes.func,
  plansInstance: PropTypes.object,
  plansTemplate: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
  const { selectedId } = state.treatmentRoom
  const room = state.treatmentRoom.byId
  const plansInstance =
    selectedId && room && room[selectedId] && room[selectedId].plansDetails
  const timeline =
    selectedId && room && room[selectedId] && room[selectedId].timeline

  const plansTemplate = state.planTemplates

  return {
    treatmentId: selectedId,
    timelineData: timeline,
    plansInstance: plansInstance && plansInstance.byId,
    plansTemplate: plansTemplate && plansTemplate.byId,
  }
}

const mapDispatchToProps = {
  getTimeline,
  loadPlans,
  loadPlanTemplates,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentTimeline)
