// component default
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import IconSearch from '../../images/icn_search.svg'
import ClearSearch from '../../images/btn_clear_search.svg'

function SearchInput({
  value,
  handleChange,
  resetValue,
  searchPlaceholder,
  searchClass,
}) {
  const [displayEraseBtn, setDisplayEraseBtn] = useState(!!value.length)
  const [searchValue, setSearchValue] = useState(value)
  const checkboxRef = useRef()

  const firstUpdate = useRef(true)
  // in place of useEffect, to avoid call on first render
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleChange(searchValue)
    setDisplayEraseBtn(!!searchValue.length)
  }, [searchValue])

  useEffect(() => {
    if (resetValue) {
      setSearchValue('')
    }
  }, [resetValue])

  return (
    <div className="input-search">
      <span className="input-search-icon">
        <IconSearch />
      </span>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <input
          autoComplete="off"
          type="text"
          value={searchValue}
          ref={checkboxRef}
          placeholder={searchPlaceholder || 'Search'}
          className={searchClass}
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
        />
        {/* Dear developer, this next line is a trick to avoid google autocomplete 
        when user change password in caseload
        page. Please see: https://pretagteam.com/question/disable-input-auto-complete-on-reactjs */}
        <input type="hidden" value="something" />
      </form>
      {displayEraseBtn && (
        <span
          className="input-search-remove"
          onClick={() => {
            setSearchValue('')
            checkboxRef && checkboxRef.current && checkboxRef.current.focus()
          }}
        >
          <ClearSearch />
        </span>
      )}
    </div>
  )
}

export default SearchInput

SearchInput.propTypes = {
  value: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchClass: PropTypes.string,
  handleChange: PropTypes.func,
}
