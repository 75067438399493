import { useTranslation as baseUseTranslation } from '@valerahealth/ui-translation'
import _en from './en'

export const SCHEDULING_NAMESPACE = 'scheduling'
function withNamespace<T>(v: T) {
  return { [SCHEDULING_NAMESPACE]: v }
}

export const en = withNamespace(_en)

/** DONT EXPORT IN PACKAGE. THIS IS INTERNAL ONLY */
export const useTranslation = () => {
  // forced to use its own namespace
  return baseUseTranslation(SCHEDULING_NAMESPACE)
}
