import { ReactElement, cloneElement, useState } from 'react'
import {
  PatientIntegrationFragment,
  patientRegistrationApi,
  patientSearchApi,
} from '@valerahealth/rtk-query'
import { Permission, RootStateWithAuth, checkPermission } from '@valerahealth/redux-auth'
import { useSelector } from 'react-redux'
import {
  Grid,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  CenteredSpinner,
  TextField as BaseTextField,
  Box,
  Typography,
  Link,
} from '../../base'
import { CloseOutlined } from '../../icons'
import {
  Checkbox,
  FormProvider,
  SaveButton,
  TextField,
  useForm,
} from '../../form'
import { useNotify } from '../Notifications'
import { useConfirmationDialog } from '../ConfirmationDialog'

const gridSpacing = 2

export type EmrIdPopupButtonProps = {
  children: ReactElement
  integration: Pick<PatientIntegrationFragment, 'emrId' | 'isActive'>
  patientProfile: {
    firstName: string
    lastName: string
    mrn?: string | null
  }
  treatmentId: string
}

export type EmrIdFormProps = Omit<EmrIdPopupButtonProps, 'children'> & {
  onCancel: () => void
}

type FormType = {
  emrId: string
  disableIntegration: boolean
}

const ConfirmationMessage = ({ emrId }: { emrId: string }) => {
  const { patient, isLoading } = patientSearchApi.useSearchPatientsQuery(
    {
      query: {
        must: { term: { 'emrId.keyword': emrId } },
      },
      pageSize: 1,
    },
    {
      selectFromResult: ({ isFetching, isUninitialized, data }) => ({
        isLoading: isFetching || isUninitialized,
        patient: data?.searchPatients.patients[0],
      }),
    },
  )

  if (isLoading)
    return (
      <Box height="4rem" width="100%">
        <CenteredSpinner />
      </Box>
    )

  if (!patient)
    return (
      <Typography>
        A patient with the EMR ID {emrId} aleady exists. Saving will clear the
        EMR ID from the conflicting patient profile and disabled their
        integration with the EMR. Do you wish to continue?
      </Typography>
    )

  return (
    <>
      <Typography gutterBottom>
        A patient with the EMR ID {emrId} aleady exists:
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ ml: 1 }}>
        <Link to={`/caseload/treatment/${patient.treatmentId}`}>
          {patient.name.fullName} - {patient.mrn}
        </Link>
      </Typography>
      <Typography>
        Saving will clear the EMR ID from the conflicting patient profile and
        disabled their integration with the EMR. Do you wish to continue?
      </Typography>
    </>
  )
}

export function EmrIdForm({
  integration,
  patientProfile,
  treatmentId,
  onCancel,
}: EmrIdFormProps) {
  const { firstName, lastName, mrn } = patientProfile

  const notify = useNotify()

  const { confirm, ConfirmationDialog } = useConfirmationDialog()

  const methods = useForm<FormType>({
    defaultValues: {
      emrId: integration.emrId ?? '',
      disableIntegration: integration.isActive === false,
    },
  })

  const [updateEmrId, updateEmrIdRes] =
    patientRegistrationApi.useUpdatePatientIntegrationMutation()

  const handleSave = async ({
    emrId,
    disableIntegration,
    forceOverwrite = false,
  }: FormType & { forceOverwrite?: boolean }) => {
    if (!emrId) {
      const confirmed = await confirm({
        body: 'Removing the EMR ID will remove the EMR integration for this patient. Do you still want to continue?',
        header: 'Remove EMR ID',
        confirmLabel: 'Yes',
        cancelLabel: 'No',
      })
      if (!confirmed) return
    }

    const data = await updateEmrId({
      treatmentId,
      content: {
        emrId: emrId || null,
        isActive: !disableIntegration,
        forceOverwrite,
      },
    })

    if ('data' in data) {
      notify({
        severity: 'success',
        message: 'Patient EMR ID updated successfully.',
      })
      methods.reset({
        emrId: data.data.updatePatientIntegration.emrId || '',
        disableIntegration:
          data.data.updatePatientIntegration.isActive === false,
      })
    } else {
      const message =
        data.error.message || "An error occured updating patient's EMR ID"

      if (message === '[patient_emr_id_conflict]') {
        const confirmed = await confirm({
          body: <ConfirmationMessage emrId={emrId} />,
          header: 'EMR ID Conflict',
          confirmLabel: 'Yes, clear and update emr id',
        })
        if (confirmed)
          handleSave({ emrId, disableIntegration, forceOverwrite: true })
      } else {
        notify({
          severity: 'error',
          message,
        })
      }
    }
  }

  return <>
    <FormProvider {...methods}>
      <Grid
        container
        spacing={gridSpacing}
        component="form"
        onSubmit={methods.handleSubmit(handleSave)}
      >
        <Grid item sm={6} xs={12}>
          <BaseTextField
            label="First Name"
            value={firstName}
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <BaseTextField
            label="Last Name"
            value={lastName}
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <BaseTextField
            label="MRN"
            value={mrn || ''}
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={gridSpacing}
          alignItems="center"
        >
          <Grid item sm={6} xs={12}>
            <TextField
              label="EMR ID"
              name="emrId"
              validate={(v) =>
                !v || !/[^\d]/.test(v) || 'Value must be a number.'
              }
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Checkbox label="Disable Integration" name="disableIntegration" />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" gap={gridSpacing}>
            <Button
              variant="text"
              color="inherit"
              sx={{ ml: 'auto' }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <SaveButton
              isSuccess={updateEmrIdRes.isSuccess}
              isError={false}
            />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
    <ConfirmationDialog
      header="EMR ID Conflict"
      confirmButtonColor="warning"
      confirmLabel="Yes, clear and update emr id"
    />
  </>;
}

export function EditEmrIdPopupButton({
  children,
  ...props
}: EmrIdPopupButtonProps) {
  const [open, setOpen] = useState(false)

  const canUpdateEmrId = useSelector((state: RootStateWithAuth) =>
    checkPermission(state, Permission.PatientIntegration_Update),
  )

  if (!canUpdateEmrId) return null

  const close = () => setOpen(false)

  return (
    <>
      {cloneElement(children, {
        onClick: () => setOpen(true),
      })}
      <Dialog open={!!open} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          Edit EMR ID
          <IconButton title="Close" onClick={close}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && <EmrIdForm {...props} onCancel={close} />}
        </DialogContent>
      </Dialog>
    </>
  )
}
