import { type HeaderProps } from 'react-big-calendar'
import { isSameDay } from 'date-fns'
import { Typography, Box } from '../../base'

export function CalendarHeaderCell({ date, label }: HeaderProps) {
  const [day, dayOfWeek] = label.split(' ')

  if (!day || !dayOfWeek)
    return (
      <Typography component="span" variant="subtitle1">
        {day || dayOfWeek}
      </Typography>
    )

  const isToday = isSameDay(date, new Date())
  return (
    <Typography
      component="span"
      variant="subtitle1"
      display="inline-flex"
      alignItems="baseline"
      gap={0.5}
      color={isToday ? (theme) => theme.palette.info.main : ''}
    >
      {isToday ? (
        <Box
          component="span"
          sx={(theme) => ({
            color: theme.palette.info.contrastText,
            backgroundColor: theme.palette.info.main,
            borderRadius: '50%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '1.5rem',
            width: '1.5rem',
            textAlign: 'center',
          })}
        >{`${day}`}</Box>
      ) : (
        day
      )}
      {dayOfWeek && (
        <Box
          component="span"
          sx={
            isToday ? { color: (theme) => theme.palette.info.main } : undefined
          }
        >{` ${dayOfWeek}`}</Box>
      )}
    </Typography>
  )
}
