import { GetStateType } from 'redux/store'
import { io } from 'socket.io-client'
import { AUTH_REDUCER_KEY } from '@valerahealth/redux-auth'
import {
  careManagerApi,
  CMChannelItem,
  CMChannelItemOverview,
  TreatmentNotification,
  UnknownEvent,
  WSCommunicationItemCreatedEvent,
} from '@valerahealth/rtk-query'
import { getIsCTMMessage } from '../components/utilities'
import { getProfileName } from '../components/utilities/treatments'
import { receiveNewChannelMessage } from '../redux/actions/treatmentRoom'

export type WebsocketEvents = Array<
  WSCommunicationItemCreatedEvent | UnknownEvent
>

function channelItemToOverview({
  domainEvent,
  message,
  ts,
  ...i
}: CMChannelItem): CMChannelItemOverview {
  return {
    target: i.attachment?.category,
    ts: new Date(ts).toISOString(),
    name: getProfileName(i.sender),
    text: message || '',
    domainEvent,
  }
}

//
function eventToNewTreatmentNotification(
  w: WSCommunicationItemCreatedEvent,
): TreatmentNotification {
  return {
    id: w.data.treatmentId,
    unread: [channelItemToOverview(w.data.channelItem)],
    count: 1,
    profile: {
      firstName: w.data.channelItem.sender?.firstName || undefined,
      lastName: w.data.channelItem.sender?.lastName || undefined,
      preferredName: w.data.channelItem.sender?.preferredName || undefined,
      picture: w.data.channelItem.sender?.picture || undefined,
    },
  }
}

export const careManagerEnhancedApi = careManagerApi.enhanceEndpoints({
  endpoints: {
    getNotifications: {
      onCacheEntryAdded: async (
        arg,
        {
          getState,
          dispatch,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateCachedData,
        },
      ) => {
        const state = getState() as ReturnType<GetStateType>
        const token = state[AUTH_REDUCER_KEY].session?.idToken.jwt
        const careManagerId = state.auth.session?.user.careManagerId
        const ws = io(`https://${process.env.CARE_MANAGER_API_DOMAIN}`, {
          transports: ['websocket', 'polling'],
          auth: { token },
        })
        try {
          await cacheDataLoaded
          ws.on('event', (data: WebsocketEvents) => {
            const messages = data.filter(
              (v): v is WSCommunicationItemCreatedEvent =>
                v.event === 'communication_item_created',
            )
            if (messages.length) {
              // update cache data
              updateCachedData((draft) => {
                // console.log(original(draft), messages)
                for (const message of messages) {
                  const notification = draft.find(
                    (d) => d.id === message.data.treatmentId,
                  )

                  if (message.data.channelItem.sender?.type === 'Patient') {
                    // legacy redux states, related to chat and caseload grid
                    dispatch(receiveNewChannelMessage(message.data))
                    // refactored part
                    if (!notification) {
                      draft.push(eventToNewTreatmentNotification(message))
                    } else {
                      const { channelItem } = message.data
                      if (notification.unread) {
                        notification.unread = [
                          channelItemToOverview(channelItem),
                          ...notification.unread,
                        ]
                      } else {
                        notification.unread = [
                          channelItemToOverview(channelItem),
                        ]
                      }
                    }
                  } else if (
                    message.data.channelItem.sender?.type === 'System' ||
                    (message.data.channelItem.sender?.type === 'CTM' &&
                      message.data.channelItem.sender?.id !== careManagerId) ||
                    (getIsCTMMessage(message.data.channelItem.domainEvent) &&
                      message.data.channelItem.sender?.id !== careManagerId)
                  ) {
                    // legacy redux states, related to chat and caseload grid
                    dispatch(receiveNewChannelMessage(message.data))
                  }
                }
              })
            }
          })
        } catch (e) {
          console.error(e)
        }
        await cacheEntryRemoved
        ws.disconnect()
      },
    },
  },
})
