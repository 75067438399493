import { createSelector } from '@reduxjs/toolkit'
import { configApi } from '../services'

export const configApiProgramSettingsSelector =
  configApi.endpoints.getProgramSettings.select()

export const isWellnessSelector = createSelector(
  configApiProgramSettingsSelector,
  (settings) => !!settings?.data?.getProgramSettings?.flags?.isWellness,
)
