import { STATES, type StateType } from '@valerahealth/rtk-query'
import { Combobox, ComboboxProps } from './Select'

type Props = Omit<ComboboxProps<StateType, false>, 'options'>

/** assumes the form is using stateCode as a value. can override setValueAs and parseValue if not */
export function USStateCombobox({
  getOptionLabel = (v) => {
    return v && `${v.name} (${v.code})`
  },
  setValueAs = (v) => {
    return v && v.code
  },
  parseValue = (v) => {
    return STATES.find((s) => s.code === v) || null
  },
  ...props
}: Props) {
  return (
    <Combobox
      {...props}
      options={STATES}
      getOptionKey={(o) => o.code}
      getOptionLabel={getOptionLabel}
      setValueAs={setValueAs}
      parseValue={parseValue}
      isOptionEqualToValue={(o, v) => o.code === v.code}
    />
  )
}

export type USStateComboboxProps = Props
