/* eslint no-param-reassign:0 */
import {
  type TypedUseSelectorHook,
  useSelector,
  useDispatch,
} from 'react-redux'
import {
  createSlice,
  PayloadAction,
  Reducer,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import {
  type CalendarActionPayloadTypes,
  initialState as calendarInititalState,
  reducer as calendarReducer,
} from '@valerahealth/ui-components/features/Calendar'
import {
  reducerPath as authReducerPath,
  AuthState,
} from '@valerahealth/redux-auth'
import type { SchedulingState, SchedulingView } from './schedulingState.types'

export * from './schedulingState.types'

const initialState: SchedulingState = {
  view: null,
  calendarState: calendarInititalState,
}

const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    openView: (state, { payload }: PayloadAction<SchedulingView>) => {
      state.view = payload
    },
    closeView: (state) => {
      state.view = null
    },
  },
})

// custom combined reducer
export const reducer: Reducer<
  SchedulingState,
  SchedulingActionPayloadTypes | CalendarActionPayloadTypes
> = (state, payload) => {
  const updated = schedulingSlice.reducer(state, payload)
  const updatedCalendarState = calendarReducer(updated.calendarState, payload)
  if (updatedCalendarState !== updated.calendarState) {
    return {
      ...updated,
      calendarState: updatedCalendarState,
    }
  }
  return updated
}

export const { actions, name: reducerPath } = schedulingSlice

export type SchedulingActionPayloadTypes = ReturnType<
  (typeof actions)[keyof typeof actions]
>

export const useReduxSelector: TypedUseSelectorHook<{
  [schedulingSlice.name]: SchedulingState
  [authReducerPath]: AuthState
}> = useSelector

export const useReduxDispatch = () =>
  useDispatch() as ThunkDispatch<
    SchedulingState,
    any,
    SchedulingActionPayloadTypes | CalendarActionPayloadTypes
  >
