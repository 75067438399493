import { beginApiCall, apiCallError, endApiCall } from './apiStatusActions'
import { addUserMessage } from './globalActions'
import dStrings from '../../strings.json'

export const callApi = ({
  name,
  dispatch,
  call,
  success,
  error,
  hideLoader,
  successMessage,
  errorMessage,
}: {
  name?: string //usefull for debugging
  dispatch: (arg0: any) => any
  call: (arg0?: any) => any
  success: (arg0: any) => void
  error?: (arg0: any) => void
  hideLoader?: boolean
  successMessage?: string
  errorMessage?: string
}) => {
  if (!hideLoader) dispatch(beginApiCall())

  const handleError = (errorData: any) => {
    console.log(errorData)
    if (!hideLoader) dispatch(endApiCall())

    if (error) error(errorData)
    else {
      dispatch(
        addUserMessage(
          'error',
          errorMessage || dStrings.errorOccurred,
          null,
          !errorMessage,
        ),
      )
      dispatch(apiCallError())
      // throw error;
    }
  }

  try {
    return call()
      .then((data: any) => {
        success(data)
        if (successMessage) dispatch(addUserMessage('success', successMessage))
        if (!hideLoader) dispatch(endApiCall())
      })
      .catch(handleError)
  } catch (error) {
    // 500
    handleError(error)
  }
}

export const getValidationError = (obj?: {
  validation: { keys: string[] }
}) => {
  if (obj?.validation?.keys[0]) {
    switch (obj.validation.keys[0]) {
      case 'profile.email':
      case 'email':
        return 'email'
      case 'profile.firstName':
      case 'firstName':
        return 'firstName'
      case 'profile.lastName':
      case 'lastName':
        return 'lastName'
      case 'profile.phone':
      case 'phone':
        return 'phone'
      case 'profile.pronouns':
        return 'pronouns'
      case 'profile.demographicsNotes':
        return 'demographicsNotes'
      default:
        break
    }
  }
}

export const getErrorMessage = (response?: any) => {
  try {
    if (response && response.message) {
      const obj = JSON.parse(response.message)
      return obj
    }
  } catch (error) {
    return null
  }
}

export const isSupervisorError = (response?: any) => {
  const errorObj = getErrorMessage(response)

  if (!errorObj || !errorObj.error) return false

  const flag =
    [
      'supervisor_has_supervisees_or_billing_supervisees',
      'supervisor_has_supervisees',
      'provider_has_supervisees',
    ].indexOf(errorObj.error) !== -1

  if (flag) return true

  return (
    [
      'supervisor_has_supervisees_or_billing_supervisees',
      'supervisor_has_supervisees',
      'provider_has_supervisees',
    ].indexOf(errorObj.message) !== -1
  )
}
