import { type GridValidRowModel } from '@mui/x-data-grid-pro'

import GridViews, { GridViewsProps } from './GridViews'
import { GridToolbar, GridToolbarProps } from '../Toolbar'

export default function Toolbar<T extends GridValidRowModel>({
  children,
  gridViewProps,
  ...props
}: GridToolbarProps & {
  gridViewProps: GridViewsProps<T>
}) {
  return (
    <GridToolbar {...props}>
      <GridViews
        {...gridViewProps}
        sx={{
          order: 550,
          flex: '2 2 100%',
          maxWidth: '20rem',
          ...gridViewProps?.sx,
        }}
      />
      {children}
    </GridToolbar>
  )
}
