import {
  add,
  format,
  formatISO,
  isAfter,
  isBefore,
  type Interval,
  addMinutes,
  startOfDay,
  areIntervalsOverlapping,
  set,
} from 'date-fns'
import {
  formatInTimeZone,
  getTimezoneOffset,
  toZonedTime,
  fromZonedTime,
} from 'date-fns-tz'

export * from 'date-fns'
export {
  formatInTimeZone,
  getTimezoneOffset,
  fromZonedTime,
  toZonedTime,
} from 'date-fns-tz'

/** expects date to be yyyy-MM-dd, returns a localized Date with YYYY-MM-DDT00:00:00  */
export const isBetween = (interval: Interval, date: Date) =>
  isAfter(date, interval.start) && isBefore(date, interval.end)

/** expects date to be yyyy-MM-dd (time portion optional), returns a localized Date with YYYY-MM-DDT00:00:00  */
export const strToDate = (val?: string | null, endOfDay: boolean = false) => {
  if (!val) return null
  const date = new Date(val)
  /**  dont actual pass back a date until they type in a full year */
  if (date.toString() === 'Invalid Date') return null
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    endOfDay ? 23 : 0,
    endOfDay ? 59 : 0,
    endOfDay ? 59 : 0,
    endOfDay ? 999 : 0,
  )
}

export const formatString = (val: string | null | undefined, f: string) => {
  if (!val) return null
  try {
    const date = strToDate(val)
    if (!date) return null
    return format(date, f)
  } catch (error) {
    return null
  }
}

/** returns '' for null or date before 1900, else returns ISO date string YYYY-MM-DD */
export const dateToStr = (val: Date | null) => {
  if (!val || Number.isNaN(val.getFullYear()) || val.getFullYear() < 1900)
    return ''
  /** this is going to be a local date */
  return format(val, 'yyyy-MM-dd')
}
/** returns ISO date string if valid date else null */
export const dateToISODateStr = (val: Date | null): string | null =>
  dateToStr(val) || null

/** returns '' for null or date before 1900, else returns ISO date string YYYY-MM-DDT00:00:00Z ignores timezone! */
export const dateToDateTimeStrIgnoreTimezone = (
  val: Date | null,
  /** true sets date a yyyy-mm-ddT23:59:59:999Z else sets time to 00:00:00 */
  endOfDay = false,
) => {
  if (!val || Number.isNaN(val.getFullYear()) || val.getFullYear() < 1900)
    return ''
  /**  this is going to be a local date */
  return `${format(val, 'yyyy-MM-dd')}${endOfDay ? 'T23:59:59Z' : 'T00:00:00Z'}`
}

/**  Null or a valid date with a year past 1900 returns true, invalid date returns false */
export const isValidDateOrNull = (
  val: string | Date | null,
): val is Date | string | null => {
  let _date = val

  if (typeof val === 'string') {
    _date = new Date(val)
  }

  return (
    _date === null ||
    (!(_date.toString() === 'Invalid Date') &&
      _date instanceof Date &&
      _date.getFullYear() > 1900)
  )
}

export const isValidDateAndNonNull = (
  val?: Date | string | null,
): val is Date | string => {
  let _date = val
  if (!val) return false

  if (typeof val === 'string') {
    _date = new Date(val)
  }
  return isValidDateOrNull(_date as Date)
}

export const isoTimeToMinutes = (isoTime: string) => {
  const [hours, minutes] = isoTime.split(':').map((v) => parseInt(v, 10))
  return hours! * 60 + minutes!
}

/** number must be less than 24 * 60 */
export const minutesToIsoTime = (minutes: number) => {
  if (minutes > 24 * 60) return '00:00'
  const hour = Math.floor(minutes / 60)
  const minute = minutes % 60
  const date = new Date(0, 0, 0, hour, minute, 0, 0)
  return format(date, 'HH:mm')
}

const generateTimeOptionsDefaultOptions = {
  displayFormat: 'h:mm aa',
  min: 0,
  max: 24,
}

export type TimeOption = { iso: string; display: string }
/**  ensures we cache results if used in multiple places in application */
const memoizedGenerateTimeOptions: Record<string, TimeOption[]> = {}

/**  15 minute time options */
export const generateTimeOptions = (
  minuteInterval: 5 | 10 | 15 | 30 | 45 | 60 | 90 | 120,
  options?: {
    /**  defaults to h:mmaaa, i.e. 9:00am. see format from date-fns for more options */
    displayFormat?: string
    /**  HOURS from start of day to start generating options, 9 = 9am */
    min?: number
    /**  Hours from start of day to end generating options, 17 = 5pm */
    max?: number
  },
) => {
  const cacheKey = JSON.stringify([minuteInterval, options])

  if (memoizedGenerateTimeOptions[cacheKey]) {
    return memoizedGenerateTimeOptions[cacheKey]!
  }

  const { displayFormat, min, max } = {
    ...generateTimeOptionsDefaultOptions,
    ...options,
  }

  const intervalsInOneHour = 60 / minuteInterval

  const timeOptions = Array.from(
    Array((max - min) * intervalsInOneHour + (max - min === 24 ? 0 : 1)).keys(),
  ).map((number) => {
    const hour = Math.floor(number / intervalsInOneHour) + min
    const minute = (number % intervalsInOneHour) * (60 / intervalsInOneHour)
    const date = new Date(0, 0, 0, hour, minute, 0, 0)
    return {
      iso: format(date, 'HH:mm'),
      display: format(date, displayFormat),
    }
  })

  memoizedGenerateTimeOptions[cacheKey] = timeOptions
  return timeOptions
}

export const DEFAULT_TIMEZONE = 'America/New_York'
export const SYSTEM_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

const defaultIsoDateTimeToTupleOptions = {
  timezone: SYSTEM_TIMEZONE,
  timeFormat: generateTimeOptionsDefaultOptions.displayFormat,
}

export type DateTimeTuple = [Date | null, TimeOption | null]
/** must be full iso datetime, splits and returns a date to be used in a datepicker, and a time option in the same format generateTimeOt */
export const isoDateTimeToTuple = (
  /** ISO DateTime */
  dateTime?: string | null,
  options?: {
    timezone?: string
    timeFormat?: string
  },
  /** can return in a specific timezone, else returns in users locale */
): DateTimeTuple => {
  if (!dateTime) return [null, null]

  const { timezone, timeFormat } = {
    ...defaultIsoDateTimeToTupleOptions,
    ...options,
  }

  // this is in the browser timezone
  const dateInLocalTime = new Date(dateTime)

  // converts to specified timezone
  const date = toZonedTime(dateInLocalTime, timezone)

  const timeOption: TimeOption = {
    iso: formatInTimeZone(dateInLocalTime, timezone, 'HH:mm'),
    display: formatInTimeZone(dateInLocalTime, timezone, timeFormat),
  }

  return [startOfDay(date), timeOption]
}

// accepts local date, and will cast to timezone if provided
export const dateTimeTupleToDate = (
  date: Date,
  time: TimeOption,
  timezone?: string,
): Date => {
  const dt = set(startOfDay(date), { minutes: isoTimeToMinutes(time.iso) })
  return timezone ? fromZonedTime(dt, timezone) : dt
}

export const dateTimeTupleToIsoDateStr = (
  date: Date,
  time: TimeOption,
  timezone?: string,
): string => {
  return formatISO(dateTimeTupleToDate(date, time, timezone))
}

/** formats a number of minutes */
export const formatMinutes = (
  minutes: number,
  displayFormat = 'h:mm aa',
): string => {
  return format(new Date(0, 0, 0, 0, minutes, 0, 0), displayFormat)
}

/**  reformats an ISO time string to any other displayed value. See date-utils format for format string */
export const formatIsoTime = (
  isoTime: string,
  displayFormat = 'h:mm aa',
): string => {
  return formatMinutes(isoTimeToMinutes(isoTime), displayFormat)
}

export const US_TIMEZONES = {
  Pacific: 'America/Los_Angeles',
  Mountain: 'America/Denver',
  Phoenix: 'America/Phoenix',
  Central: 'America/Chicago',
  Eastern: 'America/New_York',
} as const

export type UsTimezone = (typeof US_TIMEZONES)[keyof typeof US_TIMEZONES]
export const US_TIMEZONE_SET = new Set(Object.values(US_TIMEZONES))
export const SYSTEM_US_TIMEZONE = [...US_TIMEZONE_SET].find(
  (x) => x === SYSTEM_TIMEZONE,
)
  ? SYSTEM_TIMEZONE
  : DEFAULT_TIMEZONE

export const TIMEZONE_VALUES = [
  {
    label: 'Pacific Time - Los Angeles',
    value: 'America/Los_Angeles' as const,
  },
  {
    label: 'Mountain Time - Denver',
    value: 'America/Denver' as const,
  },
  {
    label: 'Mountain Time - Phoenix',
    value: 'America/Phoenix' as const,
  },
  {
    label: 'Central Time - Chicago',
    value: 'America/Chicago' as const,
  },
  {
    label: 'Eastern Time - New York',
    value: 'America/New_York' as const,
  },
]

type TimeZone = (typeof TIMEZONE_VALUES)[number]['value']

export const TIMEZONE_LABELS = Object.fromEntries(
  TIMEZONE_VALUES.map(({ label, value }) => [value, label]),
) as { [key in TimeZone]: string }

/** ensures that if we call this function multiple times throughout the application it wont keep regenerating values */
let memoizedGetTimeZoneValues:
  | { value: TimeZone; utcOffset: number; label: string }[]
  | null = null
export const getTimezoneValues = () => {
  if (memoizedGetTimeZoneValues) return memoizedGetTimeZoneValues
  memoizedGetTimeZoneValues = TIMEZONE_VALUES.map(({ label, value }) => ({
    value,
    utcOffset: getTimezoneOffset(value),
    label: `(${formatInTimeZone(new Date(), value, 'OOOO')}) ${label}`,
  })).sort((a, b) => a.utcOffset - b.utcOffset)
  return memoizedGetTimeZoneValues
}

export const getUSTimezone = (timezone?: string | null): UsTimezone =>
  (timezone && [...US_TIMEZONE_SET].find((x) => x === timezone)
    ? timezone
    : SYSTEM_US_TIMEZONE) as UsTimezone

/** takes an iso date YYYY-MM-DD, treats it as a date in your locale and formats it, DO NOT USE FOR DATETIMES */
export const formatIsoDate = (
  isoDate: string | null | undefined,
  displayFormat = 'MMMM d, yyyy',
) => {
  const date = strToDate(isoDate)
  return date ? format(date, displayFormat) : ''
}

/** takes a full ISO Date Time and formats it, DO NOT USE FOR DATES i.e. 'YYYY-MM-DD' */
export const formatIsoDateTime = (
  isoDate: string,
  displayFormat = 'MMMM d, yyyy p',
) => format(new Date(isoDate), displayFormat)

/**  given an array of timeranges, returns the index of the first found range that overlapps with another else -1 */
export const indexOfOverlappingInterval = (timeranges: Interval[]) => {
  let overlappingIndex = -1
  /**  for loop so i can break at first occurance instead of running, also dont need to check the last element because there will be nothing to compare it against */
  for (let i = 0; i < timeranges.length - 1; i += 1) {
    const hasOverlap = timeranges
      /**  only need to compare to items after the current in the array, since the comparison of earlier array elements to current would have already been checked */
      .slice(i + 1)
      .some((range) => areIntervalsOverlapping(timeranges[i]!, range))
    if (hasOverlap) {
      overlappingIndex = i
      break
    }
  }
  return overlappingIndex
}

/** digitAfterDecimal defaults to 1 */
export const convertMinuteToHour = (minutes: number, digitAfterDecimal = 1) => {
  if (!minutes) return 0
  return Number(Number(minutes / 60).toFixed(digitAfterDecimal))
}

export const getIntervalWithDateAndTime = (input: {
  startDate?: Date | null
  startTime?: string | null
  endDate?: Date | null
  endTime?: string | null
}): Interval | undefined => {
  const { startDate, startTime, endDate, endTime } = input
  if (!(startDate && endDate && startTime && endTime)) return undefined
  try {
    return {
      start: addMinutes(startDate, isoTimeToMinutes(startTime)),
      end: addMinutes(endDate, isoTimeToMinutes(endTime)),
    }
  } catch {
    return undefined
  }
}
