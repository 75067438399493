import { useMemo } from 'react'
import {
  type AppointmentConflict,
  type AppointmentWarningFragment,
  type ConflictErrorResponse,
  AppointmentStatus,
  ResourceType,
} from '@valerahealth/rtk-query'
import LABELS from '@valerahealth/ui-translation/locales/en'
import {
  formatInTimeZone,
  toZonedTime,
} from '@valerahealth/ui-components/utils/date'
import { ConfirmTrigger, Typography } from '@valerahealth/ui-components'
import { useFormContext } from '@valerahealth/ui-components/form'

import { FormType, SubmittedFormType } from './formType'
import { isPatientApptServiceType } from '../../utilities'
import { usePatientApptMeta } from '../PatientAppointmentMeta/hooks'

export const conflictToMessage = (
  { serviceType, startDate, endDate }: AppointmentConflict,
  isPatient: boolean,
  timezone: string,
): string => {
  const startDateTime = new Date(startDate)
  const endDateTime = new Date(endDate)
  return `This appointment conflicts with a ${
    serviceType ? LABELS.ServiceTypeCode[serviceType] : ''
  } scheduled with the ${
    isPatient ? 'Patient' : 'Provider'
  } on ${formatInTimeZone(
    startDateTime,
    timezone,
    'PP, p',
  )} - ${formatInTimeZone(endDateTime, timezone, 'p z')}.`
}

export const handleAppointmentConflicts = async (
  conflictError: ConflictErrorResponse,
  fields: SubmittedFormType,
  status: AppointmentStatus,
  confirm: ConfirmTrigger,
): Promise<false | AppointmentStatus> => {
  const { serviceType, timezone } = fields
  const isOverlapAllowed =
    isPatientApptServiceType(serviceType) &&
    !conflictError.patientConflictAppt?.length &&
    !conflictError.providerConflictAppt?.some(
      (a) => a.serviceType && isPatientApptServiceType(a.serviceType),
    )
  const conflictMessages = [
    ...conflictError.patientConflictAppt.map((conf) =>
      conflictToMessage(conf, true, timezone),
    ),
    ...conflictError.providerConflictAppt.map((conf) =>
      conflictToMessage(conf, false, timezone),
    ),
  ].map((v) => (
    <Typography key={v} gutterBottom>
      {v}
    </Typography>
  ))

  // if not over writable
  if (!isOverlapAllowed) {
    await confirm({
      header: 'Appointment Conflict',
      hideCancelBtn: true,
      confirmLabel: 'Confirm',
      body: (
        <>
          {...conflictMessages}
          <Typography>
            Please change the appointment time to avoid conflicts.
          </Typography>
        </>
      ),
    })
    return false
  }
  let s: AppointmentStatus | false = false

  const result = await confirm({
    header: 'Appointment Conflict',
    body: (
      <>
        {...conflictMessages}
        <Typography>Do you want to save the appointment anyway?</Typography>
      </>
    ),
    hideConfirmBtn: true,
    extraButtons: [
      {
        label: 'Propose',
        onClick: () => {
          s = AppointmentStatus.Proposed
        },
      },
      {
        label: 'Schedule',
        onClick: () => {
          s = status
        },
      },
    ],
  })

  return result ? s : false
}

export const handleAppointmentWarningConflicts = async (
  warnings: AppointmentWarningFragment[],
  timezone: string,
  confirm: ConfirmTrigger,
) => {
  try {
    const warningMessages = warnings.map(({ startDate, endDate, message }) => {
      const start = startDate && new Date(startDate)
      const end = endDate && new Date(endDate)
      const participants = [
        message?.includes(ResourceType.Practitioner) ? 'the Provider' : '',
        message?.includes(ResourceType.Patient) ? 'the Patient' : '',
      ].filter((v) => !!v)
      return (
        <Typography
          key={startDate}
          component="li"
        >{`The recurring appointment on ${
          start
            ? formatInTimeZone(start, timezone, 'EEEE, LLLL d, yyyy - h:mm aa')
            : ''
        } - ${
          end ? formatInTimeZone(end, timezone, 'h:mm aa') : ''
        }, could not be scheduled, only proposed, because ${participants.join(
          ' and ',
        )} ${
          participants.length > 1 ? 'have' : 'has'
        } an existing appointment that conflicts with this time.`}</Typography>
      )
    })

    if (warningMessages.length) {
      await confirm({
        header: 'Appointment Conflict',
        body: (
          <>
            <Typography gutterBottom>
              One or more appointments in the repeated series had a conflict:
            </Typography>
            <ol>{warningMessages}</ol>
          </>
        ),
        hideCancelBtn: true,
        confirmLabel: 'Okay',
        confirmButtonColor: 'info',
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export const useFormPatientApptMeta = () => {
  const { watch } = useFormContext<FormType>()
  const treatmentId = watch('patient')?.treatmentId
  const providerId = watch('providerId')
  const [startDate, timezone] = watch(['startDate', 'timezone'])

  const appointmentDate = useMemo(() => {
    return startDate && timezone ? toZonedTime(startDate, timezone) : null
  }, [startDate, timezone])

  return {
    treatmentId,
    providerId,
    appointmentDate,
    ...usePatientApptMeta({
      treatmentId,
      providerId,
      appointmentDate,
    }),
  }
}

export type FormPatientApptMeta = ReturnType<typeof useFormPatientApptMeta>
