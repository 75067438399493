import { Check } from '@mui/icons-material'
import { InsuranceOrdinality } from '@valerahealth/rtk-query'
import {
  Stack,
  Chip,
  SxProps,
  Theme,
  CircularProgress,
  Typography,
} from '@valerahealth/ui-components'
import { PatientAppointmentMetaReturnType } from './hooks'

export const AppointmentAlerts = ({
  patientInsurance,
  isAnyLoading,
  fullConsentPackedSigned,
  creditCardOnFile,
  provider,
  patientBilling,
  sx,
}: PatientAppointmentMetaReturnType & { sx?: SxProps<Theme> }) => {
  const primIns = patientInsurance?.[InsuranceOrdinality.Primary]
  const secIns = patientInsurance?.[InsuranceOrdinality.Secondary]
  const tertIns = patientInsurance?.[InsuranceOrdinality.Tertiary]
  const { isHighDeductible, eligibilityVerified, priorAuthRequired } =
    primIns || {}

  const patientBillingLink = patientBilling
    ? `https://${process.env.CARE_MANAGER_UI_DOMAIN}/caseload/treatment/${patientBilling.treatmentId}/billing`
    : ''

  const warnings: { label: string; color?: 'warning' | 'error' }[] = []

  // only show if we've loaded patient billings
  if (patientBilling && !fullConsentPackedSigned)
    warnings.push({ label: 'Consent Missing' })
  if (patientBilling && !creditCardOnFile)
    warnings.push({ label: 'Credit Card Missing' })

  // only show if we have primary insurance
  if (primIns && isHighDeductible) warnings.push({ label: 'High Deductible' })
  if (primIns && !eligibilityVerified)
    warnings.push({ label: 'Insurance Not Verified' })
  if (primIns && priorAuthRequired)
    warnings.push({ label: 'Prior Auth Required' })

  // only show if we have a provider selected
  if (provider && primIns?.billingProvider?.isActive === false)
    warnings.push({
      label: 'Billing provider does not accept primary insurance',
    })
  if (provider && secIns?.billingProvider?.isActive === false)
    warnings.push({
      label: 'Billing provider does not accept secondary insurance',
      color: 'warning',
    })
  if (provider && tertIns?.billingProvider?.isActive === false)
    warnings.push({
      label: 'Billing provider does not accept tertiary insurance',
      color: 'warning',
    })

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap={1}
      justifyContent="flex-start"
      alignItems="center"
      sx={sx}
    >
      <Typography
        variant="h6"
        sx={{
          flex: warnings.length === 0 && !isAnyLoading ? '0 0 0%' : '1 1 100%',
        }}
      >
        Alerts
      </Typography>

      {isAnyLoading && <CircularProgress size={30} />}
      {warnings.map(({ label, color = 'error' }) => (
        <Chip
          key={label}
          // onclick adds button styes to chip
          onClick={() => {}}
          component="a"
          target="_blank"
          href={patientBillingLink}
          variant="outlined"
          color={color}
          label={label}
        />
      ))}
      {warnings.length === 0 && !isAnyLoading && (
        <Chip
          variant="outlined"
          size="small"
          icon={<Check />}
          label="No active alerts"
          color="success"
          sx={{ ml: 2 }}
        />
      )}
    </Stack>
  )
}
