import { Typography, Stack } from '@mui/material'
import { Link, Box } from '../../base'

const environmentMap = {
  RND: { color: 'warning' },
  QA: { color: 'success' },
  TST: { color: 'info' },
}

export type NavbarLogoProps = {
  appName: string
  imgUrl: string
}

export function NavbarLogo({ appName, imgUrl }: NavbarLogoProps) {
  const appNameAndVersion = `${appName} - v${process.env.APP_VERSION}`
  return (
    <>
      {process.env.ENV !== 'PRD' && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            color: `${environmentMap[process.env.ENV].color}.contrastText`,
            backgroundColor: `${environmentMap[process.env.ENV].color}.main`,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              writingMode: 'vertical-rl',
            }}
          >
            {process.env.ENV}
          </Typography>
        </Box>
      )}

      <Link
        to="/"
        sx={{ textDecoration: 'none', p: 1.5, color: 'inherit' }}
        title={appNameAndVersion}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <img
            style={{ width: '2rem', height: 'auto' }}
            src={imgUrl}
            alt={appNameAndVersion}
            title={appNameAndVersion}
          />

          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontSize: '1.2rem !important',
            }}
          >
            {appName}
          </Typography>
        </Stack>
      </Link>
    </>
  )
}
