import { useMemo, useEffect } from 'react'
import {
  DayOfWeek,
  NUMBER_TO_DAY,
  ServiceTypeCode,
} from '@valerahealth/rtk-query'
import { Grid, TextField as BaseTextField } from '@valerahealth/ui-components'
import {
  Combobox,
  DatePicker,
  DayOfWeekFabs,
  TimePicker,
  useFormContext,
} from '@valerahealth/ui-components/form'
import {
  addDays,
  isoTimeToMinutes,
  formatMinutes,
  max,
} from '@valerahealth/ui-components/utils/date'
import { getDurationOptionsForServiceType } from '../../utilities'
import { useTranslation } from '../../locales'
import { TimeSelectionFormTypeSlice } from './formType'
// import EditAppointmentSeriesDialog from './EditAppointmentSeriesDialog'

const weeklyFrequencyOptions = [1, 2, 4, 8, 12]

export const TimeSelectionFormPart = ({
  serviceType,
}: {
  serviceType: ServiceTypeCode | null
}) => {
  const { t } = useTranslation()
  const { watch, setValue, getValues } =
    useFormContext<TimeSelectionFormTypeSlice>()
  const minutesDuration = watch('minutesDuration')
  const startTime = watch('startTime')
  const durationOptions = useMemo(
    () => getDurationOptionsForServiceType(serviceType),
    [serviceType],
  )
  // update the minutes duration dropdown when the options change
  useEffect(() => {
    if (durationOptions.length > 0) {
      setValue('minutesDuration', durationOptions[0]!, { shouldDirty: true })
      return
    }
    const current = getValues('minutesDuration')
    if (current && !durationOptions.includes(current))
      setValue('minutesDuration', null, { shouldDirty: true })
  }, [durationOptions, getValues, setValue])

  const endTimeDisplay = useMemo(() => {
    if (!startTime || !minutesDuration) return ''
    const endTimeMinutes = isoTimeToMinutes(startTime.iso) + minutesDuration
    return formatMinutes(endTimeMinutes)
  }, [startTime, minutesDuration])

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={4}>
        <TimePicker
          name="startTime"
          label={t('startTime')}
          required
          disableClearable
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <BaseTextField
          variant="standard"
          fullWidth
          label={t('endTime')}
          value={endTimeDisplay}
          disabled
        />
      </Grid>
      <Grid item xs={4}>
        <Combobox
          name="minutesDuration"
          label={t('duration')}
          disableClearable
          getOptionLabel={(o) => `${o} min`}
          options={durationOptions}
          readOnly={durationOptions.length <= 1}
          required={durationOptions.length > 1}
        />
      </Grid>
    </Grid>
  )
}

export const RecurringAppointmentFormPart = ({
  readOnly,
  startDate,
  requireStartDateDayOfWeek,
}: {
  readOnly?: boolean
  startDate: Date
  requireStartDateDayOfWeek?: boolean
}) => {
  const [t] = useTranslation()

  const minDate = useMemo(() => {
    // take the later of the two
    return max([new Date()].concat(startDate || []))
  }, [startDate])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <DayOfWeekFabs
          name="reoccuranceInfo.dayOfWeek"
          hideLabel
          label="Day of Week"
          required
          validate={
            requireStartDateDayOfWeek
              ? {
                  requireStartDateDayOfWeek: (value: DayOfWeek[] | null) => {
                    //let required validation to take over
                    if (!value || !value.length) return true
                    const startDateDayOfWeek =
                      NUMBER_TO_DAY[startDate.getDay()]!
                    return (
                      value.includes(startDateDayOfWeek) ||
                      `${t(`DayOfWeek.${startDateDayOfWeek}`)} must be selected`
                    )
                  },
                }
              : undefined
          }
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <Combobox
          name="reoccuranceInfo.interval"
          label="Frequency"
          disableClearable
          getOptionLabel={(o) => (o === 1 ? 'Weekly' : `${o} Weeks`)}
          isOptionEqualToValue={(o, v) => o === v}
          options={weeklyFrequencyOptions}
          fullWidth
          required
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label={t('endDate')}
          name="reoccuranceInfo.endDate"
          maxDate={readOnly ? undefined : addDays(new Date(), 90)}
          minDate={readOnly ? undefined : minDate}
          format="MMMM d, yyyy"
          fullWidth
          required
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  )
}
