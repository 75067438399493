import * as types from '../actions/actionTypes'
import initialState from './initialState'
import { getListByIdAndAllIds } from '../../components/utilities'

export default function supervisionReducer(
  state = initialState.supervision,
  action,
) {
  switch (action.type) {
    case types.LOAD_SUPERVISION_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...getListByIdAndAllIds(action.users),
        },
      }
    default:
      return state
  }
}
