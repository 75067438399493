export default {
  addSection: 'ADD SECTION',
  copyPreviousNote: 'COPY PREVIOUS NOTE',
  progressNotes: 'Progress Notes',
  templates: 'Templates',
  diagnosis: 'Diagnosis',
  optional: 'OPTIONAL',
  provider: 'Provider',
  MRN: 'MRN',
  date: 'Date',
  DOB: 'DOB',
  errorOccurred: 'Error occurred',
  title: 'Title',
  noteOnlyChangeByOwner:
    'Notes can only be changed by the creater of the note.',
  pendingTemplateMessage: 'Save Note in order to edit this section',
  api: {
    note: {
      saveSuccess: 'Note was successfully saved.',
      saveFailure: 'Could not save note.',
    },
  },
}
