import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  AUTH_REDUCER_KEY,
  Permission,
  RootStateWithAuth,
} from '@valerahealth/redux-auth'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { api as endpoints } from './generated'
import { initApplyPermissionheaders } from '../../utils'

const applyPermissionHeaders = initApplyPermissionheaders({
  //billing
  verifyEligibility: Permission.Patient_Update,
  loadEligibilityVerification: Permission.Patient_Read,
  listEligibilityVerifications: Permission.Patient_Read,
  listPatientEligibilityVerifications: Permission.Patient_Read,
  loadPatientBilling: Permission.Patient_Read,
  savePatientBilling: Permission.Patient_Update,
  generatePatientLink: Permission.Patient_Update,
  // documents
  getDocument: Permission.TreatmentDocument_Read,
  getDocumentsByTreatmentId: Permission.TreatmentDocument_Read,
  appendDocuments: Permission.TreatmentDocument_Update,
  updateDocuments: Permission.TreatmentDocument_Update,
  removeDocuments: Permission.TreatmentDocument_Update,
  // Patient Profile
  sendConsentPacket: Permission.Patient_Update,
  syncToEmr: Permission.Patient_Update,
  getPatientByTreatmentId: Permission.PatientIntegration_Read,
  updatePatientIntegration: Permission.PatientIntegration_Update,
})

export const getBaseApi = (
  origin: string,
  getAccessToken: (state: any) => string | null | undefined,
) =>
  createApi({
    reducerPath: 'patientRegistrationApi',
    tagTypes: [
      'billing',
      'eligibilityVerification',
      'eligibilityVerificationExternal',
      'documentsByTreatmentId',
      'document',
      'patient',
    ],
    keepUnusedDataFor: 120,
    baseQuery: graphqlRequestBaseQuery({
      url: `${origin}/graphql`,
      
      customErrors: (error) => {
        console.error(error.response)
        const e = error.response.errors?.[0] as any
        const response: ErrorResponse = {
          name: e?.errorType || '',
          message: e?.message || '',
          stack: error.stack || '',
        }
        return response
      },
      prepareHeaders: (headers, api) => {
        const state = api.getState() as RootStateWithAuth
        const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]
        if (isAuthenticated) {
          const accessToken = getAccessToken(state)
          headers.set('Authorization', `Bearer ${accessToken}`)
          applyPermissionHeaders(headers, session!, api.endpoint)
        }
        return headers
      },
    }),
    endpoints,
  })

export type BaseApiType = ReturnType<typeof getBaseApi>
