import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import { connect } from 'react-redux'
import { OptionsMenu } from '@valerahealth/ui-core'
import {
  changeShowMenuValue,
  loadTreatments,
} from '../../../../redux/actions/caseloadActions'
import dStrings from '../../../../strings.json'
import DefaultSortingIcon from '../../../../images/sorting/icn_default_sorting.svg'
import FamilyNameAZIcon from '../../../../images/sorting/icn_az_sorting.svg'
import FamilyNameZAIcon from '../../../../images/sorting/icn_za_sorting.svg'
import SelectSortIcon from '../../../../images/sorting/icn_sorting_selected.svg'
import ActiveDaysIcon from '../../../../images/sorting/icn_active_sorting.svg'
import InactiveDaysIcon from '../../../../images/sorting/icn_unactive_sorting.svg'
import HighestSortIcon from '../../../../images/sorting/icn_highest_sorting.svg'
import LowerSortIcon from '../../../../images/sorting/icn_lowest_sorting.svg'
import SortingIcon from '../../../../images/sorting/sorting.svg'
import SortingSelectedIcon from '../../../../images/sorting/sorting_selected.svg'
import ActivityIcon from '../../../../images/icn_activity.svg'
import UnselectedActivityIcon from '../../../../images/icn_activity_unselected.svg'
import CareplanIcon from '../../../../images/icn_careplan.svg'
import UnselectedCareplanIcon from '../../../../images/icn_careplan_unselected.svg'
import TagsIcon from '../../../../images/icn_diagnosis.svg'
import UnselectedTagsIcon from '../../../../images/icn_diagnosis_unselected.svg'
import PopulationCareplanIcon from '../../../../images/icn_careplan-1.svg'
import UnselectedPopulationCareplanIcon from '../../../../images/icn_careplan_unselected-1.svg'
import AppointmentIcon from '../../../../images/icn_appointment.svg'
import UnselectedAppointmentIcon from '../../../../images/icn_appointment_unselected.svg'
import { pageSize } from '../../../utilities/enums'

function ShowMenu({
  pagination,
  isWellness,
  showValues,
  clinicalIndicators,
  changeShowMenuValue,
  loadTreatments,
}) {
  const [selectedPagination, setSelectedPagination] = useState(pagination)
  const [selectedSort, setSelectedSort] = useState()
  const [canLoad, setCanLoad] = useState(false)

  useEffect(() => {
    if (isWellness === undefined) return

    if (!canLoad) {
      setSelectedSort('lastNotificationSorting')
      return
    }

    const p = selectedPagination.pagination
    if (!p) return

    loadTreatments(selectedPagination)

    if (!p.sort) {
      setSelectedSort('lastNotificationSorting')
    } else if (p.sort.by === 'last_name') {
      if (p.sort.order === 'ascending') {
        setSelectedSort('FamilyNameAZ')
      } else {
        setSelectedSort('FamilyNameZA')
      }
    } else if (p.sort.by === 'last_activity') {
      if (p.sort.order === 'descending') {
        setSelectedSort('ActiveInLastDays')
      } else {
        setSelectedSort('InactiveInLastDays')
      }
    } else if (p.sort.by === 'next_appointment') {
      setSelectedSort('nextApptSorting')
    } else if (p.sort.by === 'last_notification') {
      setSelectedSort('lastNotificationSorting')
    } else {
      // questionnaire_last_score
    }
  }, [isWellness, selectedPagination, canLoad])

  const getSortMenu = () => {
    const defaultList = [
      {
        title: dStrings.lastNotificationSorting,
        icon: <DefaultSortingIcon />,
        onclick: () => {
          setCanLoad(true)
          setSelectedPagination({
            pagination: {
              pageSize: pageSize.CASELOAD,
              pageNumber: 1,
              sort: {
                by: 'last_notification',
                order: 'ascending',
              },
            },
          })
        },
        border: true,
        class: 'sort-item-popup',
        endIcon: selectedSort === 'lastNotificationSorting' && (
          <SelectSortIcon />
        ),
      },

      {
        title: dStrings.FamilyNameAZ,
        icon: <FamilyNameAZIcon />,
        onclick: () => {
          setCanLoad(true)
          setSelectedPagination({
            pagination: {
              pageSize: pageSize.CASELOAD,
              pageNumber: 1,
              sort: {
                by: 'last_name',
                order: 'ascending',
              },
            },
          })
        },
        class: 'sort-item-popup',
        endIcon: selectedSort === 'FamilyNameAZ' && <SelectSortIcon />,
      },
      {
        title: dStrings.FamilyNameZA,
        icon: <FamilyNameZAIcon />,
        onclick: () => {
          setCanLoad(true)
          setSelectedPagination({
            pagination: {
              pageSize: pageSize.CASELOAD,
              pageNumber: 1,
              sort: {
                by: 'last_name',
                order: 'descending',
              },
            },
          })
        },
        border: true,
        class: 'sort-item-popup',
        endIcon: selectedSort === 'FamilyNameZA' && <SelectSortIcon />,
      },
      {
        title: dStrings.ActiveInLastDays,
        icon: <ActiveDaysIcon />,
        onclick: () => {
          setCanLoad(true)
          setSelectedPagination({
            pagination: {
              pageSize: pageSize.CASELOAD,
              pageNumber: 1,
              sort: {
                by: 'last_activity',
                order: 'descending',
              },
            },
          })
        },
        class: 'sort-item-popup',
        endIcon: selectedSort === 'ActiveInLastDays' && <SelectSortIcon />,
      },
      {
        title: dStrings.InactiveInLastDays,
        icon: <InactiveDaysIcon />,
        onclick: () => {
          setCanLoad(true)
          setSelectedPagination({
            pagination: {
              pageSize: pageSize.CASELOAD,
              pageNumber: 1,
              sort: {
                by: 'last_activity',
                order: 'ascending',
              },
            },
          })
        },
        border: true,
        class: 'sort-item-popup',
        endIcon: selectedSort === 'InactiveInLastDays' && <SelectSortIcon />,
      },
      {
        title: dStrings.nextApptSorting,
        icon: <DefaultSortingIcon />,
        onclick: () => {
          setCanLoad(true)
          setSelectedPagination({
            pagination: {
              pageSize: pageSize.CASELOAD,
              pageNumber: 1,
              sort: {
                by: 'next_appointment',
                order: 'ascending',
              },
            },
          })
        },
        border: true,
        class: 'sort-item-popup',
        endIcon: selectedSort === 'nextApptSorting' && <SelectSortIcon />,
      },
    ]

    const clinicalIndicatorList = []
    if (clinicalIndicators && clinicalIndicators.length) {
      for (let i = 0; i < clinicalIndicators.length; i++) {
        const { name } = clinicalIndicators[i]

        clinicalIndicatorList.push({
          title: dStrings.HighestScoreFirst.replace('${name}', name),
          icon: <HighestSortIcon />,
          onclick: () => {
            setSelectedSort(`ZA${name}`)
            setCanLoad(true)
            setSelectedPagination({
              pagination: {
                pageSize: pageSize.CASELOAD,
                pageNumber: 1,
                sort: {
                  by: 'questionnaire_last_score',
                  questionnaireTemplateIds: clinicalIndicators[i].templateIds,
                  order: 'descending',
                },
              },
            })
          },
          class: 'sort-item-popup',
          endIcon: selectedSort === `ZA${name}` && <SelectSortIcon />,
        })

        clinicalIndicatorList.push({
          title: dStrings.LowestScoreFirst.replace('${name}', name),
          icon: <LowerSortIcon />,
          onclick: () => {
            setSelectedSort(`AZ${name}`)
            setCanLoad(true)
            setSelectedPagination({
              pagination: {
                pageSize: pageSize.CASELOAD,
                pageNumber: 1,
                sort: {
                  by: 'questionnaire_last_score',
                  questionnaireTemplateIds: clinicalIndicators[i].templateIds,
                  order: 'ascending',
                },
              },
            })
          },
          border: true,
          class: 'sort-item-popup',
          endIcon: selectedSort === `AZ${name}` && <SelectSortIcon />,
        })
      }
    }

    return [...defaultList, ...clinicalIndicatorList]
  }

  const getIcons = (key, selected) => {
    switch (key) {
      case 'activeLast7Days':
        if (selected) return <ActivityIcon />
        return <UnselectedActivityIcon />
      case 'tags':
        if (selected) return <TagsIcon />
        return <UnselectedTagsIcon />
      case 'clinicalIndicators':
        if (selected) return <CareplanIcon />
        return <UnselectedCareplanIcon />
      case 'PopulationCarePlan':
        if (selected) return <PopulationCareplanIcon />
        return <UnselectedPopulationCareplanIcon />
      case 'appointments':
        if (selected) return <AppointmentIcon />
        return <UnselectedAppointmentIcon />
    }
  }

  const [sortMenu, setSortMenu] = useState(getSortMenu)

  useEffect(() => {
    setSortMenu(getSortMenu())
  }, [selectedSort, clinicalIndicators])

  return (
    <div className="show-menu">
      <div className="show-menu-icon-and-show">
        <OptionsMenu
          Icon={SortingIcon}
          SelectedIcon={SortingSelectedIcon}
          items={sortMenu}
        />
        <div style={{ fontWeight: 'bold', marginLeft: '16px' }}>
          {dStrings.show}:
        </div>
      </div>
      <div className="show-menu-container">
        {Object.keys(showValues).map((key, index) => {
          return (
            <div
              key={index}
              className={`show-menu-element ${
                showValues[key] ? 'selected' : ''
              }`}
              id={key}
              // checked={showValues[key]}
              onClick={(e) => {
                changeShowMenuValue(key)
              }}
            >
              <span className="show-element-icon">
                {getIcons(key, showValues[key])}
              </span>
              {dStrings[key]}
            </div>
          )
        })}
      </div>
    </div>
  )
}

ShowMenu.propTypes = {
  // showMenuList: PropTypes.array,
  clinicalIndicators: PropTypes.array,
  showValues: PropTypes.object,
  changeShowMenuValue: PropTypes.func,
  loadTreatments: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    // showMenuList: state.caseload.showMenuList,
    showValues: state.caseload.showValues,
    clinicalIndicators:
      state.program.configurations &&
      state.program.configurations.clinicalIndicators,
    pagination: state.caseload.pagination,
    isWellness: state.program.configurations.isWellness,
  }
}

const mapDispatchToProps = {
  changeShowMenuValue,
  loadTreatments,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowMenu)
