import { useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Chip,
  CircularProgress,
} from '@valerahealth/ui-components/base'
import { Edit, Delete } from '@valerahealth/ui-components/icons'
import {
  endOfDay,
  formatIsoDate,
  formatIsoDateTime,
  isBefore,
  strToDate,
} from '@valerahealth/ui-components/utils/date'
import { useTranslation } from '../locales'

export type ScheduleSummaryProps = {
  startDate: string
  endDate?: string | null
  dateFormat?: 'date' | 'dateTime'
  subHeading?: string
  isPending?: boolean
  locations?: string[]
  onEdit?: (() => void) | null
  onDelete?: (() => Promise<void>) | null
}

export default function ScheduleSummary({
  startDate,
  endDate,
  dateFormat = 'date',
  subHeading,
  isPending,
  locations,
  onEdit,
  onDelete,
}: ScheduleSummaryProps) {
  const formatFn = dateFormat === 'date' ? formatIsoDate : formatIsoDateTime
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const isInactive = endDate
    ? isBefore(strToDate(endDate)!, endOfDay(new Date()))
    : false

  return (
    <Box
      sx={{
        my: 1,
        pb: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" gutterBottom>
          {formatFn(startDate)} {endDate && `- ${formatFn(endDate)}`}
        </Typography>
        {isInactive && (
          <Chip
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
            variant="outlined"
            label={t('inactive')}
          />
        )}
      </Box>

      {subHeading && (
        <Typography variant="caption" gutterBottom>
          {subHeading}
        </Typography>
      )}
      {locations && (
        <Typography variant="body1" gutterBottom>
          {locations.join(', ')}
        </Typography>
      )}

      <Stack direction="row" gap={1} alignItems="center">
        <Chip
          color={isPending ? 'warning' : 'success'}
          variant="outlined"
          label={isPending ? t('pending') : t('approved')}
        />
        {onEdit && (
          <IconButton sx={{ ml: 'auto' }} title={t('edit')} onClick={onEdit}>
            <Edit />
          </IconButton>
        )}

        {onEdit && onDelete && <Typography color="divider">|</Typography>}
        {onDelete && (
          <IconButton
            disabled={isLoading}
            title={t('delete')}
            onClick={async () => {
              setIsLoading(true)
              await onDelete()
              setIsLoading(false)
            }}
          >
            {isLoading ? <CircularProgress size={25} /> : <Delete />}
          </IconButton>
        )}
      </Stack>
    </Box>
  )
}
