import CONFIG, { getHeader, handleResponse, handleError } from './apiUtils'

const { signal } = CONFIG

const baseUrl = `https://${process.env.CARE_MANAGER_API_DOMAIN}/api`

export const getQuestionnairesTemplates = () => {
  return fetch(`${baseUrl}/tools/questionnaire`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getMediaItemsTemplates = () => {
  return fetch(`${baseUrl}/tools/media`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getConsentFormsTemplates = () => {
  return fetch(`${baseUrl}/tools/consent-form`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getQuestionnaire = (id) => {
  return fetch(`${baseUrl}/tools/questionnaire/${id}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getMediaItem = (id) => {
  return fetch(`${baseUrl}/tools/media/${id}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getConsentForm = (id) => {
  return fetch(`${baseUrl}/tools/consent-form/${id}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const getTreatmentQuestionnaire = (treatmentId, id) => {
  return fetch(`${baseUrl}/treatment/${treatmentId}/questionnaire/${id}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export const exportQuestionnaire = (treatmentId, id, patientId) => {
  return fetch(
    `${baseUrl}/treatment/${treatmentId}/questionnaire/${id}/export?pId=${patientId}`,
    {
      method: 'POST',
      signal,
      headers: getHeader(),
    },
  )
    .then((response) => response.blob())
    .catch(handleError)
}

export const getTreatmentMediaItem = (treatmentId, id, patientId) => {
  return fetch(
    `${baseUrl}/treatment/${treatmentId}/media/${id}?pId=${patientId}`,
    {
      method: 'GET',
      signal,
      headers: getHeader(),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}

export const getTreatmentConsentForm = (treatmentId, id, patientId) => {
  return fetch(
    `${baseUrl}/treatment/${treatmentId}/consent-form/${id}?pId=${patientId}`,
    {
      method: 'GET',
      signal,
      headers: getHeader(),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}
