import { ValuesType } from 'utility-types'
import {
  PatientGenderIdentity,
  PatientLegalSex,
  PatientStatus,
  AddressComponentsInput,
} from '../../shared/generated.types'

/** ENUMS */

export const PatientPronounsEnum = {
  'She/Her': 'She/Her',
  'He/Him': 'He/Him',
  'They/Them': 'They/Them',
  Other: 'Other',
} as const

export type PatientPronoun = ValuesType<typeof PatientPronounsEnum>

export const ReasonForConsultEnum = {
  ANXIETY: 'anxiety',
  GROUP_THERAPY: 'groupTherapy',
  LGBTQ_CENTERED_CARE: 'LGBTQCenteredCare',
  MEDICATIONS: 'Medications',
  PREGNANCY_POSTPARTUM: 'pregnancy_postPartum',
  RELATIONSHIPS: 'relationships',
  SADNESS: 'sadness',
  SCHIZOPHRENIA_OR_BIPOLAR_DISORDER: 'schizophreniaOrBipolarDisorder',
  STRESS: 'stress',
  WORK_STRESS: 'workStress',
  WANT_TO_TALK_TO_SOMEONE: 'wantToTalkToSomeone',
} as const
export type ReasonForConsult = ValuesType<typeof ReasonForConsultEnum>

export const CHANNEL_DOMAIN_EVENT_ENUM = {
  //Chat
  CHAT_MESSAGE_SENT: 'chat_message_sent',

  //Tool Assigned
  QUESTIONNAIRE_ASSIGNED: 'questionnaire_assigned',
  MEDIA_CONTENT_ASSIGNED: 'media_content_assigned',
  CONSENT_FORM_ASSIGNED: 'consent_form_assigned',
  REMINDER_SENT: 'reminder_sent',

  //Tool events
  QUESTIONNAIRE_COMPLETED: 'questionnaire_completed',
  CONSENT_FORM_AGREED: 'consent_form_agreed',
  REMINDER_ACKNOWLEDGED: 'reminder_acknowledged',

  PATIENT_REQUEST_SENT: 'patient_request_sent',
  //APPOINTMENT
  APPOINTMENT_SUGGESTED: 'appointment_suggested',
  APPOINTMENT_PLANNED: 'appointment_planned',
  APPOINTMENT_ACCEPTED: 'appointment_accepted',
  APPOINTMENT_DECLINED: 'appointment_declined',
  APPOINTMENT_RESCHEDULE_REQUESTED: 'appointment_reschedule_requested',
  APPOINTMENT_DETAILS_CHANGED: 'appointment_details_changed',
  APPOINTMENT_RESCHEDULED: 'appointment_rescheduled',

  //SYSTEM
  ALERT_CREATED: 'alert_created',

  //CARE_TEAM_MEMBER
  CTM_ADDED: 'ctm_added',
  CTM_REMOVED: 'ctm_removed',
  PCTM_CHANGED: 'pctm_changed',

  MISSED_VIDEO_CALL: 'missed_video_call',
  VIDEO_SESSION_TERMINATED: 'video_session_terminated',

  TREATMENT_CHAT_DISABLED: 'treatment_chat_disabled',
  TREATMENT_CHAT_ENABLED: 'treatment_chat_enabled',

  MEDICATION_REFILL_CREATED: 'medication_refill_created',
} as const

export type ChannelDomainEvent = ValuesType<typeof CHANNEL_DOMAIN_EVENT_ENUM>

export const DomainEventEnum = {
  alert_created: 'alert_created',
  alert_resolved: 'alert_resolved',
  appointment_accepted: 'appointment_accepted',
  appointment_planned: 'appointment_planned',
  appointment_deleted: 'appointment_deleted',
  appointment_declined: 'appointment_declined',
  appointment_edited: 'appointment_edited',
  appointment_reschedule_requested: 'appointment_reschedule_requested',
  appointment_suggested: 'appointment_suggested',
  appointment_reminder_sent: 'appointment_reminder_sent',
  ctm_added: 'ctm_added',
  ctm_removed: 'ctm_removed',
  pctm_changed: 'pctm_changed',
  consent_form_agreed: 'consent_form_agreed',
  consent_form_assigned: 'consent_form_assigned',
  consent_form_seen: 'consent_form_seen',
  article_assigned: 'article_assigned',
  article_viewed: 'article_viewed',
  audio_track_assigned: 'audio_track_assigned',
  audio_track_viewed: 'audio_track_viewed',
  episode_assigned: 'episode_assigned',
  episode_viewed: 'episode_viewed',
  plan_assigned: 'plan_assigned',
  plan_unassigned: 'plan_unassigned',
  questionnaire_assigned: 'questionnaire_assigned',
  questionnaire_completed: 'questionnaire_completed',
  sentiment_assigned: 'sentiment_assigned',
  sentiment_completed: 'sentiment_completed',
  reminder_assigned: 'reminder_assigned',
  reminder_acknowledged: 'reminder_acknowledged',
  video_session_terminated: 'video_session_terminated',
  missed_video_call: 'missed_video_call',
  patient_registered: 'patient_registered',
  patient_synced_from_emr: 'patient_synced_from_emr',
  treatment_discharged: 'treatment_discharged',
  treatment_restored: 'treatment_restored',
  treatment_flagged: 'treatment_flagged',
  treatment_flagged_changed: 'treatment_flagged_changed',
  appointment_requested: 'appointment_requested',
  appointment_scheduled: 'appointment_scheduled',
  appointment_rescheduled: 'appointment_rescheduled',
  article_scheduled: 'article_scheduled',
  audio_track_scheduled: 'audio_track_scheduled',
  message_scheduled: 'message_scheduled',
  questionnaire_scheduled: 'questionnaire_scheduled',
  sentiment_scheduled: 'sentiment_scheduled',
  reminder_scheduled: 'reminder_scheduled',
  medication_refill_created: 'medication_refill_created',
  message_sent: 'message_sent',
  message_read: 'message_read',
} as const

export type DomainEvent = ValuesType<typeof DomainEventEnum>

export const TreatmentStatusEnum = {
  ACTIVE: 'active',
  FLAGGED: 'flagged',
  DISCHARGED: 'discharged',
} as const
export type TreatmentStatus = ValuesType<typeof TreatmentStatusEnum>

export const TIMELINE_TYPE_ENUM = {
  // TOOLS
  QUESTIONNAIRE: 'questionnaire',
  SENTIMENT: 'sentiment',
  CONSENT_FORM: 'consent_form',
  MEDIA: 'media',
  REMINDER: 'reminder',

  //OTHER
  ALERT: 'alert',
  APPOINTMENT: 'appointment',
  CARE_TEAM: 'care_team',
  PLAN: 'plan',
  VIDEO: 'video',
  TREATMENT: 'treatment',
  MEDICATION: 'medication',
} as const

export type TimelineType = ValuesType<typeof TIMELINE_TYPE_ENUM>

export enum SupportiveContactRelationEnum {
  Self = 'Self',
  Spouse = 'Spouse',
  ParentGuardian = 'Parent/Guardian',
  Other = 'Other',
  SupportiveContact = 'Supportive Contact',
  MinorPatient = 'Minor Patient',
  AdditionalPatientContact = 'Additional Patient Contact',
}

/** END ENUMS */

export interface PatientProfileOverview {
  firstName?: string | null
  lastName?: string | null
  picture?: string | null
  preferredName?: string | null
  displayName?: string | null
  pronouns?: string | null
}

export type EMRStatusDescription = {
  isIntegrationActive?: boolean
  emrPatientLink?: string | null
  drChronoId?: number | null
}

export type CMDischargePatientReq = {
  treatmentId: string
  body: {
    description: string
    reason: string
  }
}

export type TreatmentDischargeInfo = {
  reason?: string | null
  description?: string | null
  /** ISO Date Time */
  ts?: string | null
}

export type SupportiveContact = {
  id?: string
  firstName: string
  lastName: string
  relation:
    | 'Self'
    | 'Spouse'
    | 'Parent/Guardian'
    | 'Other'
    | 'Supportive Contact'
    | 'Minor Patient'
    | 'Additional Patient Contact'
  phone: string
  email?: string | null
  use: 'personal' | 'work' | 'emergency' | null
  primary?: boolean | null
}

export type CMCode = {
  code: string
  name: string
}

export type CMClinicalIndicator = {
  name?: string
  templateId?: string
  score?: number
  lastScore?: number | null
}

export type CMPlan = {
  id: string
  name?: string
  templateId?: string
}

export type CMChannelItemSender = {
  id: string | null
  type: 'CTM' | 'Patient' | 'CarePlan' | 'RuleEngine' | 'Appointment' | 'System'
  firstName?: string | null
  lastName?: string | null
  displayName?: string | null
  picture?: string | null
  preferredName?: string | null
  pronouns?: string | null
  details?: {
    module: 'CarePlan' | 'Appointment'
    name: string
    type: 'individual_public' | 'individual_private' | 'population'
  }
}

export type CMChannelItem = {
  id: string
  sender?: CMChannelItemSender
  message: string | null
  attachment: {
    type: 'info' | 'communication'
    title: string
    category?:
      | 'appointment'
      | 'questionnaire'
      | 'sentiment'
      | 'reminder'
      | 'video'
      | 'consent_form'
      | 'media'
      | 'telehealth'
      | 'alert'
      | 'care_team_member'
      | 'medication'
    item?: {
      id: string
      picture?: string
      type?:
        | 'sent'
        | 'approved'
        | 'declined'
        | 'video_session_terminated'
        | 'missed_video_call'
        | 'assessment_answer'
        | 'assessment_score'
        | 'treatment_eligibility'
        | 'unchanged_location'
      eventDetails?: string
      description?: string //joi has string or any object
      url?: string
      ts?: string //iso datetime
      custom?: {
        notes?: string
        providerName?: string
      }
      emrId?: string
      idType?: 'PM' //practice manager id indicates its an appointment id from scheduling api
    }
  } | null
  ts: string // ISO Datetime
  viewedBy: {
    id: string
    type: 'CTM' | 'Patient'
    ts: string // ISO Datetime
  }[]
  domainEvent: ChannelDomainEvent
  noteIds?: string[]
}

export type TreatmentTimelineItem = {
  // ISO Datetime
  ts: string
  type: TimelineType
  eventTitle: string
  item?: {
    id: string
    category?: string
    type?:
      | 'missing'
      | 'appointment'
      | 'questionnaire'
      | 'sentiment'
      | 'reminder'
      | 'video'
      | 'consent_form'
      | 'article'
      | 'audio_track'
      | 'episode'
      | 'message'
      | 'medication'
    title?: string
    description?: unknown
    custom?: unknown
    context?: {
      id?: string
      type: string
      name: string
    }
    domainEvent: DomainEvent
    noteIds?: string[]
  }
}

export type CMChannelItemOverview = {
  target?: string
  ts?: string
  name?: string
  text: string
  domainEvent: ChannelDomainEvent
  custom?: {
    providerName?: string
    notes?: string
  }
}

export type CMChannelOverview = {
  count?: number
  unread?: CMChannelItemOverview[]
}

export type CMProfileWithTreatmentCount = {
  id: string
  profile: {
    firstName: string
    lastName: string
    picture?: string
  }
  treatmentCount: number
}

export type CMAppointmentRef = {
  id: string
  /** ISO Date Time */
  date: string
}

export type Treatment = {
  id: string
  patientId: string
  mrn?: string
  profile?: PatientProfileOverview
  channel?: CMChannelOverview
  clinicalIndicators?: CMClinicalIndicator[]
  plans?: CMPlan[]
  nextAppointment?: CMAppointmentRef
  lastAppointment?: CMAppointmentRef
  unresolvedAlertCount?: number
  openTasksCount?: number
  /** ISO Date Time */
  lastActivity?: string | null
  diagnosis?: string[]
  status?: TreatmentStatus
  dischargeInfo?: TreatmentDischargeInfo
  supportiveContacts?: SupportiveContact[]
  isCTM: boolean
  /** on joi validation in backend but it doesn't ever return this in actuality */
  //emrStatus?: EMRStatusDescription
}

export type PatientProfile = {
  picture?: string
  profileId: string
  firstName: string
  lastName: string
  addressComponents?: {
    street?: string
    city?: string
    state?: string
    zipcode?: string
  } | null
  gender: PatientLegalSex
  genderIdentity?: PatientGenderIdentity
  genderDetails?: string | null
  phone: string
  email?: string | null
  primaryPhone?: string | null
  primaryEmail?: string | null
  dateOfBirth: string
  status: PatientStatus
  middleName?: string | null
  middleInital?: string
  preferredLanguage?: string
  languages?: string[]
  username?: string
  mrn?: string
  diagnosis?: string[]
  medicalDiagnosis?: CMCode[]
  scaleId?: string | null
  questionnaires?: {
    name?: string
    score: number
  }[]
  videoLink?: string | null
  integration?: {
    fitbitIntegrationLink?: string | null
  } | null
  device?: {
    type?: string | null
    useMobileApp?: boolean | null
  }
  disableChat?: boolean
  preferredName?: string | null
  pronouns: PatientPronoun
  timezone?: string
  referralSource?: string | null
  reasonForConsult?: ReasonForConsult[]
  onBoardingNotes?: string | null
  hospitalized?: boolean
  registrationSource?: string
  address?: string | null
  displayName?: string | null
}

export type RegisterTreatmentReq = {
  programId: string
  /** whether to send an email to patient - default true */
  notify?: boolean
  /** if coming from the pending patients list (DEPRECATED ON FRONTEND) */
  patientId?: string
  body: RegisterTreatment
}

export type RegisterTreatment = {
  profile: {
    firstName: string
    middleName?: string
    middleInitial?: string
    lastName: string
    address?: string | null
    gender: PatientLegalSex
    genderIdentity?: PatientGenderIdentity
    preferredName?: string | null
    pronouns?: string | null
    timezone: string
    phone: string
    email: string
    username?: string
    /** if left blank defaults to phone */
    primaryPhone?: string
    /** if left blank defaults to email */
    primaryEmail?: string
    /** ISO Date Time */
    dateOfBirth: string
    genderDetails?: string
    status?: PatientStatus
    referralSource?: string
    hospitalized?: boolean
    languages?: string[]
    preferredLanguage?: string
    addressComponents?: AddressComponentsInput | null
  }
  treatment: {
    mrn?: string
    diagnosis?: string[]
    scaleId?: string
    questionnaires?: {
      name: 'PHQ' | 'GAD7'
      score: number
    }[]
    device?: {
      type?: 'wirelessScale'
      useMobileApp?: boolean
    }
    disableChat?: boolean
  }
  careTeam: {
    pctmId: string
    members?: {
      ctmId: string
    }[]
    forcePctmOOO?: boolean
  }
  supportiveContact?: Omit<SupportiveContact, 'id'>[]
  planTemplateIds?: string
  forceMultiPhone?: boolean
}

export interface TreatmentNotification {
  id: string // treatmentId
  profile?: {
    firstName?: string
    lastName?: string
    picture?: string
    preferredName?: string
    pronouns?: string
  }
  count?: number
  unread?:CMChannelItemOverview[]
  unresolvedAlertCount?: number
}
