import React from 'react'
import PropTypes from 'prop-types'
import { isCellEditable } from './table.domain'
import { useOptionsContext } from './optionsContext'
// import SwitchButton from "../SwitchButton/index";

export default function TableCell({
  data,
  columnHeader,
  orderKey,
  editMode,
  handleChange,
}) {
  const options = useOptionsContext()

  const editLine = isCellEditable(data, columnHeader, editMode)

  let cellClasses = ''
  cellClasses = options.cellClass || columnHeader.classe || 'basic-cell'
  if (orderKey === columnHeader.key) cellClasses += ' ordered-cell'

  /**
   *
   * @param {Event} e event send
   */
  const emitChangeEvent = (e) => {
    switch (columnHeader.type) {
      case 'text':
      case 'number':
        handleChange(columnHeader.key, e.target.value)
        break
      case 'select':
        handleChange(columnHeader.key, e.target.selectedOptions[0].value)
        break
      case 'checkbox':
      case 'switch':
        handleChange(columnHeader.key, e.target.checked)
        break
    }
  }

  const displayCellContent = () => {
    switch (columnHeader.type) {
      case 'text':
      case 'number':
        if (!editLine) {
          return <div> {data[columnHeader.key]}</div>
        }
        return (
          <input
            type={columnHeader.type}
            value={data[columnHeader.key]}
            onChange={emitChangeEvent}
          />
        )

      case 'select':
        // if (!editLine) {
        // return <div>{columnHeader.select[data[columnHeader.key]]}</div>;
        // } else {
        return (
          <select
            onChange={emitChangeEvent}
            className="basic-select"
            value={data[columnHeader.key]}
            disabled={!editLine}
          >
            {Object.keys(columnHeader.select).map((key, index) => {
              const disabled =
                columnHeader.isOptionDisabled &&
                columnHeader.isOptionDisabled(data, key)
              return (
                <option key={key} value={key} disabled={disabled}>
                  {columnHeader.select[key]}
                </option>
              )
            })}
          </select>
        )
      // }

      case 'checkbox':
        return (
          <input
            type="checkbox"
            checked={data[columnHeader.key]}
            onChange={emitChangeEvent}
            disabled={!editLine}
          />
        )

      // case "switch":
      //   return (
      //     <SwitchButton
      //       value={data[columnHeader.key]}
      //       handlerGetData={emitChangeEvent}
      //     />
      //   );
      case 'picture':
        return (
          <img
            src={data[columnHeader.key]}
            onError={(e) => {
              e.target.onerror = null

              if (columnHeader.defaultPicture) {
                e.target.src = columnHeader.defaultPicture
              }
            }}
          />
        )
      case 'customize':
        return columnHeader.getCell(data, (newValue) => {
          handleChange(columnHeader.key, newValue)
        })

      default:
        return <div>{data[columnHeader.key]}</div>
    }
  }

  return <td className={cellClasses}>{displayCellContent()}</td>
}

TableCell.propTypes = {
  data: PropTypes.object.isRequired,
  columnHeader: PropTypes.object.isRequired,
  orderKey: PropTypes.string,
  editMode: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  handleChange: PropTypes.func.isRequired,
}
