import { Grid } from '@valerahealth/ui-components'
import { Popup } from '@valerahealth/ui-core'
import { useTranslation } from '@valerahealth/ui-translation'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  restorePatient,
  toggleRestoreTreatmentPopup,
} from '../../../redux/actions/treatmentRoom'

const RestorePatient = ({ handleSuccess, handleCancel, avoidClose, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedId } = useSelector((state) => state.treatmentRoom)
  const [disabledBtn, setDisabledBtn] = useState(false)

  const handleClose = () => {
    if (!avoidClose) {
      dispatch(toggleRestoreTreatmentPopup(false))
    }
  }

  return (
    <Popup
      title={t('restorePatient')}
      onClosed={() => {
        handleClose()
      }}
      buttons={[
        {
          text: t('cancel'),
          type: 'link',
          onClick: () => {
            handleClose()
            handleCancel && handleCancel()
          },
        },
        {
          text: t('ok'),
          type: 'primary',
          disable: disabledBtn,
          onClick: () => {
            setDisabledBtn(true)
            dispatch(
              restorePatient(id || selectedId, () => {
                setDisabledBtn(false)
                handleClose()
                handleSuccess && handleSuccess()
              }),
            )
          },
        },
      ]}
    >
      <Grid>{t('restorePatientConfirmation')}</Grid>
    </Popup>
  )
}
export default RestorePatient
