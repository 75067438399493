import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function providerReducer(state = initialState.provider, action) {
  switch (action.type) {
    case types.LOAD_CARE_COORDINATORS_SUCCESS:
      return {
        ...state,
        careCoordinators: action.careCoordinators,
      }
    case types.EDIT_OOO:
      return {
        ...state,
        editedOOO: action.editedOOO,
      }
    case types.CANCEL_EDIT_OOO:
      return {
        ...state,
        editedOOO: undefined,
      }
  }
  return state
}
