import { Dispatch, ReactElement, SetStateAction, cloneElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LABELS from '@valerahealth/ui-translation/locales/en'
import RegisterTreatmentForm, {
  RegisterTreatmentFormProps,
} from './RegisterTreatmentForm'
import { type DialogProps, Modal } from '../../base'

export type RegisterTreatmentModalProps = {
  open: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  onCancel: () => void
  modalDialogProps?: Omit<DialogProps, 'onClose' | 'open'>
  formatSuccessRoute: (id: string) => string
} & Pick<RegisterTreatmentFormProps, 'onSuccess'>

export function RegisterTreatmentModal({
  open,
  setModalOpen,
  onSuccess,
  onCancel,
  modalDialogProps,
  formatSuccessRoute,
}: RegisterTreatmentModalProps) {
  const navigate = useNavigate()
  return (
    <Modal
      open={open}
      onClose={onCancel}
      dialogProps={{
        keepMounted: false,
        fullWidth: true,
        maxWidth: 'md',
        ...modalDialogProps,
      }}
      title={LABELS.addNewPatient}
    >
      <RegisterTreatmentForm
        onSuccess={
          onSuccess ||
          (({ treatmentId }) => {
            const to = formatSuccessRoute(treatmentId)
            if (to.startsWith('http')) {
              window.location.assign(to)
            } else {
              navigate(to)
            }
            setModalOpen(false)
          })
        }
        onCancel={onCancel}
      />
    </Modal>
  )
}
export type RegisterTreatmentButtonProps = Omit<
  RegisterTreatmentModalProps,
  'open' | 'onCancel' | 'setModalOpen'
> & {
  /** the button that has an onClick handler injected to control the modal open */
  children: ReactElement
}
export function RegisterTreatmentButton({
  children,
  ...rest
}: RegisterTreatmentButtonProps) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      {cloneElement(children, { onClick: () => setModalOpen(true) })}
      <RegisterTreatmentModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        onCancel={() => setModalOpen(false)}
        {...rest}
      />
    </>
  )
}
