import { AnyAction } from 'redux'
import { DispatchType, GetStateType } from 'redux/store'
import { CTMBase, CareTeamMember } from '@valerahealth/rtk-query'
import { callApi, getErrorMessage } from '../../utilities'
import { addUserMessage } from '../../globalActions'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'
import dStrings from '../../../../strings.json'

export const loadCareTeamSuccess = (careTeam: CareTeamMember[]) => ({
  type: types.LOAD_PROFILE_CARETEAM_SUCCESS,
  careTeam,
})

export function loadPatientCareTeam(id?: string) {
  return (dispatch: DispatchType, getState: GetStateType): AnyAction => {
    return callApi({
      name: 'loadPatientCareTeam',
      dispatch,
      call: () => {
        return treatmentAPI.getCareTeam(
          getState().treatmentRoom.selectedId || id,
        )
      },
      success: (careTeam) => {
        dispatch(loadCareTeamSuccess(careTeam))
      },
    })
  }
}

export function updateCareTeam(ctmids: string[]) {
  return (dispatch: DispatchType, getState: GetStateType): AnyAction => {
    return callApi({
      name: 'updateCareTeam',
      dispatch,
      call: () => {
        return treatmentAPI.updateCareTeam(
          getState().treatmentRoom.selectedId,
          ctmids,
        )
      },
      successMessage: dStrings.careTeamSuccessfullyUpdated,
      success: () => {
        let newCTMProfiles: CTMBase[] = []
        const ctm = getState().program.careTeam?.byId || {}

        try {
          newCTMProfiles = ctmids.map((ctmid) => {
            return ctm[ctmid]!
          })
        } catch (error) {
          //
        }

        dispatch({
          type: types.UPDATE_CARE_TEAM,
          ids: ctmids,
          profiles: newCTMProfiles,
        })
      },
    })
  }
}

export function deleteCareTeamMember(id: string) {
  return (dispatch: DispatchType, getState: GetStateType) => {
    const { selectedId } = getState().treatmentRoom
    return callApi({
      name: 'deleteCareTeamMember',
      dispatch,
      call: () => {
        return treatmentAPI.deleteCareTeamMember(selectedId, id)
      },
      success: () => {
        dispatch({ type: types.DELETE_CARE_TEAM_MEMBER, id })
        dispatch(addUserMessage('success', dStrings.memberSuccessfullyRemoved))
      },
      error: (err) => {
        if (err?.message) {
          const error = getErrorMessage(err)
          if (error?.message === 'away_provider_cannot_be_removed') {
            dispatch(
              addUserMessage('error', dStrings.awayProviderCannotBeRemoved),
            )
          } else {
            dispatch(addUserMessage('error', dStrings.errorOccurred))
          }
        }
      },
    })
  }
}

export function changeCareTeamMemberRole({
  ctmid,
  errcb,
  force,
}: {
  ctmid: string
  errcb?: (arg?: string) => void
  force?: boolean
}) {
  return (dispatch: DispatchType, getState: GetStateType) => {
    const id = getState().treatmentRoom.selectedId
    return callApi({
      name: 'changeCareTeamMemberRole',
      dispatch,
      call: () => {
        return treatmentAPI.changeCareTeamMemberRole(id, ctmid, force)
      },
      success: (err) => {
        if (err) {
          const obj = JSON.parse(err)
          errcb?.(obj && obj.message)
        } else {
          dispatch(loadPatientCareTeam())
          dispatch(addUserMessage('success', dStrings.pctmHasBeenChanged))
        }
      },
    })
  }
}
