import Button, { type ButtonProps } from '@mui/material/Button'
import Link, { type LinkProps } from '../Link'

export type ButtonLinkProps = Omit<
  ButtonProps<'a', LinkProps>,
  'component' | 'variant'
> & { variant?: 'text' | 'outlined' | 'contained' }

export default function ButtonLink(props: ButtonLinkProps) {
  return <Button component={Link} {...props} />
}
