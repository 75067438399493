import { callApi } from '../../utilities'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'

export const getTimeline = (from, to, loadMore, filters) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'getTimeline',
      dispatch,
      call: () => {
        return treatmentAPI.getTimeline(
          getState().treatmentRoom.selectedId,
          from,
          to,
          filters,
        )
      },
      success: (timeline) => {
        if (!loadMore) {
          dispatch({
            type: types.LOAD_PROFILE_TIMELINE,
            timeline,
          })
        } else {
          dispatch({
            type: types.LOAD_MORE_PROFILE_TIMELINE,
            timeline,
            loadMore,
          })
        }
      },
    })
  }
}
