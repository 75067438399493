import { connect } from 'react-redux'
import EyeIcon from '../../../images/eye.svg'
import { editItem } from '../../../redux/actions/globalActions'

function AssessmentIcon({ alert, editItem }) {
  if (
    !~[
      'assessment_score',
      'assessment_answer',
      'incomplete_assessment',
    ].indexOf(alert.type) ||
    !alert.item ||
    !alert.item.id
  )
    return ''

  return (
    <span className="icon">
      <EyeIcon
        onClick={() => {
          editItem({
            assigned: true,
            info: {
              id: alert.item.id,
              templateId: alert.item.id,
            },
            type: 'questionnaire',
          })
        }}
      />
    </span>
  )
}

function mapStateToProps(state, ownProps) {
  return ownProps
}

const mapDispatchToProps = {
  editItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentIcon)
