import {
  fetchBaseQuery,
  createApi,
  BaseQueryFn,
  FetchArgs,
} from '@reduxjs/toolkit/query/react'
import { createService } from '../../utils'
import type {
  CMPractitionerAggregates,
  CMPractitionerAggregatesRaw,
  CMProgramSettings,
  CareManager,
  CMRestorePatientRes,
  CMRegisterToEMRRes,
  CMSendAppInviteRes,
  CMSendAppInviteReq,
  CMGetMeetingRoomRes,
  CMAppointment,
  AppointmentInvitationStatus,
  CMUserSettings,
  ProgramTag,
  CareManagerBasic,
  CareTeamMemberLegacy,
} from './careManagerApi.types'
import {
  Treatment,
  PatientProfile,
  CMProfileWithTreatmentCount,
  CMDischargePatientReq,
  EMRStatusDescription,
  RegisterTreatmentReq,
  CMChannelItem,
  DomainEvent,
  TreatmentTimelineItem,
  SupportiveContact,
  TreatmentNotification,
} from './treatment.types'
import { RegisterTreatmentErrorCode } from './constants'

import * as ToolTypes from './tool.types'
import * as TelehealthTypes from './telehealth.types'
export * from './careManagerApi.types'
export * from './constants'
export * from './tool.types'
export * from './telehealth.types'
export * from './treatment.types'

const careManagerApiService = createService(({ origin, getAccessToken }) =>
  createApi({
    reducerPath: 'careManagerApi',
    keepUnusedDataFor: 120,
    baseQuery: fetchBaseQuery({
      baseUrl: `${origin}/api`,
      prepareHeaders: (headers, { getState }) => {
        const accessToken = getAccessToken(getState())
        headers.set('Authorization', `Bearer ${accessToken}`)
        return headers
      },
    }) as BaseQueryFn<
      string | FetchArgs,
      unknown,
      {
        data: {
          statusCode?: number
          error?: string
          message?: string
          meta?: any
          validation?: any
        }
      },
      {}
    >,
    tagTypes: [
      'User',
      'Appointment',
      'ProgramTags',
      'MediaItem',
      'Questionnaire',
      'ConsentForm',
      'Treatment',
      'PatientProfile',
      'TreatmentCareTeam',
      'TreatmentEMRStatus',
      'TreatmentMediaItem',
      'TreatmentQuestionnaire',
      'TreatmentConsentForm',
      'TreatmentTimeline',
      'TreatmentChannel',
      'Contacts',
      'NotificationByTreatmentId',
      'languages',
    ],
    endpoints: (build) => ({
      /**
       *
       * QUERIES
       *
       * */

      /** Treatment Queries */
      getTreatment: build.query<Treatment, { treatmentId: string }>({
        query: ({ treatmentId }) => ({ url: `/treatment/${treatmentId}` }),
        providesTags: (res, err, req) => [
          { type: 'Treatment', id: req.treatmentId },
        ],
      }),
      getPatientProfileByTreatmentId: build.query<
        PatientProfile,
        {
          /** treatmentId */
          id: string
        }
      >({
        query: (params) => ({ url: `/treatment/${params.id}/profile` }),
        providesTags: (res, err, { id }) => [{ id, type: 'PatientProfile' }],
      }),
      getTreatmentChannel: build.query<
        CMChannelItem[],
        {
          pageSize?: number
          pageNumber?: number
          treatmentId: string
        }
      >({
        query: ({ treatmentId, ...params }) => ({
          url: `/treatment/${treatmentId}/channel`,
          params,
        }),
        providesTags: (res, err, req) => [
          { type: 'TreatmentChannel', id: req.treatmentId },
        ],
      }),
      getTreatmentTimeline: build.query<
        TreatmentTimelineItem[],
        {
          /** ISO Datetime */
          from?: string
          /** ISO Datetime */
          to?: string
          filter?: {
            domainEvents?: DomainEvent | DomainEvent[]
          }
          treatmentId: string
        }
      >({
        query: ({ treatmentId, filter, ...params }) => ({
          url: `/treatment/${treatmentId}/timeline`,
          params: {
            ...params,
            filter: filter && JSON.stringify(filter),
          },
        }),
        providesTags: (res, err, req) => [
          { type: 'TreatmentTimeline', id: req.treatmentId },
        ],
      }),
      /** end Treatment Queries */
      getAllUsers: build.query<CareManager[], { programIds?: string[] }>({
        query: (params) => ({ url: '/admin/users', params }),
        providesTags: (res) =>
          res?.map(({ id }) => ({ type: 'User', id })) || [],
      }),
      getProvidersTreatmentCounts: build.query<
        CMProfileWithTreatmentCount[],
        { id: string }
      >({
        query: (params) => ({
          url: `/provider/${params.id}/supervisor/caseload`,
        }),
      }),
      searchPatients: build.query<
        Treatment[],
        { id: string; pageSize?: number; name?: string }
      >({
        query: ({ id, ...params }) => ({
          url: `/provider/${id}/caseload`,
          params,
        }),
        providesTags: (res) =>
          res?.map(({ id }) => ({ type: 'Treatment', id })) || [],
      }),
      getNotifications: build.query<
        TreatmentNotification[],
        { careManagerId: string; pageSize?: number; pageNumber?: number }
      >({
        query: ({ careManagerId, ...params }) => ({
          url: `/provider/${careManagerId}/caseload/notifications`,
          params,
        }),
        providesTags: (res, err, req) =>
          res?.map(({ id }) => ({ type: 'NotificationByTreatmentId', id })) ||
          [],
      }),
      getEmrStatus: build.query<EMRStatusDescription, { treatmentId: string }>({
        query: ({ treatmentId }) => ({
          url: `/treatment/${treatmentId}/emr-status`,
        }),
        providesTags: (res, err, req) => [
          { type: 'TreatmentEMRStatus', id: req.treatmentId },
        ],
      }),
      getAppointment: build.query<
        CMAppointment,
        { treatmentId: string; appointmentId: string }
      >({
        providesTags: (res, error, { appointmentId }) => {
          return [{ type: 'Appointment', appointmentId }]
        },
        query: ({ treatmentId, appointmentId }) => ({
          url: `treatment/${treatmentId}/appointment/${appointmentId}`,
        }),
      }),
      getAppointments: build.query<
        CMAppointment[],
        {
          treatmentId: string
          status?: AppointmentInvitationStatus | 'all'
          from?: string
          to?: string
          pageSize?: string
          pageNumber?: string
        }
      >({
        query: ({ treatmentId, ...params }) => ({
          url: `/treatment/${treatmentId}/care-team`,
          params,
        }),
        providesTags: (res) => {
          return res ? res.map((v) => ({ id: v.id, type: 'Appointment' })) : []
        },
      }),
      aggregatesCMPractitioner: build.query<CMPractitionerAggregates, void>({
        query: () => '/provider/list/config',
        keepUnusedDataFor: 28800, // eight hours, these values rarely if ever change
        transformResponse: (
          baseQueryReturnValue: CMPractitionerAggregatesRaw,
        ) => {
          const {
            id,
            programId,
            filters,
            insurancePlans,
            forbiddenInsuranceRules,
            ...rest
          } = baseQueryReturnValue

          const sortedAggs = {
            forbiddenInsuranceRules,
            insurancePlans: insurancePlans.sort((a, b) => {
              const aName = a.name.trim().toLowerCase()
              const bName = b.name.trim().toLowerCase()
              return aName > bName ? 1 : aName < bName ? -1 : 0
            }),
            ...Object.fromEntries(
              Object.entries(rest).map(([key, value]) => [
                key,
                Array.isArray(value) ? value.sort() : value,
              ]),
            ),
          }
          return sortedAggs as CMPractitionerAggregates
        },
      }),
      getUserSettings: build.query<CMUserSettings, void>({
        query: () => ({
          url: `provider/settings`,
        }),
      }),
      getProgramSettings: build.query<CMProgramSettings, string>({
        query: (id) => ({
          url: `program/${id}`,
        }),
      }),
      getProgramTags: build.query<ProgramTag[], string>({
        providesTags: ['ProgramTags'],
        keepUnusedDataFor: 28800, // eight hours, these values rarely if ever change
        query: (programId) => ({
          url: `/program/${programId}/tags`,
        }),
      }),
      getLanguages: build.query<
        {
          code: string
          display: string
        }[],
        void
      >({
        providesTags: ['languages'],
        keepUnusedDataFor: 28800, // eight hours, these values rarely if ever change
        query: () => ({
          url: `/treatment/get-languages`,
        }),
      }),
      getAllCareManagers: build.query<
        CareManagerBasic[],
        { programId: string }
      >({
        query: ({ programId }) => ({
          url: `/program/${programId}/provider`,
        }),
      }),
      /** THESE ARE TYPED WRONG - TIED TO UNFINISHED FEATURE Care Team New */
      getProgramProviders: build.query<CareTeamMemberLegacy[], { id: string }>({
        query: ({ id }) => ({
          url: `/program/${id}/provider`,
        }),
      }),
      /** THESE ARE TYPED WRONG - TIED TO UNFINISHED FEATURE Care Team New */
      getTreatmentCareTeam: build.query<CareTeamMemberLegacy[], { id: string }>(
        {
          query: ({ id }) => ({
            url: `/treatment/${id}/care-team`,
          }),
          providesTags: (res, error, { id }) => {
            return [{ type: 'TreatmentCareTeam', id }]
          },
        },
      ),
      getMeetingRoom: build.query<CMGetMeetingRoomRes, string>({
        query: (
          /** patientId */
          patientId,
        ) => ({
          url: `patient/${patientId}/telehealth`,
        }),
      }),
      /* Tools Endpoints - questionnaires, consent forms, media items, etc */
      getMediaItem: build.query<ToolTypes.MediaItem, { id: string }>({
        query: ({ id }) => ({
          url: `/tools/media/${id}`,
        }),
        providesTags: (res, error, { id }) => [{ type: 'MediaItem', id }],
        keepUnusedDataFor: 3600,
      }),
      getMediaItems: build.query<ToolTypes.MediaItem[], void>({
        query: () => ({
          url: `/tools/media`,
        }),
        providesTags: (res) =>
          res?.map(({ id }) => ({ type: 'MediaItem', id })) || [],
        keepUnusedDataFor: 3600,
      }),
      getConsentForm: build.query<ToolTypes.ConsentForm, { id: string }>({
        query: ({ id }) => ({
          url: `/tools/consent-form/${id}`,
        }),
        providesTags: (res, error, { id }) => [{ type: 'ConsentForm', id }],
        keepUnusedDataFor: 3600,
      }),
      getConsentForms: build.query<ToolTypes.ConsentForm[], void>({
        query: () => ({
          url: `/tools/consent-form`,
        }),
        providesTags: (res) =>
          res?.map(({ id }) => ({ type: 'ConsentForm', id })) || [],
        keepUnusedDataFor: 3600,
      }),
      getQuestionnaire: build.query<
        ToolTypes.QuestionnaireBase,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `/tools/questionnaire/${id}`,
        }),
        providesTags: (res, error, { id }) => [{ type: 'Questionnaire', id }],
        keepUnusedDataFor: 3600,
      }),
      getQuestionnaires: build.query<ToolTypes.QuestionnaireBase[], void>({
        query: () => ({
          url: '/tools/questionnaire',
        }),
        providesTags: (res) =>
          res?.map(({ id }) => ({ type: 'Questionnaire', id })) || [],
        keepUnusedDataFor: 3600,
      }),
      getTreatmentMediaItem: build.query<
        ToolTypes.MediaItem,
        { treatmentId: string; assignedMediaItemId: string }
      >({
        query: ({ treatmentId, assignedMediaItemId }) => ({
          url: `/treatment/${treatmentId}/media/${assignedMediaItemId}`,
        }),
        providesTags: (res, error, { treatmentId, assignedMediaItemId }) => [
          {
            type: 'TreatmentMediaItem',
            id: `${treatmentId}_${assignedMediaItemId}`,
          },
        ],
      }),
      getTreatmentConsentForm: build.query<
        ToolTypes.AssignedConsentForm,
        {
          treatmentId: string
          assignedConsentFormId: string
          patientId: string
        }
      >({
        query: ({ treatmentId, assignedConsentFormId, patientId }) => ({
          url: `/treatment/${treatmentId}/consent-form/${assignedConsentFormId}`,
          params: { pId: patientId },
        }),
        providesTags: (res, error, { treatmentId, assignedConsentFormId }) => [
          {
            type: 'TreatmentConsentForm',
            id: `${treatmentId}_${assignedConsentFormId}`,
          },
        ],
      }),
      getTreatmentQuestionnaire: build.query<
        ToolTypes.AssignedQuestionnaire,
        { treatmentId: string; assignedQuestionnaireId: string }
      >({
        query: ({ treatmentId, assignedQuestionnaireId }) => ({
          url: `/treatment/${treatmentId}/questionnaire/${assignedQuestionnaireId}`,
        }),
        providesTags: (
          res,
          error,
          { treatmentId, assignedQuestionnaireId },
        ) => [
          {
            type: 'TreatmentQuestionnaire',
            id: `${treatmentId}_${assignedQuestionnaireId}`,
          },
        ],
      }),
      /** returns a download url */
      exportTreatmentQuestionnaire: build.query<
        string,
        {
          treatmentId: string
          patientId: string
          assignedQuestionnaireId: string
        }
      >({
        query: ({ treatmentId, assignedQuestionnaireId, patientId }) => ({
          method: 'POST',
          url: `/treatment/${treatmentId}/questionnaire/${assignedQuestionnaireId}/export`,
          params: { pId: patientId },
          responseHandler: (res) =>
            res.blob().then((blob) => window.URL.createObjectURL(blob)),
        }),
      }),
      /* End Tools Endpoints */

      /** TELEHEALTH ENDPOINTS */

      sendTelehealthOTP: build.mutation<
        void,
        TelehealthTypes.SendTelehealthOTPReq
      >({
        query: (body) => ({
          method: 'POST',
          url: `/telehealth/auth?type=sms`,
          body,
        }),
      }),

      confirmTelehealthOTP: build.mutation<
        TelehealthTypes.ConfirmTelehealthOTPRes,
        TelehealthTypes.ConfirmTelehealthOTPReq
      >({
        query: (body) => ({
          method: 'POST',
          url: `/telehealth/auth/verify`,
          body,
        }),
      }),

      getWaitingRoom: build.query<
        TelehealthTypes.GetWaitingRoomRes,
        { sessionId: string; roomId: string }
      >({
        query: ({ roomId, sessionId }) => ({
          url: `/telehealth/room/${roomId}/session/${sessionId}/waiting`,
        }),
      }),

      getTelehealthWaitingRoom: build.query<
        TelehealthTypes.GetWaitingRoomRes,
        { sessionId: string; roomId: string }
      >({
        query: ({ roomId, sessionId }) => ({
          url: `/telehealth/room/${roomId}/session/${sessionId}/waiting`,
        }),
      }),

      /**
       *
       * MUTATIONS
       *
       *  */
      registerTreatment: build.mutation<Treatment, RegisterTreatmentReq>({
        query: ({ programId, body, ...params }) => ({
          method: 'POST',
          url: `program/${programId}/treatment`,
          params,
          body,
        }),
        transformErrorResponse: (error) => {
          const data = error.data as {
            error: RegisterTreatmentErrorCode
            message?: {
              realm?: 'patient'
              programId?: string
            }
          }

          return {
            data: {
              message: data.error,
              meta: data.message,
            },
          }
        },
      }),
      registerToEMR: build.mutation<CMRegisterToEMRRes, string>({
        query: (treatmentId) => ({
          method: 'POST',
          url: `treatment/${treatmentId}/register-emr`,
        }),
        invalidatesTags: (res, err, id) =>
          res ? [{ type: 'TreatmentEMRStatus', id }] : [],
      }),
      dischargePatient: build.mutation<void, CMDischargePatientReq>({
        query: ({ treatmentId, body }) => ({
          method: 'POST',
          url: `treatment/${treatmentId}/discharge`,
          body,
        }),
        invalidatesTags: (res, err, req) => [
          { type: 'Treatment' as const, id: req.treatmentId },
          { type: 'PatientProfile' as const, id: req.treatmentId },
        ],
      }),
      restorePatient: build.mutation<CMRestorePatientRes, string>({
        query: (treatmentId) => ({
          method: 'POST',
          url: `treatment/${treatmentId}/restore`,
        }),
        invalidatesTags: (res, err, id) =>
          res
            ? [
                { type: 'Treatment', id },
                { type: 'PatientProfile', id },
              ]
            : [],
      }),
      sendAppInvite: build.mutation<CMSendAppInviteRes, CMSendAppInviteReq>({
        query: ({ treatmentId, method }) => ({
          method: 'POST',
          url: `treatment/${treatmentId}/send_registration`,
          params: {
            method,
          },
        }),
      }),
      addProgramTag: build.mutation<
        void,
        { programId: string; body: { tag: string } }
      >({
        invalidatesTags: ['ProgramTags'],
        query: ({ programId, body }) => ({
          method: 'POST',
          url: `/program/${programId}/tag`,
          body,
        }),
      }),
      getSupportiveContacts: build.query<
        Array<SupportiveContact>,
        { treatmentId: string }
      >({
        providesTags: (res, err, req) => [
          { type: 'Contacts', id: req.treatmentId },
        ],
        query: ({ treatmentId }) => ({
          method: 'GET',
          url: `/treatment/${treatmentId}/supportive-contacts`,
        }),
      }),
      editAdditionalContact: build.mutation<
        SupportiveContact,
        {
          treatmentId: string
          contactId: string
          contact: Omit<SupportiveContact, 'id'>
        }
      >({
        invalidatesTags: (res, err, req) => [
          { type: 'Contacts', id: req.treatmentId },
        ],
        query: ({ treatmentId, contactId, contact: body }) => ({
          method: 'PUT',
          url: `/treatment/${treatmentId}/supportive-contact/${contactId}`,
          body: { id: contactId, ...body },
        }),
      }),
      createAdditionalContact: build.mutation<
        SupportiveContact,
        { treatmentId: string; contact: Omit<SupportiveContact, 'id'> }
      >({
        invalidatesTags: (res, err, req) => [
          { type: 'Contacts', id: req.treatmentId },
        ],
        query: ({ treatmentId, contact: body }) => ({
          method: 'POST',
          url: `/treatment/${treatmentId}/supportive-contact`,
          body,
        }),
      }),
      deleteAdditionalContact: build.mutation<
        SupportiveContact,
        { treatmentId: string; contactId: string }
      >({
        invalidatesTags: (res, err, req) => [
          { type: 'Contacts', id: req.treatmentId },
        ],
        query: ({ treatmentId, contactId }) => ({
          method: 'DELETE',
          url: `/treatment/${treatmentId}/supportive-contact/${contactId}`,
        }),
      }),
      setChannelSeen: build.mutation<string, { treatmentId: string }>({
        invalidatesTags: (res, err, req) => [
          { type: 'NotificationByTreatmentId', id: req.treatmentId },
        ],
        query: ({ treatmentId }) => ({
          method: 'POST',
          url: `/treatment/${treatmentId}/channel/seen`,
        }),
      }),
      setChannelUnseen: build.mutation<
        string,
        { treatmentId: string; ids?: Array<string> }
      >({
        invalidatesTags: (res, err, req) => [
          { type: 'NotificationByTreatmentId' },
        ],
        query: ({ treatmentId, ids }) => ({
          method: 'POST',
          url: `/treatment/${treatmentId}/channel/unseen`,
          body: ids,
        }),
      }),
    }),
  }),
)

export const careManagerApi = careManagerApiService({
  origin: `https://${process.env.CARE_MANAGER_API_DOMAIN}`,
  getAccessToken: (state) => state.auth.session?.idToken.jwt,
})

export type CareManagerApiType = typeof careManagerApi
