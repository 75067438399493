import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const CHECKED = 1
export const UNCHECKED = 2
export const INDETERMINATE = -1

function ThreeStateCheckbox({ value, id, handleChange }) {
  const checkRef = useRef()

  useEffect(() => {
    checkRef.current.checked = value === CHECKED
    checkRef.current.indeterminate = value === INDETERMINATE
  }, [value])

  return (
    <input
      type="checkbox"
      ref={checkRef}
      id={id}
      defaultChecked={false}
      onClick={(e) => {
        handleChange(e.target.checked)
      }}
    />
  )
}

ThreeStateCheckbox.propTypes = {
  value: PropTypes.number,
  id: PropTypes.string,
  handleChange: PropTypes.func,
}

export default ThreeStateCheckbox
