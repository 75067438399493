import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import dStrings from '../../strings.json'
import { getProfileName } from '../utilities/treatments'
import { getCustomStyles } from '../utilities'
import {
  getSingleValue,
  ProfilesOptions,
} from '../AddEditProvider/selectOptions'

const getCoveringProviderName = (cpId, providers) => {
  let coveringName = ''
  try {
    coveringName = getProfileName(providers.find((p) => p.id === cpId))
  } catch (error) {
    //
  }

  return coveringName
}

export const handleScheduleError = ({
  key,
  error,
  data,
  user,
  providers,
  coveringUser,
  displayConfirmation,
  handleSaveEditDates,
  addUserMessage,
}) => {
  const minimumDurationPeriod = () => {
    if (!key || !key.minimumDuration) return dStrings.errorOccurred

    const details = key.minimumDuration

    let period
    switch (details.unit) {
      case 'days':
        period = `${details.value} ${
          details.value > 1 ? dStrings.days : dStrings.day
        }`
        break
      case 'weeks':
        period = `${details.value} ${
          details.value > 1 ? dStrings.weeks : dStrings.week
        }`
        break
      case 'hours':
        period = `${details.value} ${
          details.value > 1 ? dStrings.hours : dStrings.hour
        }`
        break
      default:
        break
    }

    addUserMessage('error', dStrings.oooMinimumDuration + period)
  }

  if (error === 'dates_lesser_than_minimum_ooo_duration')
    return minimumDurationPeriod()

  let id = data && data.coveringProviderId
  let displayError = false

  switch (key) {
    case 'already_covering_provider_on_same_dates':
      if (!data.coveringProviderId && !data.outOfOffice?.coveringProviderId) {
        displayConfirmation({
          details: {
            hideCancelBtn: true,
            confirmBtnText: dStrings.ok,
            title: dStrings.confirmation,
            content: (
              <>
                <div>{dStrings.youAreCovering}</div>
                <div>{dStrings.needSelectCovering}</div>
                <CoveringSelect
                  providers={
                    providers &&
                    providers.allIds.map((_id) => providers.byId[_id])
                  }
                  coveringProviderId={id}
                  displayError={displayError}
                  handleChange={(t) => {
                    if (t && t.value) {
                      if (displayError) displayError = false
                      id = t.value
                    }
                  }}
                />
              </>
            ),
          },
          callback: () => {
            handleSaveEditDates({ ...data, coveringProviderId: id })
          },
        })
      } else {
        displayConfirmation({
          details: {
            confirmBtnText: dStrings.yes,
            content: (
              <>
                <div>{dStrings.youAreCoveringTime}</div>
                <div>
                  {dStrings.replaceOOOCovering.replace(
                    '${coveringName}',
                    getCoveringProviderName(data.coveringProviderId, providers),
                  )}
                </div>
              </>
            ),
          },
          callback: () => {
            handleSaveEditDates(data, { forceOOO: true })
          },
        })
      }
      break
    case 'missing_covering_provider':
    case 'covering_provider_ooo_on_away_providers_dates':
      addUserMessage('error', dStrings.coveringIsOOO)
      break
    case 'covering_provider_ooo_on_same_dates':
      addUserMessage('error', dStrings.selectedCoveringOOO)
      break
    case 'ooo_overlap':
      addUserMessage('error', dStrings.oooOverlap)
      break
    case 'provider_cannot_be_own_covering_provider':
      addUserMessage('error', dStrings.providerCannotBeOwnCoveringProvider)
      break
    case 'away_pctm_will_return_by_end_of_ooo':
      addUserMessage(
        'error',
        dStrings.pctmOOOSelectOther.replace(
          '${providerName}',
          getProfileName(user.profile),
        ),
      )
      break
    case 'covering_provider_for_another_provider_on_same_dates':
      displayConfirmation({
        details: {
          confirmBtnText: dStrings.yes,
          cancelBtnText: dStrings.no,
          content: (
            <>
              <div>
                {dStrings.coveringIsAlreadyCovering.replace(
                  '${coveringName}',
                  getCoveringProviderName(data.coveringProviderId, providers),
                )}
              </div>
              <div>{dStrings.areYouSureYouWantContinue}</div>
            </>
          ),
        },
        callback: () => {
          handleSaveEditDates(data, { forceCoveringProvider: true })
        },
      })
      break
    case 'new_pctm_currently_ooo':
      displayConfirmation({
        details: {
          title: dStrings.confirmation,
          content: (
            <>
              <div>
                {dStrings.providerIsOOO
                  .replace(/\${providerName}/g, getProfileName(user.profile))
                  .replace(
                    '${coveringUser}',
                    coveringUser ||
                      getCoveringProviderName(
                        data.coveringProviderId,
                        providers,
                      ),
                  )}
              </div>
              <div>{dStrings.doWishContinue}</div>
            </>
          ),
        },
        callback: () => {
          handleSaveEditDates(data, { forceCoveringProvider: true })
        },
      })
      break
    default:
      return 'not_covering_error'
  }
}

export function CoveringSelect({
  providers,
  handleChange,
  coveringProviderId,
  displayError,
  width,
}) {
  const [providerOptions, setProviderOptions] = useState()
  const [value, setValue] = useState({ value: coveringProviderId })

  useEffect(() => {
    let list = []
    if (providers && providers.length) {
      list = providers.map((p) => {
        return {
          label: getProfileName(p),
          value: p.id,
          profile: p,
        }
      })

      list.unshift({ label: dStrings.NA, value: null })
    }

    setProviderOptions(list)
  }, [providers])

  const customStyles = getCustomStyles({ width })
  const comp = {
    Option: ProfilesOptions,
    SingleValue: getSingleValue(providers),
  }

  return (
    <>
      <Select
        // menuIsOpen={true}
        closeMenuOnSelect={false}
        styles={customStyles}
        components={comp}
        menuPlacement="bottom"
        hideSelectedOptions={false}
        searchable
        noOptionsMessage={() => {
          return <div> {dStrings.noResultSearch} </div>
        }}
        onChange={(t) => {
          setValue(t)
          handleChange(t)
        }}
        placeholder={dStrings.noCoveringUser}
        value={value}
        isMulti={false}
        options={providerOptions}
      />
      {displayError && (
        <div className="invalid-message small-font">
          {dStrings.selectCoveringUser}
        </div>
      )}
    </>
  )
}
