import {
  type TemplateSection,
  type SectionItem,
  type Field,
  type AnswerOnFieldData,
} from '@valerahealth/rtk-query'

export type TemplateFormType = {
  [x: string]: AnswerOnFieldData['value'] | Date
}

export const templateToForm = (sections: TemplateSection[]) => {
  const obj: TemplateFormType = {}

  const fieldToForm = (field: Field) => {
    obj[field.id] = field.value
  }

  const sectionToForm = (section: TemplateSection) => {
    section.items.forEach((sectionItem: SectionItem) => {
      switch (sectionItem.itemType) {
        case 'field':
          fieldToForm(sectionItem)
          break
        case 'field_group':
          sectionItem.fields.forEach((field: Field) => {
            fieldToForm(field)
          })
          break
        case 'section':
          obj[`section_${sectionItem.id}`] = sectionItem.include
          sectionToForm(sectionItem)
          break
        default:
          break
      }
    })
  }

  sections.forEach((section: any) => {
    sectionToForm(section)
  })

  return obj
}
