import React from 'react'
import Modal from 'react-modal'
import Button from '../Button'
import IconButton from '../IconButton'
import './style.css'
import PropTypes from 'prop-types'

export default function Popup({
  title,
  buttons,
  onClosed,
  hideCloseBtn,
  children,
  bodyClass,
  headerClass,
  className,
  hideHeader,
  parentSelector,
  overlayClassName,
  avoidCloseOnEsc,
  headerIcon,
  options = {},
}) {
  const customStyles = {
    content: {
      position: 'initial',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      borderRadius: '5px',
      minWidth: '350px',
      overflow: 'initial', // be carefull. Set it to "auto" cause bug when open <Select /> into.
      opacity: 1,
      zIndex: 3,
      fontSize: '13px',
    },
    overlay: {
      background: 'rgba(20, 44, 60, 0.5)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }

  // if (isIOS) {
  //   customStyles.content.fontFamily = "Avenir";
  //   customStyles.content.fontSize = "13px";
  // } else {
  //   customStyles.content.fontFamily = "Verdana";
  //   customStyles.content.fontSize = "12px";
  // }

  const checkClose = (e) => {
    if (e.keyCode && e.keyCode === 27) onClosed && onClosed()
    else return
  }

  return (
    <div onKeyUp={checkClose}>
      <Modal
        shouldCloseOnEsc={!avoidCloseOnEsc}
        isOpen
        onAfterClose={onClosed}
        style={customStyles}
        ariaHideApp={false}
        className={className}
        parentSelector={parentSelector}
        overlayClassName={overlayClassName}
      >
        {!hideHeader && (
          <div className={headerClass || 'popup-header'}>
            <div className="popup-header-content">{title}</div>
            <div className="popup-header-icon">
              {!hideCloseBtn && (
                <IconButton
                  onClick={() => {
                    onClosed && onClosed()
                  }}
                  type="close"
                />
              )}
              {headerIcon && headerIcon()}
            </div>
          </div>
        )}
        <div className={bodyClass || 'popup-body'}>{children}</div>
        {!options.hideFooter && (
          <div className="popup-footer">
            {buttons &&
              buttons.map((b, index) => {
                return <Button key={index} type={b.type || 'primary'} {...b} />
              })}
          </div>
        )}
      </Modal>
    </div>
  )
}

Popup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttons: PropTypes.array,
  type: PropTypes.string,
  className: PropTypes.string,
  onClosed: PropTypes.func,
  hideCloseBtn: PropTypes.bool,
  bodyClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  options: PropTypes.object,
}
