import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import ReactDatePicker from 'react-date-picker'
import CloseIcon from '../../images/icon_close.svg'
import { daysLetters } from '../../utilities'

function DatePicker({
  value,
  onChange,
  className,
  disabled,
  maxDate,
  minDate,
  dateFormat,
}) {
  // test
  return (
    <ReactDatePicker
      formatShortWeekday={(locale, date) => {
        return daysLetters[date.getDay()]
      }}
      onChange={onChange}
      value={value}
      className={`${className || ''} dashboard-datepicker`}
      clearIcon={<CloseIcon />}
      calendarIcon={null}
      disabled={disabled}
      format={dateFormat || 'MM/dd/yyyy'}
      maxDate={maxDate}
      minDate={minDate}
      calendarType="US"
    />
  )
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
}

export default DatePicker
