import React, { useCallback, useMemo } from 'react'
import {
  DataGridPro,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
  type GridEventListener,
  type GridRowId,
  type GridApi,
  DataGridProProps,
  GridValidRowModel,
  GridColDef,
} from '@mui/x-data-grid-pro'
import { Popover, Button, Alert, Box } from '../../base'

import {
  useConfirmationDialog,
  type ConfirmDialogArguments,
} from '../../features/ConfirmationDialog'

import { useTranslation } from '../../utils/hooks'
import { renderEditableGridActions } from './ActionsCell'

const defaultGetRowId = (data: any) => {
  if ('id' in data) {
    return data.id as GridRowId
  }
  throw new Error(
    'You must provide a getRowId prop to the EditableDataGrid if your data type does not have an id property',
  )
}

type EditabledataGridBaseProps<DataType extends GridValidRowModel> = {
  rows: DataType[]
  apiRef?: React.MutableRefObject<GridApi>
  isLoading?: boolean
  /** seeds a new row */
  onAddRow?: () => DataType
  columns: GridColDef<DataType>[]
  GridWrapperComponent?: React.ComponentType<any>
  /** defaults to assuming an id property exists on the DataType */
  getRowId?: (data: DataType) => GridRowId
  /** if there is an error, it returns an error message */
  onSave: (
    /** the row being saved */
    data: DataType,
    /** the other rows in the grid (doesn't include the one being saved) */
    otherRows: DataType[],
  ) =>
    | Promise<
        | {
            data: DataType
          }
        | {
            error: string
          }
        | void /** return undefined if the save action needs to be aborted */
      >
    | {
        data: DataType
      }
    | {
        error: string
      }
    /** return undefined if the save action needs to be aborted */
    | void

  /** void on success else error */
  onDelete: (
    /** the row being deleted */
    data: DataType,
    /** the other rows in the grid (doesn't include the one being saved) */
    otherRows: DataType[],
  ) =>
    | Promise<void | {
        error: string
      }>
    | void
    | {
        error: string
      }
  addButtonOptions?: {
    text: string
    variant?: 'text' | 'outlined' | 'contained'
    className: string
    style: object
  }
  /** allows you to override the default actions render cell */
  renderActions?: typeof renderEditableGridActions<DataType>
  renderActionsGridColDef?: Partial<GridColDef<DataType>>
  /** allows you to override the default button */
  renderAddButton?:
    | boolean
    | ((props: {
        isEditingGrid?: boolean
        /** using this function will add a new inline editing row to the grid */
        onAddRow: () => void
      }) => JSX.Element)

  /* returning undefined or null will skip shownig the confirm modal */
  onDeleteConfirm?: (row: DataType) => ConfirmDialogArguments | undefined | null
  /* returning undefined or null will skip shownig the confirm modal */
  onSaveConfirm?: (
    uneditedRow: DataType,
    editedRow: DataType,
  ) => ConfirmDialogArguments | undefined | null
  /** undefined defaults to allowing all to be backwards compatible. Null treats all as false */
  permissions?: {
    allowAdd?: boolean
    allowUpdate?: boolean
    allowDelete?: boolean
  } | null
}

export type EditableDataGridProps<DataType extends GridValidRowModel> =
  EditabledataGridBaseProps<DataType> &
    Omit<
      DataGridProProps<DataType>,
      | keyof EditabledataGridBaseProps<DataType>
      | 'editMode'
      | 'rowModesModel'
      | 'expiramental'
      | 'onRowEditStart'
      | 'onRowEditStop'
      | 'experimentalFeatures'
    >

export default function EditableDataGrid<
  DataType extends { _isNew?: boolean } & GridValidRowModel,
>({
  isLoading,
  columns,
  onSave,
  onDelete,
  onAddRow,
  GridWrapperComponent,
  rows: rowProp,
  apiRef: apiRefProp,
  getRowId = defaultGetRowId,
  addButtonOptions,
  renderActions = renderEditableGridActions,
  renderActionsGridColDef,
  renderAddButton,
  onDeleteConfirm,
  onSaveConfirm,
  initialState,
  /** undefined defaults to allowing all to be backwards compatible. Null treats all as false */
  permissions = {
    allowAdd: true,
    allowUpdate: true,
    allowDelete: true,
  },
  ...props
}: EditableDataGridProps<DataType>) {
  const [t] = useTranslation()
  const { confirm, ConfirmationDialog: DeleteConfirmationDialog } =
    useConfirmationDialog()
  // row and rowModel
  const internalApiRef = useGridApiRef()
  // allows user to pass in their own ref if they want to use api features
  const apiRef = apiRefProp || internalApiRef

  const [rows, setRows] = React.useState(rowProp || [])
  React.useEffect(() => {
    // if new rows gets passed in, update
    setRows(rowProp)
  }, [setRows, rowProp])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  )

  const isEditingGrid = React.useMemo(
    () =>
      Object.values(rowModesModel).some(
        (rowMode) => rowMode.mode === GridRowModes.Edit,
      ),
    [rowModesModel],
  )

  // poppver UI state
  const [invalid, setInvalidation] = React.useState<{
    anchorEl: HTMLDivElement
    message: string
  } | null>(null)

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    event.defaultMuiPrevented = true
  }

  const handleEdit = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSave = async (editRowId: GridRowId) => {
    
    const editRow =
      apiRef.current.getRowWithUpdatedValues(
        editRowId,
        '', // no field needed when row editing
      ) as DataType

    // this is a private property that we dont want to submit to the validator
    delete editRow._isNew

    const otherRows = rows.filter((v) => getRowId(v) !== editRowId)

    const validation = await onSave(editRow, otherRows)
    if (!validation) return

    if ('error' in validation) {
      const anchorEl = apiRef.current.getCellElement(editRowId, 'actions')!
      setInvalidation({
        message:
          typeof validation.error === 'string'
            ? validation.error
            : t('editableDataGrid.genericInvalidRowMessage'),
        anchorEl,
      })
    } else {
      // desctructuring takes the current edit row out of the model. Important because if its a new row the id will change. If an id is left in the model but doesnt exist in the rows the grid crashes
      setRowModesModel(({ [editRowId]: editRowMode, ...model }) => ({
        ...(editRowId === getRowId(validation.data)
          ? {
              [editRowId]: {
                mode: GridRowModes.View,
              },
            }
          : null),
        ...model,
      }))
      // update the row in place with the new value
      setRows((rows) =>
        rows.map((r) => (getRowId(r) === editRowId ? validation.data! : r)),
      )
    }
  }

  const handleAdd = useCallback(() => {
    const newRow = onAddRow ? onAddRow() : ({ id: Date.now() } as any)
    newRow._isNew = true

    setRowModesModel((model) => ({
      ...model,
      [getRowId(newRow)]: { mode: GridRowModes.Edit },
    }))
    setRows((rows) => [newRow, ...rows])
  }, [getRowId, onAddRow])

  const handleDelete = async (id: GridRowId) => {
    // we dont want to submit new rows (i.e rows that have yet to be saved)
    if (rows?.find((row) => row?.id === id)?.cannotBeDeleted) {
      setInvalidation({
        message: t('editableDataGrid.rowCannotBeDeleted'),
        anchorEl: apiRef.current.getCellElement(id, 'actions')!,
      })
      return
    }
    const otherRows = rows.filter((r) => !r._isNew)
    const rowIndex = otherRows.findIndex((r) => getRowId(r) === id)

    // removes the element from the array and returns it
    const deletedRow = otherRows.splice(rowIndex, 1)[0]!

    const validation = await onDelete(deletedRow, otherRows)

    if (!validation) {
      setRows(rows.filter((row) => getRowId(row) !== id))
    } else {
      setInvalidation({
        message: validation.error,
        anchorEl: apiRef.current.getCellElement(id, 'actions')!,
      })
    }
  }

  const handleCancel = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
    const editedRow = rows.find((row) => getRowId(row) === id)!
    if (editedRow._isNew) {
      setRows(rows.filter((row) => getRowId(row) !== id))
    }
  }

  const actionsCell = renderActions({
    field: 'action',
    allowUpdate: permissions?.allowUpdate ?? false,
    allowDelete: permissions?.allowDelete ?? false,
    handleCancel,
    handleDelete,
    handleDeleteConfirmation: onDeleteConfirm
      ? (data) => {
          const confirmationProps = onDeleteConfirm(data)
          return confirmationProps ? confirm(confirmationProps) : true
        }
      : undefined,
    handleSaveConfirmation: onSaveConfirm
      ? (...props) => {
          const confirmationProps = onSaveConfirm(...props)
          return confirmationProps ? confirm(confirmationProps) : true
        }
      : undefined,
    handleEdit,
    handleSave,
    isEditingGrid,
    disableActions: isLoading
      ? ['delete', 'save', 'edit']
      : isEditingGrid
      ? ['edit', 'delete']
      : [],
  })

  // when adding a new row, we want to lock state and control column visibility, sorting
  React.useEffect(() => {
    const currentSorting = apiRef.current?.getSortModel()
    const hasNewRow = rows.some((r) => r._isNew)
    const currentlySortingOnNew = currentSorting?.some(
      (v) => v.field === '_isNew',
    )

    if (hasNewRow && !currentlySortingOnNew) {
      apiRef.current?.setSortModel([
        { field: '_isNew', sort: 'desc' },
        ...currentSorting,
      ])
    } else if (!hasNewRow && currentlySortingOnNew) {
      apiRef.current?.setSortModel(
        currentSorting.filter((v) => v.field !== '_isNew'),
      )
    }
  }, [rows, apiRef])

  const _initialState = useMemo(
    () => ({
      ...initialState,
      columns: {
        ...initialState?.columns,
        columnVisibilityModel: {
          ...initialState?.columns?.columnVisibilityModel,
          _isNew: false,
        },
      },
    }),
    [initialState],
  )

  const addButtonElement = useMemo(() => {
    if (typeof renderAddButton === 'function') {
      return renderAddButton({ onAddRow: handleAdd, isEditingGrid })
    }

    if (typeof renderAddButton === 'boolean' && !renderAddButton) return null

    const { variant, className, style, text } = addButtonOptions || {}
    const { allowAdd } = permissions || {}

    return allowAdd ? (
      <Button
        color="primary"
        variant={variant}
        className={className}
        onClick={handleAdd}
        sx={{ mr: 'auto' }}
        disabled={isEditingGrid || isLoading}
        style={style}
      >
        {text || t('add')}
      </Button>
    ) : null
  }, [
    handleAdd,
    isEditingGrid,
    isLoading,
    renderAddButton,
    permissions,
    addButtonOptions,
    t,
  ])

  return (
    <>
      <Box
        sx={{
          mb: 1,
        }}
      >
        {addButtonElement}
      </Box>

      <Box
        component={GridWrapperComponent}
        sx={{
          flexGrow: 1,
          '& .MuiDataGrid-cell:focus': {
            outlineWidth: '0px !important',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outlineWidth: '0px !important',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outlineWidth: '0px !important',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outlineWidth: '0px !important',
          },
        }}
      >
        <DataGridPro
          showColumnVerticalBorder
          {...props}
          rows={rows}
          columns={columns
            .concat(
              actionsCell
                ? {
                    field: 'actions',
                    type: 'actions',
                    headerName: t('editableDataGrid.actionsColumn'),
                    width: 150,
                    disableColumnMenu: true,
                    align: 'left',
                    headerAlign: 'left',
                    renderCell: actionsCell,
                    ...renderActionsGridColDef,
                  }
                : [],
            )
            .concat(
              rows.some((r) => r._isNew)
                ? [
                    {
                      field: '_isNew',
                      type: 'boolean',
                    },
                  ]
                : [],
            )}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          apiRef={apiRef}
          getRowId={getRowId}
          loading={isLoading}
          initialState={_initialState}
        />
        <Popover
          open={!!invalid}
          anchorEl={invalid?.anchorEl}
          onClose={() => setInvalidation(null)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert severity="warning">{invalid?.message}</Alert>
        </Popover>
        <DeleteConfirmationDialog keepMounted />
      </Box>
    </>
  )
}
