import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { ControlledTextField, type ControlledTextFieldProps } from './TextField'
import { maskPhoneNumber } from '../../utils/data'
import { useTranslation } from '../../utils/hooks'

const phoneRegexp = /^(\+\d{1,3})?[0-9]{10}$/

const setValueAs = (v?: string | null) => {
  const value = (v ?? '')
    .trim()
    .replace(/[^0-9]/g, '')
    .slice(0, 10)
  return value.length === 10 ? `+1${value}` : value
}

export function MaskedPhoneInput({
  name,
  label,
  errorFieldName,
  ...rest
}: ControlledTextFieldProps) {
  const { t } = useTranslation()

  const { setValue, getValues } = useFormContext()

  useEffect(() => {
    const [value] = getValues([name]) as [string | null | undefined]
    // make sure the initial value get formatted properly
    if (value && !phoneRegexp.test(value ?? '')) {
      setValue(name, setValueAs(value), {
        shouldDirty: false,
        shouldTouch: false,
      })
    }
  }, [setValue, getValues, name])

  return (
    <ControlledTextField
      {...rest}
      name={name}
      label={label}
      pattern={{
        value: phoneRegexp,
        message: t('form_invalid_field', { field: errorFieldName || label }),
      }}
      setValueAs={setValueAs}
      onFocus={(e) => {
        e.target.setSelectionRange(0, e.target.value.length)
      }}
      mask={(val, e) => {
        let newValue = maskPhoneNumber(val)
        if (!e) return newValue
        let cursorPosition = e.target.selectionStart || 0
        const direction = val.length < newValue.length ? -1 : 1
        const part1 = val.slice(0, cursorPosition)
        const part2 = val.slice(cursorPosition)
        if (direction === -1) {
          // if no formatting on originalValue then we selected all and replaced, set after last number
          if (!/[_ ()]/.test(val)) {
            cursorPosition = (/\d(?!.*\d)/.exec(newValue)?.index || 0) + 1
          } else {
            cursorPosition = (/\d(?!.*\d)/.exec(part1)?.index || 0) + 1
          }
        } else {
          if (val.length - newValue.length === 1) {
            // mask removed the
            newValue = maskPhoneNumber(val.slice(0, val.length - 1))
          }
          const add = /^([() -]+)/.exec(part2)?.[1]?.length || 0
          cursorPosition += add
        }
        e.target.value = newValue
        e.target.setSelectionRange(cursorPosition, cursorPosition)
        return e.target.value
      }}
    />
  )
}
