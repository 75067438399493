import {
  useState,
  useEffect,
  useRef,
  useMemo,
  RefObject,
  KeyboardEvent,
  MouseEvent,
} from 'react'
import { connect, ConnectedProps } from 'react-redux'
import './style.less'
// @ts-ignore
import { CallLoader } from '@valerahealth/ui-core'
import { NON_ACTIVE_PATIENT_STATUSES } from '@valerahealth/rtk-query'
import {
  Box,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopupMenu,
  TextField,
} from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import { RootState } from 'redux/reducers'
import SendBtn from '../../images/btn_send.svg'
import ChatMessage from './ChatMessage'
import {
  loadChannelMessages,
  sendChannelMessage,
} from '../../redux/actions/treatmentRoom'
import EmptyStateIcon from '../../images/img_channel.svg'
import AttachmentIcon from '../../images/btn_channel_attachment.svg'
import EditItemPopup from '../PlanTemplate/EditItemPopup'
import {
  setAllMessageAsReaded,
  setChannelSeen,
} from '../../redux/actions/caseloadActions'
import dStrings from '../../strings.json'
import { MESSAGES_LENGTH } from '../utilities'
import { getTreatment } from '../utilities/treatments'
import EmptyPage from '../common/EmptyPage'
import { helpAction } from '../../redux/actions/globalActions'

import { getToolTemplateIcon } from '../utilities/planTemplate'

function mapStateToProps(
  state: RootState,
  ownProps: { id?: string; disabled?: boolean },
) {
  const treatment = getTreatment(state)
  const events = state.program.configurations?.unreadChat?.events
  return {
    providerId: ownProps?.id || state.auth.session?.user.careManagerId,
    treatmentId: state.treatmentRoom.selectedId,
    toolsTemplates: state.toolsTemplates,
    disabled: ownProps?.disabled,
    events,
    status: treatment?.profile?.status,
    chatMessages: treatment?.channelMessages,
    cannotLoadMore: treatment?.cannotLoadMore,
    unreadMessages: treatment?.channel?.unread?.length || 0,
    profileId: treatment?.profile?.profileId,
    isCTM: treatment?.isCTM,
  }
}

const mapDispatchToProps = {
  loadChannelMessages,
  sendChannelMessage,
  setChannelSeen,
  setAllMessageAsReaded,
  helpAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector>

const ChannelInput = ({
  sendMessageOptions,
  sendMessage,
  msgText,
  setMsgText,
}: {
  sendMessageOptions: {
    title: string
    icon: JSX.Element
    onclick: () => void
  }[]
  sendMessage: (
    event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>,
  ) => void
  msgText: string
  setMsgText: (value: string) => void
}) => {
  return (
    <TextField
      multiline
      fullWidth
      minRows={1}
      maxRows={5}
      onKeyUp={sendMessage}
      value={msgText}
      onChange={(event) => {
        setMsgText(event.target.value)
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PopupMenu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              button={
                <IconButton
                  size="medium"
                  aria-label="valera apps"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AttachmentIcon />
                </IconButton>
              }
            >
              {sendMessageOptions.map(({ title, onclick, icon }) => (
                <MenuItem onClick={onclick} key={title}>
                  {icon && (
                    <ListItemIcon sx={{ width: '15px', height: '15px' }}>
                      {icon || ''}
                    </ListItemIcon>
                  )}
                  <ListItemText>{title}</ListItemText>
                </MenuItem>
              ))}
            </PopupMenu>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={sendMessage}>
              <SendBtn />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

function TreatmentChannel({
  providerId,
  disabled,
  chatMessages,
  treatmentId,
  profileId,
  isCTM,
  loadChannelMessages,
  toolsTemplates,
  events,
  cannotLoadMore,
  unreadMessages,
  sendChannelMessage,
  status,
  setChannelSeen,
  setAllMessageAsReaded,
  helpAction,
}: ComponentProps) {
  const chatBody = useRef<HTMLDivElement>(null)
  const dummy = useRef<HTMLDivElement>(null)
  const redLine = useRef<HTMLDivElement>(null)
  const [msgText, setMsgText] = useState('')
  const [shouldScroll, setShouldScroll] = useState(true)
  const [previousScrollHeight, setPreviousScrollHeight] = useState<number>(0)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const [firstScroll, setFirstScroll] = useState(true)
  const [displayUnread, setDisplayUnread] = useState(true)
  const [flag, setFlag] = useState(false)
  const [markedAsUnreadCount, setMarkedAsUnreadCount] = useState(0)

  const { t } = useTranslation()

  useEffect(() => {
    helpAction()
    return () => {
      setAllMessageAsReaded()
    }
  }, [helpAction, setAllMessageAsReaded])

  useEffect(() => {
    if (!chatMessages && treatmentId && providerId) {
      loadChannelMessages()
    }
  }, [chatMessages, treatmentId, providerId, loadChannelMessages])

  useEffect(() => {
    if (cannotLoadMore && isLoadingMore) {
      setIsLoadingMore(false)
    }
  }, [cannotLoadMore, isLoadingMore])

  const lastUnviewedMessage = useMemo(() => {
    return chatMessages?.findIndex(
      (message) =>
        !message.viewedByMe &&
        events?.includes(message.domainEvent) &&
        message.sender?.id !== providerId,
    )
  }, [providerId, events, chatMessages])

  const sendNewMessage = (
    e: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    if (('keyCode' in e && e.keyCode !== 13) || e.shiftKey) return

    if (msgText.trim().length) {
      setMarkedAsUnreadCount(0)
      setDisplayUnread(false)
      sendChannelMessage({ message: msgText.trim() })
    }

    setMsgText('')
  }

  /*
    scrollHeight: total container size.
    scrollTop: amount of scroll user has done.
    clientHeight: amount of container a user sees.
    */

  const loadMore = () => {
    if (!treatmentId) return

    if (cannotLoadMore || !chatMessages || isLoadingMore) return

    if (chatBody.current && !chatBody?.current.scrollTop) {
      setShouldScroll(
        chatBody.current.scrollTop + chatBody.current.clientHeight ===
          chatBody.current.scrollHeight,
      )

      setPreviousScrollHeight(chatBody.current.scrollHeight)
      setIsLoadingMore(true)
      loadChannelMessages()
    }
  }

  const scrollToBottom = ($element: RefObject<HTMLDivElement>) => {
    $element.current?.scrollIntoView()
    if (firstScroll) setFirstScroll(false)
  }

  const sendMessageOptions = useMemo(() => {
    return (
      [
        'questionnaire',
        'article',
        'audio_track',
        'sentiment',
        'consent_form',
      ] as const
    )
      .filter((k) => {
        return toolsTemplates[k].list?.allIds.length
      })
      .map((t) => {
        return {
          title: toolsTemplates[t].title,
          icon: getToolTemplateIcon(t),
          onclick: () => {
            setSelectedType(t)
          },
        }
      })
  }, [toolsTemplates])

  useEffect(() => {
    if (flag) return
    scrollToBottom(redLine)
  }, [redLine && redLine.current, flag])

  useEffect(() => {
    // on chat, scroll should be on bottom
    if (
      shouldScroll ||
      (chatMessages && chatMessages.length === MESSAGES_LENGTH)
    ) {
      if (unreadMessages) {
        setTimeout(() => {
          setChannelSeen(treatmentId)
        }, 1000)
      }

      scrollToBottom(dummy)
    } else {
      setIsLoadingMore(false)
      if (chatBody.current) {
        chatBody.current.scrollTop =
          chatBody.current.scrollHeight - previousScrollHeight - 200
      }
    }

    helpAction()
  }, [chatMessages?.length])

  return (
    <>
      {selectedType && (
        //@ts-ignore
        <EditItemPopup
          title={dStrings.SendItem}
          saveText={dStrings.send}
          handleClose={() => {
            setSelectedType(null)
          }}
          disableType
          item={{
            actionItem: {
              type: selectedType,
              info: { templateId: undefined, text: '' },
            },
          }}
          handleSave={(item: any) => {
            setMarkedAsUnreadCount(0)
            sendChannelMessage({
              attachment: {
                target: item.actionItem.type,
                templateId: item.actionItem.info.templateId,
              },
            })

            setSelectedType(null)
          }}
        />
      )}
      <Box
        id="treatment-chat"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          ref={chatBody}
          id="chat-body"
          onScroll={loadMore}
          sx={{
            overflowY: 'auto',
            flex: '1 1 100%',
            height: '100%',
            py: 1,
          }}
        >
          {isLoadingMore && (
            <div className="loading-more-container">
              <CallLoader />
            </div>
          )}
          {chatMessages &&
            (chatMessages.length ? (
              <>
                {chatMessages.map((message, index, array) => {
                  return (
                    <div key={message.id}>
                      {isCTM &&
                        displayUnread &&
                        lastUnviewedMessage === index && (
                          <div
                            className="text-between-lines unread-message"
                            ref={redLine}
                          >
                            <span>
                              {index !== 0
                                ? dStrings.newMessages
                                : dStrings.newMessage}{' '}
                            </span>
                          </div>
                        )}
                      <ChatMessage
                        message={message}
                        ownMessage={message.sender?.id === providerId}
                        patientMessage={message.sender?.id === profileId}
                        index={index}
                        messagesArr={array}
                        handleMarkAsUnread={() => {
                          setFlag(true)
                          setMarkedAsUnreadCount(markedAsUnreadCount + 1)
                          if (!displayUnread) {
                            setDisplayUnread(true)
                          }
                        }}
                      />
                    </div>
                  )
                })}
                <div ref={dummy} style={{ visibility: 'hidden' }} />
              </>
            ) : (
              <EmptyPage Icon={EmptyStateIcon}>
                <div className="channel-empty-state">
                  <div>{dStrings.noChannelMessage}</div>
                </div>
              </EmptyPage>
            ))}
        </Box>
        {!disabled &&
          status &&
          (!NON_ACTIVE_PATIENT_STATUSES.includes(status) ? (
            <ChannelInput
              sendMessageOptions={sendMessageOptions}
              sendMessage={sendNewMessage}
              msgText={msgText}
              setMsgText={setMsgText}
            />
          ) : (
            <div id="chat-footer" className="discharged-footer">
              <div className="discharge-message">
                {t('TreatmentChannel.chatDisabledMessage', {
                  status: t(`PatientStatus.${status}`),
                })}
              </div>
            </div>
          ))}
      </Box>
    </>
  )
}

export default connector(TreatmentChannel)
