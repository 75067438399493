import React, { useState } from 'react'
import { connect } from 'react-redux'
import './style.less'
import { domainEventIcon } from '../../../utilities/domainEvent'
import { getAlertIcon } from '../../../utilities'
import {
  getPlanTemplateIcon,
  getToolTemplateIcon,
} from '../../../utilities/planTemplate'
import BadgeIcon from '../../../../images/icn_badge.svg'
import EMRSync from '../../../../images/clinical-note.svg'
import CallIcon from '../../../../images/icn_call-1.svg'
import EyeIcon from '../../../../images/eye.svg'
import { editItem } from '../../../../redux/actions/globalActions'
import ResolvedAlertsPopup from '../../ResolvedAlertsPopup'
import dStrings from '../../../../strings.json'
import { domainEventEnum } from '../../../utilities/enums'
import { Message } from '@valerahealth/ui-components/icons'
import { format } from '@valerahealth/ui-components/utils/date'

function Icon({ text }) {
  return text || <EyeIcon />
}

function TreatmentTimelineItemDetails({
  timelineElement,
  toolsTemplates,
  treatmentId,
  editItem,
}) {
  const [editAlert, setEditAlert] = useState(false)
  const [isPastElement] = useState(
    new Date().getTime() > new Date(timelineElement.ts).getTime(),
  )

  const getItemIcon = () => {
    try {
      if (
        timelineElement.domainEvent === 'patient_synced_from_emr' ||
        timelineElement.domainEvent === 'patient_registered'
      ) {
        return <EMRSync />
      }

      if (
        timelineElement.domainEvent === 'message_sent' ||
        timelineElement.domainEvent === 'message_read'
      ) {
        return <Message />
      }

      if (
        ~[
          'questionnaire',
          'sentiment',
          'media',
          'reminder',
          'appointment',
        ].indexOf(timelineElement.type)
      )
        return getToolTemplateIcon(timelineElement.item.type)

      if (timelineElement.domainEvent === 'alert_created')
        return getAlertIcon(timelineElement.item.type)
      if (timelineElement.type === 'care_team') return <BadgeIcon />
      if (timelineElement.type === 'video') return <CallIcon />
      if (timelineElement.type === 'plan')
        return getPlanTemplateIcon(timelineElement.item.type)
    } catch (error) {
      // silent
    }
  }

  const getItemTitle = () => {
    if (timelineElement.item.type === 'appointment') {
      if (timelineElement.item.custom && timelineElement.item.custom.ts)
        return format(
          new Date(timelineElement.item.custom.ts),
          'EEEE, MMM d, hh:mm a',
        )
      if (timelineElement.ts)
        return format(new Date(timelineElement.ts), 'EEEE, MMM d, hh:mm a')
    }

    if (
      [
        'questionnaire',
        'sentiment',
        'audio_track',
        'article',
        'consent_form',
      ].indexOf(timelineElement.item.type) === -1 ||
      isPastElement
    )
      return timelineElement.item.title

    if (!toolsTemplates[timelineElement.item.type]) {
      console.error(`${timelineElement.item.type} is not in ${toolsTemplates}`)
      return 'Error occurred;'
    }

    const content =
      toolsTemplates[timelineElement.item.type].list.byId[
        timelineElement.item.id
      ]
    const contentTitle =
      content && content[toolsTemplates[timelineElement.item.type].key]

    if (contentTitle) {
      return contentTitle
    }

    return timelineElement.item.title || 'ERROR OCCURED'
  }

  const getItemDescription = () => {
    if (timelineElement.domainEvent === 'video_session_terminated')
      return (
        dStrings.videoSessionStartFor.replace(
          '{startDate}',
          format(
            new Date(timelineElement.item.custom.ts),
            'EEEE, MMM d, hh:mm a',
          ),
        ) + timelineElement.item.custom.duration
      )

    if (timelineElement.domainEvent === 'medication_refill_created')
      return (
        <>
          <div>{timelineElement.item.description.medication}</div>
          {timelineElement.item.description.pharmacyName && (
            <div>{timelineElement.item.description.pharmacyName}</div>
          )}
        </>
      )

    return timelineElement.item.description
  }

  const shouldDisplayEye = () => {
    return (
      [
        'alert_resolved',
        'consent_form_assigned',
        'article_assigned',
        'audio_track_assigned',
        'episode_assigned',
        'questionnaire_assigned',
        'questionnaire_scheduled',
        'sentiment_assigned',
        'questionnaire_completed',
        'sentiment_completed',
        'article_viewed',
        'audio_track_viewed',
        'consent_form_agreed',
        'consent_form_seen',
        'message_scheduled',
        'medication_refill_created',
        'sentiment_scheduled',
      ].indexOf(timelineElement.domainEvent) !== -1
    )
  }

  return (
    <>
      {editAlert && (
        <ResolvedAlertsPopup
          treatmentId={treatmentId}
          itemId={timelineElement.item.id}
          handleClose={() => {
            setEditAlert(false)
          }}
        />
      )}
      <div className="timeline-item-details">
        <div className="timeline-item-title-container">
          <div className="timeline-item-icon">
            <Icon text={domainEventIcon[timelineElement.domainEvent]} />
          </div>
          <div className="timeline-item-title">
            {timelineElement.eventTitle}
          </div>
          {shouldDisplayEye() && (
            <EyeIcon
              onClick={() => {
                if (timelineElement.domainEvent === 'alert_resolved') {
                  setEditAlert(true)
                } else {
                  editItem({
                    assigned: isPastElement,
                    info: {
                      id: timelineElement.item.id,
                      templateId: timelineElement.item.id,
                    },
                    type: timelineElement.item.type || timelineElement.type,
                  })
                }
              }}
            />
          )}
        </div>
        {timelineElement.item && (
          <div className="timeline-item-details-container">
            {timelineElement.item.category && (
              <div className="category xsmall-font">
                {timelineElement.item.category}
              </div>
            )}
            {timelineElement.domainEvent !== 'medication_refill_created' && (
              <div className="icon-and-title">
                <span className="icon">{getItemIcon()}</span>
                <span className="title">{getItemTitle()}</span>
              </div>
            )}
            {timelineElement.item.description && (
              <div className="description">{getItemDescription()}</div>
            )}
            {timelineElement.domainEvent ===
              domainEventEnum.APPOINTMENT_RESCHEDULED &&
              timelineElement.item.custom?.notes && (
                <div className="notes">
                  {dStrings.patientNote}: {timelineElement.item.custom?.notes}
                </div>
              )}
          </div>
        )}
      </div>
    </>
  )
}

TreatmentTimelineItemDetails.propTypes = {}

function mapStateToProps(state, ownProps) {
  const treatmentId = state.treatmentRoom && state.treatmentRoom.selectedId
  const room = state.treatmentRoom.byId

  return {
    timelineElement: ownProps.timelineElement,
    toolsTemplates: ownProps.toolsTemplates,

    treatmentId,
  }
}

const mapDispatchToProps = {
  editItem,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TreatmentTimelineItemDetails)
