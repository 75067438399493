import {
  GridFilterOperator,
  getGridStringOperators as muiGetGridStringOperators,
} from '@mui/x-data-grid-pro'

let _stringOperators: GridFilterOperator[]
export default function getGridStringOperators() {
  if (!_stringOperators)
    _stringOperators = muiGetGridStringOperators().map((operator) => {
      if (operator.value !== 'isAnyOf') return operator
      const { InputComponent } = operator
      return {
        ...operator,
        InputComponent: InputComponent
          ? (props: any) => (
              <InputComponent
                {...props}
                ChipProps={{
                  size: 'small',
                  variant: 'outlined',
                  ...props?.ChipProps,
                }}
                variant="standard"
              />
            )
          : undefined,
      }
    })
  return _stringOperators
}
