// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.empty-page {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/EmptyPage/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".empty-page-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n.empty-page {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
