import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { format, addDays } from 'date-fns'
import dStrings from '../../strings.json'
import Popup from '../Popup'
import './style.css'
import { dateFormatEnum } from '../../utilities/enums'
import Calendar from 'react-calendar'
import { daysLetters } from '../../utilities'
import '../DatePicker'

function RangeDapicker({ start, end, label, handleSave }) {
  const rangeDatepicker = useRef()
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [selectedDate, setSelectedDate] = useState()
  const [selected, setSelected] = useState('')

  useEffect(() => {
    setStartDate(start)
  }, [start])

  useEffect(() => {
    setEndDate(end)
  }, [end])

  return (
    <div className="range-datepicker" ref={rangeDatepicker}>
      <div className="range-selection">
        {label || dStrings.showing}{' '}
        <span
          className={`displayed-date ${selected !== 'start' ? '' : 'opened'}`}
          onClick={() => {
            setSelectedDate(startDate)
            setSelected('start')
          }}
        >
          {startDate
            ? format(startDate, dateFormatEnum.MONTH_DAY_YEAR_WORD)
            : ''}
        </span>{' '}
        -{' '}
        <span
          className={`displayed-date ${selected !== 'end' ? '' : 'opened'}`}
          onClick={() => {
            setSelectedDate(endDate)
            setSelected('end')
          }}
        >
          {endDate ? format(endDate, dateFormatEnum.MONTH_DAY_YEAR_WORD) : ''}
        </span>
      </div>
      {!!selected.length && (
        <Popup
          parentSelector={() => rangeDatepicker.current}
          overlayClassName="range-datepicker-test"
          className="range-datepicker-popup"
          hideHeader
          buttons={[
            {
              text: dStrings.cancel,
              type: 'link',
              onClick: () => {
                setStartDate(start)
                setEndDate(end)
                setSelected('')
              },
            },
            {
              text: dStrings.apply,
              type: 'primary',
              onClick: () => {
                if (selected === 'start') {
                  setStartDate(selectedDate)
                  let e = endDate
                  if (selectedDate > endDate) {
                    e = addDays(selectedDate, 1)
                  }

                  handleSave(selectedDate, e)
                } else {
                  setEndDate(selectedDate)
                  let s = startDate
                  if (selectedDate < startDate) {
                    s = addDays(selectedDate, -1)
                  }

                  handleSave(s, selectedDate)
                }
                setSelected('')
              },
            },
          ]}
        >
          <div
            className={`dashboard-datepicker range-datepicker-container ${
              !selectedDate ? '' : 'selected-state'
            }`}
          >
            <Calendar
              calendarType="US"
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(date)
              }}
              formatShortWeekday={(locale, date) => {
                return daysLetters[date.getDay()]
              }}
              showNeighboringMonth={false}
            />
          </div>
        </Popup>
      )}
    </div>
  )
}

RangeDapicker.propTypes = {
  patient: PropTypes.object,
}

export default RangeDapicker
