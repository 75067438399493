// default component
import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

function IconCount({ count, elementClass, onClick, style }) {
  return (
    <span
      className={`icon-count ${elementClass || ''}`}
      onClick={onClick}
      style={style}
    >
      {count}
    </span>
  )
}

IconCount.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  style: PropTypes.object,
  elementClass: PropTypes.string,
}

export default IconCount
