import { Box } from '../../../base'
import { TaskSearchGrid as SearchGrid, TaskSearchGridProps } from './TaskSearchGrid'
import { TaskFormDialog } from './TaskFormDialog'

export function TaskSearchGrid(props: TaskSearchGridProps) {
  return (
    <Box sx={{ p: 2, height: '100%' }}>
      <SearchGrid {...props} />
      <TaskFormDialog />
    </Box>
  )
}
