// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-info {
  border-radius: 3px;
  background-color: #e7ebed;
  display: flex;
  align-items: baseline;
}
.warning-info .text {
  padding: 9px 0;
}
.warning-info .icon {
  align-self: center;
}

.red-info .text {
  color: #e9434a;
  font-size: 13px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/WarningInfo/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".warning-info {\n  border-radius: 3px;\n  background-color: #e7ebed;\n  display: flex;\n  align-items: baseline;\n}\n.warning-info .text {\n  padding: 9px 0;\n}\n.warning-info .icon {\n  align-self: center;\n}\n\n.red-info .text {\n  color: #e9434a;\n  font-size: 13px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
