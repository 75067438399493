import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

function EmptyPage({ Icon, children }) {
  return (
    <div className="empty-page-container">
      <div className="empty-page">
        <Icon />
        {children}
      </div>
    </div>
  )
}

EmptyPage.propTypes = {
  Icon: PropTypes.func,
  children: PropTypes.object,
}

export default EmptyPage
