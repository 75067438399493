// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.path-filter {
  display: inline-flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  padding: 10px;
  margin-left: -10px;
}
.opened .path-filter {
  background: #f1f3f4;
}
.opened.path-filter-container {
z-index: 100;
}
.check-list-container.select-list {
  max-height: 300px;
  overflow: auto;
  position: absolute;
}
.path-filter-selected {
  font-weight: bold;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/ElementsFilter/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;AACA,YAAY;AACZ;AACA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".path-filter {\n  display: inline-flex;\n  align-items: center;\n  height: 36px;\n  cursor: pointer;\n  padding: 10px;\n  margin-left: -10px;\n}\n.opened .path-filter {\n  background: #f1f3f4;\n}\n.opened.path-filter-container {\nz-index: 100;\n}\n.check-list-container.select-list {\n  max-height: 300px;\n  overflow: auto;\n  position: absolute;\n}\n.path-filter-selected {\n  font-weight: bold;\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
