import React from 'react'
import PropTypes from 'prop-types'
import Close from '../../images/icon_close.svg'
import Plus from '../../images/icon_plus.svg'
import Link from '../../images/icon_link.svg'
import './style.css'

const iconPath = {
  plus: Plus,
  close: Close,
  link: Link,
}

function IconButton({ labelText, onClick, type, disable }) {
  const Icon = iconPath[type]
  const iconStyle = {}
  const className = disable
    ? 'icon-button-component disable-btn'
    : 'icon-button-component'
  if (onClick) {
    return (
      <div
        className={className}
        onClick={onClick}
        style={{
          zIndex: 1000,
          alignSelf: 'center',
        }}
      >
        {iconPath[type] && <Icon />}
        {labelText && <span>{labelText}</span>}
      </div>
    )
  }
  return (
    <div className={`${className} not-btn`}>
      {iconPath[type] && <Icon style={iconStyle} />}
      {labelText && <span>{labelText}</span>}
    </div>
  )
}

IconButton.propTypes = {
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  disable: PropTypes.bool,
}
export default IconButton
