import dStrings from '../../strings.json'
import {
  differenceInDays,
  format,
  formatInTimeZone,
  getTimezoneOffset,
} from '@valerahealth/ui-components/utils/date'
import dotProp from 'dot-prop-immutable'
import {
  PatientFragment,
  PatientProfile,
  PatientProfileOverview,
  Treatment,
} from '@valerahealth/rtk-query'
import { RootState } from '../../redux/reducers'
import { caseloadStateEnum, dateFormatEnum, timezoneEnum } from './enums'

export type ApptSummry = {
  isLegacyAppt: boolean //whether its a scheduling api appt or legacy cm appt
  id: string
  start: Date
  end?: Date
}

export type TreatmentRoomRouteContext = {
  treatmentId: string
  treatment: Treatment
  treatmentProfile: PatientProfile
  patient: PatientFragment
  nextAppt?: ApptSummry
  lastAppt?: ApptSummry
}

export const getTreatment = (state: RootState) => {
  return (
    (state.treatmentRoom &&
      state.treatmentRoom.selectedId &&
      state.treatmentRoom.byId &&
      state.treatmentRoom.byId[state.treatmentRoom.selectedId]) ||
    null
  )
}

export const getProfileName = (
  profile:
    | Pick<
        PatientProfileOverview,
        'firstName' | 'lastName' | 'preferredName' | 'displayName'
      >
    | null
    | undefined,
) => {
  if (!profile) return ''

  const { firstName, lastName, preferredName, displayName } = profile

  if (displayName) return displayName

  return (
    (firstName || '') +
    (preferredName ? ` (${preferredName}) ` : ' ') +
    (lastName || '')
  )
}

export const getMessageDateTime = (date: any) => {
  if (!date) return ''
  const d = new Date(date)
  const days = differenceInDays(new Date(), d)
  if (!days) return format(d, dateFormatEnum.FULL_TIME).toUpperCase()
  if (days > 7) return format(d, dateFormatEnum.DATE_BASIC)
  return format(d, 'E').toUpperCase()
}

export const toggleSelectTreatment = (state: any, selectStatus: any) => {
  const ids = state.allIds

  const tempState = dotProp.set(state, `byId`, (treatments: any) => {
    // set "selected key" of all patients to false
    const newObj = JSON.parse(JSON.stringify(treatments))

    for (let index = 0; index < ids.length; index++) {
      newObj[ids[index]].selected = selectStatus
    }

    return newObj
  })

  const tempSelected: any = {}
  if (state.selectedTreatments && state.selectedTreatments.length) {
    for (let i = 0; i < state.selectedTreatments.length; i++) {
      tempSelected[state.selectedTreatments[i]] = true
    }
  }

  const selectedTreatments: any = []
  for (let i = 0; i < state.allIds.length; i++) {
    tempSelected[state.allIds[i]] = tempState.byId[state.allIds[i]].selected
  }

  Object.keys(tempSelected).forEach((p) => {
    if (tempSelected[p]) {
      selectedTreatments.push(p)
    }
  })

  const tempState2 = dotProp.set(
    tempState,
    `selectedTreatments`,
    selectedTreatments,
  )

  return {
    ...tempState2,
    treatments: tempState2.treatments.map((t: any) => {
      return {
        ...t,
        selected: selectStatus,
      }
    }),
    allTreatmentsAreSelected: selectStatus,
    excluded: undefined,
  }
}

export const getTreatmentsById = (byId: any, treatments: any) => {
  if (!~treatments.indexOf('unreadChatMessage')) return byId

  const obj: any = {}
  for (const key in byId) {
    if (Object.hasOwn(byId, key)) {
      obj[key] = {
        ...byId[key],
        unreadChatMessage: byId[key].channel && byId[key].channel.count,
      }
    }
  }

  return obj
}

export const getFilters = (list: any) => {
  const filters = []

  if (list.plans) {
    for (let i = 0; i < list.plans.length; i++) {
      const plan = list.plans[i]
      filters.push({
        key: plan.id,
        title: `${plan.name} <b>(${plan.count})</b>`,
        tooltip: `${plan.name} (${plan.count})`,
        checked: false,
        parent: 'careplan',
      })
    }
  }

  if (list.tags) {
    for (let i = 0; i < list.tags.length; i++) {
      const diagnose = list.tags[i]
      filters.push({
        key: diagnose.name,
        title: `${diagnose.name} <b>(${diagnose.count})</b>`,
        tooltip: `${diagnose.name} (${diagnose.count})`,
        parent: 'diagnosis',
        checked: false,
      })
    }
  }

  filters.push({
    key: 'openAlerts',
    title: `${dStrings.openAlerts} <b>(${list.openAlerts || 0})</b>`,
    tooltip: `${dStrings.openAlerts} (${list.openAlerts || 0})`,
    checked: false,
    parent: 'treatment',
  })

  filters.push({
    key: 'unreadChatMessages',
    title: `${dStrings.unreadMessages} <b>(${list.unreadMessages || 0})</b>`,
    tooltip: `${dStrings.unreadMessages} (${list.unreadMessages || 0})`,
    checked: false,
    parent: 'treatment',
  })

  filters.push({
    key: 'upcomingAppointments',
    title: `${dStrings.upcomingAppointments} <b>(${list.upcomingAppointments})</b>`,
    tooltip: `${dStrings.upcomingAppointments} (${list.upcomingAppointments})`,
    checked: false,
    parent: 'treatment',
  })

  return filters
}

export const filterInTreatments = (state: any) => {
  const filtered = state.filters.client
    ? state.filters.client.filter((f: any) => f.checked)
    : []
  const treatments = []
  const diagnosis = []
  const careplans = []
  const list = []
  for (let i = 0; i < filtered.length; i++) {
    if (filtered[i].parent === 'treatment') treatments.push(filtered[i].key)
    if (filtered[i].parent === 'careplan') careplans.push(filtered[i].key)
    if (filtered[i].parent === 'diagnosis') diagnosis.push(filtered[i].key)
  }

  if (!treatments.length && !careplans.length && !diagnosis.length) return null

  // in same category of filter => AND filter
  const byId = getTreatmentsById(state.byId, treatments)
  for (let y = 0; y < state.allIds.length; y++) {
    const id = state.allIds[y]
    let selected = false
    if (treatments.length) {
      for (let z = 0; z < treatments.length; z++) {
        const key = treatments[z]
        if (!byId[id][key]) break
        if (z === treatments.length - 1) {
          list.push(id)
          selected = true
        }
      }
    }

    if (!selected && careplans.length) {
      for (let n = 0; n < careplans.length; n++) {
        const careplan = careplans[n]
        const treatmentPlans = state.byId[id].plans.map(
          (p: any) => p.templateId,
        )
        if (
          !treatmentPlans ||
          !treatmentPlans.length ||
          !~treatmentPlans.indexOf(careplan)
        )
          break

        if (n === careplans.length - 1) {
          list.push(id)
          selected = true
        }
      }
    }

    if (!selected && diagnosis.length) {
      for (let n = 0; n < diagnosis.length; n++) {
        const diagnose = diagnosis[n]
        const treatmentDiagnosis = state.byId[id].diagnosis

        if (
          !treatmentDiagnosis ||
          !treatmentDiagnosis.length ||
          !~treatmentDiagnosis.indexOf(diagnose)
        )
          break

        if (n === diagnosis.length - 1) {
          list.push(id)
          selected = true
        }
      }
    }
  }

  return list
}

export const getSelectedCount = (state: any) => {
  let selectedCount = 0
  if (state.caseload.allTreatmentsAreSelected) {
    if (!state.caseload.excluded) {
      selectedCount = state.caseload.treatmentCount
    } else {
      selectedCount =
        state.caseload.treatmentCount - state.caseload.excluded.length
    }
  } else if (state.caseload.selectedTreatments) {
    selectedCount = state.caseload.selectedTreatments.length
  }

  return selectedCount
}

export const getFilterOptions = (caseload: any, options: any, search: any) => {
  let op: any = {}
  if (caseload.caseloadState === caseloadStateEnum.ACTIVE) {
    if (options) op = { ...options }
    else {
      op = caseload.filters.server || {}
    }
  } else {
    // caseloadStateEnum.DISCHARGE
    op = {
      archived: true,
    }
  }

  try {
    if (!search) {
      delete op.name
    } else if (search) {
      op.name = search
    } else if (caseload.filters.server.name) {
      op.name = caseload.filters.server.name
    }
  } catch (error) {
    // silent
  }

  return op
}

export const getBulkFilterGroup = (caseload: any) => {
  return {
    filter:
      caseload.allTreatmentsAreSelected && caseload.filters
        ? caseload.filters.server || {}
        : undefined,
    includeTreatmentIds: caseload.allTreatmentsAreSelected
      ? undefined
      : caseload.selectedTreatments,
    excludeTreatmentIds: caseload.allTreatmentsAreSelected
      ? caseload.excluded
      : undefined,
  }
}

export const getTimezoneWithGMT = () => {
  const obj: any = {}

  Object.keys(timezoneEnum).forEach((key) => {
    const offset = getTimezoneOffset(key)
    // @ts-ignore
    obj[key] = `(${formatInTimeZone(offset, key, 'OOOO')}) ${timezoneEnum[key]}`
  })

  return obj
}
