import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function ErrorPage({ title = 'Error', message = 'Error occurred' }) {
  return (
    <>
      <h2>{title}</h2>
      <div>{message}</div>
    </>
  )
}

ErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    title: state.global.title,
    message: state.global.message,
  }
}

export default connect(mapStateToProps)(ErrorPage)
