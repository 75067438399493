import { useMemo } from 'react'
import {
  SERVICE_CATEGORIES,
  ServiceCategoryCode,
  type ScheduleFragment,
} from '@valerahealth/rtk-query'
import {
  Stack,
  IconButton,
  PopupMenu,
  ListItemText,
  ListItemButton,
  IconButtonLink,
  Divider,
} from '@valerahealth/ui-components/base'
import { MoreHoriz, Add } from '@valerahealth/ui-components/icons'

import { useTranslation } from '../locales'
import { useProvider } from './ProviderCalendarContext'
import { useGetMutationPermissionsForPMCalendar } from '../hooks/useGetMutationPermissions'
import { actions, useReduxDispatch } from '../reducer'
import { getDrChronoAppointmentURL } from '../utilities'

type Props = {
  schedules?: ScheduleFragment[]
}

export default function ActionButtons({ schedules }: Props) {
  const provider = useProvider()
  const dispatch = useReduxDispatch()
  const { t } = useTranslation()
  const {
    canCreateSchedule,
    canReadAppointment,
    canCreateAppointment,
    canReadSchedule,
  } = useGetMutationPermissionsForPMCalendar(provider._id)
  const scheduleTypes = useMemo(
    () =>
      schedules
        ? SERVICE_CATEGORIES.filter((c) =>
            schedules.some((v) => v.serviceCategory.code === c.code),
          )
        : [],
    [schedules],
  )

  return (
    <Stack direction="row" justifyContent="flex-end" gap={2}>
      {process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS ? (
        canCreateAppointment && (
          <IconButton
            sx={{
              backgroundColor: (theme) => theme.palette.divider,
              borderRadius: '64px',
            }}
            onClick={() => {
              dispatch(
                actions.openView({
                  type: 'appointmentForm',
                  mode: 'add',
                  code: ServiceCategoryCode.Patient,
                  initialState: {
                    providerId: provider._id,
                  },
                }),
              )
            }}
          >
            <Add />
          </IconButton>
        )
      ) : (
        <IconButtonLink
          to={getDrChronoAppointmentURL()}
          sx={{
            backgroundColor: (theme) => theme.palette.divider,
            borderRadius: '64px',
          }}
        >
          <Add />
        </IconButtonLink>
      )}
      {((scheduleTypes.length && canReadSchedule) ||
        canCreateSchedule ||
        canReadAppointment) && (
        <PopupMenu
          variant="menu"
          button={
            <IconButton
              sx={{
                backgroundColor: (theme) => theme.palette.divider,
                borderRadius: '64px',
              }}
              disableRipple
            >
              <MoreHoriz />
            </IconButton>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {canCreateSchedule && (
            <ListItemButton
              onClick={() => {
                dispatch(
                  actions.openView({
                    type: 'scheduleForm',
                    mode: 'add',
                    provider,
                  }),
                )
              }}
            >
              <ListItemText
                sx={{
                  mr: 'auto',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {t('Add Schedule')}
              </ListItemText>
              <Add sx={{ color: (theme) => theme.palette.action.active }} />
            </ListItemButton>
          )}
          {scheduleTypes.map(({ code }) => (
            <ListItemButton
              key={code}
              onClick={() =>
                dispatch(
                  actions.openView({
                    type: 'scheduleList',
                    code,
                    provider,
                  }),
                )
              }
            >
              <ListItemText>{t(`ServiceCategoryCode.${code}`)}</ListItemText>
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton
            onClick={() =>
              dispatch(
                actions.openView({
                  type: 'appointmentList',
                  code: ServiceCategoryCode.OutOfOffice,
                  mode: 'future',
                  providerId: provider._id,
                }),
              )
            }
          >
            <ListItemText>
              Upcoming{' '}
              {t(`ServiceCategoryCode.${ServiceCategoryCode.OutOfOffice}`)}
            </ListItemText>
          </ListItemButton>
        </PopupMenu>
      )}
    </Stack>
  )
}
