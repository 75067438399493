// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-item-part {
  display: grid;
  grid-template-columns: 100px 320px;
  align-items: center;
}
.add-new-item-part.with-open-eye {
  display: grid;
  grid-template-columns: 100px 280px 40px;
  align-items: center;
}
.add-new-item-part select,
.add-new-item-part input {
  width: 100% !important;
}
.add-new-item-part {
  margin-bottom: 15px;
}
.eye-icon-container {
  cursor: pointer;
  justify-self: center;
}
span.react-select-option-icon {
  width: 13px;
  display: inline-block;
}
.bulk-message {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PlanTemplate/AddEditItem/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uCAAuC;EACvC,mBAAmB;AACrB;AACA;;EAEE,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".add-new-item-part {\n  display: grid;\n  grid-template-columns: 100px 320px;\n  align-items: center;\n}\n.add-new-item-part.with-open-eye {\n  display: grid;\n  grid-template-columns: 100px 280px 40px;\n  align-items: center;\n}\n.add-new-item-part select,\n.add-new-item-part input {\n  width: 100% !important;\n}\n.add-new-item-part {\n  margin-bottom: 15px;\n}\n.eye-icon-container {\n  cursor: pointer;\n  justify-self: center;\n}\nspan.react-select-option-icon {\n  width: 13px;\n  display: inline-block;\n}\n.bulk-message {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
