import React, { useEffect, useState } from 'react'
import { format, toZonedTime } from '@valerahealth/ui-components/utils/date'
import dStrings from '../../strings.json'
import { dateFormatEnum } from '../utilities/enums'
import ProfilePicture from '../common/Picture'
import { getProfileName } from '../utilities/treatments'
import './OOOBanner.css'
import RightArrowIcon from '../../images/right_arrow.svg'
import TrashIcon from '../../images/trash_mui.svg'
import PencilIcon from '../../images/pen_mui.svg'
import { CoveringSelect } from '../UserProfile/utilities'

function BannerTag({ data }) {
  if (!data) return <div />

  if (data.status === 'current') {
    return <div className="banner-state green">{dStrings.Current}</div>
  }

  return <div className="banner-state orange">{dStrings.upcoming}</div>
}

function BannerDates({ startDate, endDate }) {
  if (!startDate || !endDate) {
    return <div className="banner-dates" />
  }

  return (
    <div className="banner-dates">
      <span className="banner-date">
        {format(
          startDate,
          `${dateFormatEnum.DATE_BASIC} ${dateFormatEnum.FULL_TIME}`,
        )}
      </span>
      <RightArrowIcon />
      <span className="banner-date">
        {format(
          endDate,
          `${dateFormatEnum.DATE_BASIC} ${dateFormatEnum.FULL_TIME}`,
        )}
      </span>
    </div>
  )
}

function CoveringUserBanner({ editable, providers, covering, setCovering }) {
  if (editable.covering) {
    return (
      <div className="banner-covering">
        <CoveringSelect
          providers={providers}
          width="165px"
          coveringProviderId={(covering && covering.id) || null}
          handleChange={(t) => {
            if (t && t.value) {
              const c = providers.find((p) => p.id === t.value)
              setCovering(c)
            } else {
              setCovering(null)
            }
          }}
        />
      </div>
    )
  }
  if (!covering) {
    return <div className="no-covering-user">{dStrings.noCoveringUser}</div>
  }

  return (
    <div className="banner-covering">
      <ProfilePicture treatment={{ profile: covering }} />
      <div className="covering-name">{getProfileName(covering)}</div>
    </div>
  )
}

function BannerOptions({ handleEdit, data, handleDelete }) {
  return (
    <div className="banner-buttons">
      <PencilIcon onClick={handleEdit} />
      {data.status === 'upcoming' && (
        <TrashIcon
          onClick={() => {
            handleDelete({ id: data.id })
          }}
        />
      )}
    </div>
  )
}

function OOOBanner({
  data,
  timeZone,
  providers,
  handleDelete,
  handleEdit,
  handleEditCovering,
  editable = { active: true },
}) {
  const [covering, setCovering] = useState('first')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  useEffect(() => {
    if (data) {
      if (data.coveringProviderId) {
        if (providers?.length) {
          const c = providers.find((p) => p.id === data.coveringProviderId)
          setCovering(c)
        } else {
          setCovering(data.coveringProviderProfile)
        }
      } else {
        setCovering()
      }

      if (!data.startDate) return

      const sd =
        typeof data.startDate === 'string'
          ? data.startDate
          : data.startDate.toISOString()
      const ed =
        typeof data.endDate === 'string'
          ? data.endDate
          : data.endDate.toISOString()

      setStartDate(toZonedTime(sd, timeZone))
      setEndDate(toZonedTime(ed, timeZone))
    }
  }, [data, providers, timeZone])

  useEffect(() => {
    if (covering === 'first') return

    handleEditCovering && handleEditCovering(covering)
  }, [covering])

  return data && data.startDate ? (
    <div className="covering-banner">
      <BannerTag data={data} />
      <BannerDates startDate={startDate} endDate={endDate} />
      <CoveringUserBanner
        editable={editable}
        providers={providers}
        covering={covering}
        setCovering={setCovering}
      />
      {editable.active && (
        <BannerOptions
          handleEdit={handleEdit}
          data={data}
          handleDelete={handleDelete}
        />
      )}
    </div>
  ) : editable.active ? (
    <span style={{ display: 'none' }} />
  ) : (
    <span className="no-ooo-dates">{dStrings.noUpcomingOOODates}</span>
  )
}

export default OOOBanner
