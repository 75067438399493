import dStrings from '../../strings.json'
import { getCaseloadMask } from '../../api/storageConfig'
import {
  avoidRedirectionReasonEnum,
  caseloadStateEnum,
  pageSize,
  planType,
} from '../../components/utilities/enums'
import { LegacyRootState } from './legacyRootState'

const initialState: LegacyRootState = {
  global: {
    apiCallsInProgress: 0,
    userMessageArray: [],
    displayError: false, // {title: "Error", message: "Error occurred"}
    careplanState: false, // true = CAREPLAN |||||| false = TREATMENT
    editedItem: null,
    displayNotification: false,
    pinNotification: false,
    confirmRedirection: false,
    openSidebar: true,
    confirmRedirectionReason: avoidRedirectionReasonEnum.UNSAVE,
    confirmation: {
      display: false,
      callback: null,
      details: {
        title: dStrings.confirmation,
        text: dStrings.areYouSure,
        cancelBtnText: dStrings.cancel,
        confirmBtnText: dStrings.ok,
      },
    },
  },
  caseload: {
    searchText: '',
    displayFilterMenu: false,
    caseloadState: caseloadStateEnum.ACTIVE,
    discharged: undefined,
    showValues: getCaseloadMask() || {
      activeLast7Days: true,
      clinicalIndicators: true,
      tags: true,
      PopulationCarePlan: true,
      appointments: true,
    },
    filters: {
      client: undefined, // client filters are displayed filters on top left in caseload page
      server: undefined, // server filters are send to server to be filtered
    },
    view: 'plan',
    displayNotification: false,
    notifications: {
      list: null,
      pagination: {
        pageSize: pageSize.NOTIFICATIONS,
        pageNumber: 1,
      },
    },
    cannotLoadMore: false,
    pagination: {
      pageSize: pageSize.CASELOAD,
      pageNumber: 1,
      sort: {
        by: 'last_notification',
        order: 'ascending',
      },
    },
  },
  treatmentRoom: {
    selectedId: undefined,
    byId: {},
    editedPatient: undefined,
  },
  planTemplates: {
    subMenu: planType.POPULATION, // "population", "individual_public"
    searchText: '',
    view: 'plan', // "plan", "timeline"
    // selectedId: 2, ==> ************ Be carefull!! Now we get selected plan template from url *************
    // byId: {},
    // allIds: [],
  },
  toolsTemplates: {
    questionnaire: {
      id: 'questionnaire',
      key: 'name',
      title: dStrings.questionnaire,
      canBeView: true,
      type: 'select',
    },
    reminder: {
      id: 'reminder',
      title: dStrings.reminder,
      canBeView: false,
      key: 'text',
      type: 'creatableSelect',
      hideInBulk: true,
    },
    article: {
      id: 'article',
      key: 'title',
      title: dStrings.article,
      canBeView: true,
      type: 'select',
    },
    audio_track: {
      id: 'audio_track',
      key: 'title',
      title: dStrings.audioTrack,
      canBeView: true,
      type: 'select',
    },
    episode: {
      id: 'episode',
      key: 'title',
      title: dStrings.audioTrack,
      canBeView: false,
      type: 'select',
      hideInBulk: true,
      hideInCarePlan: true,
    },
    message: {
      id: 'message',
      title: dStrings.message,
      type: 'text',
    },
    sentiment: {
      id: 'sentiment',
      key: 'name',
      title: dStrings.sentiment,
      canBeView: true,
      type: 'select',
    },
    consent_form: {
      id: 'consent_form',
      key: 'title',
      title: dStrings.consent_form,
      type: 'select',
      hideInCarePlan: true,
    },
    appointment: {
      id: 'appointment',
      key: 'title',
      title: dStrings.appointment,
      hideInBulk: true,
      hideInCarePlan: true,
    },
    medication: {
      id: 'medication',
      key: 'title',
      title: dStrings.medication,
      canBeView: true,
      hideInBulk: true,
      hideInCarePlan: true,
    },
  },
  program: {
    configurations: {},
    tags: null,
  },
  provider: {},
  patient: {},
  // careplans: {}, // do not remove
  supervision: {},
}

export default initialState
