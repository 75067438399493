import React from 'react'
import './style.css'

function DayCursor() {
  return (
    <div className="day-cursor">
      <div className="cursor-ball" />
    </div>
  )
}

export default DayCursor
