import { getListByIdAndAllIds } from '../../components/utilities'
import * as types from '../actions/actionTypes'
import initialState from './initialState'

const getClinicalIndicatorObj = (clinicalIndicators) => {
  const obj = {}
  for (let i = 0; i < clinicalIndicators.length; i++) {
    const clinicalIndicator = clinicalIndicators[i]
    for (let y = 0; y < clinicalIndicator.templateIds.length; y++) {
      const templateId = clinicalIndicator.templateIds[y]
      obj[templateId] = clinicalIndicator.name
    }
  }

  return obj
}

export default function programReducer(state = initialState.program, action) {
  switch (action.type) {
    case types.LOAD_PROGRAM_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configurations: action.configurations,
        templateToClinicalIndicator: getClinicalIndicatorObj(
          action.configurations.clinicalIndicators,
        ),
      }
    case types.LOAD_PROGRAM_CARETEAM_SUCCESS:
      return { ...state, careTeam: getListByIdAndAllIds(action.careteam) }
    case types.LOAD_REPORTS_CARETEAM_SUCCESS:
      return { ...state, summaryReport: action.data }
    case types.LOAD_PROGRAM_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags
          ? action.tags.map((tag) => {
              return tag.name
            })
          : [],
      }
    case types.CREATE_PROGRAM_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags ? [...state.tags, action.tag] : [action.tag],
      }
    default:
      return state
  }
}
