// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.icon-count {
  height: 16px;
  min-width: 16px;
  background-color: var(--main-font-color);
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  padding: 3px;
}
`, "",{"version":3,"sources":["webpack://./../../packages/ui-core/src/components/IconCount/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,wCAAwC;EACxC,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["span.icon-count {\n  height: 16px;\n  min-width: 16px;\n  background-color: var(--main-font-color);\n  color: #ffffff;\n  font-weight: 500;\n  letter-spacing: 0;\n  line-height: 11px;\n  text-align: center;\n  display: inline-block;\n  border-radius: 10px;\n  padding: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
