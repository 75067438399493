import { ReactElement, useEffect, useState } from 'react'
import {
  type GridRenderCellParams,
  GridValidRowModel,
  useGridApiContext,
  GridRowModes,
} from '@mui/x-data-grid-pro'
import { Stack, IconButton, CircularProgress } from '../../base'
import { Save, Edit, Cancel, Delete } from '../../icons'
import { useTranslation } from '../../utils/hooks'
import type {
  EditableGridActionsCellProps,
  RenderGridActionsCellProps,
} from './index.types'

export function EditableGridActionsCell<DataType extends GridValidRowModel>({
  id,
  row,
  field,
  disableActions = [],
  handleSave,
  handleCancel,
  handleEdit,
  handleDelete,
  handleDeleteConfirmation,
  handleSaveConfirmation,
  allowUpdate = true,
  allowDelete = true,
  leftIcons,
  children,
  sx,
  editTitle,
  isActionLoading = false,
}: EditableGridActionsCellProps<DataType>) {
  const [t] = useTranslation()
  const [isLoading, setLoading] = useState(isActionLoading)
  useEffect(() => {
    setLoading(isActionLoading)
  }, [isActionLoading])

  const gridApi = useGridApiContext()
  const editing = gridApi.current.getRowMode(id) === GridRowModes.Edit

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={sx}>
      {isLoading ? (
        <CircularProgress size={30} />
      ) : editing ? (
        <>
          <IconButton
            title={t('save')}
            placement="left"
            color="secondary"
            onClick={async (e) => {
              const confirmed = handleSaveConfirmation
                ? await handleSaveConfirmation(
                    row,
                    gridApi.current.getRowWithUpdatedValues(
                      id,
                      field,
                    ) as DataType,
                  )
                : true
              if (confirmed) {
                setLoading(true)
                await handleSave(id, e)
                setLoading(false)
              }
            }}
            disabled={disableActions.includes('save')}
          >
            <Save />
          </IconButton>
          <IconButton
            title={t('cancel')}
            placement="right"
            color="secondary"
            onClick={(e) => handleCancel(id, e)}
            disabled={disableActions.includes('cancel')}
          >
            <Cancel />
          </IconButton>
        </>
      ) : (
        <>
          {leftIcons}
          {allowUpdate && (
            <IconButton
              title={editTitle || t('edit')}
              placement="left"
              color="secondary"
              onClick={async (e) => {
                setLoading(true)
                await handleEdit(id, e)
                setLoading(false)
              }}
              disabled={disableActions.includes('edit')}
            >
              <Edit />
            </IconButton>
          )}
          {allowDelete && (
            <IconButton
              title={t('delete')}
              placement="right"
              color="error"
              onClick={async (e) => {
                const confirmed = handleDeleteConfirmation
                  ? await handleDeleteConfirmation(row)
                  : true
                if (confirmed) {
                  handleDelete(id, e)
                  setLoading(true)
                }
              }}
              disabled={disableActions.includes('delete')}
            >
              <Delete />
            </IconButton>
          )}
          {children}
        </>
      )}
    </Stack>
  )
}

export function renderEditableGridActions<DataType extends GridValidRowModel>({
  allowDelete = true,
  allowUpdate = true,
  isEditingGrid,
  ...rest
}: RenderGridActionsCellProps<DataType>) {
  const RenderEditableGridActionsCell = ({
    id,
    row,
  }: GridRenderCellParams<any, DataType>):
    | ReactElement
    | ReactElement[]
    | null => {
    return (
      <EditableGridActionsCell
        {...rest}
        id={id}
        row={row}
        isEditingGrid
        allowUpdate={allowUpdate}
        allowDelete={allowDelete}
      />
    )
  }
  return allowUpdate || allowDelete ? RenderEditableGridActionsCell : null
}

export type RenderEditableDataGridActions<DataType extends GridValidRowModel> =
  typeof renderEditableGridActions<DataType>
