import {
  Box,
  Tab,
  Tabs,
  CenteredSpinner,
  useNotify,
} from '@valerahealth/ui-components'
import { isoDateTimeToTuple } from '@valerahealth/ui-components/utils/date'
import { ComponentPropsWithoutRef, useState, useMemo, useEffect } from 'react'
import {
  SessionType,
  patientSearchApi,
  practiceMgrApi,
  PracticeStateAvailabilityStatus,
} from '@valerahealth/rtk-query'
import AppointmentBilling from './PatientAppointmentBilling'

import {
  CANCELED_APPOINTMENT_STATUSES_SET,
  getAppointmentLocation,
  getAppointmentPatient,
  getAppointmentProvider,
  getDurationOptionsForServiceType,
} from '../../utilities'
import { useTranslation } from '../../locales'
import {
  formatStringToCancelationReasonType,
  reoccuranceTemplateToForm,
  type FormType,
} from './formType'
import {
  EditAppointmentView,
  actions,
  useReduxDispatch,
  AddPatientApptFormViewState,
} from '../../reducer'
import AppointmentForm from './PatientAppointmentForm'
import { AppointmentFormProvider } from './AppointmentFormProvider'

function TabPanel(
  props: ComponentPropsWithoutRef<'div'> & { value: number; index: number },
) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      id={`add-appointment-sidebar-tabpanel-${index}`}
      aria-labelledby={`add-appointment-sidebar-tab-${index}`}
      {...other}
      sx={{
        display: value === index ? 'flex' : 'none',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
      }}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `add-appointment-sidebar-${index}`,
    'aria-controls': `add-appointment-sidebar-tabpanel-${index}`,
  }
}

const AppointmentFormContainer = ({
  state,
  tabIndex,
}: {
  state: AddPatientApptFormViewState | EditAppointmentView
  tabIndex: number
}) => {
  // beginnings to our form initial state
  const { treatmentId, locationId, ..._defaultValues } = useMemo((): Omit<
    FormType,
    'patient' | 'location'
  > & { treatmentId?: string | null; locationId?: string | null } => {
    if (state.mode === 'add') {
      const {
        providerId = null,
        startDateTime,
        serviceType = null,
        timezone = 'America/New_York',
        ...rest
      } = state.initialState || {}

      const minutesDuration = getDurationOptionsForServiceType(serviceType)

      const [startDate, startTime] = isoDateTimeToTuple(startDateTime, {
        timezone,
      })

      return {
        ...rest,
        providerId,
        serviceType,
        startDate,
        startTime,
        timezone,
        minutesDuration:
          minutesDuration.length > 0 ? minutesDuration[0]! : null,
        channelType: SessionType.ValeraVideo,
        addressUrl: '',
        comment: '',
        cancelationReason: null,
        reoccuranceInfo: null,
        status: null,
      }
    }

    const {
      serviceType,
      startDate: startDateTime,
      minutesDuration,
      virtualService,
      reoccuranceTemplate,
      status,
    } = state.appointment

    const { timezone = 'America/New_York' } = state
    const [startDate, startTime] = isoDateTimeToTuple(startDateTime, {
      timezone,
    })

    const { reoccuranceInfo } = reoccuranceTemplate
      ? reoccuranceTemplateToForm(reoccuranceTemplate)
      : { reoccuranceInfo: null }

    const cancelationReason = formatStringToCancelationReasonType(
      state.appointment.cancelationReason,
    )
    return {
      treatmentId: getAppointmentPatient(state.appointment)?._id,
      status,
      locationId: getAppointmentLocation(state.appointment)?._id,
      providerId: getAppointmentProvider(state.appointment)?._id ?? null,
      serviceType: serviceType?.code || null,
      startDate,
      startTime,
      timezone,
      minutesDuration: minutesDuration || null,
      channelType: virtualService?.channelType || SessionType.ValeraVideo,
      addressUrl: virtualService?.addressUrl || '',
      reoccuranceInfo,
      comment: '',
      cancelationReason: CANCELED_APPOINTMENT_STATUSES_SET.has(status)
        ? cancelationReason
        : null,
    }
  }, [state])

  const notify = useNotify()
  const dispatch = useReduxDispatch()

  const {
    patient,
    isSuccess: getPatientSuccess,
    error: getPatientError,
    isLoading: isLoadingPatient,
  } = patientSearchApi.useSearchPatientByTreatmentIdQuery(
    { treatmentId: treatmentId! },
    {
      skip: !treatmentId,
      selectFromResult: ({
        error,
        currentData,
        isSuccess,
        isUninitialized,
        isFetching,
      }) => ({
        isSuccess,
        patient: currentData || null,
        error,
        isLoading: !!treatmentId && (isUninitialized || isFetching),
      }),
    },
  )

  useEffect(() => {
    if (getPatientError) {
      notify({
        severity: 'error',
        message:
          getPatientError.message ||
          'Failed to retrieve patient for appointment.',
      })
      dispatch(actions.closeView())
    }
    if (getPatientSuccess && !patient) {
      notify({ severity: 'error', message: 'Patient not found.' })
      dispatch(actions.closeView())
    }
  }, [getPatientError, notify, getPatientSuccess, patient, dispatch])

  const { locations, error: getLocationsError } =
    practiceMgrApi.useGetLocationsQuery(undefined, {
      selectFromResult: ({ error, data }) => ({
        error,
        locations: data?.getLocations,
      }),
    })
  useEffect(() => {
    if (getLocationsError) {
      notify({
        severity: 'error',
        message:
          getLocationsError.message ||
          'Failed to retrieve appointment locations.',
      })
      dispatch(actions.closeView())
    }
  }, [getLocationsError, notify, dispatch])

  const { practiceStates, error: getPracticeStatesError } =
    practiceMgrApi.useGetPracticeStatesQuery(undefined, {
      selectFromResult: ({ error, data }) => ({
        error,
        practiceStates: data?.getPracticeStates,
      }),
    })
  useEffect(() => {
    if (getPracticeStatesError) {
      notify({
        severity: 'error',
        message:
          getPracticeStatesError.message ||
          'Failed to retrieve appointment locations.',
      })
      dispatch(actions.closeView())
    }
  }, [getPracticeStatesError, notify, dispatch])

  const activePracticeStates = useMemo(() => {
    return (
      practiceStates &&
      new Set(
        practiceStates
          .filter(
            (v) => v.availabilityStatus === PracticeStateAvailabilityStatus.All,
          )
          .map((v) => v.stateCode) || [],
      )
    )
  }, [practiceStates])

  const location = useMemo(
    () => locations?.find((l) => l.id === locationId) || null,
    [locations, locationId],
  )

  const defaultValues = useMemo(
    () => ({
      location,
      patient,
      ..._defaultValues,
    }),
    [location, patient, _defaultValues],
  )

  return !locations || !activePracticeStates || isLoadingPatient ? (
    <CenteredSpinner />
  ) : (
    <AppointmentFormProvider
      appointment={state.mode === 'edit' ? state.appointment : null}
      defaultValues={defaultValues}
    >
      <TabPanel value={tabIndex} index={0}>
        <AppointmentForm
          appointment={state.mode === 'edit' ? state.appointment : null}
          locations={locations}
          activePracticeStates={activePracticeStates}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <AppointmentBilling />
      </TabPanel>
    </AppointmentFormProvider>
  )
}

export const AddEditPatientAppointment = ({
  view,
}: {
  view: AddPatientApptFormViewState | EditAppointmentView
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { t } = useTranslation()
  return (
    <>
      <Tabs
        sx={{
          flexShrink: 0,
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: (theme) => theme.palette.background.default,
        }}
        variant="fullWidth"
        value={tabIndex}
        onChange={(event, newValue) => {
          setTabIndex(newValue)
        }}
      >
        <Tab
          sx={{ minHeight: '3rem' }}
          label={t('details')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ minHeight: '3rem' }}
          label={t('billing')}
          {...a11yProps(1)}
        />
      </Tabs>
      <AppointmentFormContainer state={view} tabIndex={tabIndex} />
    </>
  )
}
