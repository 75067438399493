import { DomainEvent } from '@valerahealth/rtk-query'
import RedTriangle from '../../images/unresolved.svg'
import SentIcon from '../../images/icn_sent.svg'
import PlanAssigned from '../../images/icn_assigned.svg'
import PlanUnassigned from '../../images/icn_unassigned.svg'
import GreenTriangle from '../../images/icn_alert_resolved.svg'
import SessionCompleted from '../../images/icn_session_completed.svg'
import SessionMissed from '../../images/icn_session_missed.svg'
import RestoredPatient from '../../images/icn_patient_restored.svg'
import RequestedAppointment from '../../images/icn_patient_requested_appointment.svg'
import FlaggedPatient from '../../images/icn_patient_flagged.svg'
import ScheduledIcon from '../../images/icn_scheduled.svg'
import RescheduledAppointmentIcon from '../../images/icn_appointment_rescheduled.svg'
import RescheduledAppointmentRedIcon from '../../images/reschedule_appointment.svg'
import CMRemovedIcon from '../../images/icn_care member removed.svg'
import CMAddedIcon from '../../images/icn_care member added.svg'
import SeenConsentForm from '../../images/icn_consent_form_seen.svg'
import DischargedPatient from '../../images/icn_discharged_patient.svg'
import RegisteredPatient from '../../images/icn_registered_patient.svg'
import ConfirmedIcon from '../../images/Check.svg'
import DeclinedIcon from '../../images/Decline.svg'
import RefillRequestIcon from '../../images/refill_request_icon.svg'

export const domainEventIcon: Record<DomainEvent, JSX.Element> = {
  alert_created: <RedTriangle />,
  alert_resolved: <GreenTriangle />,
  appointment_accepted: <ConfirmedIcon />,
  appointment_planned: <ConfirmedIcon />,
  appointment_deleted: <DeclinedIcon />,
  appointment_declined: <DeclinedIcon />,
  appointment_edited: <RescheduledAppointmentIcon />,
  appointment_reschedule_requested: <RescheduledAppointmentRedIcon />,
  appointment_suggested: <RequestedAppointment />,
  appointment_reminder_sent: <RequestedAppointment />,
  ctm_added: <CMAddedIcon />,
  ctm_removed: <CMRemovedIcon />,
  pctm_changed: <RescheduledAppointmentIcon />,
  consent_form_agreed: <ConfirmedIcon />,
  consent_form_assigned: <SentIcon />,
  consent_form_seen: <SeenConsentForm />,
  article_assigned: <SentIcon />,
  article_viewed: <ConfirmedIcon />,
  audio_track_assigned: <SentIcon />,
  audio_track_viewed: <ConfirmedIcon />,
  episode_assigned: <SentIcon />,
  episode_viewed: <SeenConsentForm />,
  plan_assigned: <PlanAssigned />,
  plan_unassigned: <PlanUnassigned />,
  questionnaire_assigned: <SentIcon />,
  questionnaire_completed: <ConfirmedIcon />,
  sentiment_assigned: <SentIcon />,
  sentiment_completed: <ConfirmedIcon />,
  reminder_assigned: <SentIcon />,
  reminder_acknowledged: <ConfirmedIcon />,
  video_session_terminated: <SessionCompleted />,
  missed_video_call: <SessionMissed />,
  patient_registered: <RegisteredPatient />,
  patient_synced_from_emr: <RegisteredPatient />,
  treatment_discharged: <DischargedPatient />,
  treatment_restored: <RestoredPatient />,
  treatment_flagged: <FlaggedPatient />,
  treatment_flagged_changed: <RescheduledAppointmentIcon />,
  appointment_requested: <RequestedAppointment />,
  appointment_scheduled: <RescheduledAppointmentIcon />,
  appointment_rescheduled: <RescheduledAppointmentIcon />,
  article_scheduled: <ScheduledIcon />,
  audio_track_scheduled: <ScheduledIcon />,
  message_scheduled: <ScheduledIcon />,
  questionnaire_scheduled: <ScheduledIcon />,
  sentiment_scheduled: <ScheduledIcon />,
  reminder_scheduled: <ScheduledIcon />,
  medication_refill_created: <RefillRequestIcon />,
  message_sent: <SentIcon />,
  message_read: <ConfirmedIcon />,
}
