import { useDispatch, useSelector } from 'react-redux'
import { type AnyAction, type ThunkDispatch } from '@reduxjs/toolkit'
import {
  noteApi,
  type ProgressNote as ProgressNoteType,
} from '@valerahealth/rtk-query'
import {
  type QuerySubState,
  type MutationSubState,
} from '@reduxjs/toolkit/dist/query/core/apiState'
import {
  Button,
  CircularProgress,
  Paper,
  useNotify,
  Typography,
  Stack,
} from '@valerahealth/ui-components'
import { FormProvider, useForm } from '@valerahealth/ui-components/form'
import { useTranslation } from '../locales'
import NoteDetails from './NoteDetails'
import NoteTemplate from './NoteTemplate'
import NoteHeader from './NoteHeader'

export type ProgressNoteProps = {
  note?: ProgressNoteType
  treatmentId: string
  /** make sure to pass in the note as a prop after handle save so that  */
  onAfterSave?: (note: ProgressNoteType) => void
} & (
  | {
      hideHeader?: false
      handleClose: () => void
    }
  | {
      hideHeader: true
      handleClose?: void
    }
)

type FormType = {
  /** used just to persist an id after create */
  _id: string | null
  title: string
  templates: ProgressNoteType['templatesOverview']
}

const noteToForm = (note?: ProgressNoteType | null): FormType => {
  const { title, templatesOverview, id } = note ?? {}
  return {
    _id: id ?? null,
    title: title ?? '',
    templates: templatesOverview ?? [],
  }
}

export function ProgressNote({
  note,
  treatmentId,
  handleClose,
  onAfterSave: handleSave,
  hideHeader,
}: ProgressNoteProps) {
  const dispatch = useDispatch() as ThunkDispatch<any, any, AnyAction>
  const { templates, isTemplatesLoading } = noteApi.useGetTemplatesQuery(
    { treatmentId },
    {
      selectFromResult: (r) => {
        return { templates: r.data, isTemplatesLoading: r.isLoading }
      },
    },
  )

  const notify = useNotify()
  const { t } = useTranslation()

  const isLoading = useSelector((state: any) => {
    const { queries = {}, mutations = {} } = (state[noteApi.reducerPath] ||
      {}) as {
      mutations?: Record<string, MutationSubState<any>>
      queries?: Record<string, QuerySubState<any>>
    }
    return [...Object.values(mutations), ...Object.values(queries)].some(
      (v) => v.status === 'pending',
    )
  })

  const getErrorMessage = (res?: { message?: string }) => {
    let message = ''

    try {
      const key = res?.message
      if (key === 'not_an_owner') {
        message = t('noteOnlyChangeByOwner')
      } else {
        message = t('errorOccurred')
      }
    } catch (error) {
      message = t('errorOccurred')
    }

    return message
  }

  const methods = useForm<FormType>({
    defaultValues: noteToForm(note),
  })
  const noteId = methods.watch('_id')
  const selectedTemplates = methods.watch('templates')
  const saveNote = async ({ title, templates }: FormType) => {
    try {
      const res = await (noteId
        ? dispatch(
            noteApi.endpoints.putProgressNote.initiate({
              treatmentId,
              noteId,
              data: {
                title,
                isDraft: false,
              },
            }),
          )
        : dispatch(
            noteApi.endpoints.postProgressNote.initiate({
              treatmentId,
              data: {
                date: new Date().toISOString(),
                title,
                isDraft: false,
              },
            }),
          ))

      if ('error' in res) {
        const message =
          ('data' in res.error
            ? getErrorMessage(res.error.data)
            : res.error.message) || t('api.note.saveFailure')!
        notify({
          message,
          severity: 'error',
        })
        return
      }

      let savedNote = res.data

      const { id, saveSessionId } = res.data
      const currentTemplates = res.data.templatesOverview.map((v) => v.id)
      const formTemplateIds = templates.map((v) => v.id)
      const add = formTemplateIds.filter((v) => !currentTemplates.includes(v))
      const remove = currentTemplates.filter(
        (v) => !formTemplateIds.includes(v),
      )

      if (add.length + remove.length > 0) {
        const postTemplateRes = await dispatch(
          noteApi.endpoints.postTemplate.initiate({
            treatmentId,
            noteId: id,
            data: {
              templateIds: {
                add,
                remove,
              },
              saveSessionId,
              isDraft: false,
            },
          }),
        )
        if ('error' in postTemplateRes) {
          const message =
            ('data' in postTemplateRes.error
              ? getErrorMessage(postTemplateRes.error.data)
              : postTemplateRes.error.message) ||
            "There was an error saving the note's templates"
          notify({
            message,
            severity: 'error',
          })
          return
        }
        savedNote = res.data
      }
      notify({
        message: t('api.note.saveSuccess'),
        severity: 'success',
      })
      methods.reset({
        _id: savedNote.id,
        title,
        templates,
      })
      handleSave?.(savedNote)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {!hideHeader && (
        <NoteHeader
          treatmentId={treatmentId}
          appointmentId={note?.appointmentId}
          handleClose={handleClose}
        />
      )}
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: hideHeader ? '100%' : 'calc(100vh - 90px)',
          overflow: 'auto',
          padding: '24px',
          background:
            'linear-gradient(0deg, rgba(238, 239, 233, 0.5), rgba(238, 239, 233, 0.5)), #FFFFFF;',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="h1">
            {t('progressNotes')}
          </Typography>
          <Button
            variant="contained"
            onClick={methods.handleSubmit(saveNote)}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
          >
            {t('save')}
          </Button>
        </Stack>
        <FormProvider {...methods}>
          <NoteDetails templates={templates} isLoading={isTemplatesLoading} />
        </FormProvider>
        {note &&
          templates
            ?.filter(({ id }) => selectedTemplates.some((v) => v.id === id))
            .map((template) => {
              const disabled = !note.templatesOverview.some(
                (t) => t.id === template.id,
              )
              return (
                <NoteTemplate
                  key={template.id}
                  template={template}
                  note={note}
                  treatmentId={treatmentId}
                  disabled={disabled}
                />
              )
            })}
      </Paper>
    </>
  )
}

export default ProgressNote
