import { format } from '@valerahealth/ui-components/utils/date'
import { AlertMessage } from '../Channel/ChatMessage'
import AssessmentIcon from '../common/AssessmentIcon'

function TreatmentAlertMessage({ alert, displayEye }) {
  return (
    <AlertMessage type={alert.type}>
      <div className="alert-title-and-description">
        <div className="alert-title">
          {format(new Date(alert.ts), 'EEEE, LLL d, h:mma')}
        </div>
        <div className="alert-description">
          {alert.title}
          {displayEye && <AssessmentIcon alert={alert} />}
        </div>
      </div>
    </AlertMessage>
  )
}

export default TreatmentAlertMessage
