/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'
import './style.less'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IconCount, OptionsMenu, Button } from '@valerahealth/ui-core'
import {
  differenceInDays,
  format,
} from '@valerahealth/ui-components/utils/date'
import { DrChronoIcon, RegisterToEmr } from '@valerahealth/ui-components'
import SeenIcon from '../../../../images/icn_seen.svg'
import NotSeenIcon from '../../../../images/icn_not_seen.svg'
import OpenTreatmentIcon from '../../../../images/btn_open_treatment.svg'
import AppointmentIcon from '../../../../images/icn_appointment.svg'
import CareplanIcon from '../../../../images/icn_careplan.svg'
import RedArrow from '../../../../images/icn_assesment_worsen.svg'
import GreenArrow from '../../../../images/icn_assesment_improved.svg'
import RestoreIcon from '../../../../images/icn_restore.svg'
import RemoveIcon from '../../../../images/img_remove_pending.svg'
import SameNote from '../../../../images/icn_appointment_rescheduled.svg'
import MRNIcon from '../../../../images/mrn.svg'
import {
  removePendingTreatment,
  selectTreatmentRow,
  setChannelSeen,
} from '../../../../redux/actions/caseloadActions'
import { getProfileName } from '../../../utilities/treatments'
import dStrings from '../../../../strings.json'
import ProfilePicture from '../../../common/Picture'
import UnreadChannelItem from '../../../common/ChannelEvents'
import { displayConfirmation } from '../../../../redux/actions/globalActions'
import {
  toggleRestoreTreatmentPopup,
  addNewPatient,
} from '../../../../redux/actions/treatmentRoom'
import { caseloadStateEnum, dateFormatEnum } from '../../../utilities/enums'

function TreatmentRow({
  treatment,
  showValues,
  clinicalIndicators,
  caseloadState,
  selectTreatmentRow,
  displayConfirmation,
  setChannelSeen,
  addNewPatient,
  removePendingTreatment,
  toggleRestoreTreatmentPopup,
}) {
  const navigate = useNavigate()
  const { isWellness } = useSelector((state) => state.program.configurations)
  const gotoTreatmentRoom = () => {
    if (treatment.id) {
      navigate(`/caseload/treatment/${treatment.id}/timeline`)
    } else {
      console.error('Error in gotoTreatmentRoom', treatment, caseloadState)
    }
  }

  const getQuestArrow = (quest) => {
    if (
      (quest.score !== null && quest.lastScore === null) ||
      (quest.score === null && quest.lastScore !== null)
    ) {
      return (
        <span>
          <SameNote />
        </span>
      )
    }
    if (quest.score > quest.lastScore) {
      return (
        <span>
          <RedArrow />
        </span>
      )
    }
    if (quest.score < quest.lastScore) {
      return (
        <span>
          <GreenArrow />
        </span>
      )
    }
    return (
      <span>
        <SameNote />
      </span>
    )
  }

  const getRowClass = () => {
    return `caseload-row ${!treatment.selected ? '' : ' selected-row'}${
      caseloadState === caseloadStateEnum.DISCHARGE ? ' discharged-row' : ''
    }${caseloadState !== caseloadStateEnum.LEAD ? ' clickable' : ''}`
  }

  return (
    <tr
      className={getRowClass()}
      onClick={(e) => {
        if (caseloadState !== caseloadStateEnum.LEAD) {
          gotoTreatmentRoom()
        }
      }}
    >
      {caseloadState === caseloadStateEnum.ACTIVE && (
        <td
          className="treatment-select"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <input
            type="checkbox"
            checked={treatment.selected || false}
            onChange={() => {
              selectTreatmentRow(treatment.id)
            }}
          />
        </td>
      )}
      <td
        className={`treatment-picture ${
          !treatment.unresolvedAlertCount ? '' : 'open-alert'
        }`}
      >
        <ProfilePicture treatment={treatment} />
      </td>
      <td className="name-and-diagnosis">
        <div className="name-container">
          <div className="name big-font">
            {getProfileName(treatment.profile)}
          </div>
          {showValues.activeLast7Days && treatment.lastActivity && (
            <div className="connect-last-days">
              {differenceInDays(new Date(), new Date(treatment.lastActivity)) >
              7 ? (
                <NotSeenIcon />
              ) : (
                <SeenIcon />
              )}
            </div>
          )}
          <DrChronoIcon {...treatment.emrStatus} />
        </div>
        {treatment.mrn && (
          <div className="treatment-mrn">
            <MRNIcon /> {treatment.mrn}
          </div>
        )}
        {showValues.tags && (
          <div className="diagnosis-container">
            {treatment.diagnosis &&
              treatment.diagnosis.map((diagnose, i) => {
                return (
                  <div
                    key={i}
                    className="diagnose timeline-item-type xsmall-font"
                  >
                    {diagnose}
                  </div>
                )
              })}
          </div>
        )}
      </td>
      {caseloadState === caseloadStateEnum.ACTIVE && (
        <td className="treatment-app-score">
          {((showValues.appointments && treatment.nextAppointment) ||
            (caseloadState === caseloadStateEnum.LEAD &&
              treatment.appointment)) && (
            <div className="next-appointment-container">
              <AppointmentIcon />{' '}
              <span className="next-appointment">
                {format(
                  new Date(
                    (treatment.nextAppointment &&
                      treatment.nextAppointment.date) ||
                      treatment.appointment.date,
                  ),
                  dateFormatEnum.FULL_DATE_AND_TIME,
                )}
              </span>
            </div>
          )}
          {treatment.clinicalIndicators &&
            !!treatment.clinicalIndicators.length &&
            showValues.clinicalIndicators && (
              <div className="clinical-indicators-container">
                <CareplanIcon />
                {treatment.clinicalIndicators.map((quest, i) => {
                  return (
                    <div className="clinical-indicators-element" key={i}>
                      {clinicalIndicators
                        ? clinicalIndicators[quest.templateId]
                        : quest.name}
                      : <b style={{ marginLeft: '5px' }}>{quest.score}</b>
                      {getQuestArrow(quest)}
                    </div>
                  )
                })}
              </div>
            )}
        </td>
      )}
      {caseloadState === caseloadStateEnum.ACTIVE &&
        showValues.PopulationCarePlan && (
          <td className="care-plan">
            <div className="care-plan-list">
              {treatment.plans && treatment.plans.length ? (
                treatment.plans.map((plan, i) => {
                  return (
                    <div
                      className="care-plan-element"
                      title={plan.name}
                      key={i}
                    >
                      {plan.name}
                    </div>
                  )
                })
              ) : (
                <div className="no-careplan xsmall-font">
                  {dStrings.noGlobalTackAssigned}
                </div>
              )}
            </div>
          </td>
        )}
      {caseloadState === caseloadStateEnum.DISCHARGE && (
        <td className="discharge-reason-container">
          <div className="discharge-reason">
            {treatment.dischargeInfo && treatment.dischargeInfo.reason}
          </div>
        </td>
      )}
      {caseloadState === caseloadStateEnum.DISCHARGE && (
        <td className="discharge-reason-container" style={{ width: '200px' }}>
          <div className="discharge-date">
            {treatment.dischargeInfo &&
              treatment.dischargeInfo.ts &&
              format(
                new Date(treatment.dischargeInfo.ts),
                dateFormatEnum.DATE_BASIC,
              )}
          </div>
        </td>
      )}
      {caseloadState !== caseloadStateEnum.LEAD ? (
        <>
          <td className="event-channel"> 
            < UnreadChannelItem treatmentId={treatment.id}/>
          </td>
          {Boolean(isWellness) && (
            <td>
              {caseloadState === caseloadStateEnum.ACTIVE &&
                !treatment.emrStatus?.emrPatientLink && (
                  <RegisterToEmr treatmentId={treatment.id} />
                )}
            </td>
          )}
          <td
            className="treatment-row-options"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {(caseloadState === caseloadStateEnum.DISCHARGE ||
              !!(
                treatment.channel.unread && treatment.channel.unread.length
              )) && (
              <OptionsMenu
                openOn="left"
                items={
                  caseloadState === caseloadStateEnum.ACTIVE
                    ? [
                        {
                          title: dStrings.markAsRead,
                          onclick: (e) => {
                            e.preventDefault()
                            setChannelSeen(treatment.id)
                          },
                        },
                      ]
                    : [
                        {
                          title: dStrings.restorePatient,
                          onclick: (e) => {
                            e.preventDefault()
                            toggleRestoreTreatmentPopup(true, {
                              id: treatment.id,
                            })
                          },
                          icon: <RestoreIcon />,
                        },
                      ]
                }
              />
            )}
          </td>
          <td className="treatment-link">
            <OpenTreatmentIcon />
          </td>
        </>
      ) : (
        <>
          <td className="pending-date">
            {format(new Date(treatment.ts), dateFormatEnum.DATE_BASIC)}
          </td>
          <td className="pending-button">
            <Button
              text={dStrings.remove}
              type="link"
              onClick={(e) => {
                e.preventDefault()
                displayConfirmation({
                  details: {
                    title: dStrings.removePatient,
                    confirmBtnText: dStrings.yes,
                    cancelBtnText: dStrings.no,
                    content: (
                      <div className="delete-pending-confirm">
                        <div className="icon">
                          <RemoveIcon />
                        </div>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: dStrings.removePendingMessage,
                          }}
                        />
                      </div>
                    ),
                  },
                  callback: () => {
                    removePendingTreatment(treatment.id)
                  },
                })
              }}
            />
            <Button
              text={dStrings.registerPatient}
              type="primary"
              onClick={(e) => {
                e.preventDefault()
                addNewPatient({
                  ...treatment.profile,
                  patientId: treatment.patientId,
                  id: treatment.id,
                })
              }}
            />
          </td>
        </>
      )}
    </tr>
  )
}

TreatmentRow.propTypes = {
  treatment: PropTypes.object,
  showValues: PropTypes.object,
  clinicalIndicators: PropTypes.object,
  selectTreatmentRow: PropTypes.func,
}

function mapStateToProps(state, ownProp) {
  return {
    caseloadState: state.caseload.caseloadState,
    treatment: ownProp.treatment,
    showValues: state.caseload.showValues,
    clinicalIndicators: state.program.templateToClinicalIndicator,
  }
}

const mapDispatchToProps = {
  selectTreatmentRow,
  displayConfirmation,
  setChannelSeen,
  addNewPatient,
  removePendingTreatment,
  toggleRestoreTreatmentPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentRow)
