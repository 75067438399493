import { ReactNode } from 'react'
import { AppBar, Toolbar, type AppBarProps, ToolbarProps } from '@mui/material'

export type NavbarProps = {
  children: ReactNode | ReactNode[]
  toolbarProps?: ToolbarProps
} & AppBarProps

export default function Navbar({
  children,
  position = 'sticky',
  color = 'transparent',
  elevation = 0,
  toolbarProps,
  ...appBarProps
}: NavbarProps) {
  return (
    <AppBar
      position={position}
      color={color}
      elevation={elevation}
      {...appBarProps}
    >
      <Toolbar {...toolbarProps}>{children}</Toolbar>
    </AppBar>
  )
}
