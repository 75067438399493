import { MutableRefObject, useCallback, useMemo, useState } from 'react'
import {
  GridLogicOperator,
  GridSortModel,
  type GridFilterModel,
  GridColDef,
  GridValidRowModel,
  useGridApiRef,
  GridFilterItem,
  GridSortItem,
  GridFilterOperator,
  DataGridProProps,
  GridPaginationModel,
  GridApiPro,
} from '@mui/x-data-grid-pro'
import { endOfDay } from 'date-fns'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import {
  type QueryDslQueryContainer,
  type SearchSortContainer,
} from '@opensearch-project/opensearch/api/types'

import {
  removeSpecialCharForStringQuery,
  escapeRegExp,
} from '@valerahealth/rtk-query/lib/utils'
import { strToDate } from '../../utils/date'
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
} from '../filterOperators'

export { type QueryDslQueryContainer, type SearchSortContainer }

export enum OperatorValueBoolean {
  is = 'is',
}

export enum OperatorValueNumeric {
  is = '=',
  not = '!=',
  gt = '>',
  gte = '>=',
  lt = '<',
  lte = '<=',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  isAnyOf = 'isAnyOf',
}

export enum OperatorValueSingleSelect {
  is = 'is',
  not = 'not',
  isAnyOf = 'isAnyOf',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
}

export enum OperatorValueStr {
  contains = 'contains',
  equals = 'equals',
  startsWith = 'startsWith',
  /** TODO remove once refactor patient search */
  endsWith = 'endsWith',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  isAnyOf = 'isAnyOf',
  /** TODO remove once refactor patient search */
  search = 'search',
}

export enum OperatorValueDate {
  is = 'is',
  not = 'not',
  after = 'after',
  onOrAfter = 'onOrAfter',
  before = 'before',
  onOrBefore = 'onOrBefore',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
}

export type operator =
  | OperatorValueDate
  | OperatorValueStr
  | OperatorValueSingleSelect

export enum OsColumnType {
  SingleSelect = 'singleSelect',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'dateTime',
  // note there are column types for the checkbox and actions cell but we do not support filtering/sorting on those
}

export type GridFilterItemToOSQuery = (
  item: GridFilterItem,
  // the Open Search Field
  osfield: string,
  items?: GridFilterItem[],
) => QueryDslQueryContainer | undefined

export type SortFitlerItemToOSSort = (
  item: GridSortItem,
  osfield: string,
  columnType?: OsColumnType,
) => SearchSortContainer | undefined

export type OSGridColDef = {
  /** if column key is different than the field in Open Search */
  osFieldKey: string
  filterItemToOSParam: GridFilterItemToOSQuery
  sortItemToOSParam: SortFitlerItemToOSSort
}

export const handleOsSort: SortFitlerItemToOSSort = (
  { sort },
  osFieldKey,
  columnType = OsColumnType.String,
) => {
  const field =
    columnType === OsColumnType.SingleSelect ||
    columnType === OsColumnType.String
      ? `${osFieldKey}.keyword`
      : osFieldKey
  return sort ? { [field]: sort === 'desc' ? 'desc' : 'asc' } : undefined
}

export const handleQuicksearchOsFilter = (
  value: undefined | string | string[],
  fields: string[],
): QueryDslQueryContainer => {
  const queryVal = (
    Array.isArray(value)
      ? value
          .map((s) => `${removeSpecialCharForStringQuery(s)}`)
          .join(' ')
          .split(' ')
      : removeSpecialCharForStringQuery(value || '').split(' ')
  ).filter((value): value is string => !!value.trim())

  return {
    query_string: {
      query: `${queryVal.map((s) => `*${s}*`).join(' ')}`,
      fields,
      fuzziness: 'AUTO',
      default_operator: 'AND',
    },
  }
}

const isArryOfStrs = (v: any): v is string[] =>
  Array.isArray(v) && v.every((v) => typeof v === 'string')

export const handleStringOsFilter: GridFilterItemToOSQuery = (
  { operator, value },
  field,
) => {
  // Doesn't require a value
  switch (operator) {
    case OperatorValueStr.isEmpty:
      return {
        bool: {
          should: [
            // either not exist OR value is empty string
            { bool: { must_not: { exists: { field } } } },
            { term: { [`${field}.keyword`]: '' } },
          ],
          minimum_should_match: 1,
        },
      }
    case OperatorValueStr.isNotEmpty:
      return {
        bool: {
          must: [
            // exist AND not be an empty string
            {
              exists: { field },
            },
            {
              bool: {
                must_not: { term: { [`${field}.keyword`]: '' } },
              },
            },
          ],
        },
      }
    default:
  }

  // value must be a string
  if (typeof value === 'string' && value) {
    switch (operator) {
      case OperatorValueStr.contains:
        return {
          regexp: {
            [`${field}.keyword`]: {
              value: `.*${escapeRegExp(value)}.*`,
              flags: 'NONE',
              case_insensitive: true,
            },
          },
        }
      case OperatorValueStr.equals:
        return { term: { [`${field}.keyword`]: value } }
      case OperatorValueStr.startsWith:
        return {
          prefix: {
            [`${field}.keyword`]: { value, case_insensitive: true },
          },
        }
      default:
    }
  }

  // value must be an array of strings
  if (isArryOfStrs(value)) {
    switch (operator) {
      case OperatorValueStr.isAnyOf:
        return {
          terms: {
            [`${field}.keyword`]: value,
          },
        }
      default:
    }
  }

  return undefined
}

export const handleDateOsFilter: GridFilterItemToOSQuery = (
  { operator, value },
  field,
) => {
  // dont require value
  switch (operator) {
    case OperatorValueDate.isEmpty:
      return { bool: { must_not: { exists: { field } } } }
    case OperatorValueDate.isNotEmpty:
      return { exists: { field } }
    default:
  }

  if (!value || typeof value !== 'string') return undefined
  // value must be a string
  switch (operator) {
    case OperatorValueDate.after:
      return {
        range: {
          [field]: {
            gt: value,
          },
        },
      }
    case OperatorValueDate.onOrAfter:
      return {
        range: {
          [field]: {
            gte: value,
          },
        },
      }
    case OperatorValueDate.before:
      return {
        range: {
          [field]: {
            lt: value,
          },
        },
      }
    case OperatorValueDate.onOrBefore:
      return {
        range: {
          [field]: {
            lte: value,
          },
        },
      }
    case OperatorValueDate.is:
      return {
        term: { [field]: value },
      }
    case OperatorValueDate.not:
      return {
        bool: {
          must_not: {
            term: { [field]: value },
          },
        },
      }
    default:
      return undefined
  }
}

export const handleDateTimeOsFilter: GridFilterItemToOSQuery = (
  { operator, value: _value },
  field,
) => {
  // dont require value
  switch (operator) {
    case OperatorValueDate.isEmpty:
      return { bool: { must_not: { exists: { field } } } }
    case OperatorValueDate.isNotEmpty:
      return { exists: { field } }
    default:
  }

  if (!_value || typeof _value !== 'string') return undefined

  // set to start of day in the users timezone
  const value = strToDate(_value)
  if (!value) return undefined

  // value must be a string
  switch (operator) {
    case OperatorValueDate.after:
      return {
        range: {
          [field]: {
            gt: endOfDay(value).toISOString(),
          },
        },
      }
    case OperatorValueDate.onOrAfter:
      return {
        range: {
          [field]: {
            gte: value.toISOString(),
          },
        },
      }
    case OperatorValueDate.before:
      return {
        range: {
          [field]: {
            lt: value.toISOString(),
          },
        },
      }
    case OperatorValueDate.onOrBefore:
      return {
        range: {
          [field]: {
            lte: endOfDay(value).toISOString(),
          },
        },
      }
    case OperatorValueDate.is:
      return {
        range: {
          [field]: {
            gte: value.toISOString(),
            lte: endOfDay(value).toISOString(),
          },
        },
      }
    case OperatorValueDate.not:
      return {
        bool: {
          should: [
            {
              range: {
                [field]: {
                  lt: value.toISOString(),
                },
              },
            },
            {
              range: {
                [field]: {
                  gt: endOfDay(value).toISOString(),
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      }
    default:
      return undefined
  }
}

export const handleBooleanOsFilter: GridFilterItemToOSQuery = (
  { operator, value },
  field,
) => {
  if (
    operator !== OperatorValueBoolean.is ||
    !(value === 'true' || value === 'false')
  )
    return undefined

  if (value === 'true') {
    return {
      must: { term: { [field]: true } },
    }
  }

  return {
    bool: {
      // not exist OR be false
      should: [
        { term: { [field]: false } },
        { bool: { must_not: { exists: { field } } } },
      ],
      minimum_should_match: 1,
    },
  }
}

export const handleNumberOsFilter: GridFilterItemToOSQuery = (
  { operator, value },
  field,
) => {
  // dont require value
  switch (operator) {
    case OperatorValueNumeric.isEmpty:
      return { bool: { must_not: { exists: { field } } } }
    case OperatorValueNumeric.isNotEmpty:
      return { exists: { field } }
    default:
  }

  //anything else requires value
  if (typeof value !== 'number') return undefined
  // value must be a string
  switch (operator) {
    case OperatorValueNumeric.gt:
      return {
        range: {
          [field]: {
            gt: value,
          },
        },
      }
    case OperatorValueNumeric.gte:
      return {
        range: {
          [field]: {
            gte: value,
          },
        },
      }
    case OperatorValueNumeric.lt:
      return {
        range: {
          [field]: {
            lt: value,
          },
        },
      }
    case OperatorValueNumeric.lte:
      return {
        range: {
          [field]: {
            lte: value,
          },
        },
      }
    case OperatorValueNumeric.is:
      return {
        term: { [field]: value },
      }
    case OperatorValueNumeric.not:
      return {
        bool: {
          must_not: {
            term: { [field]: value },
          },
        },
      }
    default:
      return undefined
  }
}

// Right now we assume the value is always going to be a string, need to assess if this works as expected if a value ever is a number
export const handleSelectOsFilter: GridFilterItemToOSQuery = (
  { operator, value: _value },
  field,
) => {
  // Doesn't require a value
  switch (operator) {
    case OperatorValueSingleSelect.isEmpty:
      return {
        bool: {
          should: [
            // either not exist OR value is empty string
            { bool: { must_not: { exists: { field } } } },
            { term: { [`${field}.keyword`]: '' } },
          ],
          minimum_should_match: 1,
        },
      }
    case OperatorValueSingleSelect.isNotEmpty:
      return {
        bool: {
          must: [
            // exist AND not be an empty string
            {
              exists: { field },
            },
            {
              bool: {
                must_not: { term: { [`${field}.keyword`]: '' } },
              },
            },
          ],
        },
      }
    default:
  }

  // normalize value can be string, string[], {value, label}, {value, label}[]
  const parseValue = (v?: unknown): string[] | undefined => {
    const parseSingleValue = (v?: unknown): string | undefined => {
      if (typeof v === 'string' && v) return v
      if (
        v &&
        typeof v === 'object' &&
        'value' in v &&
        typeof v.value === 'string' &&
        v.value
      )
        return v.value
      return undefined
    }
    if (Array.isArray(v)) {
      const mapped = v.map(parseSingleValue).filter((v): v is string => !!v)
      return mapped.length ? mapped : undefined
    }
    const value = parseSingleValue(v)
    if (value) return [value]
    return undefined
  }

  const value = parseValue(_value)
  if (!value?.length) return undefined

  switch (true) {
    case operator === OperatorValueSingleSelect.isAnyOf && value.length > 1:
      return {
        terms: {
          [`${field}.keyword`]: value,
        },
      }
    case operator === OperatorValueSingleSelect.isAnyOf ||
      operator === OperatorValueSingleSelect.is:
      return { term: { [`${field}.keyword`]: value[0] } }
    case operator === OperatorValueSingleSelect.not:
      return {
        bool: { must_not: { term: { [`${field}.keyword`]: value[0] } } },
      }
    default:
      return undefined
  }
}

export type ServerSideColumn<T extends GridValidRowModel> = GridColDef<T> &
  Partial<OSGridColDef>

let _getGridStringOperators: GridFilterOperator[] | undefined
export const getOsGridStringOperators = () => {
  if (!_getGridStringOperators)
    _getGridStringOperators = getGridStringOperators().filter(
      (op) => op.value !== 'endsWith',
    )
  return _getGridStringOperators
}
let _getGridSingleSelectOperators: GridFilterOperator[] | undefined
export const getOsGridSingleSelectOperators = () => {
  if (!_getGridSingleSelectOperators)
    _getGridSingleSelectOperators = [
      ...getGridSingleSelectOperators(),
      ...getOsGridStringOperators().filter(({ value }) =>
        (
          [
            OperatorValueSingleSelect.isEmpty,
            OperatorValueSingleSelect.isNotEmpty,
          ] as string[]
        ).includes(value),
      ),
    ]
  return _getGridSingleSelectOperators
}
let _getGridBooleanOperators: GridFilterOperator[] | undefined
export const getOsGridBooleanOperators = () => {
  if (!_getGridBooleanOperators)
    _getGridBooleanOperators = getGridBooleanOperators()
  return _getGridBooleanOperators
}
let _getGridDateOperators: GridFilterOperator[] | undefined
export const getOsGridDateOperators = () => {
  if (!_getGridDateOperators) _getGridDateOperators = getGridDateOperators()
  return _getGridDateOperators
}
let _getGridNumericOperators: GridFilterOperator[] | undefined
export const getOsGridNumericOperators = () => {
  if (!_getGridNumericOperators)
    _getGridNumericOperators = getGridNumericOperators()
  return _getGridNumericOperators
}

const columnTypeDefaults: Record<
  string,
  | {
      getFilterOperators: () => GridFilterOperator[]
      defaultFilterItemToOSParam: GridFilterItemToOSQuery
    }
  | undefined
> = {
  [OsColumnType.SingleSelect]: {
    defaultFilterItemToOSParam: handleSelectOsFilter,
    getFilterOperators: getOsGridSingleSelectOperators,
  },
  [OsColumnType.String]: {
    defaultFilterItemToOSParam: handleStringOsFilter,
    getFilterOperators: getOsGridStringOperators,
  },
  [OsColumnType.Number]: {
    defaultFilterItemToOSParam: handleNumberOsFilter,
    getFilterOperators: getOsGridNumericOperators,
  },
  [OsColumnType.Boolean]: {
    defaultFilterItemToOSParam: handleBooleanOsFilter,
    getFilterOperators: getOsGridBooleanOperators,
  },
  [OsColumnType.Date]: {
    defaultFilterItemToOSParam: handleDateOsFilter,
    getFilterOperators: getOsGridDateOperators,
  },
  [OsColumnType.DateTime]: {
    defaultFilterItemToOSParam: handleDateTimeOsFilter,
    getFilterOperators: getOsGridDateOperators,
  },
}

export const handleNestedOsType: (
  path: string,
  type: OsColumnType,
  transformItem?: (item: GridFilterItem) => GridFilterItem,
) => GridFilterItemToOSQuery = (path, type, transformItem) => (item, field) => {
  const handleOsFilter = columnTypeDefaults[type]?.defaultFilterItemToOSParam
  if (!handleOsFilter) return undefined
  const query = handleOsFilter(
    transformItem ? transformItem(item) : item,
    field,
  )
  if (!query) return undefined
  return {
    nested: {
      path,
      query,
    },
  }
}

/**  splits off the Open Search specific properties, default column filters that are allowed for open search */
function selectOSParams<T extends GridValidRowModel>(
  columns: ServerSideColumn<T>[],
) {
  return columns.reduce(
    (sum, column) => {
      const columnType = column.type || OsColumnType.String
      const defaults = columnTypeDefaults[columnType]

      const {
        field,
        osFieldKey,
        filterItemToOSParam,
        sortItemToOSParam = handleOsSort,
        filterOperators = defaults?.getFilterOperators(),
        ...rest
      } = column
      sum.gridColumns.push({
        ...rest,
        filterOperators,
        field,
        type: column.type,
      })
      // column type could be actions or checkbox selection which we dont support filter/sort
      if (defaults) {
        sum.osFieldMap[field] = {
          columnType: columnType as OsColumnType,
          osFieldKey: osFieldKey || field,
          filterItemToOSParam:
            filterItemToOSParam || defaults.defaultFilterItemToOSParam,
          sortItemToOSParam,
        }
      }
      return sum
    },
    {
      gridColumns: [],
      osFieldMap: {},
    } as {
      gridColumns: Omit<(typeof columns)[number], keyof OSGridColDef>[]
      osFieldMap: { [x: string]: OSGridColDef & { columnType: OsColumnType } }
    },
  )
}

export const defaultQuickFilterSortOverride: SearchSortContainer[] = [
  { _score: { order: 'desc' } },
]

// check to see what field type is valid for doc accessor
// This function can not access any field in nested type, or directly access non-simple type, eg no object, no array, no nested...
// https://www.elastic.co/guide/en/elasticsearch/reference/current/modules-scripting-fields.html

export const getSortFilterItemToOSSortScriptCustomizedRank: (
  rankMapping: {
    [s: string]: number
  },
  isKeywordType?: boolean,
  undefinedOrNullRank?: number,
) => SortFitlerItemToOSSort =
  (r, isKeywordType = true, undefinedOrNullRank = 0) =>
  (item, osFieldKey) => {
    const doc = `doc['${osFieldKey}${isKeywordType ? '.keyword' : ''}']`
    const source = `try {return params.scores[${doc}?.value]} catch (Exception e) {return ${undefinedOrNullRank};}`

    return {
      // Note: the script ignores any invalid or empty value in availabilityStatus field
      _script: {
        order: item.sort === 'asc' ? 'asc' : 'desc',
        type: 'number',
        script: {
          lang: 'painless',
          source,
          params: {
            scores: r,
          },
        },
      },
    } as SearchSortContainer
  }

export const osSortByArraySize: (
  isKeywordType?: boolean,
  undefinedOrNullRank?: number,
) => SortFitlerItemToOSSort =
  (isKeywordType = false, undefinedOrNullRank = 0) =>
  (item, osFieldKey) => ({
    _script: {
      order: item.sort === 'asc' ? 'asc' : 'desc',
      type: 'number',
      script: {
        lang: 'painless',
        source: `try { return doc['${osFieldKey}${
          isKeywordType ? '.keyword' : ''
        }'].length; } catch (Exception e) { return ${undefinedOrNullRank}; }`,
      },
    },
  })

// for array field, 1d array.
// for nested type, you can't access anything inside through doc, use _source instead and create a scripted field for sorting.
// https://www.elastic.co/guide/en/elasticsearch/reference/current/modules-scripting-fields.html#modules-scripting-source

export const getSortFilterItemToOSSortScriptCustomizedRankForArray: (
  rankMapping: {
    [s: string]: number
  },
  isKeywordType?: boolean,
) => SortFitlerItemToOSSort =
  (r, isKeywordType = true) =>
  (item) => {
    const field = `${item.field}${isKeywordType ? '.keyword' : ''}`
    return {
      _script: {
        order: item.sort === 'asc' ? 'asc' : 'desc',
        type: 'number',
        script: {
          lang: 'painless',
          source: `
          try {
            if (doc['${field}'].length==0){
              return 0;
            }
            def score = 0;
            for (item in doc['${field}']){
              score += params.scores[item.value];
            }
            return score;
          } catch (Exception e){
            return 0; 
          }
          `,
          params: {
            scores: r,
          },
        },
      },
    } as SearchSortContainer
  }

export type QueryPropsType = {
  pageSize: number
  from: number
  query: { must?: QueryDslQueryContainer[] }
  sort: SearchSortContainer[]
}

export type SSGridPropsType = Pick<
  DataGridProProps,
  | 'apiRef'
  | 'initialState'
  | 'columns'
  | 'sortingMode'
  | 'paginationMode'
  | 'filterMode'
  | 'pagination'
  | 'pageSizeOptions'
  | 'paginationModel'
  | 'onFilterModelChange'
  | 'onSortModelChange'
  | 'onPaginationModelChange'
> & { apiRef: MutableRefObject<GridApiPro> }

export function useServerSideGrid<T extends GridValidRowModel>(
  columns: ServerSideColumn<T>[],
  initialState?: GridInitialStatePro,
  quickFilterOsFieldKeys?: string[],
  quickFilterSortOverride?: SearchSortContainer[],
  disableSortOnQuickFilter = true,
  defaultPageSize = 50,
): { gridProps: SSGridPropsType; queryProps: QueryPropsType } {
  const { gridColumns, osFieldMap } = useMemo(
    () => selectOSParams(columns),
    [columns],
  )

  const apiRef = useGridApiRef()

  const filterItemsToQuery = useCallback(
    (filterModel: GridFilterModel | undefined) => {
      if (!filterModel) return {}

      const { items, logicOperator } = filterModel

      const columnFilters = items
        .map((item) => {
          const config = osFieldMap[item.field]
          if (!config) return null
          const { filterItemToOSParam, osFieldKey } = config
          return filterItemToOSParam(item, osFieldKey, items)
        })
        .filter((v): v is QueryDslQueryContainer => !!v)

      const must: QueryDslQueryContainer[] = []

      if (columnFilters.length) {
        must.push({
          bool: {
            [logicOperator === GridLogicOperator.Or ? 'should' : 'must']:
              columnFilters,
            ...(logicOperator === GridLogicOperator.Or
              ? {
                  minimum_should_match: 1,
                }
              : null),
          },
        })
      }
      return { must }
    },
    [osFieldMap],
  )

  const sortItemsToQuery = useCallback(
    (sortModel: GridSortModel | undefined) => {
      return (sortModel || [])
        .map((item) => {
          const sortConfig = osFieldMap[item.field]
          if (!sortConfig) return null

          const { sortItemToOSParam, osFieldKey, columnType } = sortConfig

          return sortItemToOSParam(item, osFieldKey, columnType)
        })
        .filter((v): v is SearchSortContainer => !!v)
    },
    [osFieldMap],
  )

  const { initialQuery, initialSort } = useMemo(() => {
    return {
      initialQuery: filterItemsToQuery(initialState?.filter?.filterModel),
      initialSort: sortItemsToQuery(initialState?.sorting?.sortModel),
    }
    // initial state is only applied on first render so no point in recalculating on a depecency change
    // eslint-disable-next-line
  }, [])

  const [queryProps, setQueryState] = useState<QueryPropsType>({
    query: initialQuery,
    sort: initialSort,
    pageSize: defaultPageSize,
    from: 0,
  })

  const onFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      const hasQuickFilter = !!(
        quickFilterOsFieldKeys?.length && filterModel.quickFilterValues?.length
      )
      const query = filterItemsToQuery(filterModel)

      if (hasQuickFilter) {
        const qf = [
          handleQuicksearchOsFilter(
            filterModel.quickFilterValues,
            quickFilterOsFieldKeys,
          ),
        ]
        query.must = qf.concat(query.must || [])
      }

      setQueryState((s) => ({
        ...s,
        query,
        sort:
          hasQuickFilter && quickFilterSortOverride
            ? quickFilterSortOverride
            : s.sort,
      }))
    },
    [filterItemsToQuery, quickFilterOsFieldKeys, quickFilterSortOverride],
  )

  const hasQuickFilter =
    quickFilterOsFieldKeys?.length &&
    !!apiRef.current?.state?.filter?.filterModel?.quickFilterValues?.length

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      if (hasQuickFilter && disableSortOnQuickFilter) return
      setQueryState((s) => ({
        ...s,
        sort: sortItemsToQuery(sortModel),
      }))
    },
    [hasQuickFilter, sortItemsToQuery, disableSortOnQuickFilter],
  )

  const onPaginationModelChange = useCallback((pm: GridPaginationModel) => {
    setQueryState((s) => ({
      ...s,
      pageSize: pm.pageSize,
      from: pm.page * pm.pageSize,
    }))
  }, [])

  const mode = 'server' as const

  return {
    gridProps: {
      apiRef,
      initialState,
      columns: gridColumns,
      sortingMode: mode,
      paginationMode: mode,
      filterMode: mode,
      pagination: true,
      pageSizeOptions: [10, 25, 50, 100],
      paginationModel: {
        page: queryProps.from / queryProps.pageSize,
        pageSize: queryProps.pageSize,
      },
      onFilterModelChange,
      onSortModelChange,
      onPaginationModelChange,
    },
    queryProps,
  }
}
