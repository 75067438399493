import CONFIG, { getHeader, handleResponse, handleError } from './apiUtils'

const baseUrl = `https://${process.env.CARE_MANAGER_API_DOMAIN}/api/program`
const { signal } = CONFIG

export function getProgramConfiguration(id) {
  return fetch(`${baseUrl}/${id}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getProgramProviders(id) {
  return fetch(`${baseUrl}/${id}/provider`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getProgramTags(id) {
  return fetch(`${baseUrl}/${id}/tags`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function createProgramTag(id, newTag) {
  return fetch(`${baseUrl}/${id}/tag`, {
    method: 'POST',
    signal,
    headers: getHeader(),
    body: JSON.stringify({ tag: newTag }),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function registerNewTreatment(programId, data, pendingPatientId) {
  return fetch(
    `${baseUrl}/${programId}/treatment${
      pendingPatientId ? `?patientId=${pendingPatientId}` : ''
    }`,
    {
      method: 'POST',
      signal,
      headers: getHeader(),
      body: JSON.stringify(data),
    },
  )
    .then(handleResponse)
    .catch(handleError)
}

export function getReports(id, from, to) {
  let url = `${baseUrl}/${id}/reports/summary`
  if (from || to) {
    url += `?from=${encodeURI(from)}&to=${encodeURI(to)}`
  }

  return fetch(url, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getReportsDataType(id, type) {
  return fetch(`${baseUrl}/${id}/reports/data/${type}`, {
    method: 'GET',
    signal,
    headers: getHeader(),
  })
    .then(handleResponse)
    .catch(handleError)
}
