import type { Overwrite } from 'utility-types'
import {
  PatientGenderIdentity,
  PatientLegalSex,
  PatientStatus,
  patientGenderList,
  REGISTER_TREATMENT_ERROR_CODE,
  patientRegistrationApi,
  CreatePatientInput,
  PatientResponse,
  isWellnessSelector,
  STATES,
  AddressComponents,
} from '@valerahealth/rtk-query'
import { useTranslation } from '@valerahealth/ui-translation'
import LABELS from '@valerahealth/ui-translation/locales/en'
import { useSelector } from 'react-redux'
import { Button, Grid, MenuItem, Typography } from '../../base'
import {
  FormProvider,
  SaveButton,
  TextField,
  DatePicker,
  ProviderSelect,
  useForm,
  Select,
  IntlPhoneInput,
  validate,
  LegacyCareManagerSelect,
  Combobox,
} from '../../form'
import {
  getUSTimezone,
  getTimezoneValues,
  startOfToday,
  SYSTEM_TIMEZONE,
  dateToStr,
} from '../../utils/date'
import { ProgramTagSelect } from '../ProgramTagSelect'
import {
  emptyStrToNullRecursive,
  providerToFullName,
  setIntlPhoneNumber,
  trimStringsRecursive,
} from '../../utils'
import { useNotify } from '../Notifications'
import { useConfirmationDialog } from '../ConfirmationDialog'

export type RegisterTreatmentFormProps = {
  onSuccess?: (treatment: PatientResponse) => void
  onCancel?: () => void
}

export const timezoneOptions = getTimezoneValues()

type FormType = {
  profile: {
    firstName: string
    preferredName: string
    lastName: string
    dateOfBirth: Date | null
    gender: PatientLegalSex | null
    genderIdentity: PatientGenderIdentity | null
    email: string
    phone: string
    timezone: string
    addressComponents: AddressComponents
  }
  treatment: {
    /** program tags */
    diagnosis: string[]
  }
  careTeam: {
    pctmId: string | null
  }
}

type SubmittedFormType = Overwrite<
  FormType,
  {
    // validation requires these fields
    profile: Overwrite<
      FormType['profile'],
      {
        dateOfBirth: Date
        gender: PatientLegalSex
        timezone: string
      }
    >
    careTeam: {
      pctmId: string
    }
  }
>
const formToPayload = (
  { profile, careTeam, treatment }: SubmittedFormType,
  forceMultiPhone: boolean,
): CreatePatientInput => {
  return emptyStrToNullRecursive(
    trimStringsRecursive({
      demographics: {
        firstName: profile.firstName,
        preferredName: profile.preferredName,
        lastName: profile.lastName,
        dateOfBirth: dateToStr(profile.dateOfBirth),
        gender: profile.gender,
        genderIdentity: profile.genderIdentity || undefined,
        email: profile.email,
        phone: setIntlPhoneNumber(profile.phone),
        timezone: profile.timezone,
        tags: treatment.diagnosis,
        status: PatientStatus.Active,
        addressComponents: profile.addressComponents,
      },
      care: {
        careTeam,
        disableChat: false,
      },
      configuration: {
        forceMultiPhone,
      },
    }),
  )
}

export default function RegisterTreatmentForm({
  onSuccess,
  onCancel,
}: RegisterTreatmentFormProps) {
  const { t } = useTranslation()
  const notify = useNotify()
  const isWellness = useSelector(isWellnessSelector)

  const [createPatient] = patientRegistrationApi.useCreatePatientMutation()

  const { confirm, ConfirmationDialog } = useConfirmationDialog()

  const methods = useForm<FormType>({
    defaultValues: {
      profile: {
        firstName: '',
        preferredName: '',
        lastName: '',
        dateOfBirth: null,
        gender: null,
        genderIdentity: null,
        email: '',
        phone: '',
        timezone: getUSTimezone(SYSTEM_TIMEZONE),
        addressComponents: {
          street: '',
          city: '',
          state: null,
          zipcode: '',
        },
      },
      treatment: {
        /** tags */
        diagnosis: [],
      },
      careTeam: {
        pctmId: null,
      },
    },
  })

  const onSubmit = async (
    values: SubmittedFormType,
    forceMultiPhone?: boolean,
  ) => {
    const body = formToPayload(values, forceMultiPhone || false)

    const res = await createPatient({ input: body })

    if ('data' in res) {
      notify({
        message: 'Successfully registered patient.',
        severity: 'success',
      })

      onSuccess?.(res.data.createPatient as PatientResponse)
      return
    }

    if ('error' in res) {
      switch (res.error.message) {
        case REGISTER_TREATMENT_ERROR_CODE.PHONE_ALREADY_EXISTS_OTHER_CLINIC: {
          const confirmed = await confirm({
            body: (
              <>
                <Typography gutterBottom>
                  This phone number is already registered in another clinic.
                </Typography>
                <Typography>
                  Do you want to continue adding a new patient account to this
                  clinic?
                </Typography>
              </>
            ),
          })
          if (confirmed) {
            await onSubmit(values, true)
          }
          break
        }
        case REGISTER_TREATMENT_ERROR_CODE.PHONE_ALREADY_EXISTS_SAME_CLINIC: {
          const confirmed = await confirm({
            body: (
              <>
                <Typography gutterBottom>
                  This phone number is already registered in this clinic.
                </Typography>
                <Typography>
                  Do you want to continue adding a new patient account to this
                  clinic?
                </Typography>
              </>
            ),
          })
          if (confirmed) {
            await onSubmit(values, true)
          }
          break
        }
        case REGISTER_TREATMENT_ERROR_CODE.FIRST_NAME:
        case REGISTER_TREATMENT_ERROR_CODE.LAST_NAME:
        case REGISTER_TREATMENT_ERROR_CODE.EMAIL:
          methods.setError(
            `profile.${res.error.message}`,
            { type: 'invalid' },
            { shouldFocus: true },
          )
          break
        default:
          notify({
            severity: 'error',
            message:
              res.error.message || 'An error occured registering the patient.',
          })
      }
    } else {
      notify({
        severity: 'error',
        message: 'An error occured registering the patient.',
      })
    }
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ pt: 0.7 }}
      component="form"
      autoComplete="off"
      onSubmit={methods.handleSubmit((values) => {
        return onSubmit(values as SubmittedFormType)
      })}
    >
      <FormProvider {...methods}>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            fullWidth
            required
            name="profile.firstName"
            label="First Name"
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextField
            fullWidth
            name="profile.preferredName"
            label="Preferred Name"
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextField
            fullWidth
            required
            name="profile.lastName"
            label="Last Name"
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <DatePicker
            fullWidth
            required
            name="profile.dateOfBirth"
            label="Date of Birth"
            maxDate={startOfToday()}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <Select
            name="profile.gender"
            label={t('legalSex')}
            fullWidth
            required
          >
            {Object.values(PatientLegalSex).map((v) => (
              <MenuItem key={v} value={v}>
                {t(`PatientLegalSex.${v}`)}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <Select
            label={t('genderIdentity')}
            name="profile.genderIdentity"
            fullWidth
          >
            {patientGenderList.map((v) => (
              <MenuItem key={v} value={v}>
                {t(`PatientGenderIdentity.${v}`)}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            name="profile.email"
            label={t('primaryEmail')}
            validate={(v) => validate.validEmail(v, t('form_invalid_email'))}
            required
            fullWidth
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <IntlPhoneInput
            name="profile.phone"
            label={t('primaryPhone')}
            required
            fullWidth
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <ProgramTagSelect
            name="treatment.diagnosis"
            label={t('tags')}
            fullWidth
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          {isWellness ? (
            <ProviderSelect
              name="careTeam.pctmId"
              fullWidth
              label="Primary Care Team Member"
              setValueAs={(p) => (p ? p.careManagerId : null)}
              parseValue={(value: string, options) =>
                options.find((o) => o.careManagerId === value) || null
              }
              getOptionLabel={(provider) =>
                provider.display?.expandedName || providerToFullName(provider)
              }
              required
            />
          ) : (
            <LegacyCareManagerSelect
              name="careTeam.pctmId"
              fullWidth
              label="Primary Care Team Member"
              setValueAs={(p) => (p ? p.id : null)}
              parseValue={(value: string, options) =>
                options.find((o) => o.id === value) || null
              }
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              color: (theme) => theme.palette.secondary.dark,
              mr: 'auto',
              fontSize: '1.1rem',
            }}
          >
            {t('address')}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={`${t('streetAddress')}`}
            name="profile.addressComponents.street"
            required
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={t('city')}
            name="profile.addressComponents.city"
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Combobox
            label={t('state')}
            name="profile.addressComponents.state"
            options={STATES}
            getOptionLabel={(v) => v && `${v.name} (${v.code})`}
            setValueAs={(v) => v?.code || null}
            parseValue={(v) => STATES.find((s) => s.code === v) || null}
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            name="profile.addressComponents.zipcode"
            label={t('zipCode')}
            validate={(v) => validate.validPostalCode(v, t('invalidZipCode'))}
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Select
            fullWidth
            label={t('timeZone')}
            name="profile.timezone"
            required
          >
            {timezoneOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
        >
          {onCancel && (
            <Button
              variant="text"
              size="large"
              color="inherit"
              disabled={methods.formState.isSubmitting}
              autoFocus
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
          )}
          <SaveButton
            isError={!!Object.keys(methods.formState.errors).length}
            isSuccess={methods.formState.isSubmitSuccessful}
            label={t('save')}
          />
        </Grid>

        <ConfirmationDialog
          header={LABELS.registerWithExistingPhoneNumber}
          confirmLabel={LABELS.addToClinic}
        />
      </FormProvider>
    </Grid>
  )
}
