import { callApi } from '../../utilities'
import * as types from '../../actionTypes'
import * as treatmentAPI from '../../../../api/treatmentAPI'

export const getData = (from, to) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'getData',
      dispatch,
      call: () => {
        return treatmentAPI.getData(
          getState().treatmentRoom.selectedId,
          from,
          to,
        )
      },
      success: (data) => {
        dispatch({
          type: types.LOAD_PROFILE_DATA,
          data,
        })
      },
    })
  }
}
