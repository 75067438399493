import { type ComponentProps, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import {
  type FormControlProps,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox as BaseCheckbox,
} from '@mui/material'
import { type BaseFormControlProps } from './index.types'
import { splitProps, getErrorMessage, useReadOnlyForm } from '../utils'
import { useTranslation } from '../../utils/hooks'
import BaseSwitch from '../../base/Switch'

type SwitchOrCheckboxProps<T> = BaseFormControlProps &
  Omit<FormControlProps, 'children' | 'error' | 'focused' | 'required'> &
  T

const withFormControl = (Component: typeof BaseSwitch | typeof BaseCheckbox) =>
  (function SwitchOrCheckbox(
    {
      name,
      required,
      helperText,
      label,
      hideLabel,
      classes,
      color,
      disabled,
      fullWidth,
      hiddenLabel,
      margin,
      size,
      sx,
      variant,
      errorFieldName,
      readOnly,
      ...rest
    }: SwitchOrCheckboxProps<ComponentProps<typeof Component>>
  ) {
    const { registerProps, fieldProps } = useMemo(
      () => splitProps(rest),
      [rest],
    )
    const { t } = useTranslation()

    const isReadOnlyForm = useReadOnlyForm().readOnly
    const isReadOnly = readOnly === undefined ? isReadOnlyForm : readOnly

    return (
      <Controller
        name={name}
        rules={registerProps}
        render={({
          field: { onChange, value, ...field },
          fieldState: { error },
        }) => {
          const errorOrHelper = error
            ? getErrorMessage(error, t, {
                field: errorFieldName || String(label),
                ...registerProps,
              })
            : helperText

          return (
            <FormControl
              {...{
                error: !!error,
                required: !!required,
                classes,
                disabled,
                fullWidth,
                hiddenLabel,
                margin,
                size,
                sx,
                variant,
              }}
            >
              <FormControlLabel
                sx={{
                  cursor: isReadOnly ? 'default' : 'pointer',
                }}
                label={!hideLabel && label}
                control={
                  <Component
                    {...fieldProps}
                    {...field}
                    checked={!!value}
                    color={color}
                    onChange={!isReadOnly ? onChange : () => {}}
                    sx={{
                      pointerEvents: isReadOnly ? 'none' : undefined,
                    }}
                  />
                }
              />

              {errorOrHelper && (
                <FormHelperText>{errorOrHelper}</FormHelperText>
              )}
            </FormControl>
          )
        }}
      />
    )
  })

export const Switch = withFormControl(BaseSwitch)
export const Checkbox = withFormControl(BaseCheckbox)
