import { useMemo, ReactNode, useState } from 'react'
import {
  DatePicker as MuiDatePicker,
  type DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers-pro'
import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
} from 'date-fns'
import { type TextFieldProps } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'
import { type BaseFormControlProps } from './index.types'
import { useTranslation } from '../../utils/hooks'
import { isValidDateOrNull } from '../../utils/date'
import { splitProps, getErrorMessage, useReadOnlyForm } from '../utils'

type DatePickerProps = Omit<MuiDatePickerProps<Date>, 'renderInput'> & {
  fullWidth?: boolean
  textFieldProps?: Omit<
    TextFieldProps,
    'ref' | 'onChange' | 'value' | 'label' | 'fullWidth'
  >
  /** defaults to false if no input format provided else true */
  openOnClick?: boolean
}

function BaseDatePicker({
  textFieldProps,
  format: inputFormat,
  fullWidth,
  openOnClick: initialOpenOnClick,
  slotProps,
  ...rest
}: DatePickerProps) {
  const [open, setOpen] = useState(false)

  // disableMaskedInput is deprecated in v6. Dev should take care of data format and mask before passing it to props

  const openOnClick =
    initialOpenOnClick === undefined ? !!inputFormat : initialOpenOnClick

  return (
    <MuiDatePicker
      {...rest}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      format={inputFormat || 'MM/dd/yyyy'}
      slotProps={{
        ...slotProps,
        textField: {
          InputProps: {
            onClick: openOnClick
              ? (e) => {
                  if (!open) setOpen(true)
                  e.stopPropagation()
                }
              : undefined,
            ...(textFieldProps?.InputProps?.endAdornment
              ? { endAdornment: textFieldProps?.InputProps?.endAdornment }
              : {}),
            ...textFieldProps?.InputProps,
          },

          fullWidth,
          ...textFieldProps,
        },
      }}
    />
  )
}

/* V6 change: pass in Date object instead of ISO string! */

export function DatePicker({
  name,
  required,
  helperText,
  label,
  hideLabel,
  validate: validateProp,
  errorFieldName,
  format: inputFormat,
  maxDate,
  minDate,
  readOnly: fieldReadOnly,
  ...rest
}: BaseFormControlProps &
  Omit<
    DatePickerProps,
    'required' | 'error' | 'renderInput' | 'onChange' | 'value' | 'inputRef'
  > & {
    helperText?: ReactNode

    textFieldProps?: Omit<
      TextFieldProps,
      'helperText' | 'onBlur' | 'name' | 'ref' | 'onChange' | 'value' | 'error'
    >
  }) {
  const {
    registerProps,
    fieldProps: { textFieldProps, ...fieldProps },
  } = useMemo(() => splitProps(rest), [rest])

  const [t] = useTranslation()
  const { readOnly: formReadOnly } = useReadOnlyForm()

  const validate = useMemo(() => {
    if (typeof validateProp === 'function') {
      return (val: Date | null, formValues: FieldValues) => {
        const result = isValidDateOrNull(val)
        if (!result) return t('form_invalidDate')
        return validateProp(val, formValues)
      }
    }

    return {
      ...(typeof validateProp === 'function'
        ? { validate: validateProp }
        : validateProp),
      ...(maxDate instanceof Date
        ? {
            maxDate: (val: Date | null) => {
              if (!val) return true
              const maxDateEOD = endOfDay(maxDate)

              return (
                isBefore(val, maxDateEOD) ||
                isEqual(val, maxDateEOD) ||
                t('form_invalid_field_max_date', {
                  field: errorFieldName || label,
                  max: format(maxDate, inputFormat || 'MM/dd/yyyy'),
                })
              )
            },
          }
        : null),
      ...(minDate instanceof Date
        ? {
            minDate: (val: Date | null) => {
              if (!val) return true
              const minDateSOD = startOfDay(minDate)
              return (
                isAfter(val, minDateSOD) ||
                isEqual(val, minDateSOD) ||
                t('form_invalid_field_min_date', {
                  field: errorFieldName || label,
                  min: format(minDate, inputFormat || 'MM/dd/yyyy'),
                })
              )
            },
          }
        : null),
      isValidDateOrNull: (val: Date | null) =>
        isValidDateOrNull(val) || t('form_invalidDate'),
    }
  }, [validateProp, maxDate, minDate, inputFormat, errorFieldName, label, t])

  return (
    <Controller
      name={name}
      rules={{
        ...registerProps,
        // show generic validation error if date is not valid
        validate,
        required,
      }}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { error },
      }) => {
        const errorOrHelper = error
          ? getErrorMessage(error, t, {
              field: errorFieldName || String(label),
              ...registerProps,
            })
          : helperText

        return (
          <BaseDatePicker
            {...fieldProps}
            onChange={onChange}
            value={value || null}
            label={!hideLabel && label}
            inputRef={ref}
            maxDate={maxDate}
            minDate={minDate}
            format={inputFormat}
            textFieldProps={{
              ...field,
              ...textFieldProps,
              helperText: errorOrHelper,
              InputLabelProps: {
                required: !!required,
                ...textFieldProps?.InputLabelProps,
              },
              error: !!error,
              variant: fieldReadOnly || formReadOnly ? 'standard' : undefined,
            }}
            readOnly={fieldReadOnly || formReadOnly}
          />
        )
      }}
    />
  )
}
