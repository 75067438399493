import { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'
import { Theme } from '../../../theme'

export type WorkflowSidebarContentProps = {
  sx?: SxProps<Theme>
  children: ReactNode | ReactNode[]
}
export function WorkflowSidebarContent({
  sx,
  children,
}: WorkflowSidebarContentProps) {
  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
        p: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
