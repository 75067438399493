import * as types from './actionTypes'
import * as planTemplateAPI from '../../api/planTemplateAPI'
import { callApi } from './utilities'
import { getPlans } from '../../components/utilities/planTemplate'
import { addUserMessage } from './globalActions'
import dStrings from '../../strings.json'
import { isNewTemplate } from '../../components/utilities'

export function changeView(view) {
  return { type: types.CHANGE_VIEW, view }
}

export function changeTemplateMenu(subMenu) {
  return { type: types.CHANGE_SUB_MENU, subMenu }
}

export function selectPlanTemplate(selectedId) {
  return { type: types.PLAN_TEMPLATE_SELECTED, selectedId }
}

export function unselectPlanTemplate() {
  return { type: types.PLAN_TEMPLATE_UNSELECTED }
}

export function trackDeletedSuccessfully(trackId) {
  return { type: types.DELETE_TRACK_SUCCESS, trackId }
}

export function tracksUpdatedSuccessfully(tracks) {
  return { type: types.UPDATE_TRACK_SUCCESS, tracks }
}

export function trackCreatedSuccessfully() {
  return { type: types.CREATE_TRACK_SUCCESS }
}

export function planTemplateSuccessfullyStarted() {
  return { type: types.PLAN_TEMPLATE_STARTED_SUCCESS }
}

export function planTemplateSuccessfullyStoped() {
  return { type: types.PLAN_TEMPLATE_STOPED_SUCCESS }
}

export function planTemplateDeletedSuccessfully() {
  return { type: types.DELETE_PLAN_TEMPLATE_SUCCESS }
}

export function planTemplateCreatedSuccessfully(plan) {
  return { type: types.CREATE_PLAN_TEMPLATE_SUCCESS, plan }
}

export function planTemplateUpdatedSuccessfully(plan) {
  return { type: types.UPDATE_PLAN_TEMPLATE_SUCCESS, plan }
}

export function planTemplateTimelineLoadedSuccessfully(templates) {
  return { type: types.LOAD_PLAN_TEMPLATES_TIMELINE_SUCCESS, templates }
}

export function searchTemplate(text) {
  return { type: types.PLAN_TEMPLATE_SEARCH, text }
}

export function editPlan(templateId) {
  return { type: types.EDIT_PLAN_TEMPLATE, templateId }
}

export function cancelEditPlan(planId) {
  if (!isNewTemplate(planId)) return { type: types.CANCEL_EDIT_PLAN_TEMPLATE }

  return { type: types.DELETE_PLAN_TEMPLATE_SUCCESS }
}

export function addPlanTemplate(planType) {
  return { type: types.ADD_PLAN_TEMPLATE, planType }
}

export function getCountSuccessfully(count) {
  return { type: types.GET_PLAN_COUNT_SUCCESS, count }
}

export function updatePlanTemplateDetails(planDetails) {
  if (!~planDetails.id.indexOf('new_planTemplate_'))
    return updatePlanTemplate(planDetails)
  return createPlanTemplate({ ...planDetails, id: undefined })
}

export function updatePlanTemplateTrack(tracks) {
  if (tracks[0].id && !~tracks[0].id.indexOf('newID_'))
    return updateTracks(tracks)

  return createTrack([{ ...tracks[0] }])
}

export function editTrack(trackId) {
  return function (dispatch) {
    dispatch({ type: types.EDIT_TRACK, trackId })
  }
}

export function cancelEditTrack(trackId) {
  if (trackId && !~trackId.indexOf('newID_'))
    return { type: types.CANCEL_EDIT_TRACK, trackId }
  return { type: types.DELETE_TRACK_SUCCESS, trackId }
}

export function addNewTrack() {
  return { type: types.ADD_TRACK }
}

export function deleteItem(position, trackId) {
  return { type: types.DELETE_ITEM, position, trackId }
}

export function addEditItem(item, trackId) {
  return { type: types.ADD_UPDATE_ITEM, item, trackId }
}

export function setSchedule(scheduleData, trackId) {
  return { type: types.SET_TRACK_SCHEDULE, scheduleData, trackId }
}

export function setPathName(pathId, name) {
  return { type: types.SET_PATH_NAME, pathId, name }
}

export function loadPlanTemplate() {
  return function (dispatch, getState) {
    return callApi({
      name: 'loadPlanTemplate',
      dispatch,
      call: () => {
        return planTemplateAPI.getPlan(getState().planTemplates.selectedId)
      },
      success: (plan) => {
        dispatch({
          type: types.LOAD_PLAN_BY_ID_SUCCESS,
          plan,
        })
      },
    })
  }
}

export function loadPlanTemplates() {
  return function (dispatch) {
    return callApi({
      name: 'loadPlanTemplates',
      dispatch,
      call: () => {
        return planTemplateAPI.getPlans()
      },
      success: (plans) => {
        dispatch({
          type: types.LOAD_PLAN_TEMPLATE_SUCCESS,
          plans: plans.sort((a, b) => {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          }),
        })
      },
    })
  }
}

export function createPlanTemplate(plan) {
  return function (dispatch) {
    return callApi({
      name: 'createPlanTemplate',
      dispatch,
      call: () => {
        return planTemplateAPI.createPlan(plan)
      },
      success: (newPlan) => {
        dispatch(planTemplateCreatedSuccessfully(newPlan))
        dispatch(addUserMessage('success', dStrings.planCreated))
      },
    })
  }
}

export function updatePlanTemplate(_plan) {
  return function (dispatch) {
    return callApi({
      name: 'updatePlanTemplate',
      dispatch,
      call: () => {
        return planTemplateAPI.updatePlan(_plan)
      },
      success: (plan) => {
        dispatch({ type: types.UPDATE_PLAN_TEMPLATE_SUCCESS, plan })
        dispatch(addUserMessage('success', dStrings.planUpdated))
      },
    })
  }
}

export function deleteTracks() {
  return function (dispatch, getState) {
    return callApi({
      name: 'deleteTracks',
      dispatch,
      call: () => {
        return planTemplateAPI.deleteTracks(getPlans(getState()).selectedId)
      },
      success: () => {
        dispatch(planTemplateDeletedSuccessfully())
        dispatch(addUserMessage('success', dStrings.planDeleted))
      },
    })
  }
}

export function startPlanTemplate() {
  return function (dispatch, getState) {
    return callApi({
      name: 'startPlanTemplate',
      dispatch,
      call: () => {
        return planTemplateAPI.startPlan(getPlans(getState()).selectedId)
      },
      success: () => {
        dispatch(planTemplateSuccessfullyStarted())
        dispatch(addUserMessage('success', dStrings.planStarted))
      },
    })
  }
}

export function stopPlanTemplate() {
  return function (dispatch, getState) {
    return callApi({
      name: 'stopPlanTemplate',
      dispatch,
      call: () => {
        return planTemplateAPI.stopPlan(getPlans(getState()).selectedId)
      },
      success: () => {
        dispatch(planTemplateSuccessfullyStoped())
        dispatch(addUserMessage('success', dStrings.planStopped))
      },
    })
  }
}

export function getPlanTemplateTimeline() {
  return function (dispatch, getState) {
    return callApi({
      name: 'getPlanTemplateTimeline',
      dispatch,
      call: () => {
        return planTemplateAPI.getPlanTimeline(getPlans(getState()).selectedId)
      },
      success: () => {
        dispatch(planTemplateTimelineLoadedSuccessfully())
      },
    })
  }
}

export function createTrack(track) {
  return function (dispatch, getState) {
    const plans = getPlans(getState())
    const newTrackId = track[0].id
    return callApi({
      name: 'createTrack',
      dispatch,
      call: () => {
        return planTemplateAPI.createTrack(plans.selectedId, [
          { ...track[0], id: undefined },
        ])
      },
      success: (updatedPlan) => {
        // search for new track in old tracks. We should to this
        // in olds tracks in order to not remove new_ID(s).
        const oldTracks = plans.byId[plans.selectedId].tracks
        const allIds = [
          ...oldTracks.multipleItemsIds,
          ...oldTracks.singleItemsIds,
        ]

        const newTrack = updatedPlan.tracks.find(
          (trck) => allIds.indexOf(trck.id) === -1,
        )
        let tracksList

        if (track[0].isSingleItem) {
          tracksList = [
            ...Object.keys(oldTracks.byId).map((id) => oldTracks.byId[id]),
            newTrack,
          ]
        } else {
          tracksList = Object.keys(oldTracks.byId).map((id) => {
            if (id !== newTrackId) return oldTracks.byId[id]
            return newTrack
          })
        }

        dispatch(tracksUpdatedSuccessfully(tracksList))
        dispatch(addUserMessage('success', dStrings.trackCreated))
      },
    })
  }
}

export function updateTracks(tracks) {
  return function (dispatch, getState) {
    const plans = getPlans(getState())
    return callApi({
      name: 'updateTracks',
      dispatch,
      call: () => {
        return planTemplateAPI.updateTracks(plans.selectedId, tracks)
      },
      success: (updatedPlan) => {
        const oldTracks = plans.byId[plans.selectedId].tracks
        const newTracks = Object.keys(oldTracks.byId).map((id) => {
          if (id !== tracks[0].id) return oldTracks.byId[id]
          return updatedPlan.tracks.find((track) => track.id === tracks[0].id)
        })

        dispatch(tracksUpdatedSuccessfully(newTracks))
        dispatch(addUserMessage('success', dStrings.trackUpdated))
      },
    })
  }
}

export function deleteTrack(trackId) {
  return function (dispatch, getState) {
    return callApi({
      name: 'deleteTrack',
      dispatch,
      call: () => {
        return planTemplateAPI.deleteTrack(
          getPlans(getState()).selectedId,
          trackId,
        )
      },
      success: () => {
        dispatch(trackDeletedSuccessfully(trackId))
        dispatch(addUserMessage('success', dStrings.trackDeleted))
      },
    })
  }
}

export function getPlanAssignmentCount(planId) {
  return function (dispatch) {
    return planTemplateAPI.getAssignmentCount(planId).then(({ count }) => {
      dispatch(getCountSuccessfully(count))
    })
  }
}
