import { Typography, Box } from '@mui/material'
import { type File } from '@valerahealth/rtk-query'
import { FileOpenOutlined } from '@mui/icons-material'
import Link from '../Link'
import { useTranslation } from '../../utils/hooks'
import { format } from '../../utils/date'

export type CommentProps = {
  author?: string | null
  date?: Date | null
  text: string
  attachments?: File[] | null
}
/** a comment made by someone at a certain time */
export default function Comment({
  text,
  date,
  author,
  attachments,
}: CommentProps) {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography variant="body2">{text}</Typography>
      {attachments?.map((a) => (
        <Link
          key={a.url}
          to={a.url}
          target="_blank"
          underline="hover"
          color="text.primary"
          sx={{ display: 'flex', alignItems: 'middle', gap: 2, my: 0.75 }}
        >
          <FileOpenOutlined color="primary" />
          <Typography variant="body2">{a.name}</Typography>
        </Link>
      ))}
      <Typography variant="caption" color="text.secondary">
        {author && t('CommentAuthor', { author })}{' '}
        {date && format(date, 'MMMM d, yyyy | h:mmaa')}
      </Typography>
    </Box>
  )
}
