import { Permission } from '@valerahealth/redux-auth'

import {
  createService,
  initApplyPermissionheaders,
  type EndpointPermissions,
} from '../../utils'
import { getBaseApi } from './baseApi'
import enhanceEndpoints from './enhanceApi'

const endpointPermissions: EndpointPermissions = {
  getProviderByCareManagerId: [
    Permission.Provider_Read,
    Permission.ProviderEmployment_Read,
  ],
  getProviders: Permission.Provider_Read,
  getProvider: [Permission.Provider_Read, Permission.ProviderEmployment_Read],
  updateProvider: Permission.Provider_Update,
  // licenses
  getProviderLicenseRequests: Permission.ProviderLicenseRequest_Read,
  appendProviderLicenses: Permission.Provider_Update,
  updateProviderLicenses: Permission.Provider_Update,
  removeProviderLicenses: Permission.Provider_Update,
  addProviderLicenseRequests: Permission.ProviderLicenseRequest_Create,
  updateProviderLicenseRequests: Permission.ProviderLicenseRequest_Update,
  removeProviderLicenseRequests: Permission.ProviderLicenseRequest_Delete,
  // insurance
  getInsurancePlans: Permission.InsurancePlan_Read,
  getInsurancePlansWithDetails: Permission.InsurancePlan_Read,
  getOutOfNetworkInsurancePlans: Permission.InsurancePlan_Read,
  getActiveInsurancePlans: Permission.InsurancePlan_Read,
  appendInsurancePlanParticipants: Permission.InsurancePlanParticipant_Create,
  removeInsurancePlanParticipants: Permission.InsurancePlanParticipant_Delete,
  updateInsurancePlanParticipant: Permission.InsurancePlanParticipant_Update,
  getInheritingProviders: Permission.InsurancePlanParticipant_Read,
  createInsurancePlan: Permission.InsurancePlan_Create,
  updateInsurancePlan: Permission.InsurancePlan_Update,
  deleteInsurancePlan: Permission.InsurancePlan_Delete,
}

const practiceMgrApiService = createService(({ origin, getAccessToken }) => {
  const applyPermissionHeaders = initApplyPermissionheaders(endpointPermissions)
  const api = getBaseApi(origin, getAccessToken, applyPermissionHeaders)
  return enhanceEndpoints(api)
})

export const practiceMgrApi = practiceMgrApiService({
  origin: `https://${process.env.PRACTICE_MANAGER_API_DOMAIN}`,
})
export type PracticeMgrApiType = typeof practiceMgrApi
