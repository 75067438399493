import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { connect } from 'react-redux'
import ProfilePicture from '../Picture'
import dStrings from '../../../strings.json'
import { getProfileName } from '../../utilities/treatments'
import { loadProgramProviders } from '../../../redux/actions/programActions'

function PCTMSelect({
  pctmId,
  pctmMissing,
  styles,
  careTeam,
  handleChange,
  loadProgramProviders,
}) {
  const getCareTeamList = () => {
    if (!careTeam || !careTeam.allIds) return []

    return careTeam.allIds.map((id) => {
      const careMember = careTeam.byId[id]
      return {
        label: getProfileName(careMember),
        value: careMember.id,
        picture: careMember.picture,
      }
    })
  }

  const [careTeamList, setCareTeamList] = useState(getCareTeamList)
  const _height = '36px'
  const { Option } = components
  const pctmStyle = {
    ...styles,
    control: (provided, state) => ({
      ...provided,
      borderColor: !pctmMissing ? '#dadcdf !important' : '#D24667',
      borderRadius: '5px',
      minHeight: _height,
      height: _height,
      boxShadow: state.isFocused ? null : null,
      width: '500px',
    }),
    option: (provided, state) => {
      return {
        ...provided,
        '&:hover': {
          backgroundColor: '#f9feff',
        },
        backgroundColor:
          state.data.value !== pctmId ? 'inherit' : '#E7EBED !important',
        cursor: 'pointer',
      }
    },
  }

  useEffect(() => {
    if (!careTeam) loadProgramProviders()
    setCareTeamList(getCareTeamList)
  }, [careTeam])

  function PCTMOptions(props) {
    return (
      <Option {...props}>
        <div className="cm-options-container">
          <div className="cm-option-picture">
            <ProfilePicture treatment={{ profile: props.data }} />
          </div>
          <div className="plan-options-title">{props.data.label}</div>
        </div>
      </Option>
    )
  }

  return (
    <div>
      <Select
        // menuIsOpen={true}
        menuPlacement="top"
        styles={pctmStyle}
        components={{ Option: PCTMOptions }}
        onChange={(t) => {
          handleChange(t.value)
        }}
        placeholder={`<${dStrings.choosePCTM}>`}
        options={careTeamList}
        value={
          pctmId && {
            value: pctmId,
            label: (careTeam && getProfileName(careTeam.byId[pctmId])) || '',
          }
        }
      />
    </div>
  )
}

PCTMSelect.propTypes = {}

function mapStateToProps(state, ownProps) {
  return {
    careTeam: state.program.careTeam,
    pctmId: ownProps.pctmId,
    styles: ownProps.styles,
    handleChange: ownProps.handleChange,
  }
}

const mapDispatchToProps = {
  loadProgramProviders,
}

export default connect(mapStateToProps, mapDispatchToProps)(PCTMSelect)
