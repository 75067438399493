import { TFunction } from '@valerahealth/ui-translation'
import { processError } from '@valerahealth/rtk-query'

type ErrorType = Parameters<typeof processError>[0]['error']

export function translateApiError(
  t: TFunction,
  error: ErrorType,
  fallback?: string,
) {
  const message = processError({ error })
  return (
    typeof message === 'string'
      ? message
      : typeof message === 'number'
      ? t(`networkError.${message}`)
      : fallback || t('networkError.unknown')
  ) as string
}
