import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { GridInitialState, GridLogicOperator } from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import {
  PersistentGridView,
  RootStateWithPersistentGrid,
  selectPersistentGridViewSettings,
} from './reduxSlice'

const mergeState = (
  viewState?: GridInitialStatePro | null,
  /*takes precedence in construction of state*/
  paramState?: GridInitialStatePro | null,
): GridInitialStatePro => {
  return {
    ...viewState,
    ...paramState,
    filter: {
      // merges filter modal, with a precedence on paramState if filter targes same column
      filterModel: {
        items: [
          ...(paramState?.filter?.filterModel?.items || []),
          ...(viewState?.filter?.filterModel?.items || []),
        ].filter(
          ({ field }, index, array) =>
            index === array.findIndex((i) => i.field === field),
        ),
        logicOperator: paramState?.filter?.filterModel?.logicOperator ||
        viewState?.filter?.filterModel?.logicOperator ||
        GridLogicOperator.And,
      },
    },
  };
}

export enum GridViewURLParamsKey {
  /** deprecated but leaving until deployed up to caremanager/practice manager */
  PresetView = 'presetView',
  View = 'view',
  InitialState = 'initialState',
}
export function useGridViewParams() {
  const [params, setSearchParams] = useSearchParams()
  const selectedView: string | null =
    params.get(GridViewURLParamsKey.View) ||
    params.get(GridViewURLParamsKey.PresetView)

  const selectView = useCallback(
    (view?: string | null) => {
      setSearchParams(view ? { [GridViewURLParamsKey.View]: view } : {})
    },
    [setSearchParams],
  )

  return {
    selectedView,
    selectView,
    params,
    setSearchParams,
  }
}

export const usePersistentGridInitialState = (
  gridName: string,
  initialGridState: GridInitialStatePro | undefined,
  presetGridViews?: PersistentGridView | undefined,
) => {
  const { defaultView, views } = useSelector(
    (state: RootStateWithPersistentGrid) =>
      selectPersistentGridViewSettings(state, gridName),
  )
  const { params, selectedView, selectView } = useGridViewParams()

  return useMemo(() => {
    let initialStateParam: GridInitialStatePro | null = null

    try {
      const param = params.get(GridViewURLParamsKey.InitialState)
      initialStateParam = param ? (JSON.parse(param) as GridInitialState) : null
    } catch (e) {
      console.warn(
        'an invalid initial state was provided and could not be json deserialized',
        params.get(GridViewURLParamsKey.InitialState),
      )
    }

    const initialViewName = selectedView || defaultView

    const view = initialViewName
      ? { ...views, ...presetGridViews }[initialViewName]
      : null

    // if no view is selected and no intial state from url params then use grid configured default state
    if (!view && !initialStateParam) return initialGridState

    if (
      // if not intitialState and no view is already populated in the url param, and we have a default view, update the url with the default view
      !initialStateParam &&
      !selectedView &&
      defaultView
    ) {
      // for some reason trying to change url parmas in the initial render cycle doesn't work, so setting a timeout.
      setTimeout(() => {
        selectView(defaultView)
      })
    }

    // if only initial state param passed in, merge with grid default state
    if (!selectedView && initialStateParam)
      return mergeState(initialGridState, initialStateParam)

    // else return the merger of the two
    return mergeState(view?.gridState, initialStateParam)
    // only want to check this on first render
    // eslint-disable-next-line
  }, [])
}
