/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { taskApi } from '@valerahealth/rtk-query'
import { isPast, strToDate } from '../../../utils/date'
import { Badge, Box } from '../../../base'

export const TaskNotification = ({ userId }: { userId: string }) => {
  const { data, isSuccess } = taskApi.useGetOutstandingTasksQuery(
    {assigneeId: userId},
    { pollingInterval: process.env.IS_LOCAL ? undefined : 60000 },
  )

  const tasks = data?.searchTasks?.tasks
  const numOverdue =
    tasks?.filter((t) => {
      const date = strToDate(t.dueDate, true)
      if (!date) return false
      if (isPast(date)) return true
      return false
    }).length || 0
  const numTodo = (tasks?.length || 0) - numOverdue
  return isSuccess ? (
    <>
      <Box display="flex" height="80%">
        {numOverdue !== 0 && (
          <Badge badgeContent={numOverdue} color="error">
            <Box width="10px" height="10px" />
          </Badge>
        )}
        {numTodo !== 0 && (
          <Badge badgeContent={numTodo} color="warning">
            <Box width="20px" height="10px" />
          </Badge>
        )}
      </Box>
    </>
  ) : null
}
