import { createSelector } from '@reduxjs/toolkit'
import { careManagerApi, configApi } from '@valerahealth/rtk-query'
import { type RootState } from './store'

export const programIdSelector = (state: RootState) =>
  state.auth.session?.user.programId || ''

export const programSettingsSelector = createSelector(
  programIdSelector,
  (state: RootState) => state,
  (id, state) => careManagerApi.endpoints.getProgramSettings.select(id)(state),
)

export const usrSettingsSelector =
  careManagerApi.endpoints.getUserSettings.select()

export const configApiProgramSettingsSelector =
  configApi.endpoints.getProgramSettings.select()

export const configProgramSettingsSelector = (state: RootState) => ({
  data: configApiProgramSettingsSelector(state).data?.getProgramSettings,
  isError: configApiProgramSettingsSelector(state).isError,
  isSuccess: configApiProgramSettingsSelector(state).isSuccess,
})

export const isWellnessSelector = createSelector(
  configProgramSettingsSelector,
  (settings) => !!settings?.data?.flags?.isWellness,
)

const CARE_MANAGER_MAIN_NAV_DEFAULT = {
  calendarHidden: false,
  caseloadHidden: false,
  caseloadLeadsHidden: true,
  patientsHidden: false,
  tasksHidden: true,
  tracksHidden: false,
  supervisionHidden: false,
  myPracticeHidden: false,
}

export const mainNavigationLayoutSelector = createSelector(
  configProgramSettingsSelector,
  ({ data, isError, isSuccess }) => {
    const calendar = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'calendar',
    )
    const caseload = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'caseload',
    )
    const caseloadLeads = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'caseload-leads',
    )
    const patients = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'patients',
    )
    const tasks = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'tasks',
    )
    const tracks = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'tracks',
    )
    const supervision = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'supervision',
    )
    const myPractice = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'my-practice',
    )

    if (isError) return CARE_MANAGER_MAIN_NAV_DEFAULT
    if (!isSuccess) {
      return {
        calendarHidden: true,
        caseloadHidden: true,
        caseloadLeadsHidden: true,
        patientsHidden: true,
        tasksHidden: true,
        tracksHidden: true,
        supervisionHidden: true,
        myPracticeHidden: true,
      }
    }

    return {
      calendarHidden: calendar ? calendar.hidden : CARE_MANAGER_MAIN_NAV_DEFAULT.calendarHidden,
      caseloadHidden: caseload ? caseload.hidden : CARE_MANAGER_MAIN_NAV_DEFAULT.caseloadHidden,
      caseloadLeadsHidden: caseloadLeads
        ? caseloadLeads.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.caseloadLeadsHidden,
      patientsHidden: patients ? patients.hidden : CARE_MANAGER_MAIN_NAV_DEFAULT.patientsHidden,
      tasksHidden: tasks ? tasks.hidden : CARE_MANAGER_MAIN_NAV_DEFAULT.tasksHidden,
      tracksHidden: tracks ? tracks.hidden : CARE_MANAGER_MAIN_NAV_DEFAULT.tracksHidden,
      supervisionHidden: supervision
        ? supervision.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.supervisionHidden,
      myPracticeHidden: myPractice
        ? myPractice.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.myPracticeHidden,
    }
  },
)
