import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form as FormComponent } from '@valerahealth/ui-core'
import dStrings from '../../../strings.json'

function ClinicalIndicatorFilter({ indicator, name, handleChange }) {
  const [open, setOpen] = useState(false)

  return (
    <div className="clinical-indicator-filter">
      <div
        className={`clinical-indicator-header ${open ? 'opened' : 'closed'}`}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <span className={`arrow-${open ? 'down' : 'right'}`} />
        {name}
      </div>
      {open && (
        <div className="clinical-indicator-body">
          <FormComponent
            data={indicator}
            handleDataChange={handleChange}
            fields={[
              {
                key: 'scoreChange',
                text: dStrings.status,
                type: 'select',
                emptyOption: dStrings.status,
                select: {
                  improvement: dStrings.improvement,
                  deterioration: dStrings.deterioration,
                  no_change: dStrings.noChange,
                },
              },
              {
                key: 'score',
                text: dStrings.score,
                type: 'select',
                emptyOption: dStrings.condition,
                select: {
                  greater_than: dStrings.greater_than,
                  greater_than_or_equal: dStrings.greater_than_or_equal,
                  less_than: dStrings.less_than,
                  less_than_or_equal: dStrings.less_than_or_equal,
                  equal: dStrings.equal,
                  not_equal: dStrings.not_equal,
                  between: dStrings.between,
                  not_between: dStrings.not_between,
                },
              },
              {
                key: 'value1',
                text: '',
                type: 'number',
              },
              {
                key: 'value2',
                text: '',
                type: 'number',
                getVisible: (data) => {
                  return ~['between', 'not_between'].indexOf(data.score)
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  )
}

ClinicalIndicatorFilter.propTypes = {
  indicator: PropTypes.object,
  name: PropTypes.string,
  handleChange: PropTypes.func,
}

export default ClinicalIndicatorFilter
