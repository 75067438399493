/*
Reference docs:
query - https://opensearch.org/docs/latest/query-dsl/compound/bool/
sort - https://opensearch.org/docs/latest/search-plugins/searching-data/sort/
*/
import { type QueryDslBoolQuery } from '@opensearch-project/opensearch/api/types'
import { TaskStatus } from '../../shared/generated.types'

interface SearchTasksQueryforAssignee {
  must: [{ assigneeId: string }, { status: TaskStatus.ToDo }]
}
export const searchTasksQueryforAssignee = (
  assigneeId: string,
): QueryDslBoolQuery => {
  return {
    must: [
      {
        term: { 'assigneeId.keyword': assigneeId },
      },
      {
        term: { 'status.keyword': TaskStatus.ToDo },
      },
    ],
  }
}
export const isQueryforAssigneeTodo = (
  obj: any,
): obj is SearchTasksQueryforAssignee => {
  if ('must' in obj && Array.isArray(obj.must) && obj.must.length === 2) {
    const array = obj.must
    if (!('match' in array[0] && 'assigneeId' in array[0].match)) return false
    if (!('match' in array[1] && 'status' in array[1].match)) return false
    return true
  }
  return false
}

export enum SearchTasksQueryType {
  assigneeTodo = 'assignee_todo',
  other = 'other',
}
export const getQueryType = (obj: any): SearchTasksQueryType =>
  isQueryforAssigneeTodo(obj)
    ? SearchTasksQueryType.assigneeTodo
    : SearchTasksQueryType.other
