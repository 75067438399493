// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.covering-banner {
  display: flex;
  align-items: center;
  border: 1px solid #dadcdf;
  border-radius: 5px;
  justify-content: space-between;
  padding: 4px;
}
.covering-banner > div {
  padding: 0 8px;
}
.banner-state {
  padding: 5px 8px;
  border-radius: 12px;
}
.banner-state.green {
  background-color: rgba(57, 198, 150, 0.2);
  color: #317b8c;
}
.banner-state.orange {
  background-color: rgba(255, 178, 0, 0.2);
}

.banner-dates {
  display: flex;
}
.banner-dates svg {
  margin: 0 22px;
}

.banner-covering {
  display: flex;
  align-items: center;
}

.banner-covering .profile-picture-container img {
  height: 32px;
  width: 32px;
  border-radius: 32px;
}
.covering-name {
  margin-left: 12px;
  white-space: nowrap;
}
.banner-buttons svg {
  visibility: hidden;
}
.covering-banner:hover .banner-buttons svg {
  visibility: visible;
  cursor: pointer;
}
.no-covering-user {
  color: rgba(84, 88, 93, 0.6);
}
.banner-date {
  white-space: nowrap;
}

.banner-dates svg {
  margin: 0 4px !important;
}

.banner-state,
.banner-dates,
.banner-covering {
  margin: 8px;
}
.no-ooo-dates {
  opacity: 0.6;
  margin-left: -20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/OutOfOffice/OOOBanner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,8BAA8B;EAC9B,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,yCAAyC;EACzC,cAAc;AAChB;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;;EAGE,WAAW;AACb;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".covering-banner {\n  display: flex;\n  align-items: center;\n  border: 1px solid #dadcdf;\n  border-radius: 5px;\n  justify-content: space-between;\n  padding: 4px;\n}\n.covering-banner > div {\n  padding: 0 8px;\n}\n.banner-state {\n  padding: 5px 8px;\n  border-radius: 12px;\n}\n.banner-state.green {\n  background-color: rgba(57, 198, 150, 0.2);\n  color: #317b8c;\n}\n.banner-state.orange {\n  background-color: rgba(255, 178, 0, 0.2);\n}\n\n.banner-dates {\n  display: flex;\n}\n.banner-dates svg {\n  margin: 0 22px;\n}\n\n.banner-covering {\n  display: flex;\n  align-items: center;\n}\n\n.banner-covering .profile-picture-container img {\n  height: 32px;\n  width: 32px;\n  border-radius: 32px;\n}\n.covering-name {\n  margin-left: 12px;\n  white-space: nowrap;\n}\n.banner-buttons svg {\n  visibility: hidden;\n}\n.covering-banner:hover .banner-buttons svg {\n  visibility: visible;\n  cursor: pointer;\n}\n.no-covering-user {\n  color: rgba(84, 88, 93, 0.6);\n}\n.banner-date {\n  white-space: nowrap;\n}\n\n.banner-dates svg {\n  margin: 0 4px !important;\n}\n\n.banner-state,\n.banner-dates,\n.banner-covering {\n  margin: 8px;\n}\n.no-ooo-dates {\n  opacity: 0.6;\n  margin-left: -20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
